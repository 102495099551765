import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatDateRol'
})
export class FormatDateRolPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (value==null || value == undefined) {
      return "";
    }
    let date;

    switch(args) {
    case "DD/MMM/YY": {
      date = moment(value, "DD/MM/YY").format('DD/MMM/YY').toUpperCase();
      break;
    }
    case "ddd": {
      date = moment(value, "DD/MM/YY").format('ddd').toUpperCase();
        break;
    }
    case "MMMM": {
      date = moment(value, "DD/MM/YY").format('MMMM').toUpperCase();
      break;
    }
    case "DD": {
      date = moment(value, "DD/MM/YY").format('DD').toUpperCase();
      break;
    }
    case "MMMM DD ddd": {
      date = moment(value, "DD/MM/YY").format('MMMM DD ddd').toUpperCase();
      break;
      }
    case "HH:mm": {      
      date = moment(value, "DD/MM/YY HH:mm").format('HH:mm').toUpperCase();
      break;
      }
    case "DD/MM/YYYY": {
      date = moment(value, "YYYY/MM/DD").format('DD/MM/YYYY').toUpperCase();
      break;
    }
   }
    return date;
  }

}
