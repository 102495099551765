<div class="container-login" [ngStyle]="{'height': heightWindows +'px'}">
    <img *ngIf="!isTestEnvironment" class="login-logo-pilot" src="assets/img/login/logo-pilot.svg" alt="login-logo-pilot" srcset="">
    <img *ngIf="isTestEnvironment" class="login-logo-pilot" width="150" src="assets/img/login/logo-pilot-beta.svg" alt="login-logo-pilot" srcset="">
    <img class="cloud-one" src="assets/img/login/cloud-one.svg" alt="cloud-one" srcset="">
    <img class="cloud-two" src="assets/img/login/cloud-two.svg" alt="cloud-two" srcset="">
    <div class="">
        <div class="pt-login">
            <div class="container-plane">
                <input type="image" class="plane-img" src="assets/img/login/login-bg.svg"
                    alt="Logo Pilot" />
            </div>
            <h1 class="title-welcome">Welcome!</h1>
            <p class="text-welcome">
                With Pilot you will be able to quickly access the information you need for your flights,
                thanks to its friendly and easy-to-use interface
            </p>
            <div class="content-get-started">
                <ng-container *ngIf="isWeb">
                    <button id="googleBtn" type="button" class="btn-pilot btn-login">
                        <svg class="logo-google" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                            <path d="M0.5 11.0049C0.5 4.93948 5.4346 0.00488281 11.5 0.00488281C13.9496 0.00488281 16.2682 0.793007 18.2052 2.28408L15.649 5.60462C14.4514 4.68271 13.0166 4.19536 11.5 4.19536C7.74523 4.19536 4.69048 7.25011 4.69048 11.0049C4.69048 14.7597 7.74523 17.8144 11.5 17.8144C14.5242 17.8144 17.0941 15.833 17.9797 13.1001H11.5V8.90965H22.5V11.0049C22.5 17.0703 17.5654 22.0049 11.5 22.0049C5.4346 22.0049 0.5 17.0703 0.5 11.0049Z" fill="white"/>
                        </svg>
                        Log in with Google
                    </button>
                </ng-container>
                <ng-container *ngIf="!isWeb">
                    <button (click)="logingGoogle()" type="button" class="btn-pilot btn-login">
                        <svg class="logo-google" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                            <path d="M0.5 11.0049C0.5 4.93948 5.4346 0.00488281 11.5 0.00488281C13.9496 0.00488281 16.2682 0.793007 18.2052 2.28408L15.649 5.60462C14.4514 4.68271 13.0166 4.19536 11.5 4.19536C7.74523 4.19536 4.69048 7.25011 4.69048 11.0049C4.69048 14.7597 7.74523 17.8144 11.5 17.8144C14.5242 17.8144 17.0941 15.833 17.9797 13.1001H11.5V8.90965H22.5V11.0049C22.5 17.0703 17.5654 22.0049 11.5 22.0049C5.4346 22.0049 0.5 17.0703 0.5 11.0049Z" fill="white"/>
                        </svg>
                        Log in with Google
                    </button>
                </ng-container>
                <ng-container *ngIf="isSupported && (isTestEnvironment || allowApp)">
                    <div class="btn-qr-content">
                        <button id="present-alert" type="button" class="btn-pilot btn-qr">
                            <i class="material-icons"> qr_code </i>
                            Scan QR Code
                        </button>
                    </div>
                    <ion-alert
                        trigger="present-alert"
                        header="Select an option"
                        message="Please select the camera you want to use."
                        [buttons]="alertButtons"
                        ></ion-alert>
                </ng-container>
            </div>
            <span (click)="toCreateAccount()" target="_blank" class="create-account">How to create an account?</span>
            <div>
                <p class="version">{{version}}</p>
            </div>
        </div>
    </div>
    <img class="bottom-img" src="assets/img/login/bottom.svg" alt="" srcset="">

</div>
