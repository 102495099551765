import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IndexeddbService } from '../../../services/indexeddb.service';
import { CommonsService } from '../../../../../core/services/commons/commons.service';
import { CrewBriefingService } from '../../../services/crew-briefing.service';
import * as moment from 'moment';
import { NetworkService } from 'src/app/modules/pilot/services/network/network.service';
import { CrewAssignmentRequest } from 'src/app/modules/pilot/domain/crew-assignment-request';
import { RolService } from 'src/app/modules/pilot/services/rol.service';
import { ProfileService } from 'src/app/modules/pilot/services/profile.service';
import { IUser } from 'src/app/modules/pilot/domain/user/user';
import { TokenApi } from 'src/app/modules/pilot/domain/tokenApi';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Activity } from 'src/app/modules/pilot/domain/roster/activity.model';


@Component({
  selector: 'flight-information',
  templateUrl: './flight-information.component.html',
  styleUrls: ['./flight-information.component.css']
})
export class FlightInformationComponent implements OnInit {

  @Input("flight") flight: Activity;
  @Input('user') user: IUser;
  @Input('origin') origin: string;

  loadingCrewBriefing: boolean;
  flightCrewAssignment: any = [];
  isConnected: boolean;
  hasRPT: boolean = false;
  loadCrew: boolean;
  paxCnt: number = 0;
  dataCrewBriefing: { [k: string]: any } = {};
  isFromBrasil: boolean = false;
  tokenApi: TokenApi = new TokenApi();
  crewEmails: any[] = [];
  isTestEnvironment: any;
  spaceExist: boolean;
  loadinCheckChatSpace: boolean = false;
  private flightCrewRequest: CrewAssignmentRequest;
  @Output() back = new EventEmitter();
  allowsGroupChatSpaces = false;

  constructor(
    protected indexeddbService: IndexeddbService,
    private commonsService: CommonsService,
    private crewBriefingService: CrewBriefingService,
    protected _network: NetworkService,
    private rolService: RolService,
    private profileService: ProfileService,
  ) { }

  ngOnInit(): void {
    this.isTestEnvironment = !environment.production;
    this.allowsGroupChatSpaces = this.user?.roles?.includes('CHAT_SPACES');
    this.getFlightInformation();
  }

  getFlightInformation() {
    const valideNetwork = async () => {
      this.isConnected = await this._network.getStatusNetwork();
      this._network.getState().subscribe(state => {
        this.isConnected = state;
        this.searchCrew();
      });
    }
    valideNetwork();
    setTimeout(() => {
      let element = document.getElementsByClassName('mat-drawer-content')[0];
      if (element) {element.scrollTop = 0}
    }, 1000);
    this.commonsService.setGtag("Roster", "FlightInformation");
  }

  private async searchCrew(){
    this.loadCrew = true;
    this.flightCrewRequest = new CrewAssignmentRequest();
    this.flightCrewRequest.flightNumber = this.flight?.flightLeg.flightNumber.toString();
    this.flightCrewRequest.carrier = this.flight?.flightLeg.carrier;
    this.flightCrewRequest.departureDate =  moment(this.flight?.flightLeg.flightDate).format('DD-MM-YYYY');
    this.flightCrewRequest.departure = this.flight?.flightLeg.departure.iataCode;
    this.flightCrewRequest.arrival = this.flight?.flightLeg.arrival.iataCode;
    if (this.flightCrewRequest.carrier == "JJ" || this.flightCrewRequest.carrier == "M3") {
      this.isFromBrasil = true;
    }
    if (this.isConnected) {
      this.rolService.getCrewRosterAssignment(this.flightCrewRequest).subscribe((crewInfo: any[]) => {
        this.loadCrew = false;
        let { emails, crew } = this.filterCrew(crewInfo);
        this.flightCrewAssignment = crew;
        this.crewEmails = emails;
        if (!environment.production) {
          this.getCrewBriefing(this.flight);
        }
      });
    } else {
      this.indexeddbService.getDataFromTable('rol', 'flightsCrew').then(resp => {
        let { carrier, flightDate, flightNumber } = this.flight.flightLeg;
        let flight = resp?.find(r => 
            carrier == r.carrier &&
            flightDate == r.flightDate &&
            flightNumber == r.flightNumber);
        if (flight) this.flightCrewAssignment = this.filterCrew(flight.crew).crew;
        this.loadCrew = false;
      });
    }
  }

  getCrewBriefing(activity: Activity) {
    if (!activity.flightLeg.checkInDates.local) return;
    this.hasRPT = true;
    this.crewBriefingService.getCrewBriefingRules(activity.flightLeg).subscribe({
      next: resp => {
        if (!resp) this.hasRPT = false;
        this.calcTime(moment(activity.flightLeg.departure.dates.local).format('HH:mm'), resp);
      },
      error: err => {
        this.hasRPT = false
        console.log(err);
      }
    });
  }

  calcTime(departureTime, rules) {
    let time = departureTime.split(":");
    const parseTime = moment().set({ 'hour': time[0], 'minute': time[1], 'second': 0 });
    this.dataCrewBriefing = {
      dispatch: moment(parseTime).subtract(rules.presentation, 'm').format('HH:mm'),
      brf: moment(parseTime).subtract(rules.end, 'm').format('HH:mm'),
      timeAtGate: moment(parseTime).subtract(rules.crewBoarding, 'm').format('HH:mm'),
      pBoard: moment(parseTime).subtract(rules.paxBoarding, 'm').format('HH:mm')
    }
    this.loadingCrewBriefing = false;
  }

  filterCrew(list: any[]) {
    let crew = [];
    let emails = [];
    let paxCnt = 0;
    list.forEach(item => {
      if ( (crew.filter(x => { return x.bp === item.bp })).length == 0 ){            
        crew.push(item);
        if(item.isPax) {
          paxCnt +=1;
        } else {
          emails.push(item.email)
        }
      }
    });
    this.paxCnt = paxCnt;
    return { emails, crew };
  }

  async demoChatApi() {
    this.loadinCheckChatSpace=true;
    this.tokenApi.accessToken = this.user.accessToken;
    this.tokenApi.idToken = this.user.idToken;
    this.tokenApi.crewEmail = this.crewEmails;
    this.tokenApi.spaceName = this.flightCrewRequest.carrier + this.flightCrewRequest.flightNumber + " " + this.flightCrewRequest.departure + " " + this.flightCrewRequest.arrival  + " " + this.flight.flightLeg.aircraftRegistration + " " + this.flightCrewRequest.departureDate;
    this.tokenApi.createdBy = this.user.userID;

    await this.profileService.checkChatSpace(this.tokenApi.spaceName).then(
      res => {
        this.spaceExist = res;
      }
    )
    console.log(this.tokenApi);

    if( !this.spaceExist ){
      if(this.tokenApi.accessToken != undefined && this.tokenApi.crewEmail!=undefined && this.tokenApi.idToken!=undefined && this.allowsGroupChatSpaces){
        this.profileService.sendToken(this.tokenApi).subscribe(
          res => {
            console.log("Resp: ", res);
            Swal.fire({
              icon: 'success',
              title: 'Chat Created',
              text: 'Check your Google Chat Spaces'
            }).then(function() {
              window.open('https://mail.google.com/chat/', 'blank');
          });
          }
        )
      } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There is not enough data to create chat'
          });
          this.loadinCheckChatSpace=false;
      }
   } else {
      Swal.fire({
        icon: 'warning',
        title: 'Already created',
        text: `A chat space with this name is already created, check your google chat space`,
        showCancelButton: false,
        confirmButtonText: "OK",
      });
      this.loadinCheckChatSpace=false;
   }
  }

  closeFlightInformation(){
    this.back.emit();
  }
}
