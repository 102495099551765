import { AirlineCode } from "./airline-code.model";
import { FlightDate } from "./flight-date.model";

export class FlightRQ {
    airlinecode: AirlineCode;
    flightNumber: string;
    suffix: string;
    flightDate: FlightDate;
    departureAirport: string;
    arrivalAirport: string;
}
