import { Injectable } from '@angular/core';
import { ChartOptions } from 'chart.js';
import { environment } from 'src/environments/environment';
import { IDelayReasons } from '../domain/flight-report/delay-reasons.interface';

/* Commons */
const BFF_PATH = 'LatamApiPilotBFF/v1/';
const AIRPORTS = 'airports/latam';
const FLEET_API = 'fleets';
const LOGIN_API = 'login/validateUser/pilot';
const LOGIN__QR_API = 'login/qr';
const VERSION = 'app/version';

//APICHAT
const SEND_TOKEN_APICHAT ='/send-token';
const API_CHAT = 'api-chat';
const CHECK_CHAT_SPACE ='/check-chat-space/';

const MY_DOCUMENTS = '/documentation/';
const MY_FLIGHTS = '/my-flights';

/* SAFETY API */
const SAFETY_API = 'safety';
const FLIGHT_LIST = '/flights/';
const FLIGHT_EVENTS = '/flights/events/';

/* SAFETY INDICATORS */

const SAFETY_INDICATORS = 'indicator/';
const SAFETY_INDICATORS_EXTEND = 'indicator-extend/';
const SAFETY_RANKING = 'ranking/event/airports/fleet/';
const SAFETY_TOPUA = 'ranking/triggerua/fleet/';
const SAFETY_UA = 'ua/pilot/';
const SAFETY_LANDING = 'landing/';
const SAFETY_AGA = 'aga/';
const SAFETY_TCAS_PILOT = '/pilot';
const SAFETY_ROLL_EX_FLIGHT_PILOT = 'rollexflight/'
const SAFETY_REJ_TAKE_OFF_PILOT = 'rejtakeoff/'
const SAFETY_ALPHA_FLOOR_PILOT = 'alpha-floor/'
const SAFETY_STICK_SHAKER_PILOT = 'stick-shaker/'
const SAFETY_STALL_WARNING_PILOT = 'stall-warning/'
const SAFETY_NO_IDLE_REVERSE_PILOT = 'no-idle-reverse/'
const SAFETY_SPD_HIGH_MINOR_1000 = 'spd-high-minor-1000/'
const SAFETY_SPOILER_NOT_ARMED = 'spoiler-not-armed/'
const SAFETY_SPD_HIGH_TAXI_OUT = 'spd-high-taxi-out/'
const SAFETY_SPD_HIGH_TAXI_IN = 'spd-high-taxi-in/'
const SAFETY_GPWS_MODE_1 = 'gpws-mode-1/'
const SAFETY_GPWS_MODE_2 = 'gpws-mode-2/'
const SAFETY_GPWS_MODE_3 = 'gpws-mode-3/'
const SAFETY_GPWS_MODE_4 = 'gpws-mode-4/'
const SAFETY_GPWS_MODE_5 = 'gpws-mode-5/'
const SAFETY_GPWS_TAD_CAUTION = 'gpws-tad-caution/'
const SAFETY_GPWS_TAD_WARNING = 'gpws-tad-warning/'
const SAFETY_GPWS_TCF = 'gpws-tcf/'
const SAFETY_STAB_TAKEOFF = 'stab-takeoff/'

/* FLIGHT REPORT API */
const REPORT_API = 'flightReports/report';
const REPORT_REASON_LIST = 'flightReports/reason-list';

/* UPLOADFILE API */
const UPLOAD_FILE = 'uploadFile/kpi-fuel';
const URL ='uploadFile/file-url';
const SAFETY = 'safety';
const EVENT_NOTIFICATIONS = '/events/notifications';
const MARK_SAFETY_VIDEO_AS_SEEN = '/mark-as-seen';

/* PILOT PROFILE API */
const PILOT_PROFILE_API = 'pilot/';
const PILOT_PROFILE = 'profile';

/* ROSTER */
const ROSTER_BASE_PATH = 'roster/';
const ROSTER_CREW_ASSIGNMENT = 'flight-information/crew';
const ROSTER_GATE_AND_BRIDGE = 'flight-information/gate-and-bridge';
const ROSTER_CATERING = 'flight-information/catering';

/* NEWSLETTERS */
const PILOT_LIST_NEWSLETTER = 'communications/pilot';
const PILOT_ACKNOWLEDGE = 'communications/acknowledge';
const PILOT_COMMUNICATION_TYPES = 'communications/types';
const PILOT_ACKNOWLEDGE_BY_BP = 'communications/acknowledge/bp';

const CREW_BRIEFING_HOURS = 'crew-briefing/existing/hours';

//DISPATCH
const DISPATCH_BASE_PATH = 'dispatch-folder';
const DISPATCH_JOIN_DOCS = '/join-docs';
const DISPATCH_URLFILE = '/signed-url-for-front-docs';
const DISPATCH_CAE_DOCS_BY_FLIGHT = '/cae-docs-by-flight';
const DISPATCH_LOADSHEET = 'dispatch/loadsheet';
const DISPATCH_LOADSHEET_ACK = 'dispatch/loadsheet/ack';

const DISPATCH_LOADSHEET_GET_COMPARE_LS = 'dispatch/loadsheet/compare';
const DISPATCH_LOADSHEET_ACK_COMPARE_LS = 'dispatch/loadsheet/compare-ack';
const DISPATCH_FEEDBACK = '/feedback';
const DISPATCH_ZFW = '/zfw';
const DISPATCH_SEATS_BY_FLIGHT = '/seats-by-flight';
const DISPATCH_MAINTENIX_REPORT = '/maintenix';
const DISPATCH_DIRECTS_REPORT = '/directs';
const DISPATCH_DIRECTS_REPORT_COMMENT = '/comment';

//LIMOPS
const LIMOPS_REPORT = 'limops/reports';

const FAQ = 'faq/questions-with-responses'

const SEARCH_FLIGHTS = 'search-flights';

const BRIEFING_TM = 'duties';
const BRIEFING_TM_BY_FLIGHT = '/btm-report-by-flight';
const BRIEFING_TM_BY_BP = '/btm-report-by-bp';

const ROUTE_REPORT_BASE_PATH ='report/route-report';

const GROUND_SUPPORT_STATUS = '/ground-support-status'

const FLIGHT_LOG_BASE_PATH = 'flight-log'
const BP_CONVERSION_BASE_PATH = 'bp-conversion';
const BP_CONVERSION_UPDATE = '/update/';

const CALCULATOR_PATH = 'calculate';

const RULES_OF_PROFILE_DOCUMENTS = {
  'PASSPORT': {name: 'PASSPORT', expirationRule: 7},
  'VISAS': {name: 'VISA', expirationRule: 2},
  'LICENSES': {name: 'LICENSES', expirationRule: 2},
  'DOCUMENTS': {name: 'DOCUMENTS', expirationRule: 2}
};

const REMOTE_CONFIG_PATH = "frc/remote-config";
const NOTIFICATIONS_PATH = "notifications";

const INCIDENT_REPORT_BASE_PATH = 'incident-report'

@Injectable()
export class PilotConstants {

  VERSION_WEB = "7.9.0";

  MARKET_IOS_URL = "https://apps.apple.com/us/app/latam-pilot/id6449002594";
  MARKET_ANDROID_URL = "https://play.google.com/store/apps/details?id=com.latam.mobile.pilotapp";

  IDS_ANDROID = {
    ID_ANDROID_STORE_BUSINESS: "com.latam.mobile.pilotapp"
  }
  IDS_IOS = {
    ID_APP_STORE_BUSINESS: "com.latam.mobile.pilotapp",
    ID_APP_STORE_ENTERPRISE: "com.lan.mobile.pilotapp",
  }


  BFF_PATH: string = BFF_PATH;
  AIRPORTS: string = AIRPORTS;
  FLEET_API: string = FLEET_API;
  LOGIN_API: string = LOGIN_API;
  LOGIN__QR_API: string = LOGIN__QR_API;

  RULES_OF_PROFILE_DOCUMENTS = RULES_OF_PROFILE_DOCUMENTS;
  DISPATCH_BASE_PATH: string = DISPATCH_BASE_PATH;
  DISPATCH_JOIN_DOCS: string = DISPATCH_JOIN_DOCS;
  DISPATCH_URLFILE: string = DISPATCH_URLFILE;
  DISPATCH_CAE_DOCS_BY_FLIGHT: string = DISPATCH_CAE_DOCS_BY_FLIGHT;
  DISPATCH_LOADSHEET: string = DISPATCH_LOADSHEET;
  DISPATCH_LOADSHEET_ACK: string = DISPATCH_LOADSHEET_ACK;
  DISPATCH_LOADSHEET_GET_COMPARE_LS: string = DISPATCH_LOADSHEET_GET_COMPARE_LS;
  DISPATCH_LOADSHEET_ACK_COMPARE_LS: string = DISPATCH_LOADSHEET_ACK_COMPARE_LS;
  DISPATCH_ZFW: string = DISPATCH_ZFW;
  DISPATCH_SEATS_BY_FLIGHT: string = DISPATCH_SEATS_BY_FLIGHT;
  DISPATCH_MAINTENIX_REPORT: string = DISPATCH_MAINTENIX_REPORT;
  DISPATCH_DIRECTS_REPORT: string = DISPATCH_DIRECTS_REPORT;
  DISPATCH_DIRECTS_REPORT_COMMENT: string = DISPATCH_DIRECTS_REPORT_COMMENT;

  LIMOPS_REPORT: string = LIMOPS_REPORT;

  SAFETY_API: string = SAFETY_API;
  FLIGHT_LIST: string = FLIGHT_LIST;
  FLIGHT_EVENTS: string = FLIGHT_EVENTS;
  MARK_SAFETY_VIDEO_AS_SEEN: string = MARK_SAFETY_VIDEO_AS_SEEN;
  SAFETY_INDICATORS: string = SAFETY_INDICATORS;
  SAFETY_INDICATORS_EXTEND: string = SAFETY_INDICATORS_EXTEND;
  SAFETY_RANKING: string = SAFETY_RANKING;
  SAFETY_TOPUA: string = SAFETY_TOPUA;
  SAFETY_UA: string = SAFETY_UA;
  SAFETY_LANDING: string = SAFETY_LANDING;
  SAFETY_AGA: string = SAFETY_AGA;
  SAFETY_TCAS_PILOT: string = SAFETY_TCAS_PILOT;
  SAFETY_ROLL_EX_FLIGHT_PILOT: string = SAFETY_ROLL_EX_FLIGHT_PILOT;
  SAFETY_REJ_TAKE_OFF_PILOT: string = SAFETY_REJ_TAKE_OFF_PILOT;
  SAFETY_ALPHA_FLOOR_PILOT: string = SAFETY_ALPHA_FLOOR_PILOT;
  SAFETY_STICK_SHAKER_PILOT: string = SAFETY_STICK_SHAKER_PILOT;
  SAFETY_STALL_WARNING_PILOT: string = SAFETY_STALL_WARNING_PILOT;
  SAFETY_NO_IDLE_REVERSE_PILOT: string = SAFETY_NO_IDLE_REVERSE_PILOT;
  SAFETY_SPD_HIGH_MINOR_1000 : string = SAFETY_SPD_HIGH_MINOR_1000;
  SAFETY_SPOILER_NOT_ARMED : string = SAFETY_SPOILER_NOT_ARMED;
  SAFETY_SPD_HIGH_TAXI_OUT : string = SAFETY_SPD_HIGH_TAXI_OUT;
  SAFETY_SPD_HIGH_TAXI_IN : string = SAFETY_SPD_HIGH_TAXI_IN;
  SAFETY_GPWS_MODE_1 : string = SAFETY_GPWS_MODE_1;
  SAFETY_GPWS_MODE_2 : string = SAFETY_GPWS_MODE_2;
  SAFETY_GPWS_MODE_3 : string = SAFETY_GPWS_MODE_3;
  SAFETY_GPWS_MODE_4 : string = SAFETY_GPWS_MODE_4;
  SAFETY_GPWS_MODE_5 : string = SAFETY_GPWS_MODE_5;
  SAFETY_GPWS_TAD_CAUTION : string = SAFETY_GPWS_TAD_CAUTION;
  SAFETY_GPWS_TAD_WARNING : string = SAFETY_GPWS_TAD_WARNING;
  SAFETY_GPWS_TCF : string = SAFETY_GPWS_TCF;
  SAFETY_STAB_TAKEOFF : string = SAFETY_STAB_TAKEOFF;

  PILOT_PROFILE_API: string = PILOT_PROFILE_API;
  PILOT_PROFILE: string = PILOT_PROFILE;
  SEARCH_FLIGHTS: string = SEARCH_FLIGHTS;
  VERSION: string = VERSION;
  ROSTER_BASE_PATH: string = ROSTER_BASE_PATH;
  ROSTER_CREW_ASSIGNMENT: string = ROSTER_CREW_ASSIGNMENT;
  ROSTER_GATE_AND_BRIDGE: string = ROSTER_GATE_AND_BRIDGE;
  ROSTER_CATERING: string = ROSTER_CATERING;

  PILOT_LIST_NEWSLETTER: string = PILOT_LIST_NEWSLETTER;
  PILOT_ACKNOWLEDGE: string = PILOT_ACKNOWLEDGE;
  PILOT_COMMUNICATION_TYPES: string = PILOT_COMMUNICATION_TYPES;
  PILOT_ACKNOWLEDGE_BY_BP: string = PILOT_ACKNOWLEDGE_BY_BP;

  API_CHAT: string = API_CHAT;
  SEND_TOKEN_APICHAT: string = SEND_TOKEN_APICHAT;
  CHECK_CHAT_SPACE: string = CHECK_CHAT_SPACE;


  CREW_BRIEFING_HOURS: string = CREW_BRIEFING_HOURS ;

  FAQ: string = FAQ;
  REPORT_API: string = REPORT_API;
  UPLOAD_FILE: string = UPLOAD_FILE;
  REPORT_REASON_LIST: string = REPORT_REASON_LIST;

  URL: string = URL;
  SAFETY: string = SAFETY;

  EVENT_NOTIFICATIONS: string = EVENT_NOTIFICATIONS;

  MY_DOCUMENTS: string = MY_DOCUMENTS;
  MY_FLIGHTS: string = MY_FLIGHTS;

  BRIEFING_TM: string = BRIEFING_TM;
  BRIEFING_TM_BY_FLIGHT: string = BRIEFING_TM_BY_FLIGHT;
  BRIEFING_TM_BY_BP: string = BRIEFING_TM_BY_BP;

  GROUND_SUPPORT_STATUS: string = GROUND_SUPPORT_STATUS;

  ROUTE_REPORT_BASE_PATH: string = ROUTE_REPORT_BASE_PATH;

  FLIGHT_LOG_BASE_PATH: string = FLIGHT_LOG_BASE_PATH;

  BP_CONVERSION_BASE_PATH: string = BP_CONVERSION_BASE_PATH;
  BP_CONVERSION_UPDATE: string = BP_CONVERSION_UPDATE;

  CALCULATOR_PATH: string = CALCULATOR_PATH;
  DISPATCH_FEEDBACK: string = DISPATCH_FEEDBACK;

  REMOTE_CONFIG_PATH: string = REMOTE_CONFIG_PATH;
  NOTIFICATIONS_PATH: string = NOTIFICATIONS_PATH;

  INCIDENT_REPORT_BASE_PATH: string = INCIDENT_REPORT_BASE_PATH;

  FLEET_LIST = [
    { id: "A320F", name: 'A320F' },
    { id: "A350", name: 'A350' },
    { id: "B767", name: 'B767' },
    { id: "B777", name: 'B777' },
    { id: "B787F", name: 'B787F' }
  ];

  FLEET_LIST_FAM = {
    "A320F": ["A320F", "A319", "A320", "A321"],
    "B787F": ["B787F", "B787-8", "B787-9", 'B787', 'B789']
  };

  FLEET_LIST_WITHOUT_FAM = ["A350", "B767", "B777"];

  OWNER_LIST = [
    { id: 1, name: '4C' },
    { id: 2, name: '4M' },
    { id: 3, name: 'JJ' },
    { id: 4, name: 'L7' },
    { id: 5, name: 'LA' },
    { id: 6, name: 'LP' },
    { id: 7, name: 'LU' },
    { id: 8, name: 'M3' },
    { id: 9, name: 'M7' },
    { id: 10, name: 'MY' },
    { id: 11, name: 'PZ' },
    { id: 12, name: 'UC' },
    { id: 13, name: 'XL' }
  ]

  OTHER_DISPATCH_OPERATOR_LIST = [
    { id: 1, name: 'LA (Comercial)', value: 'LA'},
    { id: 2, name: 'XL (Comercial - Cargo)', value: 'XL'},
    { id: 3, name: 'UC (Cargo)', value: 'UC' },
    { id: 4, name: 'L7 (Cargo)', value: 'L7' },
    { id: 5, name: 'M3 (Cargo)', value: 'M3' },
    { id: 6, name: 'MY (Cargo)', value: 'MY' },
    { id: 7, name: 'M7 (Cargo)', value: 'M7' }
  ]


  OTHER_FLIGHTS_OPERATORS = [
    { id: 1, name: '4C' },
    { id: 2, name: '4M' },
    { id: 3, name: 'JJ' },
    { id: 4, name: 'L7' },
    { id: 5, name: 'LA' },
    { id: 6, name: 'LP' },
    { id: 7, name: 'LU' },
    { id: 8, name: 'M3' },
    { id: 9, name: 'M7' },
    { id: 10, name: 'MY' },
    { id: 11, name: 'PZ' },
    { id: 12, name: 'UC' },
    { id: 13, name: 'XL' }
  ]

  REPORT_REASONS_LIST = [
    { id: 1, name: 'ATC' },
    { id: 2, name: 'Aircraft not available' },
    { id: 3, name: 'Crew members’ delay' },
    { id: 4, name: 'Emigrations' },
    { id: 5, name: 'Flight planning, CCV' },
    { id: 6, name: 'Fuel load' },
    { id: 7, name: 'Ground delay program' },
    { id: 8, name: 'Hotel' },
    { id: 9, name: 'Ill passenger' },
    { id: 10, name: 'Baggage retrieval' },
    { id: 11, name: 'Maintenance' },
    { id: 12, name: 'Security control' },
    { id: 13, name: 'Transportation to the airport, AT' },
    { id: 14, name: 'Weather' },
    { id: 15, name: 'Other' }
  ];

  REPORT_SUB_REASON = [
    "W&B form late delivered",
    "Door closed after ETD -2 '",
    "CG Adjustment",
    "Pilot on training",
    "FLP not received by ATC",
    "Apron traffic",
    "Frequency saturation",
    "Slot or ATFM",
    "Other reasons"]

  REPORT_DELAY_REASON: IDelayReasons[] = [
    { "id": 1,  name: "ATC" },
    { "id": 2,  name: "Aircraft not available" },
    { "id": 3,  name: "Crew members’ delay" },
    { "id": 4,  name: "Emigrations and Security Check" },
    { "id": 5,  name: "Flight planning, CCV" },
    { "id": 6,  name: "Fuel load" },
    { "id": 7,  name: "Slow Boarding" },
    { "id": 8,  name: "Cumulative Delay" },
    { "id": 9,  name: "Non Operational Info" },
    { "id": 10,  name: "Ground delay program" },
    { "id": 11,  name: "Hotel" },
    { "id": 12,  name: "Passenger" },
    { "id": 13, name: "Baggage retrieval" },
    { "id": 14, name: "Maintenance" },
    { "id": 15, name: "Transportation to the airport, AT" },
    { "id": 16, name: "Weather" },
    { "id": 17, name: "Other" }
  ]

  REPORT_EXTRAFUEL_REASON = [
    { id: 1, name: 'Weather at departure airport' },
    { id: 2, name: 'Weather enroute' },
    { id: 3, name: 'Weather at arrival airport' },
    { id: 4, name: 'Weather at alternate airport' },
    { id: 5, name: 'Weather LVP' },
    { id: 6, name: 'ZFW greater than Flight plan' },
    { id: 7, name: 'NOTAM' },
    { id: 8, name: 'Planing error' },
    { id: 9, name: 'Refueling error' },
    { id: 10, name: 'Other' }
  ];

  REPORT_OTHER_REASON = [
    { id: 1, name: 'CCV' },
    { id: 2, name: 'CCO' },
    { id: 3, name: 'HCC' },
    { id: 4, name: 'Maintenance' },
    { id: 5, name: 'Airport' },
    { id: 6, name: 'Catering' },
    { id: 7, name: 'Counter' },
    { id: 8, name: 'Roster' },
    { id: 9, name: 'Customer service' },
    { id: 10, name: 'ATC Event' },
    { id: 11, name: 'Other' },
  ];

  TAIL_NUMBERS = [
    "CC-BAA",
    "CC-BAB",
    "CC-BAC",
    "CC-BAD",
    "CC-BAE",
    "CC-BAF",
    "CC-BAG",
    "CC-BAH",
    "CC-BAI",
    "CC-BAJ",
    "CC-BAK",
    "CC-BAL",
    "CC-BAM",
    "CC-BAQ",
    "CC-BAR",
    "CC-BAS",
    "CC-BAT",
    "CC-BAU",
    "CC-BAV",
    "CC-BAW",
    "CC-BAX",
    "CC-BAY",
    "CC-BAZ",
    "CC-BBA",
    "CC-BBB",
    "CC-BBC",
    "CC-BBD",
    "CC-BBE",
    "CC-BBF",
    "CC-BBG",
    "CC-BBH",
    "CC-BBI",
    "CC-BBJ",
    "CC-BCD",
    "CC-BDB",
    "CC-BDC",
    "CC-BEA",
    "CC-BEB",
    "CC-BEC",
    "CC-BED",
    "CC-BEE",
    "CC-BEF",
    "CC-BEG",
    "CC-BEH",
    "CC-BEI",
    "CC-BEJ",
    "CC-BEK",
    "CC-BEL",
    "CC-BEM",
    "CC-BEN",
    "CC-BEO",
    "CC-BEP",
    "CC-BEQ",
    "CC-BER",
    "CC-BFA",
    "CC-BFB",
    "CC-BFC",
    "CC-BFD",
    "CC-BFE",
    "CC-BFF",
    "CC-BFG",
    "CC-BFH",
    "CC-BFI",
    "CC-BFJ",
    "CC-BFK",
    "CC-BFL",
    "CC-BFM",
    "CC-BFN",
    "CC-BFO",
    "CC-BFP",
    "CC-BFQ",
    "CC-BFR",
    "CC-BFS",
    "CC-BFT",
    "CC-BFU",
    "CC-BFV",
    "CC-BFW",
    "CC-BFX",
    "CC-BGA",
    "CC-BGB",
    "CC-BGC",
    "CC-BGD",
    "CC-BGE",
    "CC-BGF",
    "CC-BGG",
    "CC-BGH",
    "CC-BGI",
    "CC-BGJ",
    "CC-BGK",
    "CC-BGL",
    "CC-BGM",
    "CC-BGN",
    "CC-BGP",
    "CC-BGQ",
    "CC-BGR",
    "CC-BGS",
    "CC-BGT",
    "CC-BGU",
    "CC-BGV",
    "CC-BGW",
    "CC-BGX",
    "CC-BGY",
    "CC-BGZ",
    "CC-BHA",
    "CC-BHB",
    "CC-BHC",
    "CC-BHD",
    "CC-BHE",
    "CC-BHF",
    "CC-BHG",
    "CC-BJD",
    "CC-BJE",
    "CC-BJF",
    "CC-BLA",
    "CC-BLB",
    "CC-BLE",
    "CC-BLG",
    "CC-BLH",
    "CC-BLI",
    "CC-BLJ",
    "CC-BLK",
    "CC-BLL",
    "CC-BLM",
    "CC-BLN",
    "CC-BLO",
    "CC-BLP",
    "CC-BMA",
    "CC-COD",
    "CC-COE",
    "CC-COF",
    "CC-COG",
    "CC-COH",
    "CC-COI",
    "CC-COK",
    "CC-COL",
    "CC-COO",
    "CC-COP",
    "CC-COQ",
    "CC-COU",
    "CC-COX",
    "CC-COY",
    "CC-COZ",
    "CC-CPF",
    "CC-CPI",
    "CC-CPJ",
    "CC-CPL",
    "CC-CPM",
    "CC-CPO",
    "CC-CPQ",
    "CC-CQK",
    "CC-CQL",
    "CC-CQM",
    "CC-CQN",
    "CC-CQO",
    "CC-CQP",
    "CC-CWF",
    "CC-CWV",
    "CC-CWY",
    "CC-CXC",
    "CC-CXD",
    "CC-CXE",
    "CC-CXF",
    "CC-CXG",
    "CC-CXH",
    "CC-CXI",
    "CC-CXJ",
    "CC-CXK",
    "CC-CYI",
    "CC-CYJ",
    "CC-CYL",
    "CC-CZZ",
    "HC-CPJ",
    "HC-CPR",
    "HC-CPY",
    "HC-CPZ",
    "N418LA",
    "N420LA",
    "N532LA",
    "N534LA",
    "N536LA",
    "N538LA",
    "N540LA",
    "N542LA",
    "N564LA",
    "N566LA",
    "N568LA",
    "N570LA",
    "N572LA",
    "PR-ABB",
    "PR-ABD",
    "PR-ACO",
    "PR-MAG",
    "PR-MAK",
    "PR-MBA",
    "PR-MBF",
    "PR-MBG",
    "PR-MBH",
    "PR-MBN",
    "PR-MBU",
    "PR-MBV",
    "PR-MBW",
    "PR-MHA",
    "PR-MHE",
    "PR-MHF",
    "PR-MHG",
    "PR-MHI",
    "PR-MHJ",
    "PR-MHK",
    "PR-MHM",
    "PR-MHP",
    "PR-MHQ",
    "PR-MHR",
    "PR-MHU",
    "PR-MHW",
    "PR-MHX",
    "PR-MHZ",
    "PR-MYA",
    "PR-MYC",
    "PR-MYH",
    "PR-MYI",
    "PR-MYJ",
    "PR-MYK",
    "PR-MYL",
    "PR-MYM",
    "PR-MYN",
    "PR-MYO",
    "PR-MYP",
    "PR-MYQ",
    "PR-MYR",
    "PR-MYT",
    "PR-MYV",
    "PR-MYW",
    "PR-MYX",
    "PR-MYY",
    "PR-MYZ",
    "PR-TQB",
    "PR-TQC",
    "PR-TYA",
    "PR-TYD",
    "PR-TYF",
    "PR-TYH",
    "PR-TYI",
    "PR-TYJ",
    "PR-TYK",
    "PR-TYL",
    "PR-TYM",
    "PR-TYN",
    "PR-TYO",
    "PR-TYP",
    "PR-TYQ",
    "PR-TYR",
    "PR-TYS",
    "PR-TYT",
    "PR-TYU",
    "PR-TYV",
    "PR-XBA",
    "PR-XBB",
    "PR-XBC",
    "PR-XBD",
    "PR-XBE",
    "PR-XBF",
    "PR-XBG",
    "PR-XBH",
    "PR-XBI",
    "PR-XBJ",
    "PR-XBK",
    "PR-XBL",
    "PR-XBM",
    "PR-XBN",
    "PR-XBO",
    "PR-XBP",
    "PR-XBQ",
    "PR-XBR",
    "PS-LAA",
    "PS-LBA",
    "PS-LBB",
    "PS-LBC",
    "PS-LBD",
    "PS-LBE",
    "PS-LBF",
    "PS-LBG",
    "PS-LBH",
    "PS-LBI",
    "PS-LBJ",
    "PS-LBL",
    "PS-LBM",
    "PT-MUA",
    "PT-MUB",
    "PT-MUC",
    "PT-MUD",
    "PT-MUE",
    "PT-MUF",
    "PT-MUG",
    "PT-MUH",
    "PT-MUI",
    "PT-MUJ",
    "PT-MXA",
    "PT-MXB",
    "PT-MXC",
    "PT-MXD",
    "PT-MXE",
    "PT-MXF",
    "PT-MXG",
    "PT-MXH",
    "PT-MXI",
    "PT-MXJ",
    "PT-MXL",
    "PT-MXM",
    "PT-MXN",
    "PT-MXO",
    "PT-MXP",
    "PT-MXQ",
    "PT-MZL",
    "PT-TMA",
    "PT-TMB",
    "PT-TMC",
    "PT-TMD",
    "PT-TME",
    "PT-TMG",
    "PT-TMI",
    "PT-TML",
    "PT-TMO",
    "PT-TMT",
    "PT-TPA",
    "PT-TPB",
    "PT-XPA",
    "PT-XPB",
    "PT-XPC",
    "PT-XPD",
    "PT-XPE",
    "PT-XPF",
    "PT-XPG",
    "PT-XPH",
    "PT-XPI",
    "PT-XPJ",
    "PT-XPL",
    "PT-XPM",
    "PT-XPN",
    "PT-XPO",
    "PT-XPQ"
  ]

  SCREEN_WIDTH_LIMIT: number = 775;

  LINE_CHART_OPTIONS: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales : {
      y: {
        min: 0,
      }
    },
    plugins: {
      legend: {
        labels: {
          boxWidth: 10,
          usePointStyle: true,
          font:{
            size:12
          }
        }
      }
    }
  };

  LINE_CHART_COLORS:Array<any> = [
    { //PILOT
      borderColor: 'rgba(255, 0, 108, 0.5)',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      pointBackgroundColor: 'rgba(255, 0, 108, 0.5)',
      pointHoverBorderColor: '#fff',
      tension: 0.5,
    },
    { // FLEET
      borderColor: 'rgba(108, 211, 251, 1)',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      pointBackgroundColor: 'rgba(108, 211, 251, 1)',
      pointHoverBorderColor: '#fff',
      tension: 0.5,
    },
    { // GOAL FLEET
      borderColor: '#1AAE8D',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      pointBorderColor: 'rgba(0, 0, 0, 0)',
      pointHoverBorderColor: 'rgba(0, 0, 0, 0)',
      borderWidth: 2
    }
  ];

  HOW_CREATE_AN_ACCOUNT = 'https://projectmanagement.appslatam.com/servicedesk/customer/portal/7/create/268';

  OTHERS_USEFUL_LINKS = [
    { link: "https://aviationweather.gov/gfa/#obs", text: "AVIATION WEATHER"},
    { link: "https://www.notams.faa.gov/dinsQueryWeb/", text:  "NOTAMs FAA (WORLDWIDE)"},
    { link: "https://skyvector.com/", text: "SKY VECTOR"},
    { link: "https://www.ogimet.com/gramet_aero.phtml", text:  "OGIMET"},
    { link: "https://elinkportal.jeppesen.com/", text:  "JEPPESEN CHARTS (WEB)"},
    { link: "https://www3.smn.gob.ar/vaac/buenosaires/inicio.php?lang=es", text: "VOLCANIC ASH ADVISORY CENTER"},
    { link: "https://www.faa.gov/air_traffic/flight_info/aeronav/aero_data/Apt_Constr_Notices/", text: "AIRPORT CONSTRUCTION NOTICES"},
    { link: "https://www.smn.gob.ar/satelite", text:  "IMÁGEN SATELITAL GOES-16"},
    { link: "https://aipchile.dgac.gob.cl/", text: "AIP CHILE"}
  ];

  SCREENS_ON_BOARDING = [
    {
      "screen": 0,
      "title": "Plan your flights efficiently and safely",
      "subtitle": "Access information in real time",
      "logo": "screen-one.svg",
      "class": "screen-on-boarding screen-one"
    },
    {
      "screen": 1,
      "title": "Access all documents for your next flight",
      "subtitle": "With notifications from your device, you can instantly know when a document is ready",
      "logo": "screen-two.svg",
      "class": "screen-on-boarding screen-two"
    },
    {
      "screen": 2,
      "title": "All the tools you need to plan your flights",
      "subtitle": "Quick and easy to use",
      "logo": "screen-three.svg",
      "class": "screen-on-boarding screen-three"
    },
  ];

  DISPATCH_SECTIONS=[
    { name: 'Loadsheet' },
    { name: 'Dispatch Documents' },
    { name: 'Both' }
  ];

  HOME_BANNERS = [
    { id: 1, img: "calendar.svg", title: "Find the crew", text: "Of another flight", link: "other-flights", tag: "Other Flights",  },
    { id: 2, img: "calculator.svg", title: "Calculator", text: "", link: "calculator", tag: "Calculator"},
    { id: 3, img: "documents.svg", title: "Find another", text: "Dispatch", link: "find-dispatch-folder",tag: "Find another dispatch" },
    { id: 4, img: "flightlog.svg", title: "FlightLog", text: "", link: "flight-logs", tag: "FlightLog" },
    { id: 5, img: "mr2.svg", title: "Deferred Item List", text: "", link: "mr2-report", tag: "MR2" }
  ];

  KEYS_DATA_INDICATORS = [
    "events", 
    'topUa', 
    'ua', 
    'rankingOfAirports',
    'shortLanding',
    "hardGraphic",
    'landingGraphic',
    'abnormalGoAround',
    'tcas',
    'rollExFlight',
    'rejTakeOff',
    'alphaFloor',
    'stickShaker',
    'stallWarning',
    'noIdleReverse',
    'spdHighMinor1000',
    'spoilerNotArmed',
    'spdHighTaxiOut',
    'spdHighTaxiIn',
    'gpwsMode1',
    'gpwsMode2',
    'gpwsMode3',
    'gpwsMode4',
    'gpwsMode5',
    'gpwsTadCaution',
    'gpwsTadWarning',
    'gpwsTCF',
    'stabTakeOff',
  ];

  hideSectionsStores: boolean = false;
  SET_DATA_INDICATORS = {
    
    ua: {
      url: ""
    },
    abnormalGoAround: {
      url: ""
    },
    landingGraphic: {
      url: "",
      type: "deep"
    },
    hardGraphic: {
      url: "",
      type: "hard"
    },
    rankingOfAirports: {
      url: "",
    },
    shortLanding: {
      url: "",
      type: "short"
    },
    tcas: {
      url: "",
      type: "tcas"
    },
    topUa: {
      url: "",
    },
    rollExFlight: {
      url: "",
    },
    rejTakeOff: {
      url: "",
    },
    alphaFloor: {
      url: "",
    },
    stickShaker: {
      url: "",
    },
    stallWarning: {
      url: "",
    },
    noIdleReverse: {
      url: "",
    },
    spdHighMinor1000: {
      url: "",
    },
    spoilerNotArmed: {
      url: "",
    },
    spdHighTaxiOut: {
      url: "",
    },
    spdHighTaxiIn: {
      url: "",
    },
    gpwsMode1: {
      url: "",
    },
    gpwsMode2: {
      url: "",
    },
    gpwsMode3: {
      url: "",
    },
    gpwsMode4: {
      url: "",
    },
    gpwsMode5: {
      url: "",
    },
    gpwsTadCaution: {
      url: "",
    },
    gpwsTadWarning: {
      url: "",
    },
    gpwsTCF: {
      url: "",
    },
    events: {
      url: "",
    },
  }


  GOOGLE_AUTH_WEB_CONFIG = {
    client_id: environment.clientId,
    cookiepolicy: 'single_host_origin',
    hosted_domain: 'latam.com',
    scope: environment.envName == "intg"? 'https://www.googleapis.com/auth/chat.spaces.create https://www.googleapis.com/auth/chat.memberships https://www.googleapis.com/auth/chat.delete': '',
    prompt: 'consent',
    plugin_name: 'login-pilot'
  }

  MESSAGES_APP = {
    "GENERAL": {
      "NO_CONNECTION": "No internet connection"
    },
    "DISPATCH_FOLDER": {
      "LOADSHEET": {
        "MAIN": "This Loadsheet and ACK are referential. You must use the official procedures described on your Operations Manuals.",
        "ACK": "This procedure is not yet official. You must use the approved procedure in the Operations Manuals."
      },
      "DOCUMENTS": {
        "MAIN": "This Dispatch information is referential. Always use the official procedures described on your Operations Manuals."
      }
    },
    "ROSTER": {
      "INFORMATION": "Remember that your roster can have modifications, you should check it daily."
    },
    "HOME": {},
    "FIND_ANOTHER_CREW": {
      "MAIN": "By federal LGPD law, JJ and M3 flights are restricted for search purposes."
    },
    "FLIGHT_REPORT": {
      "MAIN": "This Report aims to inform about events during your flight. Remember this report does not replace Safety Report (AQD)"
    },
    "PROFILE": {
      "DOCUMENTS_MESSAGE": "The information uploaded in PILOT may take a few minutes to be processed."
    }
  }

  DISABLED_REFRESH_SCREENS = ["/reports", "/calculator", "/other-flights", "/login", "/on-boarding", "/newsletter", "/fuel", "/tools"];

  ALLOW_ACCOUNTS_DEMO_CHAT = [];
  SPACE_NAME_CHAT = "API POC v1"
  
  // Se valida contra operador operacional del vuelo
  OFICIAL_DISPATCH_OPERATORS = ["4C", "LA", "LU", "XL", "PZ", "LP", "JJ"];
  OFICIAL_LOADSHEET_OPERATORS = ["4C", "LA", "LU", "XL", "PZ", "LP", "JJ"];

  // FLeets WB
  FLEETS_WB_FAMILY = [787, 777, 789, 767, 763];

  BRASIL_OPERATORS = ["JJ", "M3"];

}
