<mat-dialog-content class="mat-typography">
    <div class="container mt-3 mb-3 " >
        <div class="modal-header-dialog">
            <h2 class="modal-title-dialog" mat-dialog-title>APP AQD</h2>
            <button mat-button (click)="closeClick();" class="button-close"><i class="material-icons"> clear </i></button>
        </div>
        <ngx-extended-pdf-viewer [src]="pdf"></ngx-extended-pdf-viewer>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="footer-modal">
    <button (click)="openLink('https://play.google.com/store/apps/details?id=com.rrdigitalnz.mobileereports')" class="btn-pilot pilot-secondary ">
        App Android
    </button>
    <button (click)="openLink('https://apps.apple.com/cl/app/mobile-ereports-sms/id1441713282?l=en-GB')" class="btn-pilot pilot-secondary ">
        App iOS
    </button>
    <button (click)="openLink('https://latam.visiumaqd.com/Latam_Prod')" class="btn-pilot pilot-secondary ">
        Portal AQD
    </button>
    <button (click)="openLink('https://videoslatam.cl/ccii/corp/2023/11/20/images/reporte_latam.pdf')" class="btn-pilot pilot-secondary ">
        Instructivo
    </button>
</mat-dialog-actions>