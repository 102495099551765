import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Camera, CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';
import { Device } from '@capacitor/device';
import { finalize } from 'rxjs';
import { confirmAlert } from 'src/app/core/utils/utils';
import { IDocumentsType, RequestSaveDocument, ResponseDocumentImage } from 'src/app/modules/pilot/domain/profile/documents-pilot';
import { ProfileService } from 'src/app/modules/pilot/services/profile.service';
import { SecurityService } from 'src/app/core/services/security/security.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { HelpModalComponent } from '../../modals/help-modal/help-modal.component';
import { SweetAlertOptions } from "sweetalert2";
import { CommonsService } from 'src/app/core/services/commons/commons.service';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent implements OnInit {

  allowExtensions = ["jpeg","jpg","png","jfif","bmp","webp","raw","ico","tiff"];
  @Input() typeDocument: IDocumentsType = "passport";
  @Output() close: EventEmitter<any> = new EventEmitter();
  formDocument : FormGroup;
  loadingImage: boolean = false;
  image: Blob | File;
  imageUrl: string;
  steps = {
    uploadImage: true,
    information: false
  }
  isIOS: boolean = false;
  dataDocument: ResponseDocumentImage;
  dialogRef: any;
  
  constructor(
    private _fb: FormBuilder,
    private _profileService: ProfileService,
    private securityService: SecurityService,
    public dialog: MatDialog,
    private commonsService: CommonsService
  ) { }

  ngOnInit(): void {
    Device.getInfo().then(info => this.isIOS = (info.platform == "ios"));
    this.formDocument = this._fb.group({
      name:['', [Validators.required]],
      lastNames:['', [Validators.required]],
      nationality:['', [Validators.required]],
      countryOfIssue:['', [Validators.required]],
      number:['', [Validators.required]],
      run:['', [Validators.required]],
      expirationDate:[null, [Validators.required]],
      visaType:['', [Validators.required]],
      efectiveDate:['', [Validators.required]],
      passportNumber:['', [Validators.required]],
    },{updateOn: 'blur'});
  }

  showInfo(){
    let data = {};
    data = { 
      title: "ISO COUNTRY CODES",
      body: '<h6>Some examples ISO codes:</h6>'+
      '<ul>'+
            '<li>COL: Colombia</li>'+
            '<li>CHL: Chile</li>'+
            '<li>BRA: Brasil</li>'+
            '<li>PER: Peru</li>'+
            '<li>ECU: Ecuador</li>'+
            '<li>PRY: Paraguay</li>'+
            '<li>ARG: Argentina</li>'+
            '<li>URY: Uruguay</li>'+
            '<li>ESP: España</li>'+
            '<li>USA: EEUU</li>'+
      '</ul>'
    }
      
    this.dialogRef = this.dialog.open(HelpModalComponent, {
      width:"500px",
      data: data,
      disableClose: true
    });
  }

  back() {
    this.steps.uploadImage = true;
    this.steps.information = false;
    this.formDocument.get("expirationDate").enable();
    this.formDocument.reset();
  }

  closeClick(): void {
    this.formDocument.reset();
    this.close.emit();
  }

  async checkPermissions() {
    const persissions = await Camera.checkPermissions();
    console.log(persissions);
  }

  async requestPermissions() {
    const requestPermissions = await Camera.requestPermissions();
    console.log(requestPermissions);
  }

  deleteImage() {
    this.image = null;
    this.imageUrl = null;
  }

  async takePicture(source: 'CAMERA' | 'PHOTOS') {
    this.commonsService.setGtag('Upload Documents', 'Select file ' + this.typeDocument);
    // this.checkPermissions();
    // this.requestPermissions();
    let imageOptions: ImageOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source: source == 'CAMERA' ? CameraSource.Camera : CameraSource.Photos,
    }
    const image = await Camera.getPhoto(imageOptions);
    this.imageUrl = image.webPath;
    this.image = await fetch(this.imageUrl).then(r => r.blob());
    let format = image.format;
    const allowedExtensions = ['jpg', "jpeg", 'png'];
    if (!allowedExtensions.includes(format)) {
      this.imageNotValid({
        icon: 'error',
        title: 'Error',
        text: 'Invalid file. The allowed extensions are: ' + allowedExtensions.join(', '),
        confirmButtonText: 'Continue',
        showCancelButton: false
      });
      return;
    }

    if (this.image.size > 10000000) {
      this.imageNotValid({
        icon: 'error',
        title: 'Error',
        text: 'The file exceeds the size limit (10MB)',
        confirmButtonText: 'Continue',
        showCancelButton: false
      });
      return;
    }

  }

  imageNotValid(alert: SweetAlertOptions) {
    this.image = null;
    this.imageUrl = null;
    confirmAlert(alert, true);
  }

  async getDocumentInformation() {
    this.commonsService.setGtag('Upload Documents', 'Get document information ' + this.typeDocument);
    this.loadingImage = true;
    this._profileService.getDocumentInformation(this.image, this.typeDocument).subscribe({
      next: res => {
        this.dataDocument = res;
        this.setDataFromDocumentImage();
      },
      error: (err: HttpErrorResponse) => {
        console.log("error", err);
        this.dataDocument = null;
        confirmAlert({
            title: `Could not get document information.`,
            text: err.status == 400 && err.error?.description? err.error.description + ` Please check the image and try again.`: "Ooops! There was a problem. Please try again",
            confirmButtonText: 'Continue',
            showCancelButton: false
        });
        this.setDataFromDocumentImage();
      },
    });
  }

  disabledFields() {
    this.formDocument.get("expirationDate").disable();
  }

  setDataFromDocumentImage() {
    console.log("Data", this.dataDocument);
    this.loadingImage = false;
    this.steps.uploadImage = false;
    this.steps.information = true;
    if (!this.dataDocument) return;
    this.formDocument.patchValue(this.dataDocument.data);
    this.disabledFields();
  }

  async uploadInformation() {
    let confirm = await confirmAlert({
      title: 'Are you sure you want to save the information?',
      text: 'Select save to load the information',
      confirmButtonText: 'Save',
      cancelButtonText: 'Back',
    });
    if (confirm) {
      this.commonsService.setGtag('Upload Documents', 'Upload document information ' + this.typeDocument);
      let data: RequestSaveDocument = this.formDocument.getRawValue(); 
      console.log("data save document:", data);
      this.securityService.setLoading(true);
      this._profileService.saveDocument(data, this.image, this.typeDocument)
      .pipe(finalize(() => this.securityService.setLoading(false)))
      .subscribe({
        next: (res: any) => {
          console.log("res saveDocument", res);
          if (res?.body?.status == "SUCCESS") {
            this.commonsService.setGtag('Upload Documents', 'Success upload document ' + this.typeDocument);
            confirmAlert({
              title: `Document saved successfully`,
              text: 'The information may take a few minutes to be processed.',
              confirmButtonText: 'Continue',
              showCancelButton: false
            });
            this.closeClick();
            return;
          } 
          this.handleError();
        },
        error: err => this.handleError(err),
      });
    }
  }

  handleError(err?: any) {
    console.log("error saveDocument", err);
    this.commonsService.setGtag('Upload Documents', 'Error upload document ' + this.typeDocument);
    confirmAlert({
      title: 'Error',
      text: 'Oooops! There was a problem saving your document. Please try again',
      showConfirmButton: false,
      showCancelButton: false
    });
  }

}
