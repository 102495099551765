<mat-expansion-panel class="panel">

    <mat-expansion-panel-header class="expansion-panel" [collapsedHeight]="'auto'" [expandedHeight]="'auto'" >
      <mat-panel-title>
        <span class="table-tittle description">{{report.task.name}}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="row mt-2 data-border ">
      <div class=" mr-8 col-4 subtittle">
        <div  class="">
          <span class="">BarCode: </span>
        </div>
      </div>
      <div  class="col-8 subtittle-data ">
        <div class="">
          <span class="">  {{report.task.tkBarcode}}</span>
        </div>
      </div>
    </div>


    <div class="row mt-2  ">
      <div class=" mr-8 col-12 subtittle">
        <div  class="">
          <span class="">{{report.fault.deferralClazz}} - {{report.fault.deferralReference}}</span>
        </div>
      </div>
    </div>

    <div class="row mt-2  ">
      <div class=" mr-8 col-4 subtittle-content">
        <div  class="">
          <span class="">Scheduling: </span>
        </div>
      </div>
      <div  class="col-8 subtittle-data ">
        <div class="">
          <span *ngIf="report.task.scheduling != null">  {{report.task.scheduling.interval}} {{report.task.scheduling.dataType}}</span>
        </div>
      </div>
    </div>


    <div class="row mt-2  ">
      <div class=" mr-8 col-4 subtittle-content">
        <div  class="">
          <span class="">Found on Date: </span>
        </div>
      </div>
      <div  class="col-8 subtittle-data ">
        <div class="">
          <span class=""> {{report.task.openDateLdt}}</span>
        </div>
      </div>
    </div>

    <div class="row mt-2 ">
      <div class=" mr-8 col-4 subtittle-content">
        <div  class="">
          <span class="">Expiration Date: </span>
        </div>
      </div>
      <div  class="col-8 subtittle-data ">
        <div class="">
          <span class="">  {{report.task.dueDateLdt}}</span>
        </div>
      </div>
    </div>

    <div class="row mt-2 data-border ">
      <div class=" mr-8 col-4 subtittle-content">
        <div  class="">
          <span class="">Extend expiration date: </span>
        </div>
      </div>
      <div  class="col-8 subtittle-data ">
        <div class="">
          <span class="">  {{report.task.extendDueDateLdt}}</span>
        </div>
      </div>
    </div>


    <div *ngIf="report.fault.failType == 'LIMOPS'">

      <div class="row mt-2 ">
        <div class=" mr-8 col-4 subtittle">
          <div  class="">
            <span class="">LIMOPS: </span>
          </div>
        </div>
        <div  class="col-8 subtittle-data ">
          <div class="">
            <span class="">  YES</span>
          </div>
        </div>
      </div>

      <div class="row mt-2 ">
        <div class=" mr-8 col-4 subtittle-content">
          <div  class="">
            <span class="">Category Limops: </span>
          </div>
        </div>
        <div  class="col-8 subtittle-data ">
          <div class="">
            <span class="">  {{report.fault.faultTypes}}</span>
          </div>
        </div>
      </div>

      <div class="row mt-2 ">
        <div class=" mr-8 col-4 subtittle-content">
          <div  class="">
            <span class="">ETOPS: </span>
          </div>
        </div>
        <div  class="col-8 subtittle-data ">
          <div class="">
            <span class="">  {{report.fault.etops}}</span>
          </div>
        </div>
      </div>

      <div class="row mt-2 ">
        <div class=" mr-8 col-4 subtittle-content">
          <div  class="">
            <span class="">CAT: </span>
          </div>
        </div>
        <div  class="col-8 subtittle-data ">
          <div class="">
            <span class="">{{report.fault.cat}}</span>
          </div>
        </div>
      </div>

      <div class="row mt-2 ">
        <div class=" mr-8 col-4 subtittle-content">
          <div  class="">
            <span class="">RVSM: </span>
          </div>
        </div>
        <div  class="col-8 subtittle-data ">
          <div class="">
            <span class="">{{report.fault.rvsm}}</span>
          </div>
        </div>
      </div>

      <div class="row mt-2 ">
        <div class=" mr-8 col-4 subtittle-content">
          <div  class="">
            <span class="">RNAV: </span>
          </div>
        </div>
        <div  class="col-8 subtittle-data ">
          <div class="">
            <span class="">{{report.fault.rnav}}</span>
          </div>
        </div>
      </div>

      <div class="row mt-2 data-border ">
        <div class=" mr-8 col-4 subtittle-content">
          <div  class="">
            <span class="">RNP: </span>
          </div>
        </div>
        <div  class="col-8 subtittle-data ">
          <div class="">
            <span class="">{{report.fault.rnp}}</span>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="report.fault.failType != 'LIMOPS'">

      <div class="row mt-2 data-border ">
        <div class=" mr-8 col-4 subtittle">
          <div  class="">
            <span class="">LIMOPS: </span>
          </div>
        </div>
        <div  class="col-8 subtittle-data ">
          <div class="">
            <span class="">  NO</span>
          </div>
        </div>
      </div>

    </div>
    
    <div  *ngIf="report.fault.repetitiveInspection == 'YES'">
      <div class="row mt-2 " >
        <div class=" mr-8 col-4 subtittle">
          <div  class="">
            <span class="">Rep. Inspection: </span>
          </div>
        </div>
        <div  class="col-8 subtittle-data ">
          <div class="">
            <span class="">  YES</span>
          </div>
        </div>
      </div>

      <div class="row mt-2 " >
        <div class=" mr-8 col-4 subtittle">
          <div  class="">
            <span class="">Action Required: </span>
          </div>
        </div>
        <div  class="col-8 subtittle-data ">
          <div class="">
            <span class="">{{report.fault.actionRequired}}</span>
          </div>
        </div>
      </div>

      <div class="row mt-2 ">
        <div class=" mr-8 col-4 subtittle">
          <div  class="">
            <span class="">Description: </span>
          </div>
        </div>
        <div  class="col-8 subtittle-data ">
          <div class="">
            <span class="">  {{report.fault.defectDescription}}</span>
          </div>
        </div>
      </div>
    </div>


    <div  *ngIf="report.fault.repetitiveInspection != 'YES'">
      <div class="row mt-2" >
        <div class=" mr-8 col-4 subtittle">
          <div  class="">
            <span class="">Rep. Inspection: </span>
          </div>
        </div>
        <div  class="col-8 subtittle-data ">
          <div class="">
            <span class="">  NO</span>
          </div>
        </div>
      </div>
    </div>

</mat-expansion-panel>