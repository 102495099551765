import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { FlightLogInfo } from 'src/app/modules/pilot/domain/flight-log/flight-log-info.model';
import { FlightLogRulesDates } from 'src/app/modules/pilot/domain/flight-log/flight-log-rules-dates';
import { FlightLogState } from 'src/app/modules/pilot/domain/flight-log/flight-log-state.model';
import { FlightLog } from 'src/app/modules/pilot/domain/flight-log/flight-log.model';
import { FlightLogService } from 'src/app/modules/pilot/services/flight-log.service';

@Component({
    selector: 'flight-log',
    templateUrl: './flight-log.component.html',
    styleUrls: ['./flight-log.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class FlightLogComponent implements OnInit {

    flightToEdit: FlightLog;
    onEdit: boolean = false;
    addingFlights: boolean = false;
    editMode: string;
    selectedIndex = 0;
    rulesDates: FlightLogRulesDates;
    selectedFlights: FlightLogInfo[] = [];
    
    constructor(
        private _fl: FlightLogService,
        private router: Router,
    ) {}
    
    ngOnInit() {
        this.getRulesDates();
    }

    catchAddFlightsEvent(selectedFlights: FlightLogInfo[]){
        this.addingFlights = true;
        this.selectedFlights = selectedFlights;
    }

    catchFinishEvent(){
        this.addingFlights = false;
        this.selectedFlights = [];
    }

    catchEditEvent(flightLogState: FlightLogState){
        this.flightToEdit = flightLogState.flightLog;
        this.editMode = flightLogState.state;
        this.onEdit = true;
    }

    catchEditEventFromLoad(value: boolean) {
        this.onEdit = false;
        this.editMode = null;
        this.selectedIndex = 1;
    }

    backTo(){
        if(this.onEdit){
            this.onEdit = false;
            this.editMode = null;
        } else {
            this.router.navigate(['/home']);
        }
    }

    getRulesDates() {
        this._fl.getDatesRules().subscribe({
            next: res => {
                if (res) {
                    this.rulesDates = res;
                } else {
                    this.getCurrentDates();
                }
            },
            error: err => {
                this.getCurrentDates();
                console.error("err getDatesRules ", err);
            },
        });
    }

    getCurrentDates() {
        this.rulesDates = new FlightLogRulesDates();
        this.rulesDates.maxDate = moment(new Date()).add(1, 'days').utc().format("YYYY-MM-DD");
        const firstDayOfMonth = moment().startOf('month');
        this.rulesDates.minDate = (new Date().getDate() > 5)? firstDayOfMonth.format('YYYY-MM-DD'):
            moment().subtract(1, 'months').startOf("month").format('YYYY-MM-DD');
    }
}