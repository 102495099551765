<div class="svg-background-container" [hidden]="showFlightInformation">
    <svg viewBox="0 0 500 150" preserveAspectRatio="xMinYMin meet">
        <defs>
            <linearGradient id="myGradient">
              <stop offset="2.78%" stop-color="#10004F" />
              <stop offset="98.95%" stop-color="#1B0088" />
            </linearGradient>
        </defs>
      <path d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z"  fill="url('#myGradient')"></path>
    </svg>
</div>
<!--Main layout-->
<main class="home-main" [hidden]="showFlightInformation">
    <div class="mb-5 container">

        <div class="welcome-container">
            <h5 class="welcome-title">Hello 👋 , {{ user?.userName }}</h5>
        </div>

        <ng-container *ngIf="contractCodeOperator">
            <app-communications [operator]="contractCodeOperator"></app-communications>
        </ng-container>

        <!-- Alerts start -->

        <div *ngIf="isTestEnvironment && !hideSection" class="alert alert-warning alert-dismissible fade show mx-auto w-responsive" role="alert">
            This environment is only for testing, please click here <a href="https://pilot.appslatam.com" style="color: blue;">https://pilot.appslatam.com</a>
        </div>

        <app-alerts
            *ngIf="!isConnected"
            [text]="'Oops, It seems you don`t have access to internet. Please connect your device to a network'"
            [class]="'w-100'"
        >
        </app-alerts>

        <app-alerts
            *ngIf="reportSuccess"
            [text]="'Your report has been sent successfully'"
            [class]="'w-100'"
            [type]="'success'"
        >
        </app-alerts>

        <!-- Alerts end -->

        <ng-container *ngIf="!isConnected && loadsheet">
            <div>
                <mat-card class="card-banner clickable"  (click)="openLoadSheetModal()">
                    <div class="card-banner-content">
    
                        <div *ngIf="!loadsheetWaiting && loadsheetExist">
                            <mat-icon 
                                class="shadow-icon status-icon icon-not-connected" 
                                [matTooltip]="'This loadsheet was accepted'"
                                matTooltipPosition="above"
                            >
                                download_for_offline
                            </mat-icon>
                        </div>
    
                        <div *ngIf="loadsheetWaiting">
                            <mat-progress-spinner class="ack-spinner-circle"  color="warn"  mode="indeterminate" [diameter]="40"></mat-progress-spinner>
                        </div>
    
                        <div  *ngIf="!loadsheetExist && !loadsheetWaiting">
                            <mat-icon class="x-circle shadow-icon status-icon" [matTooltip]="'This loadsheet was refused'" >cancel</mat-icon>
                        </div>
                        
                        <div>
                            <div class="row text-rows">
                                <span class="acknowledge-card">Last Loadsheet Accepted</span>
                                <span class="acknowledge-card-flights-text">&nbsp; {{loadsheet.operator}}-{{loadsheet.flightNumber}}</span>
                            </div>
    
                            <div *ngIf="loadsheetExist && !loadsheetWaiting" class="row text-rows">
                                <span *ngIf="loadsheet.ack == 1" class="card-state-text">Completed</span>
                            </div>
    

                            <div *ngIf="loadsheetWaiting" class="row text-rows">
                                <span  class="card-state-text">Search for Files</span>
                            </div>
    
                        </div>
                    </div>
                </mat-card>
            </div>
        </ng-container>
        
        <ng-container *ngIf="this.isConnected">
            <div  *ngIf="this.rosterFcard?.activity && this.rosterFcard?.activity?.dutyDesignator != 'PS'" >
                <mat-card class="card-banner clickable"  (click)="openLoadSheetModal()">
                    <div class="card-banner-content">
    
                        <div *ngIf="!this.loadsheetWaiting && this.loadsheetExist">
                            <mat-icon class="shadow-icon waiting status-icon" *ngIf="loadsheet.ack == 0" [matTooltip]="'Waiting for your reply'" matTooltipPosition="above">error</mat-icon>
                            <mat-icon class="shadow-icon check-ok status-icon" *ngIf="loadsheet.ack == 1" [matTooltip]="'This loadsheet was accepted'" matTooltipPosition="above">check_circle</mat-icon>
                            <mat-icon class="shadow-icon ack-rejected status-icon" *ngIf="loadsheet.ack == -1" [matTooltip]="'Rejected, Waiting for another Loadsheet'" matTooltipPosition="above">cancel</mat-icon>
                        </div>
    
                        <div *ngIf="this.loadsheetWaiting">
                            <mat-progress-spinner class="ack-spinner-circle"  color="warn"  mode="indeterminate" [diameter]="40"></mat-progress-spinner>
                        </div>
    
                        <div  *ngIf="!this.loadsheetExist && !this.loadsheetWaiting">
                            <mat-icon class="x-circle shadow-icon status-icon" [matTooltip]="'This loadsheet was refused'" >cancel</mat-icon>
                        </div>
                        <div>
                            <div class="row text-rows">
                                <span class="acknowledge-card">Acknowledge</span>
                                <span class="acknowledge-card-flights-text">&nbsp; {{this.rosterFcard?.activity?.flightLeg?.operator}}-{{this.rosterFcard?.activity?.flightLeg.flightNumber}}</span>
                            </div>
    
                            <div *ngIf="this.loadsheetExist && !this.loadsheetWaiting" class="row text-rows">
                                <span *ngIf="loadsheet.ack == 0" class="card-state-text">Acknowledge Pending</span>
                                <span *ngIf="loadsheet.ack == 1" class="card-state-text">Completed</span>
                                <span *ngIf="loadsheet.ack == -1" class="card-state-text">Rejected, Waiting for another Loadsheet</span>
    
                            </div>
    
                            <div *ngIf=" !this.loadsheetExist && !this.loadsheetWaiting" class="row text-rows">
                                <span  class="card-state-text">No available</span>
                            </div>
                            <div *ngIf="this.loadsheetWaiting" class="row text-rows">
                                <span  class="card-state-text">Search for Files</span>
                            </div>
    
                        </div>
                    </div>
                </mat-card>
            </div>
    
            <div *ngIf="this.rosterFcard?.activity && this.rosterFcard?.activity?.dutyDesignator != 'PS' && !hideSection" (click)="goToDispatchFolder()">
                <mat-card appearance="outlined"  class="card-banner clickable">
                    <div class="card-banner-content">
                        <div>
                            <span class="shadow-icon dispatch-icon material-symbols-outlined" >
                                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,1,0" />
                                folder_open
                            </span>
                        </div>
    
                        <div>
                            <div class="row text-rows">
                                <span class="acknowledge-card">Dispatch</span>
                                <span class="acknowledge-card-flights-text">&nbsp; {{this.rosterFcard?.activity?.flightLeg?.operator}}-{{this.rosterFcard?.activity?.flightLeg.flightNumber}}</span>
                            </div>
                            <div class="row text-rows">
                                <span  class="card-state-text">Documents of the last trip available</span>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </ng-container>

        <div class="mt-4" *ngIf="!hideSection && activities[0]">
            <roster-fcard [nextFlight]="true"  class="next-flight-home" [activity]="activities[0]" (searchFlightInformation)="openFlightInformation()"></roster-fcard>
        </div>

        <app-banners *ngIf="contractCodeOperator" [contractCodeOperator]="contractCodeOperator" [flightlogsToSend]="flightlogsToSend"></app-banners>

    </div>

</main>


<ng-container *ngIf="showFlightInformation">
  
    <flight-information 
      (back)="closeFlightInformation()" 
      [user]="user"
      [origin]="'home'"
      [flight]="activities[0]"
    >
    </flight-information>
  
 </ng-container>