export class FlightLogDatesInfo {
  id?: number;
  startTime: string;
  endTime: string;
  blockTime: string;
  scheduledDatetimeDeparture: string;
  scheduledDatetimeArrival: string;
  actualDatetimeDeparture: string;
  actualDatetimeArrival: string;
  localScheduledDatetimeDeparture: string;
  localScheduledDatetimeArrival: string;
  localActualDatetimeDeparture: string;

  constructor(){
    this.id = null;
    this.startTime = null;
    this.endTime = null;
    this.blockTime = null;
    this.scheduledDatetimeDeparture = null;
    this.scheduledDatetimeArrival = null;
    this.actualDatetimeDeparture = null;
    this.actualDatetimeArrival = null;
    this.localScheduledDatetimeDeparture = null;
    this.localScheduledDatetimeArrival = null;
    this.localActualDatetimeDeparture = null;
  }
}