
<swiper-container class="mySwiper" space-between="20" pagination="true" pagination-dynamic-bullets="true" loop="false" autoplay-delay="5000" autoplay-disable-on-interaction="true" auto-height="true">
    <ng-container *ngFor="let communication of communications">
        <swiper-slide>
            <div class="slide">
                <div class="img-slide">
                    <img [src]="communication.img" alt="" srcset="">
                </div>
                <div class="text-slide">
                    <h6 class="title">{{ communication.title }}</h6>
                    <p class="text">{{ communication.text }}</p>
                    <span class="link" (click)="navigate(communication.link.href)" *ngIf="!communication.doc && communication?.link?.text">
                        {{ communication.link.text }}
                        <img width="10" height="10" src="assets/img/home/new-home/communications/underline.svg" alt="" srcset="">
                    </span>
                    <button class="button-get-document col-centered" (click)="getDocOfNew(communication.link.href, communication.link.text)" *ngIf="communication.doc">Download</button>
                </div>
            </div>
        </swiper-slide>
    </ng-container>
</swiper-container>
