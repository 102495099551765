import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { confirmAlert } from 'src/app/core/utils/utils';
import { environment } from 'src/environments/environment';
import { PilotConstants } from '../../../commons/pilot-constants';

@Component({
  selector: 'last-minute-change-dialog',
  templateUrl: './last-minute-change-dialog.component.html',
  styleUrls: ['./last-minute-change-dialog.component.css']
})
export class LastMinuteChangeDialogComponent implements OnInit {

  public lastMinuteChangeForm: FormGroup;

  public isWB: boolean;
  public is4C:boolean = false;
  public initialPaxData: number = 0;
  public initialTMData: number = 0;
  public initialTCData: number = 0;
  public popup = false;
  public messageAlert = "";

  isTestEnvironment: boolean = !environment.production;
  fleetsWB = this._contants.FLEETS_WB_FAMILY;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LastMinuteChangeDialogComponent>,
    private _fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _contants: PilotConstants
  ) { }

  ngOnInit() {
    
    this.is4C = (this.data.operator == '4C') ? true : false;
    this.isWB = this.fleetsWB.includes(+this.data.fleet);

    this.lastMinuteChangeForm = this._fb.group({
      sign: ['', Validators.required],
      pax: ['', Validators.required],
      tcsign: ['', ],
      tc: ['', ],
      tm: ['', ],
      tmsign: ['', ],
    }, { updateOn: 'blur' });

    this.inicializarValores();

  }

  inicializarValores() {
    
    if (this.data.pax != null && this.data.pax != 0) {
      this.initialPaxData = Math.abs(this.data.pax);
      this.lastMinuteChangeForm.get('sign').setValue(this.data.pax < 0? "-":"+");
      this.lastMinuteChangeForm.get('pax').setValue(this.initialPaxData);
    }
    
    if (this.data.tm != null  && this.data.tm != 0) {
      this.initialTMData = Math.abs(this.data.tm);
      this.lastMinuteChangeForm.get('tmsign').setValue(this.data.tm < 0? "-":"+");
      this.lastMinuteChangeForm.get('tm').setValue(Math.abs(this.data.tm));
    }

    if (this.data.tc != null  && this.data.tc != 0) {
      this.initialTCData = Math.abs(this.data.tc);
      this.lastMinuteChangeForm.get('tcsign').setValue(this.data.tc < 0? "-":"+");
      this.lastMinuteChangeForm.get('tc').setValue(Math.abs(this.data.tc));
    }
  }

  save() {
    let data = this.lastMinuteChangeForm.getRawValue();
    
    let lmcPax = Number.parseInt(data['sign'] + data['pax']) || 0;
    let lmcTC = Number.parseInt((data['tcsign'] + data['tc']) || 0);
    let lmcTM = Number.parseInt((data['tmsign'] + data['tm']) || 0);

    let total = lmcPax + lmcTC + lmcTM;
    
    if (!this.isWB && (total > 5 || total < -5)) {
      this.messageAlert = "Changes between passengers and crew should not vary by more than +/- 500 kg";
      this.popup = true;
    } else if (this.isWB && (total > 10 || total < -10)) {
      this.messageAlert = "Changes between passengers and crew should not vary by more than +/- 1000 kg";
      this.popup = true;
    } else {
      this.dialogRef.close({lmcPax,lmcTC,lmcTM});
    }
  }

  resetValues() {
    confirmAlert({
      title: 'Are you sure you want to reset the last minute change?',
      text: 'Confirm to reset values.',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    }).then((result) => {
        if (result) {
          this.dialogRef.close({lmcPax: 0, lmcTC: 0, lmcTM: 0 });
        }
    });
  }

  cancel() {
    this.dialogRef.close(null);
  }

}