<main class="indicator_container m5">
    <h5 class="latam_bold t-gray-4">{{ title }}</h5>
    <div class="body_indicators" *ngIf="showLoading">
        <mat-progress-spinner  style="margin: 0 auto" [color]="'accent'" [mode]="'indeterminate'" [value]="50">
        </mat-progress-spinner>
    </div>
    <div class="body_indicators" *ngIf="!showLoading">     
        <div *ngIf="lineChartData.datasets.length>0 && !theresData" class="not-found t-gray-6">
            Results not found for the selected filters
        </div>
        <div class="row indicators_data" *ngIf="lineChartData.datasets.length>0 && theresData">
            <div class="col-md-12 col-sm-12">
                <canvas baseChart width="400" height="200" [data]="lineChartData" [type]="lineChartType" [options]="lineChartOptions" [legend]="lineChartLegend"  ></canvas>
            </div>
        </div>
    </div>
</main>