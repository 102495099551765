
<mat-accordion *ngFor="let duty of briefingTmData; first as isFirst">
  <mat-expansion-panel class="duty-panel mb-1"  [expanded]="isFirst">
    <mat-expansion-panel-header
      [collapsedHeight]="screenWidth >= constants.SCREEN_WIDTH_LIMIT ? '5rem' : '6rem'"
      [expandedHeight]="screenWidth >= constants.SCREEN_WIDTH_LIMIT ? '5rem' : '6rem'"
      style="font-family: 'Latam_Sans_Regular'">
      <mat-panel-title>
        <b class="duty-title">
          <img src="/assets/img/rol/flight_cycle.svg" alt="">
          <span *ngIf="!isFromBrasil" class="d-inline-flex">
            {{(checked ? duty.plannedStart.utc : duty.plannedStart.local) | date : 'MMMM dd' }}
          </span>

          <span *ngIf="isFromBrasil" class="d-inline-flex">
            {{(checked ? duty.plannedStart.local : duty.plannedStart.brasilia) | date : 'MMMM dd' }}
          </span>
        </b>
      </mat-panel-title>

      <mat-panel-description class="duty-description">
        <b>Presentation:</b>
        <ng-container *ngIf="!isFromBrasil">
          {{(checked ? duty.plannedStart.utc : duty.plannedStart.local) | date : 'HH:mm' }}
        </ng-container>

        <ng-container *ngIf="isFromBrasil">
          {{(checked ? duty.plannedStart.local : duty.plannedStart.brasilia) | date : 'HH:mm' }}
        </ng-container>

        <br>
        <div class="duty-time"><b>Duty time:</b> {{duty.dutyTime}}</div>

        <div class="d-inline-flex">
          <b>End of journey:</b>

          <ng-container *ngIf="!isFromBrasil">
            {{(checked ? duty.plannedEnd.utc : duty.plannedEnd.local) | date : 'HH:mm' }}
          </ng-container>

          <ng-container *ngIf="isFromBrasil">
            {{(checked ? duty.plannedEnd.local : duty.plannedEnd.brasilia) | date : 'HH:mm' }}
          </ng-container>
          <span class="time-circle"
            *ngIf="(((!checked && !isFromBrasil) || (checked && isFromBrasil)) && duty.dutyLocalDateEndsTomorrow) ||
                    (checked && !isFromBrasil && duty.dutyUtcDateEndsTomorrow) ||
                    (!checked && isFromBrasil && duty.dutyBrasiliaDateEndsTomorrow)">
              +1
          </span>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-accordion class="rol-flight-accordion" *ngFor="let flight of duty.items">
      <div class="rol-flight-main">
        <roster-fcard [nextFlight]="false" [grayCard]="false" [activity]="flight" [isFromBrasil]="isFromBrasil" [checked]="checked" [origin]="'briefing'" (searchFlightInformation)="rolComponent.openFlightInformation(flight, 'briefing')" ></roster-fcard>
        <p class="data-between-flights font-18 text-center mt-3 mb-3" *ngIf="flight.tat != '00:00'">
          <img src="/assets/img/rol/tat_plane.svg" alt="">
          TAT {{flight.tat}} {{flight.flightLeg.hasAircraftChange ? 'A/C' : ''}}
        </p>
      </div>
    </mat-accordion>
  </mat-expansion-panel>
  <p class="rest-time font-weight-bold text-center" *ngIf="duty.restTime">
    <img src="/assets/img/rol/chair.svg" alt="" alt="">
    {{duty.restTime}} hs
  </p>
</mat-accordion>
