import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PilotConstants } from '../commons/pilot-constants';
import * as moment from 'moment';
import { CrewInformation } from '../domain/crew-information';
import { CrewInfo } from '../domain/dispatch/btm-report/crew-info.model';

@Injectable({
  providedIn: 'root'
})
export class CrewBriefingService {

  constructor(
    private httpClient: HttpClient,
    private constants: PilotConstants
  ) { }

  getCrewBriefingRules(rq): Observable<any>{
    const params = new HttpParams()
    .set('operator', rq.operator)
    .set('fleet', rq.fleet)
    .set('departure', rq.departure.iataCode)
    .set('business', rq.business);
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.CREW_BRIEFING_HOURS}`;
    return this.httpClient.get(URL, { params });
  }

  calculateDiff(local: string , utc:string){

    let localMoment = moment(local);
    let utcMoment = moment(utc);

   
    let duration = moment.duration(localMoment.diff(utcMoment));
    let hours = duration.get("hours");
    let minutes = duration.get("minutes");

    let hoursToReturn:string = hours.toString();
    let minutesToReturn: string =  Math.abs(minutes).toString();
    
    if(Math.abs(minutes) < 10){
      minutesToReturn = "0"+Math.abs(minutes);
    }
   
    if(hours>0 || (hours ==0 && minutes > 0)){
      hoursToReturn = "+" + hours;
    }

    if(hours == 0 &&  minutes < 0){
      hoursToReturn = "-".concat(hoursToReturn);
    }

    return hoursToReturn + ":" + minutesToReturn;
  }

  //Timezone diff between the first flight and the current one
  getFlightDiff( local: string , utc:string, nextFlightHourDiff: number) {
    const currentFlightLocalDate = moment(local);
    const currentFlightUtcDate = moment(utc);
    const currentFlightDuration = moment.duration(currentFlightLocalDate.diff(currentFlightUtcDate));
    const currentFlightHourDiff = currentFlightDuration.asHours();

    return (nextFlightHourDiff - currentFlightHourDiff).toString().concat(":00");
  }

  fillCrew(crew: Array<CrewInformation>, isBrasilFlight: boolean) {

    let crewList: CrewInfo[] = new Array();
    let totalPax = 0;
    let totalCrew = 0;
    crew?.forEach(currentCrew => {
      if (currentCrew.isPax) {
        totalPax += 1;
      } else{ 
        totalCrew += 1;
      }
      if (isBrasilFlight) {
        crewList.push(new CrewInfo(currentCrew.category, currentCrew.warName, currentCrew.bp.toString().padStart(8, '0'), currentCrew.codAnac, currentCrew.email, currentCrew.isPax, currentCrew.order));
      } else {
        crewList.push(new CrewInfo(currentCrew.category, `${currentCrew.name} ${currentCrew.lastName}`, currentCrew.bp.toString().padStart(8, '0'), currentCrew.documentNumber, currentCrew.email, currentCrew.isPax, currentCrew.order));
      }
    });

    return { crewList, totalCrew, totalPax };
  }

  setCrewBriefingTimes(flight) {
    if (flight.flightLeg.checkInDates.local == null) return;
    return new Promise<{presentationTimeLT: string, timeAtGateLT: string, PBLt: string, presentationTimeUTC: string, timeAtGateUTC: string, PBUtc: string}>((resolve, reject) => {
      this.getCrewBriefingRules(flight.flightLeg).subscribe({
        next: resp => resolve(this.assignTimes(moment(flight.flightLeg.departure.dates.local).format('HH:mm'), moment(flight.flightLeg.departure.dates.utc).format('HH:mm'), resp)),
        error: err => {
          console.error("Error at setCrewBriefingTimes", err)
          reject();
        }
      });
    });
  }

  private assignTimes(departureTimeLT, departureTimeUTC, rules) {
    const time = departureTimeLT.split(":");
    const parseTime = moment().set({ 'hour': time[0], 'minute': time[1], 'second': 0 });

    const timeUTC = departureTimeUTC.split(":");
    const parseTimeUTC = moment().set({ 'hour': timeUTC[0], 'minute': timeUTC[1], 'second': 0 });

    let presentationTimeLT: string, timeAtGateLT: string, PBLt: string;
    let presentationTimeUTC: string, timeAtGateUTC: string, PBUtc: string;
    if (rules) {
      presentationTimeLT = moment(parseTime).subtract(rules.presentation, 'm').format('HH:mm');
      timeAtGateLT = moment(parseTime).subtract(rules.crewBoarding, 'm').format('HH:mm');
      PBLt = moment(parseTime).subtract(rules.paxBoarding, 'm').format('HH:mm');

      presentationTimeUTC = moment(parseTimeUTC).subtract(rules.presentation, 'm').format('HH:mm');
      timeAtGateUTC = moment(parseTimeUTC).subtract(rules.crewBoarding, 'm').format('HH:mm');
      PBUtc = moment(parseTimeUTC).subtract(rules.paxBoarding, 'm').format('HH:mm');
    }
    return {presentationTimeLT, timeAtGateLT, PBLt, presentationTimeUTC, timeAtGateUTC, PBUtc}
  }

}
