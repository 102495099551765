import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RolRoutingModule } from './rol-routing.module';
import { RolComponent } from './rol.component';
import { FlightInformationComponent } from './flight-information/flight-information.component';
import { IonicModule } from '@ionic/angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { RosterActcard } from './roster-actcard/roster-actcard.component';
import { RosterFcard } from './roster-fcard/roster-fcard.component';
import { BriefingTmComponent } from './briefing-tm/briefing-tm.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { AlertsModule } from 'src/app/standalone-modules/alerts/alerts.module';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CrewBriefingComponent } from './crew-briefing/crew-briefing.component';
import { NoDataComponent } from 'src/app/standalone-modules/no-data/no-data.component';


@NgModule({
  declarations: [
    RolComponent,
    FlightInformationComponent,
    RosterActcard,
    RosterFcard,
    BriefingTmComponent,
    CrewBriefingComponent,
  ],
  imports: [
    CommonModule,
    RolRoutingModule,
    IonicModule,

    MatProgressSpinnerModule,
    MatTabsModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatCardModule,
    MatIconModule,
    AlertsModule,
    NoDataComponent
  ],
  exports: [
    FlightInformationComponent,
    RosterActcard,
    RosterFcard,
    BriefingTmComponent,
    CrewBriefingComponent,
  ]
})
export class RolModule { }
