import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PilotConstants } from 'src/app/modules/pilot/commons/pilot-constants';
import { LoadSheetsComparedACK } from 'src/app/modules/pilot/domain/dispatch/cosmos/compare-ack-request';
import { UiService } from 'src/app/modules/pilot/services/ui/ui.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'feedback-compared-ls',
  templateUrl: './feedback-compared-ls.component.html',
  styleUrl: './feedback-compared-ls.component.scss'
})
export class FeedbackComparedLsComponent implements OnInit, OnDestroy{
  

  loadSheetsComparedACK: LoadSheetsComparedACK;
  public ackForm: FormGroup;
  disclaimer: string = this.constants.MESSAGES_APP.DISPATCH_FOLDER.LOADSHEET.ACK;
  hiddenDisclaimersForOperators = this.constants.OFICIAL_LOADSHEET_OPERATORS;
  showDisclaimer: boolean = true;
  isTestEnvironment: boolean = !environment.production;
  @Input() operator: string;

  constructor(
    private _ui: UiService,
    public constants: PilotConstants,
    private _fb: FormBuilder,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this._ui.listenKeyboard('modal-feedback-compared-ls');
    this.ackForm = this._fb.group({
      comment: [''],
    }, { updateOn: 'blur' });
  }

  ngOnDestroy(): void {
    this._ui.removeKeyboardListeners();
  }

  closeClick() {
    return this.modalCtrl.dismiss(null, 'close');
  }

  async performAck(ack: 1 | -1) {
    this.loadSheetsComparedACK = new LoadSheetsComparedACK();
    this.loadSheetsComparedACK.usefulComparison = ack;
    this.loadSheetsComparedACK.pilotCommentary = this.ackForm.get('comment').value;
    
    return this.modalCtrl.dismiss(this.loadSheetsComparedACK, 'action');
  }


}
