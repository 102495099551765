
export class DispatchDocument {
    name: string;
    id: number;
    isSelected: boolean;
    content: string;

    constructor(name: string, id: number = null, content: string = null){
        this.name = name;
        this.id = id;
        this.content = content;
        this.isSelected = false;
    }
}
