import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fetchPDF, openBrowser } from 'src/app/core/utils/utils';
import { ICommunications } from 'src/app/modules/pilot/domain/remote-config/communications';
import { IncidentReportComponent } from '../incident-report/incident-report.component';
import { NewsService } from 'src/app/modules/tools/services/news.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss']
})
export class CommunicationsComponent implements OnChanges {

  communications: ICommunications[] = [];
  @Input() operator: string;

  constructor(
    private news:NewsService,
    private router: Router,
    private platform: Platform,
    private dialog: MatDialog
  ) { }

  ngOnChanges(): void {
    this.getCommunications();
  }

  getCommunications() {
    this.news.getAllNewsForPilot().subscribe({
      next: (communications: ICommunications[]) => {this.communications = communications ?? []},
      error: (err) => console.error("Error getCommunications: ", err),
    });
  }

  getDocOfNew(docToSearch:String, docName:String){

    this.news.getDocOfNew(docToSearch).subscribe({
      next: (resp) => this.showPdf(resp, docName),
      error: (err) => console.error("Error getCommunications: ", err),
    });

  }


  private showPdf(base64, filename){
    let name = filename +'.pdf';
    fetchPDF(base64, name, this.platform.is('hybrid'));
  }

  navigate(url: string) {
    if (url == "report-incident") {
      this.reportIncident();
    }
    url.startsWith("http")? openBrowser(url) : this.router.navigate([url]);
  }

  reportIncident(){
    this.dialog.open(IncidentReportComponent, {
      width: '600px',
      maxWidth: '95vw',
      id: "modal-feedback",
      disableClose: true,
    });
  }

}
