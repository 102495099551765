import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { IUser } from 'src/app/modules/pilot/domain/user/user';
import { SecurityService } from '../../services/security/security.service';
import { validateRolePath } from '../../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  constructor(
    public router: Router, 
    private _secure: SecurityService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return Preferences.get({ key: 'user' }).then((storage) => {
      let user: IUser = JSON.parse(storage.value);
      let hasPermission: boolean = validateRolePath(route, user?.roles || []);
      // console.log("hasPermission? ", hasPermission);
      // no idToken No permission for this path
      if(!hasPermission || !user?.idToken){
        this._secure.logOut();
        return false;
      }
      
      if (user?.profile?.onBoarding && state.url.includes("on-boarding")) {
        this.router.navigate(['/home']);
        return false;
      }

      return true;
    }).catch((err) => {
      this._secure.logOut();
      return false;
    });
  }

  
}
