import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewsletterService } from '../../services/newsletter.service';
import { NewsletterListRequest } from '../../domain/newsletter-list-request';
import { NewsletterListResponse } from '../../domain/newsletter-list-response';
import { NewsletterModalComponent } from '../modals/newsletter-modal/newsletter-modal.component';
import * as moment from 'moment';
import { CommunicationType } from '../../domain/communication-type';
import { UserService } from 'src/app/core/services/user/user.service';
import { IProfile } from 'src/app/modules/pilot/domain/user/user';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {
  @ViewChild('typeFilterSelected',{static: false}) typeFilterSelected;
  @ViewChild('allFilter',{static: false}) allFilter;

  newsletters: Array<NewsletterListResponse>;
  newsResponse: Array<NewsletterListResponse> = new Array<NewsletterListResponse>();
  newslettersRequest: NewsletterListRequest = new NewsletterListRequest();
  loadingNewsletter: boolean;
  dialogViewNewsletter: any;
  profile: IProfile;
  typesNews: Array<CommunicationType> = new Array<CommunicationType>();

  constructor(
    private dialog: MatDialog,
    private newsletterService: NewsletterService,
    private _user: UserService
  ) {}
  
  ngOnInit() {
    let user = this._user.getUserValue;
    this.profile = user?.profile;
    this.loadTypesCommunication();
  }

  loadTypesCommunication() {
    this.newsletterService.getTypes().subscribe(
      resp => {
        this.typesNews = resp;
        this.verifyLanguage(this.profile["operator"], resp, "combo");
        this.typesNews = [{id: 0, type: 'All' }].concat(resp);
        this.typeFilterSelected.value = "All";
        this.loadNewsletters();
    });
  }

  loadNewsletters() {
    
    this.newslettersRequest.fleet = this.profile['fleet'];
    this.newslettersRequest.operator = this.profile['operator'];
    this.newslettersRequest.startDate = moment().format('YYYY-MM-DD');
    this.newslettersRequest.endDate = moment().subtract(365, "days").format('YYYY-MM-DD');
  
    this.loadingNewsletter = true;
    
    this.newsletterService.listNewsletter(this.newslettersRequest).subscribe({
      next: resp => {
        this.loadingNewsletter = false;
        resp = this.sortByPublishDate(resp);
        let itemsWithAcknowledge = this.filterWithAcknowledge(resp);
        let itemsWithoutAcknowledge = this.filterWithoutAcknowledge(resp);
        resp = itemsWithAcknowledge.concat(itemsWithoutAcknowledge);
        this.newsletters = new Array<NewsletterListResponse>();
        this.newsletters = this.verifyLanguage(this.profile["operator"], resp, "newsletter");
        this.newsResponse = this.verifyLanguage(this.profile["operator"], resp, "newsletter");
      },
      error: err => {
        this.loadingNewsletter = false;
      }
    });
  }

  verifyLanguage(operator, data, type){
    if(operator == "JJ" || operator ==  "M3" || operator == "PZ"){
      if(type == "newsletter"){
        data.forEach(item => {
          if(item.typeName == "Nuestros Casos") item.typeName = "Nossos casos"
          if(item.typeName == "Informativos de seguridad") item.typeName = "Informativo de Segurança"
        });
      }else {
        data[0].type = "Nossos casos";
        data[2].type = "Informativo de Segurança";
      }
      return data;
    }else {
      return data;
    }
  }

  openNewsletter(newsletter:NewsletterListResponse) {
    this.dialogViewNewsletter = this.dialog.open(NewsletterModalComponent, {
      maxWidth: '100vw !important',
      width:"97vw",
      height: "455px",
      data: newsletter,
      panelClass: 'custom-dialog-container'
    });
    this.dialogViewNewsletter.afterClosed().subscribe((result: boolean) => {
      if(result) {
        this.loadNewsletters();
      }
    });
  }

  applyFilter() {
    let news;
    if(this.typeFilterSelected.value){
      news = this.filterType(this.newsResponse);
    }
    this.filterAll(news);
  }

  filterType(objectToFilter) {
    let news = objectToFilter;
    if(this.typeFilterSelected.value != "All") {
      news = objectToFilter.filter(value => {
        return value.typeName == this.typeFilterSelected.value;
      });
    }
    return news;
  }

  filterAll(news) {
    let filteredNames = news.filter(objectNew => {
      return objectNew.title.toLowerCase().indexOf(this.allFilter.nativeElement.value.toLowerCase()) > -1;
   });
    this.newsletters = filteredNames;
  }

  sortByPublishDate(resp): void {
    resp.sort((a, b) => {
        return new Date(b.publishingDate).getTime() - new Date(a.publishingDate).getTime();
    });
    return resp;
  }

  filterWithAcknowledge (response) {
    let communications = response.filter(communication => {
      return !communication.acknowledge;
    });
    return communications;
  }

  filterWithoutAcknowledge (response) {
    let communications = response.filter(communication => {
      return communication.acknowledge;
    });
    return communications;
  }

}
