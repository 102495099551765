import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { PilotConstants } from 'src/app/modules/pilot/commons/pilot-constants';
import { confirmAlert } from 'src/app/core/utils/utils';
import { FlightId } from 'src/app/modules/pilot/domain/flight-id.model';
import { FlightLogDatesInfo } from 'src/app/modules/pilot/domain/flight-log/flight-log-dates-info.model';
import { FlightLogInfo } from 'src/app/modules/pilot/domain/flight-log/flight-log-info.model';
import { FlightLogRulesDates } from 'src/app/modules/pilot/domain/flight-log/flight-log-rules-dates';
import { FlightLog } from 'src/app/modules/pilot/domain/flight-log/flight-log.model';
import { CommonsService } from 'src/app/core/services/commons/commons.service';
import { FlightLogService } from 'src/app/modules/pilot/services/flight-log.service';
import { SecurityService } from 'src/app/core/services/security/security.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'load-flight-log',
    templateUrl: './load-flight-log.component.html',
    styleUrls: [
        './load-flight-log.component.scss',
        './../../../commons/styles/custom-inputs.scss'
    ]
})

export class LoadFlightLogComponent implements OnInit {

    @ViewChild('picker',{static: false}) datePicker;
    @Output() editEvent = new EventEmitter<boolean>();
    @Input() flightLogToEdit: FlightLog;
    @Input() editMode: string;
    @Input() rulesDates: FlightLogRulesDates;

    public flightLogToCreate: FlightLog;

    public selectedFile: File = null;
    public filePlaceholder: string;

    public flights: Array<FlightLogInfo> = [];

    public formflightLog: FormGroup;

    public flightToFind: FlightId;
    private currentFlight: FlightLogInfo;

    private operatorAllowed = 'LA';

    public flightSearchingState: string;
    public operators: any[] = this.constants.OWNER_LIST.filter(ol => ol.name == this.operatorAllowed);

    public multiplesFlightsFound: boolean = false;
    public saving: boolean = false;
    public flightsFound: FlightLogInfo[] = [];
    
    constructor(
        private _fb: FormBuilder,
        private flightLogService: FlightLogService,
        public constants: PilotConstants,
        private commonsService:CommonsService,
        private security: SecurityService
    ) {}

    ngOnInit() {
        this.init();
    }

    init() {
        this.commonsService.setGtag('Flight log', 'Main page');

        this.flights = [];
        this.selectedFile = null;
        this.flightSearchingState = 'Search for a flight by filling in the fields date, departure and flight number';
        this.flightToFind = new FlightId({flightNumber: null, tail: null, operator: this.operatorAllowed, flightDate: null, departureIataCode: null});
        this.currentFlight = new FlightLogInfo();
        this.flightsFound = [];
        this.multiplesFlightsFound = false;
        
        if(this.editMode){

            this.filePlaceholder = 'Replace the current file loaded';
            let flightInfo: FlightLogInfo = new FlightLogInfo();

            if(this.isAddingFlight()){
                this.flights = this.flightLogToEdit.flights;
                this.flights.forEach( f => f.removable = false);
                this.flightLogToEdit.flights = [];
            }
            else{
                flightInfo = this.flightLogToEdit.flights[0];
            }

            this.formflightLog = this._fb.group({
                flightLogNumber:[this.flightLogToEdit.flightLogNumber, [Validators.required, Validators.max(99999999999999999999)]],
                bookImage: [''],
                flightDate:[flightInfo.flightDate, Validators.required],
                operator:[this.operatorAllowed, Validators.required],
                flightNumber:[flightInfo.flightNumber, Validators.required],
                fleet:[flightInfo.fleet, Validators.required],
                aircraftRegistrationNumber:[flightInfo.aircraftRegistrationNumber, Validators.required],
                departure:[flightInfo.departure, Validators.required],
                arrival:[flightInfo.arrival, Validators.required],
                startTime:[flightInfo.dates.startTime?.substring(0,5),[Validators.required, Validators.minLength(4)]],
                endTime:[flightInfo.dates.endTime?.substring(0,5), [Validators.required, Validators.minLength(4)]],
                blockTime:[flightInfo.dates.blockTime?.substring(0,5), Validators.required],
            },{updateOn: 'blur'});

        }
        else{
            this.filePlaceholder = 'Upload an image';
            this.flightLogToCreate = new FlightLog(null, null);
            this.formflightLog = this._fb.group({
                flightLogNumber:['', [Validators.required, Validators.max(99999999999999999999)]],
                bookImage: ["", Validators.required],
                flightDate:["", Validators.required],
                operator:[this.operatorAllowed, Validators.required],
                flightNumber:[ "", Validators.required],
                fleet:["", Validators.required],
                aircraftRegistrationNumber:["", Validators.required],
                departure:["", Validators.required],
                arrival:["", Validators.required],
                startTime:[null, [Validators.required, Validators.minLength(4)]],
                endTime:[null, [Validators.required, Validators.minLength(4)]],
                blockTime:["", Validators.required],
            },{updateOn: 'blur'});
        }

        
        this.setForm();
        this.listenForm();
    }

    public findFlight() {
        if(this.flightToFind.hasBasicInfo()){
            this.flightSearchingState = 'Searching flight';
            this.flightToFind.flightDate = moment(this.flightToFind.flightDate).format('YYYY-MM-DD');
            this.flightLogService.getFlightLogInfo(this.flightToFind.operator, this.flightToFind.flightNumber, this.flightToFind.departureIataCode.toUpperCase(), this.flightToFind.flightDate).
            subscribe({
                next: (resp: FlightLogInfo[]) => {
                    if(!resp){
                        this.flightSearchingState = 'Flight not found';
                        this.emptyCurrentFlight();
                        for (const iterator of ["flightDate", "flightNumber", "departure"]) {
                            this.formflightLog.controls[iterator].setValue(null,{ emitEvent: false } );
                        }
                        this.multiplesFlightsFound = false;
                    } else if (resp.length == 1) {
                        this.flightSearchingState = 'Flight found';
                        this.multiplesFlightsFound = false;
                        this.formflightLog.controls["arrival"].disable({emitEvent: false});
                        this.currentFlight = resp[0];
                        this.setFormFromCurrentFlight();
                    } else if (resp.length > 1) {
                        this.formflightLog.controls["arrival"].enable({emitEvent: false});
                        this.flightSearchingState = 'Multiples Flights found';
                        this.multiplesFlightsFound = true;
                        this.flightsFound = resp;
                    }
                },
                error: err => {
                    this.flightSearchingState = 'Error at searching flight';
                    console.log(err);
                }
            });
        }
    }
    
    public removeFlight(fl:FlightLogInfo) {        
        confirmAlert({
            title: 'Are you sure you want to delete the flight?',
            text: 'Confirm to delete.',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result) {
                let index = this.flights.findIndex( currentFl => fl.flightNumber == currentFl.flightNumber && fl.departure == currentFl.departure && fl.flightDate == currentFl.flightDate);
                this.flights.splice(index, 1);
                if(this.isAddingFlight()){
                    let index = this.flightLogToEdit.flights.findIndex( currentFl => fl.flightNumber == currentFl.flightNumber && fl.departure == currentFl.departure && fl.flightDate == currentFl.flightDate);
                    this.flightLogToEdit.flights.splice(index, 1);
                }
            }
        });
    }

    public addFlight() {
        let existInFLightList = this.flights.find( 
            f => 
            f.flightDate == this.currentFlight.flightDate && 
            f.flightNumber == this.currentFlight.flightNumber && 
            f.departure == this.currentFlight.departure && 
            f.arrival == this.currentFlight.arrival && 
            f.aircraftRegistrationNumber == this.currentFlight.aircraftRegistrationNumber
        );
        if (existInFLightList) {
            confirmAlert({
                icon: 'warning',
                title: 'Already added',
                text: 'Already exists in the flight list',
                showCancelButton: false,
                confirmButtonText: "OK",
            }, true);
            return;
        }

        this.currentFlight.dates.startTime = this.formatTimeField(this.formflightLog.get('startTime').value);
        this.currentFlight.dates.endTime = this.formatTimeField(this.formflightLog.get('endTime').value);
        this.currentFlight.dates.blockTime = this.formflightLog.get('blockTime').value;
        this.currentFlight.multipleFlights = this.multiplesFlightsFound;
        this.currentFlight.removable = true;
        this.flights.push(JSON.parse(JSON.stringify(this.currentFlight)));

        let flightLogNumber = null;

        if(this.isAddingFlight()){
            this.flightLogToEdit.flights.push(JSON.parse(JSON.stringify(this.currentFlight)));
            flightLogNumber = this.flightLogToEdit.flightLogNumber;
        } else {
            flightLogNumber = this.flightLogToCreate.flightLogNumber;
        }

        this.emptyCurrentFlight();

        //Reset form except fields on the array
        const excludedFields: string[] = ['bookImage', 'flightLogNumber', 'operator'];
        Object.keys(this.formflightLog.controls).forEach(key => {
            if (excludedFields.findIndex(q => q === key) === -1) {
                this.formflightLog.get(key).reset();
            }
         });

    }
    
    public saveFlightLog() {

        this.saving = true;

        //Add flights to a flight log
        this.security.setLoading(true);
        if(this.isAddingFlight()){
            this.flightLogService.addFlights(this.flightLogToEdit.flightLogNumber, this.flightLogToEdit.flights)
            .pipe(finalize(() => {
                this.saving = false;
                this.security.setLoading(false);
            }))
            .subscribe({
                next: resp => {
                    this.commonsService.setGtag('Flight log', 'Flight addition');
                    confirmAlert({
                        icon: 'success',
                        title: 'Updated',
                        text: this.flightLogToEdit.flights.length +' flights have been added to your flight log',
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    }, true ).then( resp => {
                        this.editEvent.emit(true);
                    });
                },
                error: err => confirmAlert({
                    icon: 'error',
                    title: 'Error',
                    text: `There was a problem adding your flights to flight log # ${ this.flightLogToEdit.flightLogNumber }`,
                    showCancelButton: false,
                    confirmButtonText: "OK",
                    }, true)
            });
        } else {
            //Create new flight log
            this.flightLogToCreate.flights = this.flights;
            this.flightLogToCreate.bookImage = this.selectedFile;
            this.flightLogService.createFlightLog(this.flightLogToCreate)
            .pipe(finalize(() => {
                this.saving = false;
                this.security.setLoading(false);
            }))
            .subscribe({
                next: resp => {
                    this.commonsService.setGtag('Flight log', 'Flight log creation');
                    confirmAlert({
                        icon: 'success',
                        title: 'Created',
                        text: 'Your flight has been created successfully',
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    }, true).then( resp => {
                        this.flightLogService.setState(true);
                        this.init();
                    });
                },
                error: err => 
                    confirmAlert({
                        icon: 'error',
                        title: 'Error',
                        text: `There was a problem creating your flight log # ${ this.flightLogToCreate.flightLogNumber }`,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    }, true)
            });
        }
    }

    public updateFlight() {
        this.commonsService.setGtag('Flight log', 'Flight update');

        this.flightLogToEdit.flights[0].dates.startTime = this.formatTimeField(this.formflightLog.get('startTime').value);
        this.flightLogToEdit.flights[0].dates.endTime = this.formatTimeField(this.formflightLog.get('endTime').value);
        this.flightLogToEdit.flights[0].dates.blockTime = this.formflightLog.get('blockTime').value;
        this.security.setLoading(true);
        this.flightLogService.updateFlightLog(this.flightLogToEdit.flights[0].dates).subscribe({
            next: resp => {
                confirmAlert({
                    icon: 'success',
                    title: 'Updated',
                    text: 'Your flight has been updated successfully',
                    showCancelButton: false,
                    confirmButtonText: "OK",
                }, true)
                this.editEvent.emit(true);
                this.security.setLoading(false);
            },
            error: err => {
                confirmAlert({
                    icon: 'error',
                    title: 'Error',
                    text: 'There was a problem updating your flight',
                    showCancelButton: false,
                    confirmButtonText: "OK",
                }, true);
                this.security.setLoading(false);
            }
        });
    }

    public replaceFile(){
        this.commonsService.setGtag('Flight log', 'File replacement');

        if(!this.selectedFile){
            confirmAlert({
                icon: 'info',
                title: 'No file selected',
                text: 'You must select a file',
                showCancelButton: false,
                confirmButtonText: "OK",
            }, true);
            return;
        }
        this.security.setLoading(true);
        this.flightLogService.uploadFile(this.selectedFile, this.flightLogToEdit.flightLogNumber)
            .then(() => {
                confirmAlert({
                    icon: 'success',
                    title: 'Updated',
                    text: 'Your image has been replaced',
                    showCancelButton: false,
                    confirmButtonText: "OK",
                }, true);
                this.security.setLoading(false);
            })
            .catch(err => {
                this.security.setLoading(false);
                console.log(err);
            });
    }

    public isFlightLogAlreadyCreated(event: any){
        let value: string = event.target.value;

        if(value == '') return;

        this.flightLogService.checkIfFlightLogExists(Number(value)).subscribe(
            (exists: boolean) => {
                if(exists){
                    confirmAlert({
                        icon: 'warning',
                        title: 'Already created',
                        text: `A flight log with this number "${ Number(value) }" is already created, you can edit it`,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    }, true);
                    this.formflightLog.controls['flightLogNumber'].setValue('', { emitEvent: false });
                    this.flightLogToCreate.flightLogNumber = null;
                }
                else{
                    this.flightLogToCreate.flightLogNumber = this.formflightLog.get('flightLogNumber').value;
                }
            },
        );
    }

    private getDifferenceTime(start: string, end: string) {
        if (!start || !end) return;
        let startTime = moment(start, "HH:mm"),
        endTime = moment(end, "HH:mm");
        let hrs = moment.utc(endTime.diff(startTime)).format("HH");
        let min = moment.utc(endTime.diff(startTime)).format("mm");
        this.formflightLog.patchValue({blockTime: [hrs, min].join(':')});
    }

    private setForm() {
        const disableFields = [ "fleet", "operator", "aircraftRegistrationNumber", "arrival", "blockTime"];
        const disableFieldsForEditFlight = [ "flightLogNumber", "flightDate", "operator", "flightNumber", "departure"];
        const disableFieldsForAddFlight = ["flightLogNumber"];

        if(this.isEditingFlight()){
            disableFields.push(...disableFieldsForEditFlight);
        }

        if(this.isAddingFlight()){
            disableFields.push(...disableFieldsForAddFlight);
        }

        for (const field of disableFields) {
            this.formflightLog.controls[field].disable();
        }
    }

    private listenForm() {
        this.formflightLog.controls.startTime.valueChanges.subscribe(input => {
            this.getDifferenceTime(input, this.formflightLog.get('endTime').value);
        });
        this.formflightLog.controls.endTime.valueChanges.subscribe(input => {
            this.getDifferenceTime(this.formflightLog.get('startTime').value, input);
        });

        for (const iterator of ["flightDate", "flightNumber", "departure", "arrival"]) {
            this.formflightLog.controls[iterator].valueChanges.subscribe(input => {
                if (iterator == 'arrival' && this.multiplesFlightsFound) {
                    this.changeArrival();
                } else {
                    this.flightToFind[iterator== 'departure'? "departureIataCode": iterator] = input;
                    this.findFlight();
                }
            });
        }
    }

    private formatTimeField(str: string) {
        str = str.replace(':', '');
        return [str.slice(0, 2), ':', str.slice(2)].join('');
    }

    private emptyCurrentFlight(){
        this.flightToFind.empty();
        this.currentFlight.id = null;
        this.currentFlight.flightDate = null;
        this.currentFlight.operatingAirlineCode = null;
        this.currentFlight.comercialAirlineCode = null;
        this.currentFlight.flightNumber = null;
        this.currentFlight.fleet = null;
        this.currentFlight.aircraftRegistrationNumber = null;
        this.currentFlight.departure = null;
        this.currentFlight.arrival = null;
        this.currentFlight.dates = new FlightLogDatesInfo();
        this.currentFlight.dates.startTime = null;
        this.currentFlight.dates.endTime = null;
        this.currentFlight.dates.blockTime = null;
        this.currentFlight.trip = [];
    }

    public isAddingFlight(): boolean{
        return this.editMode == 'add-flight';
    }

    public isEditingFlight(): boolean{
        return this.editMode == 'edit-flight';
    }

    changeArrival() {
        let arrival = this.formflightLog.get('arrival').value;
        this.currentFlight = this.flightsFound.find( f => f.arrival == arrival);
        this.setFormFromCurrentFlight();
    }
    
    setFormFromCurrentFlight() {
        let inputs = ["fleet", "aircraftRegistrationNumber"];
        if (!this.multiplesFlightsFound) inputs.push('arrival');
        for (const iterator of inputs) {
            this.formflightLog.controls[iterator].setValue(this.currentFlight[iterator],{ emitEvent: false });
        }
    }

    clickPicker() {
        this.datePicker.open();
    }

    public onFileSelected(event: any) {
        if (event.target.files.length > 0) {
            let file = event.target.files[0] || null;
            if (file?.size <= 5000000) {
                this.selectedFile = file;
                this.formflightLog.controls['bookImage'].setValue(file?.name, { emitEvent: false });
            } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'The attachment exceeds the size limit'
                });
            }
        }
    }

}