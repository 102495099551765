export class DispatchSubcomponent<T>{

    private subcomponent: T;
    private state: string;
    private showModal: boolean;
    private alertMessage: string;

    constructor(){
        this.state = 'waiting';
        this.showModal = false;
    }

    public getSubcomponent(): T{
        return this.subcomponent;
    }

    public setSubcomponent(subcomponent: T){
        this.subcomponent = subcomponent;
    }

    public success(): boolean{
        return this.state == 'success';
    }

    public isWaiting(): boolean{
        return this.state == 'waiting';
    }

    public hasError(): boolean{
        return this.state == 'error';
    }

    public setState(state: string){
        this.state = state;
    }

    public getState(): string{
        return this.state;
    }

    public showTheModal(){
        this.showModal = true;
    }

    public hideTheModal(){
        this.showModal = false;
    }

    public isModalBeingShown(): boolean{
        return this.showModal;
    }

    public setAlertMessage(message: string){
        this.alertMessage = message;
    }

    public getAlertMessage(){
        return this.alertMessage;
    }

    public notAvailableMessage(){
        this.alertMessage = 'Not available';
    }

    public errorMessage(){
        this.alertMessage = 'There was an error';
    }
 }