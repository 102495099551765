import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, Injectable, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CdkTableModule } from '@angular/cdk/table';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LocationStrategy, PathLocationStrategy, TitleCasePipe } from '@angular/common';

//Material
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion'
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule, MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

// libraries
import { NgChartsModule } from 'ng2-charts';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';

// pipes
import { FormatDateRolPipe } from './modules/pilot/commons/pipes/format-date-rol.pipe';
import { DateToBackPipe } from './modules/pilot/commons/pipes/date-to-back.pipe';
import { ObjectProcessLoopPipe } from './modules/pilot/commons/pipes/object-process-loop.pipe';
import { FormatDatePipe } from './modules/pilot/commons/pipes/format-date.pipe';
import { BooleanTranslatorPipe } from './modules/pilot/commons/pipes/boolean-translator.pipe';
import { LowStabilizationPipe } from './modules/pilot/commons/pipes/low-stabilization.pipe';
import { SafeUrlPipe } from './modules/pilot/commons/pipes/safe-url.pipe';
import { MapReaderPipe } from './modules/pilot/commons/pipes/map-reader.pipe';
import { DateAgoPipe } from './modules/pilot/commons/pipes/date-ago.pipe';

// services
import { CookieService } from 'ngx-cookie-service';
import { SecurityService } from './core/services/security/security.service';
import { TokenInterceptorService } from './core/services/interceptor/token-interceptor.service';

// components
import { AppComponent } from './app.component';
import { HomeComponent } from './modules/pilot/components/home/home.component';
import { SafetyComponent } from './modules/pilot/components/safety/safety.component';
import { ReportsComponent } from './modules/pilot/components/reports/reports.component';
import { LoginComponent } from './modules/pilot/components/login/login.component';
import { NavbarComponent } from './standalone-modules/navbar/navbar.component';
import { LoadingComponent } from './standalone-modules/loading/loading.component';
import { SideNavComponent } from './standalone-modules/side-nav/side-nav.component';
import { SafetyListComponent } from './modules/pilot/components/safety/safety-list/safety-list/safety-list.component';
import { RankingAirportsComponent } from './modules/pilot/components/safety/safety-indicators/ranking-airports/ranking-airports.component';
import { TopUaComponent } from './modules/pilot/components/safety/safety-indicators/top-ua/top-ua.component';
import { GpwsComponent } from './modules/pilot/components/safety/safety-indicators/gpws/gpws.component';
import { ProfileModalComponent } from './modules/pilot/components/modals/profile-modal/profile-modal.component';
import { NewsletterComponent } from './modules/pilot/components/newsletter/newsletter.component';
import { NewsletterModalComponent } from './modules/pilot/components/modals/newsletter-modal/newsletter-modal.component';
import { HelpModalComponent } from './modules/pilot/components/modals/help-modal/help-modal.component';
import { StateNoDataComponent } from './modules/pilot/components/home/animations/state-no-data/state-no-data.component'
import { ProfileComponent } from './modules/pilot/components/profile/profile.component';
import { LicenseCardListComponent } from './modules/pilot/components/profile/license-card-list/license-card-list.component';
import { DocumentCardListComponent } from './modules/pilot/components/profile/document-card-list/document-card-list.component';
import { VisaCardListComponent } from './modules/pilot/components/profile/visa-card-list/visa-card-list.component';
import { FaqComponent } from './modules/pilot/components/faq/faq.component';
import { OtherFlightsComponent } from './modules/pilot/components/other-flights/other-flights.component';
import { DispatchComponent } from './modules/pilot/components/dispatch/dispatch.component';
import { MessageDispatchDialogComponent } from './modules/pilot/components/dispatch/message-dispatch-dialog/message-dispatch-dialog.component';
import { AcknowledgeComponent } from './modules/pilot/components/dispatch/message-dispatch-dialog/ack-confirm/ack-confirm.component';
import { RouteReportComponent } from './modules/pilot/components/dispatch/route-report/route-report.component';
import { LimopsReportComponent } from './modules/pilot/components/dispatch/limops-reports/limops-report.component';
import { LimopsReportDetailComponent } from './modules/pilot/components/dispatch/limops-reports/limops-report-detail/limops-report-detail.component';
import { DispatchSectionComponent } from './modules/pilot/components/dispatch/dispatch-section/dispatch-section.component';
import { BtmReportComponent } from './modules/pilot/components/dispatch/btm-report/btm-report.component';
import { BtmReportViewComponent } from './modules/pilot/components/dispatch/btm-report/btm-report-view/btm-report-view.component';
import { OtherDispatchFolderComponent } from './modules/pilot/components/dispatch/other-dispatch-folder/other-dispatch-folder.component';
import { GroundSupportStatusComponent } from './modules/pilot/components/dispatch/ground-support-status/ground-support-status.component';
import { FlightLogComponent } from './modules/pilot/components/flight-log/flight-log.component';
import { LoadFlightLogComponent } from './modules/pilot/components/flight-log/load-flight-log/load-flight-log.component';
import { MyFlightLogsComponent } from './modules/pilot/components/flight-log/my-flight-logs/my-flight-logs.component';
import { LastMinuteChangeDialogComponent } from './modules/pilot/components/dispatch/last-minute-change-dialog/last-minute-change-dialog.component';
import { BackHomeComponent } from './standalone-modules/back-home/back-home.component';
import { PastFlightsComponent } from './modules/pilot/components/flight-log/past-flights/past-flights.component';
import { RosterFcardSm } from './modules/pilot/components/rol/roster-fcard-sm/roster-fcard-sm.component';
import { UploadDocumentsComponent } from './modules/pilot/components/profile/upload-documents/upload-documents.component';
import { AddFlightLogsComponent } from './modules/pilot/components/flight-log/add-flight-logs/add-flight-logs.component';
import { LoginQrComponent } from './modules/pilot/components/login-qr/login-qr.component';
import { GraphicComponent } from './modules/pilot/components/safety/safety-indicators/safety-graphic/safety-graphic.component';
import { NotificationCommunicationsComponent } from './modules/pilot/components/notifications/notification-communications/notification-communications.component';
import { AqdModalComponent } from './modules/pilot/components/modals/aqd-modal/aqd-modal.component';
import { CalculatorComponent } from './modules/pilot/components/calculator/calculator.component';
import { BannersComponent } from './modules/pilot/components/home/banners/banners.component';
import { CommunicationsComponent } from './modules/pilot/components/home/communications/communications.component';
import { OnBoardingComponent } from './standalone-modules/on-boarding/on-boarding.component';
import { FeedbackModalComponent } from './modules/pilot/components/modals/feedback-modal/feedback-modal.component';
import { NotificationsComponent } from './modules/pilot/components/notifications/notifications.component';
import { NotificationComponent } from './modules/pilot/components/notifications/notification/notification.component';
import { SafetyIndicatorsComponent } from './modules/pilot/components/safety/safety-indicators/safety-indicators.component';
import { IncidentReportComponent } from './modules/pilot/components/home/incident-report/incident-report.component';
import { StarsRatingComponent } from './standalone-modules/stars-rating/stars-rating.component';
import { MR2ReportComponent } from './modules/pilot/components/dispatch/mr2-report/mr2-report.component';
import { MaintenixReportComponent } from './modules/pilot/components/dispatch/maintenix-report/maintenix-report.component';
import { MaintenixReportSection } from './modules/pilot/components/dispatch/maintenix-report/maintenix-report-subcomponent/maintenix-report-section.component';


import { AppRoutingModule } from './app-routing.module';
import { AlertsModule } from './standalone-modules/alerts/alerts.module';
import { PilotConstants } from './modules/pilot/commons/pilot-constants';
import { ConstantsTools } from './modules/tools/commons/constants';

import { IonicModule } from '@ionic/angular';

import { environment } from 'src/environments/environment';
import { DirectosRecommendationComponent } from './modules/pilot/components/dispatch/directos/diretos-recommendation.component';
import { FeedbackComparedLsComponent } from './modules/pilot/components/dispatch/message-dispatch-dialog/feedback-compared-ls/feedback-compared-ls.component';
import { ReportsGuideModalComponent } from './modules/pilot/components/reports/reports-guide-modal/reports-guide-modal.component';
import { NoDataComponent } from './standalone-modules/no-data/no-data.component';
import { RolModule } from './modules/pilot/components/rol/rol.module';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        SafetyComponent,
        ReportsComponent,
        ReportsGuideModalComponent,
        LoginComponent,
        NavbarComponent,
        FormatDatePipe,
        LoadingComponent,
        ObjectProcessLoopPipe,
        SideNavComponent,
        BooleanTranslatorPipe,
        LowStabilizationPipe,
        SafetyListComponent,
        RankingAirportsComponent,
        TopUaComponent,
        GpwsComponent,
        DateToBackPipe,
        ProfileModalComponent,
        NewsletterComponent,
        NewsletterModalComponent,
        FormatDateRolPipe,
        HelpModalComponent,
        StateNoDataComponent,
        ProfileComponent,
        LicenseCardListComponent,
        DocumentCardListComponent,
        VisaCardListComponent,
        FaqComponent,
        OtherFlightsComponent,
        DispatchComponent,
        MessageDispatchDialogComponent,
        AcknowledgeComponent,
        RouteReportComponent,
        LimopsReportComponent,
        DispatchSectionComponent,
        BtmReportComponent,
        BtmReportViewComponent,
        MapReaderPipe,
        OtherDispatchFolderComponent,
        LimopsReportDetailComponent,
        GroundSupportStatusComponent,
        DateAgoPipe,
        FlightLogComponent,
        LoadFlightLogComponent,
        MyFlightLogsComponent,
        CalculatorComponent,
        BannersComponent,
        CommunicationsComponent,
        MR2ReportComponent,
        MaintenixReportComponent,
        MaintenixReportSection,
        DirectosRecommendationComponent,
        OnBoardingComponent,
        FeedbackModalComponent,
        NotificationsComponent,
        NotificationComponent,
        SafetyIndicatorsComponent,
        IncidentReportComponent,
        StarsRatingComponent,
        LastMinuteChangeDialogComponent,
        BackHomeComponent,
        PastFlightsComponent,
        RosterFcardSm,
        UploadDocumentsComponent,
        SafeUrlPipe,
        AddFlightLogsComponent,
        LoginQrComponent,
        GraphicComponent,
        NotificationCommunicationsComponent,
        AqdModalComponent,
        FeedbackComparedLsComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    imports: [
        AppRoutingModule,
        BrowserModule,
        IonicModule.forRoot({
            mode: 'ios'
        }),
        BrowserAnimationsModule,
        NgxMaskDirective,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
        MatSliderModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatSidenavModule,
        MatExpansionModule,
        MatDividerModule,
        MatDialogModule,
        MatTooltipModule,
        MatToolbarModule,
        MatChipsModule,
        MatButtonToggleModule,
        NgChartsModule,
        MatIconModule,
        ReactiveFormsModule,
        FormsModule,
        CdkTableModule,
        MatTableModule,
        MatPaginatorModule,
        MatTabsModule,
        MatSnackBarModule,
        MatProgressBarModule,
        MatMenuModule,
        MatCardModule,
        MatCheckboxModule,
        MatListModule,
        MatBottomSheetModule,
        NgxExtendedPdfViewerModule,
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
        MatBadgeModule,
        MatButtonModule,
        AlertsModule,
        QRCodeModule,
        NoDataComponent,
        RolModule
    ],
    providers: [
        CookieService,
        PilotConstants,
        ConstantsTools,
        DateToBackPipe,
        SecurityService,
        TitleCasePipe,
        HomeComponent,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
        {
            provide: MAT_CHIPS_DEFAULT_OPTIONS,
            useValue: {
                separatorKeyCodes: [ENTER, COMMA]
            }
        },
        provideEnvironmentNgxMask(),
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }
