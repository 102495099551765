import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output, ViewChild, Input } from '@angular/core';

import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import 'jspdf-autotable';
import { LimopsResponse } from 'src/app/modules/pilot/domain/dispatch/limops-response';


@Component({
  selector: 'limops-report-detail',
  templateUrl: './limops-report-detail.component.html',
  styleUrls: ['./limops-report-detail.component.css', '../limops-report.component.css'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        animate('0.5s', keyframes([
          style({ transform: 'translateX(100%)' }),
          style({ transform: 'translateX(0%)' }),
        ])),
        transition(':leave', [
          animate('0.5s', keyframes([
            style({ transform: 'translateX(0%)' }),
            style({ transform: 'translateX(100%)' })
          ]))
        ])
      ])]
    )]
})
export class LimopsReportDetailComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() limopsResponse: LimopsResponse;
  @Input() typeReport: string;
  @Input() codeBar: string;

  public report: any;

  public displayedColumnsOpLim: string[] = ['Bar Code', 'Moc No.',  'A/C Registration', 'A/C Subtype','Defect Found', 'MEL', 'Level', 'Open Date', 'Procedure','Equipment', 'ETOPS', 'CAT', 'RVSM', 'RNAV', 'RNP', 'Cargo Affect'];
  public displayedColumnsMainPro: string[] = ['Bar Code', 'Moc No.', 'A/C Registration',  'A/C Subtype', 'Defect Found', 'MEL', 'Level', 'Open Date', 'Action Required', 'Repetetive Inspection'];
  public displayedColumnsPassCon: string[] = ['Bar Code', 'Moc No.', 'A/C Registration', 'A/C Subtype', 'Defect Found','MEL','Level', 'Open Date', 'Seat Category'];
  
  
  public columnNames;
  public reportTitle: string;
  public columnValues;


  @Output() closeDetailModalEvent = new EventEmitter<any>();

  ngOnInit() {
    this.selectReportToDetailView();
  }

 selectReportToDetailView(){
  if(this.typeReport == "O"){
    this.limopsResponse.operationalLimitationsReport.forEach(report =>{
      this.reportTitle = "Operational Limitations Report";
      this.columnNames = this.displayedColumnsOpLim;
      this.fillData(report);

    })
  }
  else if(this.typeReport == "M"){
    this.limopsResponse.maintenanceProceduresReport.forEach(report =>{
      this.reportTitle = "Maitenance Procedures Report";
      this.columnNames = this.displayedColumnsMainPro;

      this.fillData(report);

    })
  }else if(this.typeReport == "PCI"){
    this.limopsResponse.passengerConvenienceItemsReport.forEach(report =>{
      this.reportTitle = "Passenger Convenience Items Report";
      this.columnNames = this.displayedColumnsPassCon;

     this.fillData(report);
    })
  }

 }

  fillData(report: any){
    if(report.barCode == this.codeBar){
      this.report = report;
      this.columnValues = Object.values(this.report);
    }
  }
  

  closeModal() {
    this.closeDetailModalEvent.emit();
  }

}
