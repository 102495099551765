import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConstantsTools } from "../commons/constants";
import { environment } from "src/environments/environment";
import { News } from "../domain/news.model";
import { ICommunications } from "../../pilot/domain/remote-config/communications";

@Injectable({
    providedIn: 'root'
})
export class NewsService {

    constructor(
        private httpClient: HttpClient,
        private constants: ConstantsTools
    ) { }

    async save(news: News){
        const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.COMMUNICATION_API_NEW_NEWS}/news-create`;
        
        return await this.httpClient.post<void>(URL, news).toPromise();
    }

    getAllNews(){
        const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.COMMUNICATION_API_NEW_NEWS}/news`;
        return  this.httpClient.get<Array<News>>(URL);

    }


    getAllNewsForPilot(){
        const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.COMMUNICATION_API_NEW_NEWS}/news-for-pilot`;
        return  this.httpClient.get<Array<ICommunications>>(URL);
    }

    async update(news: News){
        const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.COMMUNICATION_API_NEW_NEWS}/news-update`;
        
        return await this.httpClient.post<void>(URL, news).toPromise();
    }

    deleteNew(news: News){
        const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.COMMUNICATION_API_NEW_NEWS}/news-delete`;
        return  this.httpClient.post<void>(URL, news);

    }

    getDocOfNew(requestedFile: String){
        const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.COMMUNICATION_API_NEW_NEWS}/news-document`;
        return this.httpClient.post(URL, requestedFile, { responseType: "text" });
    }

    publishNew(news: News){
        const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.COMMUNICATION_API_NEW_NEWS}/news-publish`;
        return  this.httpClient.post<void>(URL, news);

    }
    
}