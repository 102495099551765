import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { PilotConstants } from 'src/app/modules/pilot/commons/pilot-constants';
import { CommonsService } from 'src/app/core/services/commons/commons.service';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.css']
})
export class BannersComponent implements OnChanges {

  @Input() contractCodeOperator: string = '';
  @Input() flightlogsToSend: number = 0;
  hideSection: boolean = this.constants.hideSectionsStores;
  banners = this.constants.HOME_BANNERS;
  bannersFilter = [];

  constructor(
    private router: Router, 
    private commonsService: CommonsService,
    private constants: PilotConstants
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.bannersFilter = this.banners.filter( b => b.id == 4? this.contractCodeOperator == "LP" : (this.hideSection && (b.id == 1 || b.id == 3))? false: true);
  }

  navigate(route: string, tag: string) {
    this.commonsService.setGtag("Home", tag);
    this.router.navigate([route]);
  }

}
