import { Component, OnInit, Input, SimpleChanges, HostListener } from '@angular/core';
import { DataChart } from '../../../../domain/data-chart';
import { ChartConfiguration, ChartType } from 'chart.js';

@Component({
  selector: 'app-gpws',
  templateUrl: './gpws.component.html'
})
export class GpwsComponent implements OnInit {

  @Input("gpwsMode1") gpwsMode1: any;
  @Input("gpwsMode2") gpwsMode2: any;
  @Input("gpwsMode3") gpwsMode3: any;
  @Input("gpwsMode4") gpwsMode4: any;
  @Input("gpwsMode5") gpwsMode5: any;
  @Input("gpwsTadCaution") gpwsTadCaution: any;
  @Input("gpwsTadWarning") gpwsTadWarning: any;
  @Input("gpwsTCF") gpwsTCF: any;
  @Input("showLoading") showLoading:any;

  public chartWidth: number;

  theresData: boolean = true;

  public barChartLegend = true;
  public barChartType:ChartType = 'bar';
  public barChartData: ChartConfiguration<'bar'>['data'] = {
    datasets: []
  }

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: false,
    maintainAspectRatio: false,
    scales : {
      y: {
        stacked: true,
        ticks: {
          stepSize: 1
        }
      },
      x: {
        stacked: true,
        ticks: {
          stepSize: 1
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          filter: (legendItem, chartData) => (!chartData.datasets[legendItem.datasetIndex].data.every(item => item === 0)),
          boxWidth: 7,
          usePointStyle: true,
          font:{
            size:12
          }
        }
      }
    }
  };

  public barChartColors:Array<any> = [
    { //MODE 1
      borderColor: 'rgba(102, 178, 230, 0.9)',
      backgroundColor: 'rgba(102, 178, 230, 0.9)',
      pointBackgroundColor: 'rgba(102, 178, 230, 0.9)',
      pointHoverBorderColor: '#fff'
    },
    { // MODE 2
      borderColor: 'rgb(151, 231, 125, 0.9)',
      backgroundColor: 'rgb(151, 231, 125, 0.9)',
      pointBackgroundColor: 'rgb(151, 231, 125, 0.9)',
      pointHoverBorderColor: '#fff'
    },
    {// MODE 3
      borderColor: 'rgba(82, 215, 38, 0.9)',
      backgroundColor: 'rgba(82, 215, 38, 0.9)',
      pointBackgroundColor: 'rgba(82, 215, 38, 0.9)',
      pointHoverBorderColor: '#fff'
    },
    {// MODE 4
      borderColor: 'rgba(198, 8, 209, 0.9)',
      backgroundColor: 'rgba(198, 8, 209, 0.9)',
      pointBackgroundColor: 'rgba(198, 8, 209, 0.9)',
      pointHoverBorderColor: '#fff'
    },
    {// MODE 5
      borderColor: 'rgba(255, 191, 0 0.9)',
      backgroundColor: 'rgba(255, 191, 0, 0.9)',
      pointBackgroundColor: 'rgba(255, 191, 0, 0.9)',
      pointHoverBorderColor: '#fff'
    },
    {// TAD CAUTION
      borderColor: 'rgba(255, 255, 65, 0.9)',
      backgroundColor: 'rgba(255, 255, 65, 0.9)',
      pointBackgroundColor: 'rgba(255, 255, 65, 0.9)',
      pointHoverBorderColor: '#fff'
    },
    {// TAD WARNING
      borderColor: 'rgba(255, 0, 24, 0.9)',
      backgroundColor: 'rgba(255, 0, 24, 0.9)',
      pointBackgroundColor: 'rgba(255, 0, 24, 0.9)',
      pointHoverBorderColor: '#fff'
    },
    {// TCF
      borderColor: 'rgba(9, 43, 156, 0.9)',
      backgroundColor: 'rgba(9, 43, 156, 0.9)',
      pointBackgroundColor: 'rgba(9, 43, 156, 0.9)',
      pointHoverBorderColor: '#fff'
    }
  ];
  
  ngOnInit() {
    this.setChartWidth();
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setChartWidth();
  }

  setChartWidth(){
    if(screen.width > 767){
      this.chartWidth = 680;
    }
    else{
      this.chartWidth = screen.width > 630 ? 500 : screen.width - (screen.width * 0.2);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.gpwsMode1 || this.gpwsMode2 || this.gpwsMode3 || this.gpwsMode4 ||
       this.gpwsMode5 || this.gpwsTadCaution || this.gpwsTadWarning || this.gpwsTCF 
    ){   
      this.barChartData = { datasets: [] };
      this.setGpwsIndicators();
    }
  }

  setGpwsIndicators(){
    //check if there's data 
    this.checkData();
    
    //set labels on x axys
    this.barChartData.labels = this.gpwsMode1?.periodList;

    //Mode 1 line
    let lineGpwsMode1:DataChart = new DataChart();
    lineGpwsMode1.data = this.gpwsMode1?.quantityList;
    lineGpwsMode1.label = "Excessive Rate Of Descent";
    lineGpwsMode1 = { ...lineGpwsMode1, ...this.barChartColors[0] };

    //Mode 2 line
    let lineGpwsMode2:DataChart = new DataChart();
    lineGpwsMode2.data = this.gpwsMode2?.quantityList;
    lineGpwsMode2.label = "Excessive Terrain Closure Rate";
    lineGpwsMode2 = { ...lineGpwsMode2, ...this.barChartColors[1] };

    // //Mode 3 line
    let lineGpwsMode3:DataChart = new DataChart();
    lineGpwsMode3.data = this.gpwsMode3?.quantityList;
    lineGpwsMode3.label = "Altitude Loss After Takeoff";
    lineGpwsMode3 = { ...lineGpwsMode3, ...this.barChartColors[2] };

    // //Mode 4 line
    let lineGpwsMode4:DataChart = new DataChart();
    lineGpwsMode4.data = this.gpwsMode4?.quantityList;
    lineGpwsMode4.label = "Unsafe Terrain Clearance";
    lineGpwsMode4 = { ...lineGpwsMode4, ...this.barChartColors[3] };

    // //Mode 5 line
    let lineGpwsMode5:DataChart = new DataChart();
    lineGpwsMode5.data = this.gpwsMode5?.quantityList;
    lineGpwsMode5.label = "Descent Below Glideslope";
    lineGpwsMode5 = { ...lineGpwsMode5, ...this.barChartColors[4] };

    // //Tad Caution line
    let lineGpwsTadCaution:DataChart = new DataChart();
    lineGpwsTadCaution.data = this.gpwsTadCaution?.quantityList;
    lineGpwsTadCaution.label = "Terrain Awareness Caution";
    lineGpwsTadCaution = { ...lineGpwsTadCaution, ...this.barChartColors[5] };

    // //Tad Warning
    let lineGpwsTadWarning:DataChart = new DataChart();
    lineGpwsTadWarning.data = this.gpwsTadWarning?.quantityList;
    lineGpwsTadWarning.label = "Terrain Awareness Warning";
    lineGpwsTadWarning = { ...lineGpwsTadWarning, ...this.barChartColors[6] };

    // //Tad Tcf
    let lineGpwsTcf:DataChart = new DataChart();
    lineGpwsTcf.data = this.gpwsTCF?.quantityList;
    lineGpwsTcf.label = "Terrain Clearance Floor"; 
    lineGpwsTcf = { ...lineGpwsTcf, ...this.barChartColors[7] };

    //push to graphic
    this.barChartData.datasets.push(lineGpwsMode1, lineGpwsMode2, lineGpwsMode3, lineGpwsMode4, lineGpwsMode5, lineGpwsTadCaution, lineGpwsTadWarning, lineGpwsTcf);
  }

  checkData(){
    this.theresData = this.gpwsMode1?.quantityList?.length != 0;
  }

}
