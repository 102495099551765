
export class FlightInfo {

    flight: string;
    acType: string;
    acRegistration: string;
    parkingDeparture: string;

    constructor(
        flight: string,
        acType: string,
        acRegistration: string,
        parkingDeparture: string
    ) {
        this.flight = flight;
        this.acType = acType
        this.acRegistration = acRegistration;
        this.parkingDeparture = parkingDeparture;
    }

}