import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PilotConstants } from 'src/app/modules/pilot/commons/pilot-constants';
import { environment } from 'src/environments/environment';

type TParamRemoteConfig = "communications" | "messages" | "version" | "allowed_operators_documents";
@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {

  constructor(
    private httpClient: HttpClient, 
    private constants: PilotConstants
  ) { }

  getParameterRemoteConfig(key: TParamRemoteConfig): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.REMOTE_CONFIG_PATH}`;
    const params = new HttpParams().set('key', key);
    return this.httpClient.get<any>(URL, { params });
  }
}
