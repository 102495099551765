import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { BehaviorSubject } from 'rxjs';
import { SimpleDialog } from 'src/app/core/services/dialog/simple-dialog.service';


@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  public statusObs: BehaviorSubject<boolean> = new BehaviorSubject(true);

  handler: any;

  constructor(
    private simpleDialog: SimpleDialog,
  ) {
    this.initNetworkEvents();
    this.getStatusNetwork();
  }

  public get getValue() {
    return this.statusObs.value;
  }

  public getState() {
    return this.statusObs.asObservable();
  }

  public setState(status: boolean) {
    let previousValue = this.getValue;
    if (status && !previousValue) {
      this.simpleDialog.snackBarMessage('Connection has been restored');
    }
    this.statusObs.next(status);
  }

  async initNetworkEvents() {
    let state = await this.getStatusNetwork();
    this.setState(state);
    this.handler = Network.addListener('networkStatusChange', (status) => {
      this.setState(status.connected);
    });
  }

  async getStatusNetwork(): Promise<boolean> {
    let status = await Network.getStatus();
    return status.connected;
  }

  removeListener() {
    this.handler?.remove();
  }
}