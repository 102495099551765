export class License {
    type: string;
    code: string;
    number: string;
    issuingAuthority: string;
    issueDate: Date;
    expirationDate: Date;

    public constructor(type: string, code: string, number: string, issuingAuthority: string, expirationDate: Date, issueDate: Date) {
        this.type = type;
        this.code = code;
        this.number = number;
        this.issuingAuthority = issuingAuthority;
        this.expirationDate = expirationDate;
        this.issueDate = issueDate;
    }
}
