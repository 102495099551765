<div class="row light-box">
  <div class="col-12 text-center">
    <div class="row">
      <div class="col-12">
        <span class="material-icons">
          {{icon}}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b>{{title}}</b>
      </div>
    </div>
  </div>
</div>
