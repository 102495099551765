  <div class="container-fluid p-0" [@enterAnimation] style="height: 100%; overflow-x: scroll; background-color: #f6f9ff;">
    <mat-toolbar class="header">
      <mat-toolbar-row>
        <span class="material-icons clickable"(click)="closeModal()">chevron_left</span>
        <span class="latam_bold_italic">Documents</span>
      </mat-toolbar-row>
    </mat-toolbar>

    <div class="container mt-6 mb-4">
      <div>
        <h1>Limops Report</h1>
        <br>
      </div>

      <div>
    <mat-expansion-panel class="report-card mb-3">
      <mat-expansion-panel-header [collapsedHeight]="'4rem'" [expandedHeight]="'4rem'">
        <mat-panel-title>
          <span class="limops-section-title">Operational Limitations Report</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

        <div primarySectionBody>
          <span *ngIf="this.operational == 'no-report'" class="td-text regular-column-size"> There are no operational limitations to report</span>

          <div *ngFor="let report of dataLimops?.operationalLimitationsReport">
            <mat-expansion-panel class="report-subcategory mb-3"  (opened)="reportOpenModal('O', report.barCode)"  hideToggle>
              <mat-expansion-panel-header #panelHeaderSM (click)="panelHeaderSM._toggle();">
                <mat-panel-title>
                  <span  class="limops-section-title truncate-section-text ml-2">{{report.barCode}}</span>
                </mat-panel-title>
                <mat-icon class="subcategory-option mt-1">chevron_right</mat-icon>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </div>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="report-card mb-3">
      <mat-expansion-panel-header [collapsedHeight]="'4rem'" [expandedHeight]="'4rem'">
        <mat-panel-title>
          <span class="limops-section-title">Maitenance Procedures Report</span>
        </mat-panel-title>

      </mat-expansion-panel-header>
        <div primarySectionBody>
          <span *ngIf="this.maintenance == 'no-report'" class="td-text regular-column-size"> There are no operational limitations to report</span>

          <div *ngFor="let report of dataLimops?.maintenanceProceduresReport">
            <mat-expansion-panel class="report-subcategory mb-3" (opened)="reportOpenModal('M', report.barCode)" hideToggle>
              <mat-expansion-panel-header #panelHeaderSM (click)="panelHeaderSM._toggle();">
                <mat-panel-title>
                  <span  class="limops-section-title truncate-section-text ml-2">{{report.barCode}}</span>


                </mat-panel-title>
                <mat-icon class="subcategory-option mt-1">chevron_right</mat-icon>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </div>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="report-card mb-3">
      <mat-expansion-panel-header [collapsedHeight]="'4rem'" [expandedHeight]="'4rem'">
        <mat-panel-title>
          <span class="limops-section-title">Passenger Convenience Items Report</span>
        </mat-panel-title>

      </mat-expansion-panel-header>
        <div primarySectionBody>
          <span *ngIf="this.passenger == 'no-report'" class="td-text regular-column-size"> There are no operational limitations to report</span>
          <div *ngFor="let report of dataLimops?.passengerConvenienceItemsReport">


            <mat-expansion-panel class="report-subcategory mb-3" (opened)="reportOpenModal('PCI', report.barCode)" hideToggle>
              <mat-expansion-panel-header #panelHeaderSM (click)="panelHeaderSM._toggle();">
                <mat-panel-title>

                  <span  class="limops-section-title truncate-section-text ml-2">{{report.barCode}}</span>


                </mat-panel-title>
                <mat-icon class="subcategory-option mt-1">chevron_right</mat-icon>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </div>
        </div>
    </mat-expansion-panel>

     </div>
    </div>

    <div class="full-screen-modal" *ngIf="!this.hideDetail">
      <limops-report-detail [hidden]="this.hideDetail" (closeDetailModalEvent)="closeDetailViewModal($event)" [limopsResponse]="this.dataLimops" [typeReport]="this.type" [codeBar]="this.codeBar" ></limops-report-detail>
    </div>

 </div>





