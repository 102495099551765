import { ActivityDates } from "./activity-dates.model";
import { Catering } from "./catering.model";
import { FlightLeg } from "./flight-leg.model";
import * as moment from 'moment';

export class Activity {
    public sequence: string;
    public tat: string;
    public diff: string;
    public flightLeg: FlightLeg;
    public dates: ActivityDates;
    public code: string;
    public comment: string;
    public pax: string;
    public dutyDesignator: string;
    public description: string;
    public catering: Array<Catering>;
    public dispatchId: number;
    public isBlocked: boolean;

    //All parameters are optional, but you have to pass at least one of them to compare flights
    public equalFlight(flightNumber: number = null, flightDeparture: string = null, flightDate: string = null, flightOperator: string = null): boolean{
        if(!this.isFlight() || (!flightNumber && !flightDeparture && !flightDate && !flightOperator))
            return false;

        return (flightNumber ? flightNumber == this.flightLeg.flightNumber : true) &&
        (flightOperator? flightOperator == this.flightLeg.carrier : true) && 
        (flightDate ? flightDate == moment(this.flightLeg.departure.dates.utc).format('YYYY-MM-DD') : true) &&
        (flightDeparture ? flightDeparture == this.flightLeg.departure.iataCode : true)
    }

    public isFlight(): boolean{
        return this.flightLeg != null;
    }
}