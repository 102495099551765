import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { RouteReportService } from 'src/app/modules/pilot/services/route-report.service';
import * as moment from 'moment';
import { RouteReport } from 'src/app/modules/pilot/domain/dispatch/route-report.model';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { SubcomponentState } from 'src/app/modules/pilot/domain/dispatch/sub-component-state.model';

@Component({
  selector: 'route-report',
  templateUrl: './route-report.component.html',
  styleUrls: ['./route-report.component.css', '../dispatch.component.css'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        animate('0.5s', keyframes([
          style({ transform: 'translateX(100%)' }),
          style({ transform: 'translateX(0%)' }),
        ])),
        transition(':leave', [
          animate('0.5s', keyframes([
            style({ transform: 'translateX(0%)' }),
            style({ transform: 'translateX(100%)' })
          ]))
        ])
      ])]
    )]
})
export class RouteReportComponent implements OnInit {

  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static: false}) sort: MatSort;

  public displayedColumns: string[] = ['hour', 'flight', 'via', 'flightLevel', 'wind', 'temperature', 'turbulence', 'ice', 'subsidence', 'remarks'];

  public dataSource: any;
  private dataForExport: any;

  @Output() closeModalEvent = new EventEmitter();
  @Output() loadIsFinished = new EventEmitter<SubcomponentState>();

  constructor( private routeReportService: RouteReportService ) { }

  ngOnInit() {
    const today: string = moment().format("YYYY-MM-DD");
    const todaysTime: string = moment().format("HH:mm:ss");

    this.routeReportService.getAll(`${today}T00:00:00`, `${today}T${todaysTime}`).subscribe({
      next: resp => {
        if(resp.length == 0){
          this.loadIsFinished.emit(new SubcomponentState(this, 'not-available'));
          return;
        }
        this.fillTable(resp);
        this.loadIsFinished.emit(new SubcomponentState(this, 'success'));
      },
      error: err => {
        console.error('Error at RouteReportComponent ngOnInit: ', err);
        this.loadIsFinished.emit(new SubcomponentState(this, 'error'));
      }
    });
  }

  fillTable(data: RouteReport[]){

    this.dataForExport = data.map(rr => [rr.hour, rr.flight, rr.via, rr.fl, rr.wind, rr.temp, rr.turb, rr.ice, rr.subsidence, rr.remarks]);   
    
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  closeModal() {
    this.closeModalEvent.emit();
  }

  getPDF(): string{
    let doc = new jsPDF("l");

    doc.setFontSize(18);
    doc.text('Route report', 11, 8);
    doc.setTextColor(100);

    (doc as any).autoTable({
      head: [['Hour', 'Flight', 'Via', 'Flight Level', 'Wind', 'Temperature', 'Turbulence', 'Ice', 'Subsidence']],
      body: this.dataForExport,
      theme: 'striped'
    });

    return doc.output('datauristring').split(',')[1];
  }

}
