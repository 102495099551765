import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FlightLogInfo } from 'src/app/modules/pilot/domain/flight-log/flight-log-info.model';
import { FlightLog } from 'src/app/modules/pilot/domain/flight-log/flight-log.model';
import { SortedFlightLog } from 'src/app/modules/pilot/domain/flight-log/sorted-flight-log.model';
import { FlightLogService } from 'src/app/modules/pilot/services/flight-log.service';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { FlightLogState } from 'src/app/modules/pilot/domain/flight-log/flight-log-state.model';
import { confirmAlert } from 'src/app/core/utils/utils';
import { Subscription } from 'rxjs';
import { UiService } from 'src/app/modules/pilot/services/ui/ui.service';
import { SecurityService } from 'src/app/core/services/security/security.service';

@Component({
    selector: 'my-flight-logs',
    templateUrl: './my-flight-logs.component.html',
    styleUrls: ['./my-flight-logs.component.css'],
})
export class MyFlightLogsComponent implements OnInit, OnDestroy {

    @Output() editEvent = new EventEmitter<FlightLogState>();
    flightLogs: SortedFlightLog;
    loading: boolean = false;
    private flightsToDelete: Array<number> = [];
    private today = new Date(); 
    private flightLogSubs: Subscription;
    private refreshSubs: Subscription;

    constructor(
        private flightLogService: FlightLogService,
        private _ui: UiService,
        private security: SecurityService
    ) { }

    ngOnInit() {
        this.refreshSubs = this._ui.getStateRefresh().subscribe( state => {
            if (state.refresh)  {
              this.getMyFlightLogs(true);
            };
        });
        this.getMyFlightLogs();
        this.flightLogSubs = this.flightLogService.getState().subscribe(
            reDraw => {
                reDraw && this.getMyFlightLogs();
            }
        );
    }

    ngOnDestroy(): void {
        this.flightLogService.setState(false);
        this.flightLogSubs?.unsubscribe();
        this.refreshSubs?.unsubscribe();
    }

    getMyFlightLogs(refresh: boolean = false) {
        this.loading = true;
        this.flightLogService.getFlightLogsSorted()
        .pipe(finalize(() => this.loading = false))
        .subscribe({
            next: (resp: SortedFlightLog) => {
                this.flightLogs = resp;
                this.flightLogs.old.forEach( fl => {
                    const createdAt = moment(fl.createdAt);
                    const isPreviousMonth = moment().subtract(1, 'month').isSame(createdAt, 'month') || moment().month() === 0 && createdAt.isSame(moment().subtract(1, 'year').month(11), 'month');
                    fl.allowEdit = isPreviousMonth && this.today.getUTCDate() <= 5;
                });
                refresh && this._ui.setStateRefresh({ refresh: false, finishEvent: true });
            },
            error: error => {
                console.log(error);
                refresh && this._ui.setStateRefresh({ refresh: false, finishEvent: true });
            }
        });
    }


    emitEditFlightEvent(fl: FlightLog, flight: FlightLogInfo){
        fl.flights = [flight];
        this.editEvent.emit(new FlightLogState(fl, 'edit-flight'));
    }

    emitAddFlightEvent(fl: FlightLog){
        this.editEvent.emit(new FlightLogState(fl, 'add-flight'));
    }

    addFlightToDelete(event, flight: FlightLogInfo){

        if(event.options[0]._selected){
            this.flightsToDelete.push(flight.id);
        }
        else{
            this.flightsToDelete = this.flightsToDelete.filter(id => id != flight.id)
        }
    }

    async deleteFlights(old = false){
        if(this.flightsToDelete.length == 0){
            confirmAlert({
                icon: 'info',
                title: 'No flights selected',
                text: 'You have to select at least one flight to delete',
                showCancelButton: false,
                confirmButtonText: "OK",
            }, true );

            return;
        }
        let confirm = await confirmAlert({
            title: 'Are you sure to delete the flight(s) selected?',
            text: 'Confirm to delete',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
        });
        if (confirm) {
            this.security.setLoading(true);
            this.flightLogService.deleteFlights(this.flightsToDelete).subscribe({
                next: resp => {
                    this.security.setLoading(false);
                    confirmAlert({
                        icon: 'success',
                        title: 'Deleted',
                        text: `${this.flightsToDelete.length} flights have been deleted`,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    }, true ).then( resp => {
                        this.flightLogs[old? 'old': 'recent'].forEach(fl => fl.flights = fl.flights.filter(f => !this.flightsToDelete.includes(f.id)));
                        this.flightsToDelete = [];
                        this.flightLogService.setState(true);
                    });
                },
                error: err => {
                    confirmAlert({
                        icon: 'error',
                        title: 'Error',
                        text: 'There was a problem deleting your flights',
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    }, true);
                    this.security.setLoading(false);
                }
            });
        }
    }

    async deleteFlightLog(flightLogNumber: string){
        let confirm = await confirmAlert({
            title: `Are you sure to delete the flight log ${flightLogNumber}?`,
            text: 'Confirm to delete',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
        });

        if (confirm) {
            this.security.setLoading(true);
            this.flightLogService.deleteFlightLog(flightLogNumber).subscribe({
                next: () => {
                    this.security.setLoading(false);
                    confirmAlert({
                    icon: 'success',
                    title: 'Deleted',
                    text: `Flight log has been deleted`,
                    showCancelButton: false,
                    confirmButtonText: "OK",
                    }, true).then( resp => {
                        this.flightLogs['recent'] = this.flightLogs['recent'].filter(fl => fl.flightLogNumber != flightLogNumber);
                        this.flightLogService.setState(true);
                    });
                },
                error: () => {
                    confirmAlert({
                        icon: 'error',
                        title: 'Error',
                        text: 'There was a problem deleting your flight log',
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    }, true);
                    this.security.setLoading(false);
                }
            });
        }
    }
}