<main class="indicator_container m5">
    <h5 class="latam_bold t-gray-4">Top 5 Airports with events</h5>
    <div class="body_indicators" *ngIf="showLoading">
        <mat-progress-spinner style="margin: 0 auto" [color]="'accent'" [mode]="'indeterminate'" [value]="50">
        </mat-progress-spinner>
    </div>
    <div class="body_indicators" *ngIf="!showLoading">
        <div *ngIf="this.airportList != undefined && this.airportList.length == 0" class="not-found t-gray-6">
            Results not found for the selected filters
        </div>
        <div class="indicators_data" *ngIf="this.airportList != undefined && this.airportList.length != 0">
            <ol class="ranking airport t-gray-4  latam_bold_italic">
                <li *ngFor="let airports of airportList">
                    {{airports}}
                </li>
            </ol>
        </div>
    </div>
</main>