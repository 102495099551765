  <div>
        <div class="name-header" *ngIf="innerWidth > 420">
            <span>{{user?.userName}} {{user?.lastName}}</span>
            <br>
            <span>{{user?.profile?.operator}} - {{user?.profile?.fleet}}</span>
        </div>
    <div *ngIf="innerWidth > 420" class="user-image user-image-sideNav"
        [ngStyle]="{ 'background-image': 'url(' + user?.profilePicture + ')'}">
    </div>
</div>
<div>
    <ul [ngClass]="innerWidth > 420 ? 'sidebar-menu' : 'sidebar-menu fix-padding'">
        <li>
            <a (click)="sendTo('home')" [ngClass]="router.url == '/home' ? 'select-sidebar' : ''">
                <span *ngIf="router.url == '/home'" class="home-on"></span>
                <span *ngIf="router.url != '/home'" class="home-off"></span> Home
            </a>
        </li>
        <li *ngIf="isWeb && allowPilotTools && innerWidth>900">
            <a (click)="sendTo('tools')" [ngClass]="router.url.includes('tools') ? 'select-sidebar' : ''">
                <span *ngIf="router.url.includes('tools')" class="communications-on"></span>
                <span *ngIf="!router.url.includes('tools')" class="communications-off"></span> 
                Pilot Tools
            </a>
        </li>
        <li *ngIf="!hideSection && !router.url.includes('tools')">
            <a (click)="sendTo('safety')" [ngClass]="router.url == '/safety' ? 'select-sidebar' : ''">
                <span *ngIf="router.url == '/safety'" class="safety-on"></span>
                <span *ngIf="router.url != '/safety'" class="safety-off"></span> Safety <span *ngIf="safetyAlerts > 0" class="notification-badge">{{safetyAlerts}}</span>
            </a>
        </li>
        <li *ngIf="!hideSection && !router.url.includes('tools')">
            <a (click)="sendTo('fuel')" [ngClass]="router.url == '/fuel' || router.url == '/fuel/Apu' || router.url == '/fuel/ExtraFuel' || router.url == '/fuel/StabAlt' || router.url == '/fuel/Calc' ? 'select-sidebar' : ''">
                <span *ngIf="router.url == '/fuel' || router.url == '/fuel/Apu' || router.url == '/fuel/ExtraFuel' || router.url == '/fuel/StabAlt' || router.url == '/fuel/Calc' " class="efficiency-on"></span>
                <span *ngIf="router.url != '/fuel' && router.url != '/fuel/Apu' && router.url != '/fuel/ExtraFuel' && router.url != '/fuel/StabAlt' && router.url != '/fuel/Calc' " class="efficiency-off"></span> Efficiency
            </a>
        </li>
        <li [ngClass]="router.url.includes('/reports') ? 'select-sidebar' : ''" *ngIf="!router.url.includes('tools')">
            <a 
                [matBadge]="'Update'"
                matBadgeOverlap="false"
                matBadgeSize="small"
                class="flight-report-nav"
                (click)="sendTo('reports')" 
            >
                <span *ngIf="router.url == '/reports'" class="report-on"></span>
                <span *ngIf="router.url != '/reports'" class="report-off"></span> Flight Report<span *ngIf="showMessageReport" class="notification-badge">1</span>
            </a>
        </li>
        <li *ngIf="!hideSection && !router.url.includes('tools')">
            <a [routerLink]="['/roster']" [queryParams]="{section: 'all'}" (click)="sendTo('roster?section=all')" [ngClass]="router.url.includes('section=all') || router.url.includes('section=flights') || router.url.includes('section=activities') ? 'select-sidebar' : ''">
                <span [ngClass]="router.url.includes('section=all') || router.url.includes('section=flights') || router.url.includes('section=activities') ? 'roster-on' : 'roster-off'"></span>
                Roster
            </a>
        </li>

        <li *ngIf="!router.url.includes('tools')">
            <a (click)="sendTo('newsletter')" [ngClass]="router.url == '/newsletter' ? 'select-sidebar' : ''">
                <span *ngIf="router.url == '/newsletter'" class="communications-on"></span>
                <span *ngIf="router.url != '/newsletter'" class="communications-off"></span> Communications
            </a>
        </li>
        <li *ngIf="!router.url.includes('tools')" class="icon-aqd">
            <a (click)="sendTo('AQD')" rel="noopener noreferrer" 
            [matBadge]="'New'"
            matBadgeOverlap="false"
            matBadgeSize="small">
                <img src="assets/img/isotipo/AQD.png" alt="logo aqd" class="aqd-logo">
            </a>
        </li>
        <li *ngIf="user?.profile!=undefined && (user?.profile?.operator=='JJ' || user?.profile?.operator=='M3' || user?.profile?.operator=='PZ') && !router.url.includes('tools')">
            <a (click)="sendTo('PasseLivre')" href="https://passelivre.appslatam.com/" target="_blank" rel="noopener noreferrer">
                <span class="epass-off"></span> Passelivre
            </a>
        </li>

        <li *ngIf="!router.url.includes('tools')">
            <a (click)="sendTo('faq')" [ngClass]="router.url == '/faq' ? 'select-sidebar' : ''">
                <span *ngIf="router.url == '/faq'" class="faq-on"></span>
                <span *ngIf="router.url != '/faq'" class="faq-off"></span> FAQ
            </a>
        </li>

        <li *ngIf="user?.profile!=undefined && (user?.profile?.operator=='JJ' || user?.profile?.operator=='M3' || user?.profile?.operator=='PZ') && !router.url.includes('tools')">
            <a (click)="sendTo('str')" href="https://srtlatam.appslatam.com/" target="_blank" rel="noopener noreferrer">
                <span class="str-off"></span> Str
            </a>
        </li>
        <li *ngIf="!router.url.includes('tools')">
            <a (click)="sendTo('hoteles-ssc')" href="https://api2.apicrewcare.com/LATAM/mytravel.aspx" target="_blank" rel="noopener noreferrer">
                <div class="icon-hotels cursor-pointer">
                    <img src="/assets/img/sidenav/hotels.svg" alt=""> <span> HOTELS </span>
                </div>
            </a>
        </li>
        <li *ngIf="!router.url.includes('tools') && user?.profile!=undefined && (user?.profile?.operator=='JJ' || user?.profile?.operator=='M3' || user?.profile?.operator=='PZ')">
            <a (click)="sendTo('hoteles-br')" href="https://api2.apicrewcare.com/Main/Login.aspx?ReturnUrl=%2fLATAM-PT%2fdashboard.aspx" target="_blank" rel="noopener noreferrer">
                Hoteles BR
            </a>
        </li>
        <li class="logOut-style" (click)="logOutGoogle()">
            <a [ngClass]="router.url == '/login' ? 'select-sidebar' : ''">
                <span class="logout-on"></span>logout
            </a>
        </li>
    </ul>
</div>
<p class="version t-gray-3">{{version}}</p>
