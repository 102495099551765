export class DispatchZFWRequest{
    airlineCode: string;
    flightNumber: string;
    departure: string;
    arrival: string;
    dateFlight: string;
    typeDate: string = "U";
    concepts: string[] = ["FGFLD", "FGWNB"];
}



