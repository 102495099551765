import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, finalize } from 'rxjs';
import { DeviceInfoRequest } from 'src/app/modules/pilot/domain/profile/device-info.model';
import { PilotConstants } from "../../commons/pilot-constants";
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { confirmAlert, openBrowser } from 'src/app/core/utils/utils';
import { environment } from 'src/environments/environment';
import { UiService } from '../ui/ui.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.PILOT_PROFILE_API}${this.constants.PILOT_PROFILE}`

  isTestEnvironment: boolean = !environment.production;

  constructor(
    private platform: Platform,
    private httpClient: HttpClient,
    private constants: PilotConstants,
    private _ui: UiService
  ) { }

  getDeviceInfoProfileDB(): Observable<DeviceInfoRequest[]> {
    console.warn("URL: ", this.URL);
    return this.httpClient.get<DeviceInfoRequest[]>(this.URL + `/device_info`);
  }

  saveDeviceInfoDB(info: DeviceInfoRequest) {
    return this.httpClient.post(this.URL + "/device", info);
  }

  async getDeviceInfo() {
    const info = await Device.getInfo();
    return info;
  };

  async getDeviceID() {
    return await Device.getId();
  }

  async logBatteryInfo() {
    const info = await Device.getBatteryInfo();
    return info;
  };

  async validateDeviceInfo(infoRQ: DeviceInfoRequest) {
    return new Promise<boolean>((resolve, reject) => {
      if (this.platform.is('hybrid')) {
        this.saveDeviceInfoDB(infoRQ)
          .pipe(finalize(() => resolve(true)))
          .subscribe({
            next: res => console.log("resp", res),
            error: err => console.error("validateDeviceInfo error ", err)
          });
      } else {
        resolve(false);
      }

    })
  }

  async validateVersionDevice(lastVersion: string, platform: "ios" | "android" | "web") {
    const app = await App.getInfo();
    if (app.id == "com.lan.mobile.pilotapp") return;
    lastVersion != app.version && this.showAlert(platform);
  }

  async showAlert(platform: "ios" | "android" | "web") {
    let confirm = await confirmAlert({
      title: '¡Update available!',
      html: 'Please update the app to continue.',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      allowOutsideClick: false,
      showCancelButton: true,
      imageUrl: 'assets/img/svg/avion.svg'
    }, true);

    if (confirm && environment.production) {
      let url = platform == "android" ? this.constants.MARKET_ANDROID_URL : this.constants.MARKET_IOS_URL;
      openBrowser(url);
    }
    // CLOSE APP IF DECLINED ALERT
    // App.exitApp();
  }

  listenAppStateChange() {
    if (this.isTestEnvironment) {
      let startTime: number = 0;
      // It doesn't work properly because it causes a loop when refreshing the application's state
      App.addListener('appStateChange', ({ isActive }) => {
        if (isActive) {
          const elapsedTimeInSeconds = Math.floor((Date.now() - startTime) / 1000);
          if (elapsedTimeInSeconds >= 300) {
            this._ui.setStateRefresh({ refresh: true, finishEvent: false });
          }
        } else {
          startTime = Date.now();
        }
      });
    }
  }
}
