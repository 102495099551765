import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IndexeddbService {
  version = 6;

  constructor() {
    let request = window.indexedDB.open("PilotDatabase", this.version);
    request.onupgradeneeded = (event) => {
      let db = request.result;
      
      if (db.objectStoreNames.length == 0) {
        for (const key of ["fuel-statistics", "safety", "profile", "rol", "report", "otp"]) {
          db.createObjectStore(key);
        }
      }
    }
  }


  addToTable(table: string, data: any, key: string){
    let request = window.indexedDB.open("PilotDatabase", this.version);
    request.onsuccess = (event) => {
      let db = request.result;   
      let transaction = db.transaction([table], "readwrite").objectStore(table); 
      transaction.put(data, key);
    }
  }

  getDataFromTable(table: string, key: string): any {
    return new Promise((resolve, reject) => {
      let request = window.indexedDB.open("PilotDatabase", this.version);
      request.onsuccess = (event) => {
        let db = request.result;
        let transaction = db.transaction([table], "readwrite").objectStore(table);
        let data: IDBRequest = transaction.get(key)
        data.onsuccess = (event) => resolve(data.result);
      }
    });
  }

  clearDatabase(){
    let request = window.indexedDB.open("PilotDatabase", this.version);
    request.onsuccess = (event) =>{
      let db  = request.result;
      for (const key of ["fuel-statistics", "safety", "profile", "rol", "otp"]) {
        let transaction = db.transaction([key], "readwrite").objectStore(key); 
        transaction.clear();
      }
    }
  }

  clearDatabaseNoProfile(){
    let request = window.indexedDB.open("PilotDatabase", this.version);
    request.onsuccess = (event) => {
      let db  = request.result;
      let transaction = db.transaction(['fuel-statistics'], "readwrite").objectStore('fuel-statistics'); 
      transaction.clear();

      transaction = db.transaction(['safety'], "readwrite").objectStore('safety'); 
      transaction.clear();
    }
    this.deleteElementFromDatabase('rol', 'activities');
    this.deleteElementFromDatabase('rol', 'flightDetailsInfo');
  }

  deleteElementFromDatabase(table: string, key: string){
    let request = window.indexedDB.open("PilotDatabase", this.version);
    request.onsuccess = (event) => {
      let db  = request.result;
      let transaction = db.transaction([table], "readwrite").objectStore(table); 
      transaction.delete(key);
    }
  }
}
