<div class="container-login-qr"> 

    <ng-container *ngIf="QRData && !loadingQR">
        <div>
            <h4>Login with QR code</h4>
            <qrcode [qrdata]="QRData?.jwt" [width]="widthQR" [errorCorrectionLevel]="errorCorrectionLevel"></qrcode>

            <div class="expiration">
                <span class="title-exp">Expiration date:</span>
                <br>
                <small class="expiration-date">{{ QRData?.expirationDate | date: 'medium' }}</small>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!QRData && !loadingQR">
        <h4>Error generating QR code</h4>
    </ng-container>

    <div class="loading-spinner" *ngIf="loadingQR">
        <mat-spinner strokeWidth="10" [diameter]="100" style="margin: 0 auto" class="custom-size"></mat-spinner>
    </div>

</div>
