<div class="banners-container clickable">
    <ng-container *ngFor="let banner of bannersFilter">
        <div class="banner" (click)="navigate(banner.link, banner.tag)">
            <div class="banner-img">
                <img [src]="'assets/img/home/new-home/banners/' + banner.img" alt="" srcset="">
            </div>
            <div>
                <h6 class="title">{{ banner.title }}</h6>
                
                <p class="text" 
                [matBadge]="flightlogsToSend" 
                matBadgeOverlap="true"
                matBadgeSize="small"
                [matBadgeHidden]="banner.id != 4 || flightlogsToSend == 0"
                >
                    {{ banner.text }}
                </p>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                        d="M6.62188 14.4969L12.1639 8.98437L0.125 8.98437L0.125 7.01562L12.1639 7.01562L6.62188 1.50312L8 0.125L15.875 8L8 15.875L6.62188 14.4969Z"
                        fill="#2D34CE" />
                </svg>
            </div>
        </div>
    </ng-container>
</div>
