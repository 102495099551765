export class FobRequest {
    initialFob: number;
    litersCharged: number;
    density: number;
    realFob: number;

    constructor(initialFob: number, litersCharged: number, density: number, realFob: number){
        this.initialFob = initialFob;
        this.litersCharged = litersCharged;
        this.density = density;
        this.realFob = realFob;
    }
}