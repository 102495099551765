import { TempVariable } from "./temp-variable.model";

export class TempResponse {
    mda1: TempVariable;
    mda2: TempVariable;
    mda3: TempVariable;
    mda4: TempVariable;
    msa1: TempVariable;
    msa2: TempVariable;
    gaalt: TempVariable;
    appMinimus: TempVariable;
    calcDone: boolean;

    constructor(){
        this.mda1 = new TempVariable();
        this.mda2 = new TempVariable();
        this.mda3 = new TempVariable();
        this.mda4 = new TempVariable();
        this.msa1 = new TempVariable();
        this.msa2 = new TempVariable();
        this.gaalt = new TempVariable();
        this.appMinimus = new TempVariable();
        this.calcDone = false;
    }
}