import { Flight } from './flight';

export class FlightDetailStatus {
    flight: Flight;
    status:boolean;
    eventList:Array<any>;
    number: string;
    hasListener: boolean;
    flightDate: string;
    phase: string;
    fleet: string;
    url: string;
    seen: number;
    isVideoPlaying: boolean;
    videoId: string;
    plateNumber: string
    flightNumber: string
    departure: string
    arrived: string
    airline: string
}
