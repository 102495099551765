import { Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { BriefingTmService } from "src/app/modules/pilot/services/briefing-tm.service";
import { Duty } from "src/app/modules/pilot/domain/roster/duty.model";
import * as moment from 'moment';
import { CrewInfo } from "src/app/modules/pilot/domain/dispatch/btm-report/crew-info.model";
import { BtmFlightInformation } from "src/app/modules/pilot/domain/dispatch/btm-report/btm-flight-information.model";
import jsPDF, { GState } from "jspdf";
import { CrewBriefingService } from "src/app/modules/pilot/services/crew-briefing.service";
import { FlightId } from "src/app/modules/pilot/domain/flight-id.model";
import { BTMReportData } from "src/app/modules/pilot/domain/dispatch/btm-report/btm-report-data.model";
import { CrewInformation } from "src/app/modules/pilot/domain/crew-information";
import { SubcomponentState } from "src/app/modules/pilot/domain/dispatch/sub-component-state.model";
import { IndexeddbService } from "src/app/modules/pilot/services/indexeddb.service";
import { FlightInfo } from "src/app/modules/pilot/domain/flightInfo";
import { Activity } from "src/app/modules/pilot/domain/roster/activity.model";
import { FlightLeg } from "src/app/modules/pilot/domain/roster/flight-leg.model";
import { didParseCellFive, didParseCellFour, didParseCellOne, didParseCellThree, didParseCellTwo, getPageSizeHeight, getPageSizeWidth } from "./utils-btm-report";
import { DispatchService } from "../../../services/dispatch.service";
import { SeatsByFlightResponse } from "src/app/modules/pilot/domain/dispatch/SeatsByFlightResponse";
import { PilotConstants } from "../../../commons/pilot-constants";


@Component({
  selector: 'btm-report',
  template: ``
})
export class BtmReportComponent implements OnInit {

  totalPax: number = 0;
  totalCrew: number = 0;
  nextFlight: Activity;
  flightInformation: BtmFlightInformation = new BtmFlightInformation();
  crewList: Array<CrewInfo>;
  seatsByFlight: SeatsByFlightResponse = new SeatsByFlightResponse();
  allFlightLegs: Array<FlightLeg> = [];
  flightInfoForBTM: Array<FlightInfo>;
  isBrasilFlight: boolean = false;

  @Input() anotherDispatch: boolean = false;
  
  @Input() unseen: boolean = false;

  @Input() nextFlightToFind: FlightId;

  @Output() 
  private loadIsFinished = new EventEmitter<SubcomponentState>();

  @Output() 
  private flightFound = new EventEmitter<Activity>();

  @Output()
  private seatsByFlightFromBtm = new EventEmitter<SeatsByFlightResponse>();

  @Input() searchByFlight: boolean;

  @Input() hideCrew: boolean;

  private pdfState: string = 'waiting';

  constructor(
    private briefingTmService: BriefingTmService,
    private crewBriefingService: CrewBriefingService,
    private indexeddbService: IndexeddbService,
    private dispatchService: DispatchService,
    private constants: PilotConstants
  ){}

  ngOnInit(): void {
    this.isBrasilFlight = this.constants.BRASIL_OPERATORS.includes(this.nextFlightToFind.carrierOperating);
    const request = {
      "departureDateGmt": moment(this.nextFlightToFind.flightDate).format('DD-MM-YYYY'),
      "flightNumber": this.nextFlightToFind.flightNumber,
      "carrier": this.nextFlightToFind.operator,
      "departure": this.nextFlightToFind.departureIataCode,
      "arrival": this.nextFlightToFind.arrivalIataCode
    };
    this.searchByFlight = true;
    const service =  this.briefingTmService.getBriefingByFlight(request);// : this.briefingTmService.getBriefingByBp(`${searchDates.startDate}`, `${searchDates.endDate}`);

    service.subscribe({
      next: (resp: BTMReportData) => {
        if(!resp || resp.crew.length == 0 || resp.duties.length == 0){
          this.loadIsFinished.emit(new SubcomponentState(this, 'not-available'));
          this.flightFound.emit(null);
          return;
        }
        this.updateIndexdDB(resp);
        this.getLegs(resp.duties);
        try{
          this.fillFlightData(resp.duties);
          this.fillCrew(resp.crew);
          this.flightFound.emit(this.nextFlight);
        } catch(ex){
          console.warn(ex);
          this.loadIsFinished.emit(new SubcomponentState(this, 'error'));
          this.flightFound.emit(null);
        }
        this.getAllSeats();
      },
      error: err => {
        this.loadIsFinished.emit(new SubcomponentState(this, 'error'));
        this.flightFound.emit(null);
      }
    });
    this.checkBTMAvailability();
   }

  updateIndexdDB(resp: BTMReportData) {
    this.indexeddbService.addToTable('rol', resp.duties, "briefing");
    this.indexeddbService.addToTable('rol', resp.crew, "crew");
  }

  checkBTMAvailability(): void {
    if(this.pdfState != 'waiting'){
      this.loadIsFinished.emit(new SubcomponentState(this, this.pdfState));
    }
  }
  
  async getAllSeats() {
    const formattedDate = moment(this.nextFlightToFind.flightDate).format("DDMMM").toUpperCase();
    const seatsByFlightRequest = {
      "flightNumber": this.nextFlightToFind.flightNumber,
      "flightDateUTC": formattedDate,
      "departureAirport": this.nextFlightToFind.departureIataCode
    };
    this.dispatchService.getSeatsByFlight(seatsByFlightRequest).subscribe({
      next: seatsByFlight => {
        if (seatsByFlight?.authSeats != null) {
          this.seatsByFlight.authSeats = seatsByFlight.authSeats.replace(/\s+/g, ' ').replace(/\s/g, '/');
          this.seatsByFlight.bookedSeats = seatsByFlight.bookedSeats.replace(/\s+/g, ' ').replace(/\s/g, '/');
          this.seatsByFlightFromBtm.emit(this.seatsByFlight);
        }
        this.pdfState = 'success';
        this.checkBTMAvailability();
      }, 
      error: error => {
        this.pdfState = 'success';
        this.checkBTMAvailability();
      }
    });
    
  }

  private getLegs(btmData: Array<Duty>){
    let briefingData: Duty;
    if(btmData.length >0) {
      briefingData = this.setBriefingData(btmData); 
      if(briefingData != null && briefingData !=undefined){
        briefingData.items.forEach(item=>{
          if(item.flightLeg !=null){
            this.allFlightLegs.push(item.flightLeg);
          }
        });
      }
    }

  }

  setBriefingData(btmData: Array<Duty>) {
    let briefingData: Duty;
   
      btmData.forEach(duty =>{
        duty.items.forEach(item=>{
          if (!item.flightLeg) return;
          if (!this.anotherDispatch) {
            if (item.flightLeg.flightNumber == this.nextFlightToFind.flightNumber && item.flightLeg.departure.iataCode == this.nextFlightToFind.departureIataCode
              && item.flightLeg.departure.dates.utc == this.nextFlightToFind.flightDate) {
              briefingData = duty;
            }
          } else if (
            item.flightLeg.flightNumber == this.nextFlightToFind.flightNumber && 
            item.flightLeg.departure.iataCode == this.nextFlightToFind.departureIataCode && 
            moment(item.flightLeg.flightDate).format('DD-MM-YYYY') == moment(this.nextFlightToFind.flightDate).format('DD-MM-YYYY')) {
              briefingData = duty;
          }
        });
      });
     return briefingData;
    
  }

  getCrewAndHeaders(existPax: boolean): { crew: any, headersOfCrewTable: Array<string> } {
    let crew = { cockpitCrew: [], cabinCrew: []};
    let headersOfCrewTable: Array<string>;

    const filterCockPitCrew = (crew: CrewInfo) => crew.pos == "CP" || crew.pos == "FO";
    const filterCabinCrew = (crew: CrewInfo) => crew.pos != "CP" && crew.pos != "FO";

    let cockPitCrew = this.crewList.filter(filterCockPitCrew);
    let cabinCrew = this.crewList.filter(filterCabinCrew);

    if (existPax){
      const mapCrewWithPax = (crew: CrewInfo) =>[this.getCrewPosition(crew), crew.name, crew.isPax, crew.bp.toString().padStart(8, '0'), crew.doc, crew.mail];
      crew['cockpitCrew'] = cockPitCrew.map(mapCrewWithPax);
      crew['cabinCrew'] = cabinCrew.map(mapCrewWithPax);
      headersOfCrewTable = this.isBrasilFlight? ['POS', 'Name', '', 'BP LATAM', 'ANAC']:['POS', 'Name', '', 'BP LATAM', 'DOC'];
    } else {
      const mapCrewWithoutPax = (crew: CrewInfo) =>[this.getCrewPosition(crew), crew.name, crew.bp.toString().padStart(8, '0'), crew.doc, crew.mail];
      crew['cockpitCrew'] = cockPitCrew.map(mapCrewWithoutPax);
      crew['cabinCrew'] = cabinCrew.map(mapCrewWithoutPax);
      headersOfCrewTable = this.isBrasilFlight? ['POS', 'Name', 'BP LATAM', 'ANAC']:['POS', 'Name', 'BP LATAM', 'DOC'];
    }
    return { crew, headersOfCrewTable };
  }

  getCrewPosition = (crew: CrewInfo): string => {
    return crew.pos === "CP" && crew.order === 1 ? "CMD" : crew.pos;
  };

  setFlightInfoMatrix() {
    let matrix = [];
    let matrixSeats = [];
    for (let i = 0; i < 5; i++) {
      matrix[i] = new Array(11);
    }
    for (let i = 0; i < 2; i++) {
      matrixSeats[i] = new Array(2);
    }

    let flightInfomatrix = [];
    flightInfomatrix[0] = new Array(6);
    flightInfomatrix[0][0] = "Flight"

    for (let i = 1; i < 6; i++) {
      if (i - 1 < this.allFlightLegs.length) {
        flightInfomatrix[0][i] = this.allFlightLegs[i - 1].icaoOperator ?
          this.allFlightLegs[i - 1].icaoOperator + "-" + this.allFlightLegs[i - 1].flightNumber + " " + this.allFlightLegs[i - 1].aircraftRegistration :
          this.allFlightLegs[i - 1].operator + "-" + this.allFlightLegs[i - 1].flightNumber + " " + this.allFlightLegs[i - 1].aircraftRegistration;
      } else {
        flightInfomatrix[0][i] = "";
      }
    }
    return { matrix, flightInfomatrix, matrixSeats };
  }

  getOperatorPdf() {
    return this.nextFlight.flightLeg.icaoOperator? this.nextFlight.flightLeg.icaoOperator:this.nextFlight.flightLeg.operator;
  }

  private setDataMatrixInfoSeats(matrix) {
    for (let i = 0; i < 2; i++) {
      for (let j = 1; j < 7; j++) {
        switch (i) {
          case 0:
            if(j>2){
              matrix[i][j] = "";
            } 
            break;
          case 1:
            if(j>2){
              matrix[i][j] = "";
            }
            break;
        }
      }
    }
    return matrix;
  }

  private setDataMatrixFunc(matrix) {
    for(let i=0; i<5;i++ ){
      for (let j = 1; j <= 10; j++) {
        switch (i) {
          case 0:
            matrix[i][j] = this.flightInformation.route[j - 1];
            break;
          case 1:
            matrix[i][j] = this.flightInformation.scheduleUtc[j - 1] != "" ?
              moment(this.flightInformation.scheduleUtc[j - 1]).format('HH:mm') :
              this.flightInformation.scheduleUtc[j - 1];
            break;
          case 2:
            matrix[i][j] = this.flightInformation.scheduleLt[j - 1] != "" ?
              moment(this.flightInformation.scheduleLt[j - 1]).format('HH:mm') :
              this.flightInformation.scheduleLt[j - 1];
            break;
          case 3:
            matrix[i][j] = this.flightInformation.diff[j - 1];
            break;
          case 4:
            matrix[i][j] = this.flightInformation.diffLTandUTC[j - 1];
            break;       
        }
      }
    } 
    return matrix;
  }
 
  public getPDF(): Promise<jsPDF> {

    return new Promise((resolve, reject) => {
      let pdf = new jsPDF();

      let pdfWidth = getPageSizeWidth(pdf);
      let pdfHeight = getPageSizeHeight(pdf);

      let img = new Image()
      img.src = 'assets/img/navbar/logo-latam-pilot.jpg';
      pdf.addImage(img, 'png', pdfWidth - 40, 10, 30, 12);

      pdf.setFontSize(18);
      pdf.text('Crew Briefing', pdfWidth / 2, 20, { align: 'center' });

      pdf.setFontSize(10);

      let flightDate = moment(this.nextFlight.flightLeg.departure.dates.local).format('DD/MM/YYYY')
      let flightDateForFlightData = moment(this.nextFlight.flightLeg.departure.dates.local).format('DD-MM-YYYY')

      pdf.text('Date: ' + flightDate + ' (Local)', 10, 35);
      pdf.text('Crew on board: ' + this.totalCrew, 10, 42);
      pdf.text('Pax: ' + this.totalPax, pdfWidth - 20, 42);

      pdf.setFontSize(9);
      pdf.setTextColor('gray');

      let existPax = false;
      this.crewList.forEach(c => {
        if (c.isPax) existPax = true;
      });

      let { crew, headersOfCrewTable } = this.getCrewAndHeaders(existPax);

      let finalY;

      if (!this.hideCrew) {

        pdf.text('Cockpit Crew', 13, 50);
        let table = this.addCrewToPdf(headersOfCrewTable, crew.cockpitCrew, existPax, 52);
        (pdf as any).autoTable(table);
        finalY = (pdf as any).lastAutoTable.finalY;

        pdf.text('Cabin Crew', 13, finalY + 10);
        let table2 = this.addCrewToPdf(headersOfCrewTable, crew.cabinCrew, existPax, finalY + 12);
        (pdf as any).autoTable(table2);
        finalY = (pdf as any).lastAutoTable.finalY;
      } else {
        finalY = 39;
      }

      pdf.setFontSize(9);
      pdf.setTextColor('gray');
      pdf.text("Flight Info ", 13, finalY + 10);

      this.flightInfoForBTM = new Array();
      let operator = this.getOperatorPdf();

      this.flightInfoForBTM.push(
        new FlightInfo((operator + "-" + this.nextFlight?.flightLeg.flightNumber).toString(), this.nextFlight?.flightLeg.fleet,
          this.nextFlight?.flightLeg.aircraftRegistration,
          (this.nextFlight?.flightLeg.gate ? this.nextFlight?.flightLeg.gate : '----')));

      this.flightInfoForBTM.push(
        new FlightInfo(flightDateForFlightData.toString(), "", "", "",));


      let flightData = this.flightInfoForBTM.map(a => [a.flight, a.acType, a.acRegistration, a.parkingDeparture]);

      (pdf as any).autoTable({
        head: [['Call sing & Fit day', 'Acft. Type', 'Acft. Regist', 'Parking Departure']],
        headStyles: {
          valign: 'middle',
          halign: 'center', fontSize: 9, textColor: [255, 255, 255], fillColor: [27, 0, 136],
        },
        columnStyles: { 0: { lineWidth: { top: 0, right: 0, bottom: 0, left: 0.3 } }, 3: { lineWidth: { top: 0, right: 0.3, bottom: 0, left: 0 } } },
        startY: finalY + 12, startX: -11,
        body: flightData,
        theme: 'plain',
        bodyStyles: { fontSize: 24, textColor: [0, 0, 0], fillColor: [255, 255, 255] },
        margin: { right: 11, left: 11 },
        didParseCell: function (data) {
          didParseCellTwo(data, flightDateForFlightData);
        }
      });

      let { matrix, flightInfomatrix, matrixSeats } = this.setFlightInfoMatrix();

      (pdf as any).autoTable({
        head: [],
        startY: (pdf as any).lastAutoTable.finalY, startX: -11,
        body: flightInfomatrix,
        columnStyles: {
          0: { fontSize: 10, cellWidth: 38, halign: 'center', valign: 'middle' },
          1: { cellWidth: 30, halign: 'center', valign: 'middle' },
          2: { cellWidth: 30, halign: 'center', valign: 'middle' },
          3: { cellWidth: 30, halign: 'center' },
          4: { cellWidth: 30, halign: 'center' },
          5: { cellWidth: 30, halign: 'center' }
        },
        theme: 'plain',
        bodyStyles: {
          fontSize: 12, minCellWidth: 10,
          lineWidth: { top: 0.3, right: 0.3, bottom: 0.3, left: 0.3 }, textColor: [0, 0, 0], fillColor: [255, 255, 255], halign: 'center'
        },
        margin: { right: 11, left: 11 },
        didParseCell: function (data) {
          didParseCellThree(data, flightData);
        }
      });

      matrix[0][0] = "Route";
      matrix[1][0] = "Schedule UTC";
      matrix[2][0] = "Schedule LT";
      matrix[3][0] = "Dif " + this.nextFlight?.flightLeg.departure.iataCode + " & Dest";
      matrix[4][0] = "Dif L.T & UTC";

      matrix = this.setDataMatrixFunc(matrix);

      (pdf as any).autoTable({
        head: [],
        headStyles: {
          valign: 'middle',
          halign: 'center', fontSize: 8, textColor: [255, 255, 255], fillColor: [27, 0, 136], lineWidth: { top: 0.3, right: 0, bottom: 0, left: 0 }
        },
        columnStyles: { 0: { cellWidth: 38, halign: 'center' } }, // Cells in first column centered and green
        startY: (pdf as any).lastAutoTable.finalY, startX: -11,
        body: matrix,
        theme: 'plain',
        bodyStyles: {
          fontSize: 10, cellWidth: 15, 
          lineWidth: { top: 0.3, right: 0.3, bottom: 0.3, left: 0.3 }, 
          textColor: [0, 0, 0], 
          fillColor: [255, 255, 255], 
          valign: 'top',
          halign: 'center'
        },
        margin: { right: 11, left: 11 },
        didParseCell: function (data) {
          didParseCellFour(data);
        }
      });


      let footTable = [new Array(2),new Array(6)];

      footTable[0][0] = "Presentation time LT";
      footTable[0][1] = this.flightInformation.presentationTimeLT;
      footTable[0][2] = "Time at gate LT";
      footTable[0][3] = this.flightInformation.timeAtGateLT;
      footTable[0][4] = "Pass boarding LT";
      footTable[0][5] = this.flightInformation.PBLt;

      footTable[1][0] = "Presentation time UTC";
      footTable[1][1] = this.flightInformation.presentationTimeUTC;
      footTable[1][2] = "Time at gate UTC";
      footTable[1][3] = this.flightInformation.timeAtGateUTC;
      footTable[1][4] = "Pass boarding UTC";
      footTable[1][5] = this.flightInformation.PBUtc;

      (pdf as any).autoTable({
        head: [],
        startY: (pdf as any).lastAutoTable.finalY, startX: -11,
        body: footTable,
        columnStyles: {
          0: { cellWidth: 53, halign: 'center' },
          1: { cellWidth: 15, halign: 'center' }, 2: { cellWidth: 30, halign: 'center' }, 3: { cellWidth: 15, halign: 'center' }, 4: { cellWidth: 45, halign: 'center' }
        },
        theme: 'plain',
        bodyStyles: {
          fontSize: 10, minCellWidth: 10,
          lineWidth: { top: 0.3, right: 0.3, bottom: 0.3, left: 0.3 }, textColor: [0, 0, 0], fillColor: [255, 255, 255], halign: 'center'
        },
        margin: { right: 11, left: 11 },
      });
      matrixSeats[0][0] ="Actf.Config J/Y";
      matrixSeats[1][0] = "Reserv.Pass J/Y";
      matrixSeats[0][1] = this.seatsByFlight.authSeats;
      matrixSeats[1][1] = this.seatsByFlight.bookedSeats
      matrixSeats = this.setDataMatrixInfoSeats(matrixSeats );
      
      (pdf as any).autoTable({
        head: [],
        startY: (pdf as any).lastAutoTable.finalY, startX: -11,
        body: matrixSeats,
        columnStyles: {
          0: { fontSize: 10, cellWidth: 38, halign: 'center', valign: 'middle' },
          1: { cellWidth: 30, halign: 'center', valign: 'middle' },
          2: { cellWidth: 30, halign: 'center', valign: 'middle' },
          3: { cellWidth: 30, halign: 'center' },
          4: { cellWidth: 30, halign: 'center' },
          5: { cellWidth: 30, halign: 'center' }
        },
        theme: 'plain',
        bodyStyles: {
          fontSize: 10, minCellWidth: 10,
          lineWidth: { top: 0.3, right: 0.3, bottom: 0.3, left: 0.3 }, textColor: [0, 0, 0], fillColor: [255, 255, 255], halign: 'center'
        },
        margin: { right: 11, left: 11 },
        didParseCell: function (data) {
          didParseCellFive(data);
        }
      });

      pdf.setGState(new GState({ opacity: 0.1 }));
      pdf.addImage(img, 'png', (pdfWidth / 2) - 50, (pdfHeight / 2) - 20, 100, 40);
      pdf.setGState(new GState({ opacity: 1 }));

      pdf.setFontSize(10);
      pdf.setTextColor('black')
      pdf.text("This document is generated exclusively by PILOT", pdfWidth / 2, pdfHeight - 10, { align: 'center' });

      resolve(pdf);
    });
  }

  addCrewToPdf(headersOfCrewTable, crew: any, existPax: boolean, startY: number) {
    return {
      head: [headersOfCrewTable],
      bodyStyles: {
        lineWidth: 0.06,
        lineColor: [217, 216, 216],
        valign: 'middle', halign: 'center', fontSize: 9,
        fillColor: [255, 255, 255],
      },
      alternateRowStyles: {
        lineWidth: 0.06,
        lineColor: [217, 216, 216],
        valign: 'middle', halign: 'center', fontSize: 9,
        fillColor: [255, 255, 255],
      },
      headStyles: {
        fillColor: [27, 0, 136],
        lineWidth: 0.06,
        lineColor: [217, 216, 216],
        valign: 'middle', halign: 'center', fontSize: 9
      },
      startY: startY, startX: -11,
      body: crew,
      theme: 'striped',
      //bodyStyles: {fontStyle: 'Latam_Sans_Bold',valign:'middle', halign:'center',fontSize:9, fillColor : [255, 255, 255], lineColor: [0, 0, 0],lineWidth: {top: 0.3, right: 0, bottom: 0, left: 0}},
      margin: { right: 11, left: 11 },
      didParseCell: function (data) {
        didParseCellOne(data, existPax);
      }
    }
  }

  private fillFlightData(btmData: Array<Duty>) {
    let firstDuty: Duty = null;

    for (let duty of btmData) {
      const dutyToCheck: Array<Activity> = duty.items
        .filter(flight => flight.flightLeg.departure.iataCode == this.nextFlightToFind.departureIataCode &&
          flight.flightLeg.flightNumber == this.nextFlightToFind.flightNumber &&
          moment(flight.flightLeg.departure.dates.utc).format('DD-MM-YYYY') == moment(this.nextFlightToFind.flightDate).format('DD-MM-YYYY') &&
          flight.flightLeg.carrier == this.nextFlightToFind.operator);

      if (dutyToCheck.length != 0) {
        firstDuty = duty;
        this.nextFlight = dutyToCheck[0];
        break;
      }
    }

    if (!firstDuty || !this.nextFlight) {
      throw new Error('No duty or flight found');
    }
    this.setCrewBriefingTimes(this.nextFlight);

    const nextFlightLocalDate = moment(this.nextFlight.flightLeg.departure.dates.local);
    const nextFlightUtcDate = moment(this.nextFlight.flightLeg.departure.dates.utc);
    const nextFlightDuration = moment.duration(nextFlightLocalDate.diff(nextFlightUtcDate));
    const nextFlightHourDiff = nextFlightDuration.asHours();

    firstDuty.items.forEach((flight, index) => {

      index = index * 2;

      this.flightInformation.route[index] = flight.flightLeg.departure.iataCode;
      this.flightInformation.scheduleUtc[index] = flight.flightLeg.departure.dates.utc;
      this.flightInformation.scheduleLt[index] = flight.flightLeg.departure.dates.local;
      this.flightInformation.diff[index] = this.crewBriefingService.getFlightDiff(flight.flightLeg.departure.dates.local, flight.flightLeg.departure.dates.utc, nextFlightHourDiff);


      this.flightInformation.diffLTandUTC[index] = this.crewBriefingService.calculateDiff(flight.flightLeg.departure.dates.local, flight.flightLeg.departure.dates.utc);

      this.flightInformation.route[index + 1] = flight.flightLeg.arrival.iataCode;
      this.flightInformation.scheduleUtc[index + 1] = flight.flightLeg.arrival.dates.utc;
      this.flightInformation.scheduleLt[index + 1] = flight.flightLeg.arrival.dates.local;
      this.flightInformation.diff[index + 1] = this.crewBriefingService.getFlightDiff(flight.flightLeg.arrival.dates.local, flight.flightLeg.arrival.dates.utc, nextFlightHourDiff);
      this.flightInformation.diffLTandUTC[index + 1] = this.crewBriefingService.calculateDiff(flight.flightLeg.arrival.dates.local, flight.flightLeg.arrival.dates.utc);
    });
  }

  private fillCrew(crew: Array<CrewInformation>){
    //No flights
    if (!crew || crew.length == 0) {
      console.log("No flights found");
      this.loadIsFinished.emit(new SubcomponentState(this, this.pdfState));
      return;
    }

    let { crewList, totalCrew, totalPax } = this.crewBriefingService.fillCrew(crew, this.isBrasilFlight);
    this.totalCrew = totalCrew;
    this.totalPax = totalPax;
    this.crewList = crewList;
  }

  private async setCrewBriefingTimes(flight) {
    let { presentationTimeLT, timeAtGateLT, PBLt, presentationTimeUTC, timeAtGateUTC, PBUtc} = await this.crewBriefingService.setCrewBriefingTimes(flight);
    this.flightInformation.timeAtGateLT = timeAtGateLT;
    this.flightInformation.PBLt = PBLt
    this.flightInformation.presentationTimeLT = presentationTimeLT;

    this.flightInformation.timeAtGateUTC = timeAtGateUTC;
    this.flightInformation.PBUtc = PBUtc
    this.flightInformation.presentationTimeUTC = presentationTimeUTC;
  }
}
