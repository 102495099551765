<div>
    <mat-toolbar class="header freeze-view" *ngIf="this.dispatch">
      <mat-toolbar-row>
        <span class="material-icons clickable"(click)="closeModal()">chevron_left</span>
        <span >Documents</span>
      </mat-toolbar-row>
    </mat-toolbar>
</div>
<div style=" overflow-x: scroll; overflow-y: scroll; height: 95%; background-color: white;">
  <div class="mt-6">
    <div class="document-container freeze-view ">
      <div class="row justify-content-center">
        <div class="alert alert-warning alert-dismissible fade show mx-auto w-responsive" style="   padding-left: 3rem" role="alert">
          Important: <br> The information provided is subject to change and may not reflect real-time updates. For any discrepancies, please contact us at  <a style="color: blue;">grp_pilotappsupport&#64;latam.com</a>
        </div>
        <h2 class="col-12 document-title text-center">Deferred Item List          <h4 class="document-title text-center">(AIRCRAFT MR2 REPORT)</h4>
        </h2>
      </div>
      <div class="row ">
        <span class="section-title" style="padding-left: 1rem;">AIRCRAFT: {{this.tail}}</span> 
      </div>
    </div>

      <div *ngFor="let order of this.maintenixMap.values()">
        <div  *ngIf="order.reports.length != 0" >
          <div class="row col-12" style=" padding-left: 3rem">
            <span class="section-title-group col-12">{{order.name}}</span>
          </div>
          <br>
          <div style="padding: 2rem; padding-right: 2; padding-top: 0;">
            <div style="text-align: center;"  *ngFor="let report of order.reports">
              <maintenix-report-section [report]="report" >
              </maintenix-report-section>
              <br>    
            </div>
          </div>
        </div>  
      </div> 
  </div>
</div>