import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-state-no-data',
  templateUrl: './state-no-data.component.html'
})
export class StateNoDataComponent {
  x = 0;
  y = 0;
  public lottieConfig: Object;
  private anim: any;

  constructor(public router: Router) { 
    this.lottieConfig = {
      path: 'assets/lottie-animation/nodata1.json',
      renderer: 'svg',
      autoplay: true,
      loop: false
    };
    (window.innerWidth > 765) ? (this.x = 70, this.y = 60) : (this.x = 50, this.y = 40);
  }
  
  handleAnimation(anim: any) {
    this.anim = anim;
  }
}
