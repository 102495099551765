<main class="m-through-nav" [hidden]="showFlightInformation">
  <div class="container pb-5">

    <div *ngIf="notAvailable" class="text-center not-found mt-2 mb-2">
      <h2>Coming Soon</h2>
      <h4>Roster is not available for {{profile.operator}} just yet</h4>
    </div>
   
    <div class="title-section">
      <h2 class="title-label latam_bold_italic">ROSTER</h2>

      <!--Update Roster Button-->
      <div style="margin-left: auto;" *ngIf="this.selectedSection == 0 || this.selectedSection == 2 ">
        <div *ngIf="!loadingRoster">
          <ion-button (click)="updateCrew(true)" expand="block" fill="outline" size="small">
            <ion-icon slot="start"name="reload-circle-outline"></ion-icon>
            &nbsp;Update Roster &nbsp;
          </ion-button>
        </div>
        <div class="row col-centered">
          <span style="font-size: 12px; font-weight: bold; color: black;">{{rosterUpdate}}&nbsp;</span>
          <span style="font-size: 12px;">- {{rosterLastUpdate}} (UTC)</span>
        </div>
      </div>

      <!--Update Briefing button-->
      <div style="margin-left: auto;" *ngIf="this.selectedSection == 1">
        <div>
          <ion-button (click)="refreshBriefing()" expand="block" fill="outline" size="small">
            <ion-icon slot="start"name="reload-circle-outline"></ion-icon>
            &nbsp;Update Briefing &nbsp;
          </ion-button>
        </div>
        <div class="row col-centered">
          <span style="font-size: 12px; font-weight: bold; color: black;">{{briefingUpdate}}&nbsp;</span>
          <span style="font-size: 12px;">- {{briefingLastUpdate}} (UTC)</span>
        </div>
      </div>
    </div>

    <div class="loading-spinner" *ngIf="loadingRoster">
      <mat-spinner strokeWidth="10" [diameter]="100" style="margin: 0 auto" class="custom-size"></mat-spinner>
    </div>

    <app-alerts 
            *ngIf="showMessageUpdated && !loadingRoster"
            [text]="'Your information has been updated'"
            [class]="'w-100'"
            [type]="'success'"
        >
    </app-alerts>

    <div [hidden]="loadingRoster">
        <mat-tab-group mat-align-tabs="center" [selectedIndex]="selectedSection" (selectedTabChange)="markSection($event.index)">
          <mat-tab label="All" tabindex="0">
            <ng-template [ngTemplateOutlet]="timezoneSwitch"></ng-template>
            <no-data *ngIf="!activities || activities.length == 0" [title]="'No data yet. If this is a mistake, please inform us to take your case'" [icon]="'announcement'"></no-data>
            <div *ngFor="let activity of activities ; let i = index;">
              <roster-actcard *ngIf="activity.flightLeg == null" [activity]="activity" [isFromBrasil]="isFromBrasil" [checked]="checked"></roster-actcard>
              <mat-accordion>
                <div class="mt-3">
                  <ng-template *ngIf="i==indexLastActivity" [ngTemplateOutlet]="rosterDisclaimer"></ng-template>
                  <roster-fcard
                    *ngIf="activity.flightLeg != null" 
                    [nextFlight]="i == nf_index" 
                    [activity]=activity 
                    [isFromBrasil]="isFromBrasil" 
                    [checked]="checked" 
                    (searchFlightInformation)="openFlightInformation(activity, 'roster')">
                  </roster-fcard>
                </div>
              </mat-accordion>
            </div>
          </mat-tab>

          <mat-tab label="Briefing">
            <ng-template mat-tab-label>
              <span>BRIEFING</span>
            </ng-template>

            <ng-template [ngTemplateOutlet]="timezoneSwitch"></ng-template>

            <no-data *ngIf="!btmHasData" [title]="'No data yet. If this is a mistake, please inform us to take your case'" [icon]="'announcement'"></no-data>
            <div>
              <briefing-tm (hasData)="checkIfBtmHasData($event)" (updating)="update($event)" [isFromBrasil]="isFromBrasil" [checked]="checked" (backFromFCard)="closeFlightInformation()" #btm></briefing-tm>
            </div>
          </mat-tab>

          <mat-tab label="Flights">
            <no-data *ngIf="!rosterHasFlights" [title]="'No data yet. If this is a mistake, please inform us to take your case'" [icon]="'announcement'"></no-data>

            <ng-container *ngIf="rosterHasFlights">
              <ng-template [ngTemplateOutlet]="timezoneSwitch"></ng-template>
              <div *ngFor="let activity of activities ; let i = index;">
                <mat-accordion>
                  <div class="mt-3">
                    <ng-template *ngIf="i==indexLastActivity" [ngTemplateOutlet]="rosterDisclaimer"></ng-template>
                    <roster-fcard 
                      *ngIf="activity.flightLeg != null" 
                      [nextFlight]="i == nf_index" 
                      [activity]=activity 
                      [isFromBrasil]="isFromBrasil"
                      [checked]="checked"
                      [origin] = "'flights'"
                      (searchFlightInformation)="openFlightInformation(activity, 'flights')">
                    </roster-fcard>
                  </div>
                </mat-accordion>
              </div>
            </ng-container>
          </mat-tab>

          <mat-tab label="Activities" >
            <no-data *ngIf="!rosterHasActivities" [title]="'No data yet. If this is a mistake, please inform us to take your case'" [icon]="'announcement'"></no-data>

            <ng-container *ngIf="rosterHasActivities">
              <ng-template [ngTemplateOutlet]="timezoneSwitch"></ng-template>
              <div *ngFor="let activity of activities ; let i = index;">
                <ng-template *ngIf="i==indexLastActivity" [ngTemplateOutlet]="rosterDisclaimer"></ng-template>
                <roster-actcard *ngIf="activity.flightLeg == null" [activity]="activity" [isFromBrasil]="isFromBrasil" [checked]="checked"></roster-actcard>
              </div>
            </ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
</main>
<ng-template #rosterDisclaimer>
  <mat-divider class="margin-divider"></mat-divider>
  <app-alerts 
    [text]="constants.MESSAGES_APP.ROSTER.INFORMATION"
    [class]="'w-100'"
  >
  </app-alerts>
</ng-template>

<ng-template #timezoneSwitch>
  <div class="timez-switch">
    <span class="switch-title">Timezone &emsp;</span>
    <span class="switch-labels" [ngClass]="{'label-on': !checked }">{{textTimezoneLeft}}</span>
    <mat-slide-toggle
      class="example-margin"
      [color]="primary"
      [checked]="checked"
      (change)="checked = !checked">
    </mat-slide-toggle>
    <span class="switch-labels" [ngClass]="{'label-on': checked }">{{textTimezoneRight}}</span>
  </div>
</ng-template>

<ng-container *ngIf="showFlightInformation">
  
  <flight-information 
    (back)="closeFlightInformation()" 
    [user]="user"
    [origin]="origin"
    [flight]="flightSelected"
  >
  </flight-information>

</ng-container>
