<mat-dialog-content>
    <div [formGroup]="incidentForm" class="container mt-3 mb-3">
        <h3 mat-dialog-title class="w-100 text-center dialog-title" mat-dialog-title>Feedback</h3>
        <button id="closeButton" mat-icon-button class="w-auto close-button-margin" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
        <div class="row">
            <span class="col-12 text-center">Describe your incident</span>
        </div>

        <div class="row px-4 py-2">
            <textarea class="form-control" rows="5" placeholder="Write here" formControlName="comment"></textarea>
        </div>

        <div class="row">
            <div class="col-12 text-center" (click)="fileInput.click()">
                <img src="assets/img/home/new-home/communications/upload.svg" alt="">
                &nbsp;<span class="upload-file-span">Upload file</span>
                &nbsp;<span style="color: gray;">{{selectedFile ? 'File selected' : '(optional)'}}</span>
                <input hidden (change)="onFileSelected($event)" #fileInput type="file">
            </div>
        </div>

        <div class="d-flex pt-2">
            <button mat-button class="btn btn-dialog" [disabled]="!incidentForm.valid || saving" (click)="sendReport()">
                <span>{{saving ? 'Sending' : 'Send'}}</span>
                <mat-progress-spinner *ngIf="saving" class="d-inline-flex ml-2" [color]="'accent'"
                    [mode]="'indeterminate'" [diameter]="25"></mat-progress-spinner>
            </button>
        </div>
    </div>
</mat-dialog-content>