import { Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent {

  constructor(
    public dialogMessage: MatDialogRef<MessageComponent>, 
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  status(value) {
    this.dialogMessage.close(value);
  }
}
