<main class="m-through-nav" id="calculator-view">
    <div class="container pb-5">
        <div class="title-section-calculator row">
            <app-back-home> </app-back-home>
        </div>
        <mat-tab-group mat-align-tabs="center">
            <mat-tab label="FOB" tabindex="0" [formGroup]="fobForm">
                <mat-expansion-panel class="mt-3 mb-1" [expanded]="true">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <span class="form-title ">FOB</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="timez-switch" *ngIf="enableOperator4C">
                        <span class="switch-labels" [ngClass]="{'label-on': !checked }">LITERS</span>
                        <mat-slide-toggle
                        class="example-margin"
                        [color]="primary"
                        [checked]="checked"
                        (change)="checked = !checked">
                        </mat-slide-toggle>
                        <span class="switch-labels" [ngClass]="{'label-on': checked }">GALLONS</span>
                    </div>

                    <div class="row mb-2 m-2">
                        <div class="calc-row col-12">
                            <span >INITIAL FOB (kg)</span> <input formControlName="initialFob" class="fob-input" type="number" placeholder="-"  value="3452"/>
                        </div>
                    </div>

                    <div class="row mb-2 m-2">
                        <div class="calc-row col-12" >
                            <span *ngIf="checked">GALLONS CHARGED</span> <span *ngIf="!checked">LITERS CHARGED</span> <input formControlName="litersCharged" class="fob-input" placeholder="-" type="number" value="5342"/>
                        </div>
                    </div>

                    <div class="row mb-2 m-2">
                        <div class="calc-row col-12">
                            <span *ngIf="!checked">DENSITY (kg/ltr)</span> <span *ngIf="checked">DENSITY (kg/gal)</span> <input *ngIf="checked" formControlName="density" step="any" class="fob-input" type="number" placeholder="3.04"  value="6432"/>
                            <input *ngIf="!checked" formControlName="density" step="any" class="fob-input" type="number" placeholder="0.8"  value="6432"/>
                        </div>
                    </div>

                    <div class="row mb-2 m-2">
                        <div class="calc-row col-12">
                            <span>REAL FOB (kg)</span> <input formControlName="realFob" class="fob-input" type="number" placeholder="-"  value="234"/>
                        </div>
                    </div>

                    <div class="row m-2 mt-4">
                        <button class="col-12 btn-pilot pilot-primary" [disabled]="!fobForm.valid" (click)="calcFob()">Calculate</button>
                    </div>
                </mat-expansion-panel>

                <div class="row m-3">
                    <span class="col-12 calc-box-title">CALCULATOR</span>
                    <div class="col-12 calc-box">
                        <div class="row mb-2 m-2">
                            <div class="calc-row col-12">
                                <span>FOB CALCULATION (kg)</span> <span class="calc-value">{{fobResult ? fobResult.calc : '-'}}</span>
                            </div>
                        </div>
                        <div class="row mb-2 m-2">
                            <div class="calc-row col-12">
                                <span>DIFFERENCE (kg)</span> <span class="calc-value">{{fobResult ? fobResult.difference : '-'}}</span>
                            </div>
                        </div>
                        <div class="result-box latam_black mt-4">
                            <span *ngIf="fobResult" style="font-size:18px" [ngStyle]="{'color': fobWithinTolerance ? '#5C7A1F' : '#B30F3B'}">
                                {{fobWithinTolerance ? 'WITHIN TOLERANCE FOR ACTUAL FOB' : 'OUT OF TOLERANCE'}}
                            </span>
                        </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="TEMP" tabindex="2" [formGroup]="tempForm">
                <div class="temp-title temp-title-secondary">
                    <span style="visibility: hidden; width: 5rem;"></span>
                    <span class="temp-title-bold">Add Values</span>
                    <span style="width: 2.5rem;"></span>
                    <span style="width: 1rem;"></span>
                </div>
                <div class="temp-box primary-input">
                    <span>Ad Ele (Ft)</span>
                    <input formControlName="adEle" class="fob-input" placeholder="-" type="number"/>
                    <span style="width: 2.5rem;"></span>
                    <span style="width: 1rem;"></span>

                </div>
                <div class="temp-box primary-input">
                    <span>Ad Temp (C)</span>
                    <input formControlName="adTemp" class="fob-input" placeholder="-" type="number"/>
                    <span style="width: 2rem;"></span>
                    <span style="width: 3rem;"></span>

                </div>

                <div class="temp-title temp-title-secondary">
                    <span style="visibility: hidden;">CalculatorHeader</span>
                    <span class="temp-title-bold">Add Values</span>
                    <span>Correction</span>
                    <span>To FMC</span>
                </div>
                <div class="temp-box secondary-input">
                    <span>MDA 1 (Ft)</span>
                    <input formControlName="mda1" class="fob-input" type="number" placeholder="-" [ngClass]="tempResponse.calcDone ? '' : 'empty-temp-response-margin'"/>
                    <span>{{tempResponse.mda1.correction }}</span>
                    <span>{{tempResponse.mda1.toFCM }}</span>
                </div>
                <div class="temp-box secondary-input">
                    <span>MDA 2 (Ft)</span>
                    <input formControlName="mda2" class="fob-input" type="number" placeholder="-" [ngClass]="tempResponse.calcDone ? '' : 'empty-temp-response-margin'"/>
                    <span>{{tempResponse.mda2.correction }}</span>
                    <span>{{tempResponse.mda2.toFCM }}</span>
                </div>
                <div class="temp-box secondary-input">
                    <span>MDA 3 (Ft)</span>
                    <input formControlName="mda3" class="fob-input" type="number" placeholder="-" [ngClass]="tempResponse.calcDone ? '' : 'empty-temp-response-margin'"/>
                    <span>{{tempResponse.mda3.correction }}</span>
                    <span>{{tempResponse.mda3.toFCM }}</span>
                </div>
                <div class="temp-box secondary-input">
                    <span>MDA 4 (Ft)</span>
                    <input formControlName="mda4" class="fob-input" type="number" placeholder="-" [ngClass]="tempResponse.calcDone ? '' : 'empty-temp-response-margin'"/>
                    <span>{{tempResponse.mda4.correction }}</span>
                    <span>{{tempResponse.mda4.toFCM }}</span>
                </div>

                <div class="temp-title temp-title-secondary">
                    <span style="visibility: hidden;">CalculatorHeader</span>
                    <span class="temp-title-bold">Add Values</span>
                    <span>Correction</span>
                    <span>To FCU</span>
                </div>
                <div class="temp-box secondary-input">
                    <span>MSA 1 (Ft)</span>
                    <input formControlName="msa1" class="fob-input" type="number" placeholder="-" [ngClass]="tempResponse.calcDone ? '' : 'empty-temp-response-margin'"/>
                    <span>{{tempResponse.msa1.correction }}</span>
                    <span>{{tempResponse.msa1.toFCM }}</span>
                </div>
                <div class="temp-box secondary-input">
                    <span>MSA 2 (Ft)</span>
                    <input formControlName="msa2" class="fob-input" type="number" placeholder="-" [ngClass]="tempResponse.calcDone ? '' : 'empty-temp-response-margin'"/>
                    <span>{{tempResponse.msa2.correction }}</span>
                    <span>{{tempResponse.msa2.toFCM }}</span>
                </div>
                <div class="temp-box secondary-input">
                    <span>GA ALT 1 (Ft)</span>
                    <input formControlName="gaalt" class="fob-input" type="number" placeholder="-" [ngClass]="tempResponse.calcDone ? 'temp-response-margin-lg' : 'empty-temp-response-margin-lg'"/>
                    <span>{{tempResponse.gaalt.correction }}</span>
                    <span>{{tempResponse.gaalt.toFCM }}</span>
                </div>

                <div class="temp-title temp-title-secondary">
                    <span style="visibility: hidden;">CalculatorHeader</span>
                    <span class="temp-title-bold">Add Values</span>
                    <span>Correction</span>
                    <span style="visibility: hidden;">To FCM</span>
                </div>
                <div class="temp-box secondary-input">
                    <span>App Min (Ft)</span>
                    <input formControlName="appMinimus" class="fob-input" type="number" placeholder="-" [ngClass]="tempResponse.calcDone ? 'temp-response-margin-lg' : 'empty-temp-response-margin-lg'"/>
                    <span>{{tempResponse.appMinimus.correction}}</span>
                    <span>{{tempResponse.appMinimus.toFCM }}</span>
                </div>

                <div class="d-flex">
                    <button class="w-50 btn-pilot pilot-primary" (click)="calcTemp()">Calculate</button>
                    <button mat-stroked-button color="primary" class="w-50 temp-restart-btn" (click)="cleanTempForm()">
                        <mat-icon>refresh</mat-icon>
                        <span>Restart</span>
                    </button>
                </div>

            </mat-tab>
          </mat-tab-group>
    </div>
</main>

