import { FlightLogInfo } from "./flight-log-info.model";

export class FlightLog {
  flightLogNumber: string;
  bookImage: File;
  createdBy?: any;
  createdAt?: any;
  flights: Array<FlightLogInfo>;
  allowEdit?: boolean;

  constructor(flightLogNumber: string, bookImage: File){
    this.flightLogNumber = flightLogNumber;
    this.bookImage = bookImage;
  }
}