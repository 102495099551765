import { Component, Input } from "@angular/core";

@Component({
  selector: 'no-data',
  standalone: true,
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.css']
})
export class NoDataComponent {

  @Input()
  title: string;

  @Input()
  icon: string;
}
