import { Component, Input } from '@angular/core';
type TypeAlert = "success" | "warning" | "danger";
@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent  {

  @Input("text") text: string = "";
  @Input("class") class: string = "";
  @Input("type") type: TypeAlert = "warning";
  @Input("dismissible") dismissible: boolean = true;
  
  showAlert: boolean = true;

}
