import { DispatchDocument } from "./dispatch-document.model";

export class JoinDocsRequest {
  caeProducts: Array<DispatchDocument> = new Array();
  frontDocumentsFilename: string;

  constructor(frontDocumentsFilename: string, caeProducts: Array<DispatchDocument>){
    this.frontDocumentsFilename = frontDocumentsFilename;
    this.caeProducts = caeProducts;
  }
}
