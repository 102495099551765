import { ReasonList } from "./reasonList";

export class ReportRequest {
    delay: number;
    fleet: string;
    flightDate: string;
    flightNumber: string;
    owner: string;
    tailNumber: string;
    cc: boolean;
    departure: string;
    arrival: string;
    reasonList: Array<ReasonList>;

    constructor() {
        this.reasonList = new Array<ReasonList>();
    }
}
