<main class="m-through-nav">
    <div class="container pb-5">
        <h2 class="title-newsletter latam_bold_italic">Safety Communication</h2>
        <div class="row">
            <div class="col-lg-12">
                <app-alerts 
                    *ngIf="newsletters!=undefined && newsletters.length==0"
                    [text]="'There`s no news availables'"
                    [class]="'w-100'"
                >
                </app-alerts>
            </div>
        </div>
        <mat-progress-bar mode="query" class="progress-bar-home" *ngIf="loadingNewsletter || newsletters==undefined" style="margin: 0 auto"></mat-progress-bar>
        <div class="row">
            <div class="col-6">
                <mat-form-field class="input-100">
                    <mat-select placeholder="Type" #typeFilterSelected (selectionChange)="applyFilter();">
                        <mat-option *ngFor="let typeNew of typesNews" [value]="typeNew.type">
                            {{typeNew.type}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        Type
                        <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="content-filter input-100">
                    <input matInput (keyup)="applyFilter()" #allFilter placeholder="Search">
                </mat-form-field>
            </div>
        </div>
        <mat-card appearance="outlined" class="card-newsletter" *ngFor="let newsletter of newsletters">
            <div>
                <div class="container-newsletter" [ngClass]="newsletter.acknowledge?'acknowledge':''">
                    <div class="col-9">
                        <span class="number-plublish">N°{{newsletter.id}}</span>
                    </div>
                    <div class="col-3">
                        <p class="publish-date">{{newsletter.publishingDate| formatDateRol : 'DD/MM/YYYY'}}</p>
                    </div>
                </div>
                <div class="container-newsletter titleNews" [ngClass]="newsletter.acknowledge?'acknowledge':''">
                    <div class="col-10">
                        <div class="content-title-newsletter">
                            {{newsletter.title}}
                        </div>
                    </div>
                    <div class="col-2">
                        <span class="circle"></span>
                    </div>
                </div>
                <div class="footer-newsletter" [ngClass]="newsletter.acknowledge?'acknowledge':''">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 title-footer"><span class="title-type">
                                <mat-chip-listbox aria-label="Fish selection">
                                    <mat-chip-option color="warn" selected (click)="openNewsletter(newsletter)" *ngIf="newsletter.typeName=='Safety Alert'">{{newsletter.typeName}}</mat-chip-option>
                                    <mat-chip-option color="primary" selected (click)="openNewsletter(newsletter)" *ngIf="newsletter.typeName=='Nuestros Casos' || newsletter.typeName=='Nossos casos'">{{newsletter.typeName}}</mat-chip-option>
                                    <mat-chip-option color="accent" selected (click)="openNewsletter(newsletter)" *ngIf="newsletter.typeName=='Informativos de seguridad' || newsletter.typeName=='Informativo de Segurança'">{{newsletter.typeName}}</mat-chip-option>
                                </mat-chip-listbox>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</main>