import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, filter, finalize, switchMap, take, throwError } from 'rxjs';
import { UserService } from '../user/user.service';
import { SecurityService } from '../security/security.service';
import { confirmAlert } from '../../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService {

  isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    private _user: UserService,
    private securityService: SecurityService,
  ) { }

  addHeaders(req: HttpRequest<any>) {
    const idToken = this._user?.user?.idToken;
    let contentType = 'application/json';
    if (idToken) {
      req = req.clone({
        headers: new HttpHeaders({
          'Authorization': `Bearer ${idToken}`,
          'Accept': contentType
        })
      });
    }
    return req;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;
    return next.handle(this.addHeaders(request)).pipe(
      catchError((err: HttpErrorResponse) => {
        return this.handleResponseError(err, request, next);
      })
    );
  }

  handleResponseError(err: HttpErrorResponse, request?: HttpRequest<any>, next?: HttpHandler) {
    if (err instanceof HttpErrorResponse && this._checkTokenExpiryErr(err)) {
      // refresh token here
      // return this.handle401Error(request, next, err);
    }
    return throwError(() => err);
  }

  private _checkTokenExpiryErr(err: HttpErrorResponse): boolean {
    console.log("checkTokenExpiryErr", err);
    return (
      err?.status === 401 &&
      err?.error?.code == 401 &&
      err?.error?.message?.includes("Jwt is expired")
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, err: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);
      this.securityService.refreshToken().then((token) => {
        this.tokenSubject.next(token);
        this.isRefreshingToken = false;
        console.log("refreshToken success", token);;
        return next.handle(this.addHeaders(request));
      })
        .catch((refreshTokenError) => {
          console.error("refresTokenError ", refreshTokenError);
          confirmAlert({
            title: "Session Expired",
            text: "Please login again",
            showCancelButton: false,
            confirmButtonText: "OK"
          }).then((confirm) => {
            this.securityService.logOut();
            return throwError(() => err);
          });
        });
    } else {
      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addHeaders(request));
        })
      );
    }
  }
}
