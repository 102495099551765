import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { PilotConstants } from '../commons/pilot-constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { ReportRequest } from '../domain/reportRequest';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  _pendingReport: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  pendingReport: Observable<boolean> = this._pendingReport.asObservable();

  constructor(
    private Constants: PilotConstants,
    private httpClient: HttpClient,
  ) {}

  postFlightReport(report: ReportRequest): Observable<any> {
    const URL = `${environment.apiUrl}${this.Constants.BFF_PATH}${this.Constants.REPORT_API}`;
    return this.httpClient.post(URL, report);
  }

}
