<main class="container mb-4 mt-6" >
    <div class="top-nextflight " *ngIf="!addingFlights">
        <a class="btn-back" (click)="backTo()"><i class="material-icons">chevron_left</i> {{onEdit ? 'Flight Log' : 'Home'}}</a>
    </div>
    <div class="top-nextflight " *ngIf="addingFlights">
        <a class="btn-back" (click)="catchFinishEvent()"><i class="material-icons">chevron_left</i> {{addingFlights ? 'Flight Log' : 'Home'}}</a>
    </div>
    <mat-tab-group [selectedIndex]="selectedIndex" *ngIf="!onEdit && !addingFlights">
        <mat-tab label="Past Flights">
            <past-flights (addFlightsEvent)="catchAddFlightsEvent($event)"></past-flights>
        </mat-tab>
        <mat-tab label="My FlightLog">
            <my-flight-logs (editEvent)="catchEditEvent($event)"></my-flight-logs>
        </mat-tab>
    </mat-tab-group>

    <load-flight-log
        *ngIf="onEdit && rulesDates" 
        [flightLogToEdit]="flightToEdit" 
        [editMode]="editMode" (editEvent)="catchEditEventFromLoad($event)"
        [rulesDates]="rulesDates"
    >
    </load-flight-log>

    <ng-container *ngIf="addingFlights">
        <add-flight-logs (finishEvent)="catchFinishEvent()" [selectedFlights]="selectedFlights"></add-flight-logs>
    </ng-container>
</main>