import { ActivatedRouteSnapshot } from '@angular/router';
import { Browser } from '@capacitor/browser';
import Swal, { SweetAlertOptions } from "sweetalert2";
import * as FileSaver from 'file-saver';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from "@capacitor-community/file-opener";

export function confirmAlert({
  title, 
  text, 
  confirmButtonText, 
  cancelButtonText, 
  allowOutsideClick = false,
  showCancelButton = true,
  showConfirmButton = true,
  reverseButtons = true,
  showCloseButton = true,
  icon = null,
  html,
  timer = null,
  imageUrl,
  imageHeight = "100px",
  imageWidth = '100px'
}: SweetAlertOptions, centerTitle = false): Promise<boolean> {
  const swalPilot = Swal.mixin({
    customClass: {
      confirmButton: 'btns-confirm-alert btn-confirm',
      cancelButton: 'btns-confirm-alert btn-cancel',
      title: centerTitle? 'title-center': 'title-left',
      popup: 'swal-pilot',
    },
    buttonsStyling: false,
    icon
  });
  return new Promise((resolve, reject) => {
    swalPilot.fire({
      title,
      text,
      html,
      confirmButtonText,
      cancelButtonText,
      showCancelButton,
      showConfirmButton,
      reverseButtons,
      showCloseButton,
      allowOutsideClick,
      timer,
      imageUrl,
      imageHeight,
      imageWidth,
    }).then((result) => {
      (result.value) ? resolve(true) : resolve(false);
    })
  });
}


export const openBrowser = async (url: string) => {
  await Browser.open({ url });
};

export const validateRolePath = (router: ActivatedRouteSnapshot, roles: string[]) => {
  const routePermissions: string[] = router.data.routePermissions;
  return routePermissions?.some( roleRoute => roles?.some(role => role === roleRoute));
}

export const fetchPDF = (base64: any, name: string, isHybrid: boolean) => {
  fetch('data:application/pdf;base64,' + base64)
    .then(res => res.blob())
    .then(blob => {
      if (!isHybrid) {
        FileSaver.saveAs(blob, name);
        let url = URL.createObjectURL(blob);
        window.open(url, '_blank', 'height=650,width=840');
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function() {
          let base64 = reader.result.toString();
          savePdf(base64, name);
        }
      }
    });
}

const KEY_PREFIX_DOCUMENTS_NAME = "df-documents-";
const KEY_PREFIX_DOCUMENTS_FOLDER = "PilotToolsApp";

export const savePdf = async (base64: string, name: string) => {
  try {
    await Filesystem.mkdir({
      path: KEY_PREFIX_DOCUMENTS_FOLDER,
      directory: Directory.Documents,
      recursive: false // like mkdir -p
    });
  } catch(e) {
    console.error('Unable to make directory', e);
  }

  Filesystem.writeFile({
    path: KEY_PREFIX_DOCUMENTS_FOLDER + "/" + KEY_PREFIX_DOCUMENTS_NAME + name,
    data: base64,
    directory: Directory.Documents,
  }).then(writeFileResponse => {
    console.log('writeFile success => ', writeFileResponse);
    FileOpener.open({ filePath: writeFileResponse.uri }).then(fo => console.log("open file success"));
  }, error => {
    console.log('writeFile error => ', error);
  });
}

export const cleanOldFiles = async(daysOld: number) =>{
  try {
    const result = await Filesystem.readdir({
      directory: Directory.Documents,
      path: KEY_PREFIX_DOCUMENTS_FOLDER
    });
    
    const currentTime = new Date().getTime();
    const daysOldInMs = daysOld * 24 * 60 * 60 * 1000;
    let pilotDocumentsToDelete = result.files.filter( file => file.name.startsWith(KEY_PREFIX_DOCUMENTS_NAME));
    
    for (const file of pilotDocumentsToDelete) {
      if ((currentTime - file.ctime) > daysOldInMs) {
        console.log(`files is older than ${daysOld} days`);
        await Filesystem.deleteFile({
          directory: Directory.Documents,
          path: KEY_PREFIX_DOCUMENTS_FOLDER + "/" + file.name
        });
        console.log(`Deleted old file: ${file.name}`);
      }
    }
  } catch (error) {
    console.error('Error cleaning old files:', error);
  }
}