<div class="form-flight-log ml-2 mt-4 mr-2" [formGroup]="formflightLog">
    <div class="row">
        <div class="col-12">
            <mat-form-field floatLabel="always" class="full-width">
                <mat-label>Flight log number</mat-label>
                <input matInput placeholder="Ej: 0112200017" formControlName="flightLogNumber" type="number" (change)="isFlightLogAlreadyCreated($event)">
            </mat-form-field>
        </div>
    </div>

    <ng-container *ngIf="editMode != 'edit-flight'">
        <div *ngIf="flights.length > 0">
            <label>Flight list</label>
        </div>
        <mat-chip-listbox >
            <ng-container *ngFor="let fl of flights; let i = index">
                <mat-chip-option class="mat-chip-pl">
                    {{ fl.comercialAirlineCode }} {{ fl.flightNumber }} {{fl.multipleFlights ? '(' + fl.arrival + ')' : ''}}
                    <mat-icon *ngIf="fl.removable" matChipRemove (click)="removeFlight(fl)">cancel</mat-icon>
                </mat-chip-option>
            </ng-container>
        </mat-chip-listbox>
    </ng-container>

    <div class="row mt-3">
        <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12">
            <mat-form-field floatLabel="always" class="full-width" >
                <mat-label>Flight date (UTC)</mat-label>
                <input [max]="rulesDates?.maxDate" [min]="rulesDates?.minDate" matInput #picker1 [matDatepicker]="picker" placeholder="Flight date (UTC)"
                formControlName="flightDate" (click)="clickPicker()" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker></mat-datepicker>
                <mat-error>
                  Flight date is
                  <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-5">
            <mat-form-field floatLabel="always" class="full-width">
                <mat-select class="mat-select-fl mat-select-fl-disabled" style="width: 40%;" placeholder="Operator" formControlName="operator" required>
                    <mat-option *ngFor="let operator of operators" [value]="operator.name">
                    {{ operator.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-5 col-sm-9 col-7">
            <mat-form-field floatLabel="always" class="full-width">
                <mat-label>Flight number</mat-label>
                <input type="text" matInput placeholder="Flight number" formControlName="flightNumber" >
            </mat-form-field>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
            <mat-form-field floatLabel="always" class="full-width">
                <mat-label>Departure</mat-label>
                <input type="text" maxlength="3" placeholder="Departure" style="text-transform: uppercase;" matInput 
                formControlName="departure" required >
            </mat-form-field>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
            <ng-container *ngIf="!multiplesFlightsFound">
                <mat-form-field floatLabel="always" class="full-width">
                    <mat-label>Arrival</mat-label>
                    <input type="text" matInput placeholder="Arrival" formControlName="arrival">
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="multiplesFlightsFound">
                <mat-form-field floatLabel="always" class="d-block">
                    <mat-label>Select an arrival</mat-label>
                    <mat-select class="mat-select-fl mat-select-fl-arrival" placeholder="Arrival" formControlName="arrival" required>
                        <mat-option *ngFor="let flight of flightsFound" [value]="flight.arrival">
                        {{ flight.arrival }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
    <div class="row latam_bold_italic font-12 justify-content-center m-0">
        <div class="d-flex" *ngIf="flightSearchingState == 'Searching flight'">
            <span class="mr-1">{{flightSearchingState}}</span>
            <mat-progress-spinner class="main-spinner" [color]="'accent'" [mode]="'indeterminate'" [diameter]="20" ></mat-progress-spinner>
        </div>
        <span *ngIf="flightSearchingState != 'Searching flight'" class="d-flex text-search">
            {{flightSearchingState}}
            <ng-container *ngIf="flightSearchingState != 'Search for a flight by filling in the fields date, departure and flight number'">
                <mat-icon matSuffix class="icon-display" style="margin-top:-4px">
                    {{flightSearchingState == 'Error at searching flight' || flightSearchingState == 'Flight not found' ? 'error' : 'check_circle'}}
                </mat-icon>
            </ng-container>
        </span>
    </div>

    <hr>

    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <mat-form-field floatLabel="always" class="full-width">
                <mat-label>Fleet</mat-label>
                <input autocomplete="off" type="text" matInput placeholder="Fleet" formControlName="fleet">
            </mat-form-field>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <mat-form-field floatLabel="always" class="full-width">
                <mat-label>Aircraft registration number</mat-label>
                <input type="text" matInput placeholder="Aircraft registration number" formControlName="aircraftRegistrationNumber">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <mat-form-field floatLabel="always" class="full-width">
                <mat-label>Start time</mat-label>
                <input autocomplete="off" type="text" mask="Hh:m0" [leadZeroDateTime]="true" matInput placeholder="Start time" formControlName="startTime">
            </mat-form-field>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <mat-form-field floatLabel="always" class="full-width">
                <mat-label>End time</mat-label>
                <input autocomplete="off" type="text" mask="Hh:m0" [leadZeroDateTime]="true" matInput placeholder="End time" formControlName="endTime">
            </mat-form-field>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <mat-form-field floatLabel="always" class="full-width">
                <mat-label>Block time</mat-label>
                <input autocomplete="off" type="text" matInput placeholder="Block time" formControlName="blockTime">
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="!isAddingFlight()">
        <div class="col-12">
            <label class="label-input-file">
                <span>Upload Flight Log book image (max 5mb)</span>
            </label>
            <button class="input-file" type="button" (click)="fileInput.click()">{{this.selectedFile ? this.selectedFile.name : filePlaceholder}}
                <mat-icon matSuffix class="icon-display">attach_file</mat-icon>
            </button>
            <input hidden (change)="onFileSelected($event)" #fileInput type="file">
        </div>
    </div>
</div>

<div class="btn-actions">
    <button 
        class="btn-action btn-pilot pilot-secondary"
        (click)="isEditingFlight() ? updateFlight() : addFlight()"
        [disabled]="!formflightLog.valid"
        >
        {{isEditingFlight() ? 'Edit Flight' : 'New Flight'}}
    </button>
    <button 
        [disabled]="isAddingFlight() ? (flightLogToEdit.flights.length == 0 || !flightLogToEdit.flightLogNumber || saving) : (flights.length == 0 || !flightLogToCreate.flightLogNumber || saving)" 
        class="btn-action btn-pilot pilot-primary"
        (click)="saveFlightLog()"
        *ngIf="!isEditingFlight()">
        {{saving ? 'Saving' : 'Load Data'}}
        <mat-progress-spinner *ngIf="saving" [color]="'accent'" [mode]="'indeterminate'" [diameter]="25" style="float: right;"></mat-progress-spinner>
    </button>
    <button 
        [disabled]="!this.selectedFile"
        class="btn-action btn-pilot pilot-primary"
        (click)="replaceFile()"
        *ngIf="isEditingFlight()">
        Replace file
    </button>
</div>