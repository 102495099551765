import { SimpleDialog } from "src/app/core/services/dialog/simple-dialog.service";
import { DispatchSubcomponent } from "src/app/modules/pilot/domain/dispatch/dispatch-subcomponent.model";
import { CommonsService } from "src/app/core/services/commons/commons.service";
import { UserService } from "src/app/core/services/user/user.service";
import { BtmReportViewComponent } from "./btm-report/btm-report-view/btm-report-view.component";
import { BtmReportComponent } from "./btm-report/btm-report.component";
import { GroundSupportStatusComponent } from "./ground-support-status/ground-support-status.component";
import { LimopsReportComponent } from "./limops-reports/limops-report.component";
import { RouteReportComponent } from "./route-report/route-report.component";
import { MaintenixReportComponent } from "./maintenix-report/maintenix-report.component";
import { DirectosRecommendationComponent } from "./directos/diretos-recommendation.component";

export class DispatchSubcomponentManager{

  //Ground support Report
  public groundSupportStatus:DispatchSubcomponent<GroundSupportStatusComponent>;

  //Limops report
  public limops:DispatchSubcomponent<LimopsReportComponent>;

  //Limops report
  public briefing:DispatchSubcomponent<BtmReportComponent>;

  public btmView:DispatchSubcomponent<BtmReportViewComponent>;

  //Limops report
  public routeReport:DispatchSubcomponent<RouteReportComponent>;
  public maintenix:DispatchSubcomponent<MaintenixReportComponent>;

  //recommendations
  public directos:DispatchSubcomponent<DirectosRecommendationComponent>;

  constructor(private simpleDialog: SimpleDialog, private commonsService:CommonsService, private _user: UserService){
    this.groundSupportStatus = new DispatchSubcomponent();
    this.limops = new DispatchSubcomponent();
    this.briefing = new DispatchSubcomponent();
    this.btmView = new DispatchSubcomponent();
    this.routeReport = new DispatchSubcomponent();
    this.maintenix = new DispatchSubcomponent();
    this.directos = new DispatchSubcomponent();
    
  }

  public atLeastOneMainComponentIsWaiting(){
    return this.briefing.isWaiting() || this.groundSupportStatus.isWaiting(); //|| this.limops.isWaiting(); 
  }

   /* ---------- BTM ---------- */

  public openBtmViewModal() {
    if (this.briefing.success()) {
      this.commonsService.setGtag('Dispatch Folder', 'Briefing Report View');
      this.btmView.showTheModal();
      document.getElementsByTagName('body')[0].classList.remove('slideInRightReverse');
    }
  }

  
  public closeBtmViewModal(e) {
    this.btmView.hideTheModal();
    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('slideInRightReverse');
  }

   public refreshBTMReport(){
    this.briefing.setState('waiting');
    this.simpleDialog.snackBarMessage('A preview of your BTM document is being generating');
    this.briefing.getSubcomponent().ngOnInit();
  }

  public reloadBTMReport(){
    this.briefing.setState('waiting');
    this.briefing.getSubcomponent().ngOnInit();
  }

  /* ---------- Limops Report ---------- */
  public openLimopsReportModal() {
    if(this.limops.success()){
      this.commonsService.setGtag('Dispatch Folder', 'Limops');
      this.limops.showTheModal();
      document.getElementsByTagName('body')[0].classList.remove('slideInRightReverse');
    }
  }

  public closeLimopsReportModal(e) {
    this.limops.hideTheModal();
    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('slideInRightReverse');
  }

  public refreshLimops(){
  //  this.limops.setState('waiting');
  //  this.limops.getSubcomponent().ngOnInit();
  }

  /* ---------- Route Report ---------- */
  public openRouteReportModal() {
    if(this.routeReport.success()){
      this.routeReport.showTheModal();
      document.getElementsByTagName('body')[0].classList.remove('slideInRightReverse');
    }
  }

  public closeRouteReportModal(e) {
    this.routeReport.hideTheModal();
    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('slideInRightReverse');
  }

  /* ----------  Ground Support Status Report ---------- */
  public openGroundSupportStatusReportModal() {
    if(this.groundSupportStatus.success()){
      this.groundSupportStatus.showTheModal();
      document.getElementsByTagName('body')[0].classList.remove('slideInRightReverse');
    }
 }

  public closeGroundSupportStatusReportModal(e) {
    this.groundSupportStatus.hideTheModal();
    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('slideInRightReverse');
  }

  public refreshGroundSupportStatus(){
    this.groundSupportStatus.setState('waiting');
    this.groundSupportStatus.getSubcomponent().ngOnInit();
  }


  public openMaintenixViewModal() {
    if(this.maintenix.success()){
      this.commonsService.setGtag('Dispatch Folder', 'Maintenix Report View');
      this.maintenix.showTheModal();
      document.getElementsByTagName('body')[0].classList.remove('slideInRightReverse');
      }
  }

  public reloadMaintenixReport(){
    this.maintenix.setState('waiting');
    this.maintenix.getSubcomponent().ngOnInit();
  }
  
  public closeMaintenixViewModal(e) {
    this.maintenix.hideTheModal();
    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('slideInRightReverse');
  }

  public openDirectosViewModal() {
    if(this.directos.success()){
      this.commonsService.setGtag('Dispatch Folder', 'Directos View');
      this.directos.showTheModal();
      document.getElementsByTagName('body')[0].classList.remove('slideInRightReverse');
      }
  }

  public reloadDirectosReport(){
    this.directos.setState('waiting');
    this.directos.getSubcomponent().ngOnInit();
  }
  
  public closeDirectosViewModal(e) {
    this.directos.hideTheModal();
    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('slideInRightReverse');
  }

}