<mat-progress-bar *ngIf="showLoading" class="progress-bar-home" [bufferValue]="bufferValue" mode="indeterminate"
    value="{{progressBar}}"></mat-progress-bar>
<!--Main layout-->
<main class="pt-5 mt-4">
    <div class="mt-3 container">

        <!--Grid row-->
        <app-back-home> </app-back-home>

        <div class="row wow fadeIn">


            <div class="col-lg-12">
                <app-alerts 
                    [text]="constants.MESSAGES_APP.FLIGHT_REPORT.MAIN"
                    [class]="'w-100'"
                >
                </app-alerts>
            </div>

            <!--Grid column-->
            <div *ngIf="reportFormGroup" class="w-100 latam_regular" [formGroup]="reportFormGroup">
                <div class="col-lg-12 mb-3">
                    <h2 class="title-flightreport latam_bold_italic">Flight Report</h2>
                    <div class="card-generic no-elevation p-4">
                        <div class="row">
                            <div class="col-sm-6">
                                <mat-form-field class="input-100">
                                    <input type="text" (keyup)="saveReportStatus()" matInput placeholder="Flight number"
                                        maxlength="5" pattern="([0-9]{1,4}[a-z]{0,1})" formControlName="flightNumber">
                                    <mat-error>
                                        Format is
                                        <strong>max 4 number and 1 letter </strong>(example: 1000a)
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-6 col-6">
                                <mat-form-field class="input-100">
                                    <mat-select (selectionChange)="saveReportStatus()" placeholder="Operator"
                                        formControlName="owner" required>
                                        <mat-option *ngFor="let owner of ownerList" [value]="owner.name">
                                            {{ owner.name }}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Operator is
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-6 col-6">
                                <mat-form-field class="input-100">
                                    <mat-select (selectionChange)="saveReportStatus()" placeholder="Fleet"
                                        formControlName="fleet" required>
                                        <mat-option *ngFor="let fleet of fleetList" [value]="fleet.name">
                                            {{ fleet.name }}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Fleet is
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-sm-6 col-6">
                                <mat-form-field class="input-100">
                                    <input type="text" (keyup)="saveReportStatus()" placeholder="Tail Number" matInput
                                        formControlName="tailNumber" [matAutocomplete]="auto">
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="saveReportStatus()">
                                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                            {{option}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>

                            <div class="col-sm-6 col-6">
                                <mat-form-field class="input-100">
                                    <input type="text" maxlength="3" (keyup)="saveReportStatus()"
                                        placeholder="Departure" style="text-transform: uppercase;" matInput
                                        [matAutocomplete]="departureAC" formControlName="departure">
                                    <mat-autocomplete #departureAC="matAutocomplete">
                                        <mat-option (click)="saveReportStatus()" *ngFor="let option of filteredDeparture | async" [value]="option">
                                            {{option}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>

                            <div class="col-sm-6 col-6">
                                <mat-form-field class="input-100">
                                    <input type="text" maxlength="3" (keyup)="saveReportStatus()" placeholder="Arrival"
                                        style="text-transform: uppercase;" [matAutocomplete]="arrivalAC" matInput
                                        formControlName="arrival">
                                    <mat-autocomplete #arrivalAC="matAutocomplete">
                                        <mat-option (click)="saveReportStatus()" *ngFor="let option of filteredArrival | async" [value]="option">
                                            {{option}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>

                            <div class="col-sm-6 col-6">
                                <mat-form-field class="input-100">
                                    <input (dateChange)="saveReportStatus()" matInput #picker1 [matDatepicker]="picker"
                                        placeholder="Date" formControlName="flightDate" (click)="clickPicker()"
                                        required>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker touchUi #picker></mat-datepicker>
                                    <mat-error>
                                        Flight date is
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 mb-3">
                    <div class="card-generic p-4">
                        <div class="row">
                            <div class="col-sm-12">

                                <div class=" btns-report">
                                    <div class=" text-center">

                                        <button (click)="openModalGuide()" [disabled]="showLoading"
                                            class="m-0  fr-button btn-pilot pilot-primary">
                                            <div class="info-button" >
                                                <mat-icon >info</mat-icon>
                                                &nbsp; Coding Guide
                                            </div>
                                        </button>
                                    </div>
                                </div>


                                <mat-form-field class="input-100">
                    
                                    <div class="col-sm-12 col-12">
                                        <label class="reasonsTitleColor latam_bold_italic">Select a Reason</label>
                                    </div>
                                    <mat-select class="col-sm-12 col-12" (selectionChange)="getDataAndReportSubReason($event.value)"
                                        #reasons  required> 
                                        <mat-option *ngFor="let reason of this.neoreasonList" [value]="reason.name">
                                            {{ reason.name }}</mat-option>
                                    </mat-select>
                                  
                                    <mat-error>
                                        Reason is
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
    
                                <mat-form-field class="input-100" >
                                    <div class="col-sm-12 col-12">
                                        <label class="reasonsTitleColor latam_bold_italic">Select a SubReason</label>
                                    </div>
                                    <mat-select class="col-sm-12 col-12"  (selectionChange)="getDataOfSubReason($event.value)"
                                        #subReason required>
                                        <mat-option *ngFor="let sub of this.subReasonList" [value]="sub.name">
                                            {{ sub.name }}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        SubReason is
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
    
                            </div>
                        </div>
                        <div class="row h-calc">
                            <div class="col-12">
                              <div class="row p-3">
                                <div class="col-sm-12 col-12">
                                    <div class="row col-sm-12 col-12">
                                        <label class="reasonsTitleColor latam_bold_italic">Time Delay</label> <label class="latam_bold_italic"> &nbsp; Optional</label>
                                    </div>
                                    <mat-form-field class="delay-input">
                                        <input [formControl]="delay" matInput type="time" min="00:00:00" max="24:59:59" style="font-size: 20px;" >
                                        <mat-hint style="color: red;">Example: 00:40  (40 minutes of delay)</mat-hint>
                                        <mat-error *ngIf="delay.invalid">{{getErrorMessage()}}</mat-error>
                                    </mat-form-field>
                                </div>
    
                                <div class="col-sm-12 col-12" *ngIf = "this.isExtraFuel">
                                    <div class="row col-sm-12 col-12">
                                        <label class="reasonsTitleColor latam_bold_italic"> Enter the amount of extra kilograms of fuel you added</label>
                                    </div>
                                    <mat-form-field class="w-100">
                                        <input  [formControl]="kgExtraFuel" matInput placeholder="KG*" type="number">
                                        <mat-error *ngIf="kgExtraFuel.invalid">{{getErrorMessage()}}</mat-error>
                                    </mat-form-field>
                                </div>
    
                                <mat-form-field class="input-100 col-lg-12 mt-2 mb-3" hintLabel="Max 2000 characters">
                                       
                                <label class="reasonsTitleColor latam_bold_italic"> Additional information</label>
                                  <textarea matInput  #freeText maxlength="2000"
                                    class="textarea-info"></textarea>
                                  <mat-hint align="end" > &nbsp; {{freeText.value?.length || 0}}/2000</mat-hint>
                                </mat-form-field>
    
                                

                                <mat-form-field class="col-sm-12 col-12 col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-10 col-sm-10 col-10">
                                            <div class="">
                                                <label class="reasonsTitleColor latam_bold_italic">Upload files</label> 
                                                <p class="font-10">(.JPG, .PNG, .PDF, .doc) (Max 5mb)</p>
   
                                            </div>
                                          
                                  
                                            <input matInput id="fileNameInput" [readonly]="true" type="text" value="No file chosen">
                                        </div>

                                        <div class="col-lg-2 col-sm-2 col-2"  style="  margin-top: -15px;">
                                            
                                            <a class="delete pull-right" floating="true">
                                                <input [formControl]="file" id="file-input" type="file" class="input-file btn" multiple>
                                                <i class="material-icons delete">attach_file</i>
                                            </a>
                                            <a class="delete pull-right" floating="true" (click)="deleteAttachment()">
                                                <i class="material-icons delete">delete</i>
                                            </a>
                                        </div>
                                    </div>
                                </mat-form-field>
                             
                                
                              </div>
                            </div>
                            <div class="col-12 div-align-bottom">
                              <div class="text-center">
                                <button (click)="saveReason()" class="col-centered btn-mdb-color-pl" [disabled]="!this.reasonSelected || !this.subReasonSelected || this.savingSubReason">
                                    Save <mat-progress-spinner *ngIf="this.savingSubReason" [color]="'accent'" [mode]="'indeterminate'" [diameter]="25" class="saving-reason-spinner"></mat-progress-spinner>
                                </button>
                              </div>
                            </div>
                          </div>
                    </div>
                </div>

             
                <div class="col-12 mb-3">
                    <h2 class="secondary-title latam_bold_italic">Reasons created</h2>
                </div>
                <div class="col-12 mt-1 mb-4" id="addedReasons">
                    <!-- REASONS -->
                    <div class="mb-2" *ngFor="let addedReason of addedReasons; let i=index">
                        <mat-card appearance="outlined" class="zoomIn addedReasons">

                            <div class="reason-description">
                                <span class="addedCategory" >{{addedReason.reasonName}} </span> 
                                <span>| {{ addedReason.subReasonName }}</span>
                            </div>
                            <div class="reason-close-button" (click)="deleteReason(i)"><i class="material-icons icon-close">close</i></div>
                        </mat-card>
                    </div>
                </div>

                <div class="col-lg-12 col-sm-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-12 mb-3">
                            <div class="card-generic no-elevation pl-4 pr-4 pt-3 col-sm-12">
                                <div class="row">
                                    <div class="col-sm-8 col-8">
                                        <p class="t-gray-3">Send me a copy of the report.</p>
                                    </div>
                                    <div class="col-sm-4 col-4">
                                        <mat-slide-toggle class="example-margin pull-right" formControlName="cc"
                                            (change)="saveReportStatus()">
                                        </mat-slide-toggle>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <app-alerts 
                        *ngIf="errorValidForm"
                        [text]="'Please fill out all required fields.'"
                        [class]="'w-100'"
                        [type]="'danger'"
                    >
                    </app-alerts>
                </div>
                <div class="col-sm-12 btns-report">
                    <div class="md-form text-center">
                        <button (click)="sendReport()" [disabled]="showLoading"
                            class="m-0 sendReport fr-button btn-pilot pilot-primary">Send Report</button>
                        <button (click)="cancelFR()"
                            class="m-0 btn-pilot cancel-btn">Cancel</button>
                    </div>
                </div> 
            </div>
        </div>

    </div>
</main>