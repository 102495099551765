<div>
    <mat-toolbar class="header freeze-view">
        <mat-toolbar-row>
            <span class="material-icons clickable" (click)="closeModal()">chevron_left</span>
            <span>Documents</span>
        </mat-toolbar-row>
    </mat-toolbar>
</div>

<div style=" background-color: white;" class="scrollable-docs" *ngIf="this.initView">
    <div class="mt-6">
        <div class="document-container freeze-view ">
            <div class="row justify-content-center">
                <h3 class="col-12 document-title text-center">Direct Heading Recommender <h5 class="document-title text-center">
                        (Operational Recommendation)</h5>
                </h3>
            </div>
        </div>
        <div *ngIf="true" class="row  col-12" style=" padding-left: 3rem">
            <div class="section-title-group col-12">
                <span class="">{{this.flightInfo}}</span>
                <span class="" style="  float: right;">{{this.flightInfoDate}}</span>

            </div>
        </div>
        <br>

        <div class=" col-12" style=" padding-left: 2rem; padding-right: 1.8rem; justify-content: center;">
            <mat-expansion-panel class="panel" [expanded]="true" [hideToggle]="true" [disabled]="true">
                <mat-expansion-panel-header class="expansion-panel" [collapsedHeight]="'auto'"
                    [expandedHeight]="'auto'">
                    <mat-panel-title>
                        <span class="recommendation-tittle description">Recommendation: </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row mt-2 data-border ">
                    <div class=" mr-8 subtittle">
                        <div class="">
                            <span class="">{{this.directsReport.recommendation}} </span>
                        </div>
                    </div>
                </div>

            </mat-expansion-panel>

        </div>
        <br>
        <br>
        <div class="row  col-12" style=" padding-left: 3rem;    justify-content: center;">
            <span class="recommendation-tittle description">Were you able to take the Direct heading
                recommendation?</span>

        </div>

        <div>

            <div class="row buttons-actions">
                <button mat-fab class="button-circle" (click)="saveComment('withoutConfirm', 1)"
                    [disabled]="this.usefulDirect==-1"><mat-icon class="check-ok">check_circle</mat-icon></button>
                &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;

                <button mat-fab class="button-circle" (click)="saveComment('withoutConfirm', -1)"
                    [disabled]="this.usefulDirect==1"> <mat-icon class="x-circle">cancel</mat-icon></button>

            </div>

        </div>
        <br>
        <div class="row  col-12" style=" padding-left: 3rem;    justify-content: center;">
            <span class="recommendation-tittle description">
                If your answer is no, please let us know why in the comments section.
            </span>
        </div>
        <br>
        <div>
            <div class="row col-12" style=" padding-left: 3rem;">
                <textarea class="commentary col-12" placeholder="Comments here(Optional)" [(ngModel)]="comment"
                    maxlength="150">
                </textarea>
            </div>
        </div>
        <br>
        <div *ngIf="this.feedback" class="row  col-12" style=" padding-left: 3rem;    justify-content: center;">
            <span class="recommendation-tittle description">
                Thanks for your feedback!
            </span>
        </div>
        <div>

            <div class="row buttons-actions">
                <button class="row btn-pilot pilot-primary" (click)="downloadPDF()">
                    Download
                </button>
            </div>
            <div class="row buttons-actions">
                <button class="row btn-pilot pilot-primary" style="margin-top: 5px" (click)="saveComment('confirm',0)"
                    [disabled]="this.savingComment">
                    {{this.savingComment ? 'Sending' : 'Send comment'}}

                    <mat-progress-spinner *ngIf="this.savingComment" [color]="'accent'" [mode]="'indeterminate'"
                        [diameter]="25" class="downloading-spinner"></mat-progress-spinner>

                </button>
            </div>

        </div>


    </div>
</div>