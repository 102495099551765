import { Injectable } from '@angular/core';
import { PilotConstants } from '../commons/pilot-constants';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(
    private httpClient: HttpClient,
    private Constants: PilotConstants
  ) { }

  getFAQ(operator: string): Observable<any>{
    const params = new HttpParams().set('operator', operator);
    const URL = `${environment.apiUrl}${this.Constants.BFF_PATH}${this.Constants.FAQ}`;
    return this.httpClient.get(URL, { params });
  }
}
