import { Injectable } from '@angular/core';
import { PilotConstants } from '../../../modules/pilot/commons/pilot-constants';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../user/user.service';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Device, DeviceInfo } from '@capacitor/device';
import { QRCode } from '../../../modules/pilot/domain/login/qrCode';
import { IUser } from '../../../modules/pilot/domain/user/user';

@Injectable({
  providedIn: 'root'
})
export class CommonsService {
  public auth2: any;
  infoDevice: DeviceInfo;
  baseUrl = environment.apiUrl;
  constructor(
    private httpClient: HttpClient,
    private constants: PilotConstants, 
    private _user: UserService,
  ) { 
    Device.getInfo().then(info => this.infoDevice = info);
  }

  getAirports() {
    const URL = `${this.baseUrl}${this.constants.BFF_PATH}${this.constants.AIRPORTS}`;
    return this.httpClient.get(URL);
  }

  getPilot() {
    const URL = `${this.baseUrl}${this.constants.BFF_PATH}${this.constants.LOGIN_API}`;
    return this.httpClient.get(URL);
  }

  getSession(code: QRCode) {
    const URL = `${this.baseUrl}${this.constants.BFF_PATH}${this.constants.PILOT_PROFILE_API}${this.constants.PILOT_PROFILE}` + `/qr/login`;
    return this.httpClient.post(URL, code);
  }

  saveSession({ idToken, accessToken, profilePicture, userID, userName, lastName } : Partial<IUser>) {
    let userSave:Partial<IUser> = {idToken, accessToken, profilePicture,userID, userName, lastName, };
    const URL = `${this.baseUrl}${this.constants.BFF_PATH}${this.constants.PILOT_PROFILE_API}${this.constants.PILOT_PROFILE}` + `/qr/save`;
    return this.httpClient.post(URL, userSave);
  }


  getFlightReportReasonList() {
    const URL = `${this.baseUrl}${this.constants.BFF_PATH}${this.constants.REPORT_REASON_LIST}`;
    return this.httpClient.get(URL);
  }


  async setGtag(category: string, action: string, label = this._user.user?.userID) {
    // view information sent to Analytics
    if (!this.infoDevice) this.infoDevice = await Device.getInfo();
    
    action = action + ` from ${this.infoDevice.platform.toUpperCase()}`;
    if (!environment.production) console.warn("data setGtag", { category, action, label});

    if (this.infoDevice.platform == "web") {
      if (typeof window["gtag"] !== 'undefined') {
        (<any>window)?.gtag('event', action, { 'event_category': category, 'event_label': label });
      }
    } else {
      FirebaseAnalytics.logEvent({
        name: action,
        params: {
          event_category: category,
          event_label: label
        },
      });
    }
  }
}
