import jsPDF from "jspdf";

export const getPageSizeWidth = (pdf: jsPDF) => {
    return pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
  }
  export const  getPageSizeHeight = (pdf: jsPDF) => {
    return pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
  }

export const didParseCellOne = (data, existPax) => {
    if (data.column.index === 2 && data.cell.section === 'body' && existPax) {
        if (data.cell.text[0] == 'true') {
            data.cell.styles.textColor = "white";
            data.cell.styles.fillColor = "#E8114B";
            data.cell.text = ['PAX'];
            data.cell.styles.fontStyle = 'bold';
        } else {
            data.cell.text = [''];
        }
    }
}

export const didParseCellTwo = (data, flightDateForFlightData) => {
    if (data.section == 'body' && (data.cell.raw.toLowerCase() == flightDateForFlightData || data.cell.raw == "")) {
        data.cell.styles.fontSize = 9;
        data.cell.styles.halign = 'center';
        data.cell.styles.valign = 'top';
        data.cell.height = 8;
        data.cell.styles.cellHeight = 8;
        data.cell.y = data.cell.y - 10;
    } else if (data.section == 'body') {
        data.cell.styles.fontSize = 24;
        data.cell.styles.halign = 'center';
        data.cell.styles.valign = 'bottom';
        data.cell.height = 8;
        data.cell.styles.cellHeight = 8;
    }
}

export const didParseCellThree = (data, flightData) => {
    if (data.section == 'body' && data.cell.raw == (flightData[0][0] + " " + flightData[0][2])) {
        data.cell.styles.fontStyle = 'bold';
        data.cell.styles.textColor = [27, 0, 136];
    }
}
export const didParseCellFour = (data) => {
    data.cell.maxWidth = 12;
    data.cell.contentWidth = 15;
    if (data.section == 'head') {
        data.cell.styles.lineWidth = { top: 1, right: 0, bottom: 0, left: 0 };
    }
}
export const didParseCellFive = (data) => {
    data.cell.maxWidth = 12;
    data.cell.contentWidth = 15;
    if (data.section == 'head') {
        data.cell.styles.lineWidth = { top: 1, right: 0, bottom: 0, left: 0 };
    }
}
