export class SubcomponentState{
    public subcomponent: any;
    public state: string;

    constructor(subcomponent: any, state: string){
        this.subcomponent = subcomponent;
        this.state = state;
    }

    public success(): boolean{
        return this.state == 'success';
    }

    public notAvailable(): boolean{
        return this.state == 'not-available';
    }
}