<div class="container-fluid p-0" [@enterAnimation] >
  <mat-toolbar class="header">
    <mat-toolbar-row>
      <span class="material-icons clickable"(click)="closeModal()">chevron_left</span>
      <span class="latam_bold_italic">Documents</span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="row justify-content-center ">
    <h2 class="col-8 document-title text-center">Ground Equipment Status</h2>
    <br>
  </div>
  <div class="row justify-content-center ">
    <div class="row w-100" style="place-content: center;">
      <mat-form-field appearance="fill">
        <mat-label>Base filtering</mat-label>
        <mat-chip-grid #chipList aria-label="Base selection">
          <mat-chip-row class="text-white"
            *ngFor="let base of bases"
            (removed)="removeBase(base)">
            {{base}}
              <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-row>
          <input
            placeholder="Search base..."
            #baseInput
            (matChipInputTokenEnd)="addBase($event)"
            [formControl]="baseCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
        </mat-chip-grid>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="baseIsSelected($event)">
          <mat-option *ngFor="let base of filteredBases | async" [value]="base">
            {{base}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

    </div>
    <div class="row w-100" style="place-content: center;">
      <mat-form-field appearance="fill">
        <mat-label>Fleet filtering</mat-label>
        <mat-chip-grid #chipFleetList aria-label="Fleet selection">
          <mat-chip-row class="text-white"
            *ngFor="let fleet of fleetList"
            (removed)="removeFleet(fleet)">
            {{fleet}}
              <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-row>
          <input
            placeholder="Search Fleet..."
            #fleetInput
            (matChipInputTokenEnd)="addFleet($event)"
            [formControl]="fleetCtrl"
            [matAutocomplete]="fleetAuto"
            [matChipInputFor]="chipFleetList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
        </mat-chip-grid>
        <mat-autocomplete #fleetAuto="matAutocomplete" (optionSelected)="fleetIsSelected($event)">
          <mat-option *ngFor="let fleet of filteredFleets | async" [value]="fleet">
            {{fleet}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

    </div>
  </div>
</div>
<div>

</div>


<div style="height: calc(100vh - 250px); width: 100%; overflow-x: hidden;">
  <div class="row h-100">
    <div class="col-12">

      <mat-card appearance="outlined" class="table-container">
        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" aria-describedby="pilot table">

            <!-- Base Column -->
            <ng-container matColumnDef="base">
                <th mat-header-cell *matHeaderCellDef class="column-title latam_bold_italic regular-column-size"> Base </th>
                <td mat-cell *matCellDef="let row" class="td-text regular-column-size"> {{row.base}} </td>
            </ng-container>

            <!-- fleet Column -->
            <ng-container matColumnDef="fleet">
                <th mat-header-cell *matHeaderCellDef class="column-title latam_bold_italic regular-column-size"> Fleet </th>
                <td mat-cell *matCellDef="let row" class="td-text regular-column-size"> {{row.fleet}} </td>
            </ng-container>

            <!-- Flight Column -->
            <ng-container matColumnDef="acu">
                <th mat-header-cell *matHeaderCellDef class="column-title latam_bold_italic regular-column-size"> ACU</th>
                <td mat-cell *matCellDef="let row" class="regular-column-size font-weight-bold" [ngClass]="row.acu == 'Available' ? 'available' : 'not-available' "> {{row.acu}}</td>
            </ng-container>

            <!-- Via Column -->
            <ng-container matColumnDef="asulpu">
                <th mat-header-cell *matHeaderCellDef class="column-title latam_bold_italic regular-column-size"> ASI/LPU</th>
                <td mat-cell *matCellDef="let row" class="regular-column-size font-weight-bold" [ngClass]="row.asulpu == 'Available' ? 'available' : 'not-available' "> {{row.asulpu}} </td>
            </ng-container>

            <!-- Flight Level Column -->
            <ng-container matColumnDef="gpu">
              <th mat-header-cell *matHeaderCellDef class="column-title latam_bold_italic regular-column-size"> GPU</th>
              <td mat-cell *matCellDef="let row" class="regular-column-size font-weight-bold" [ngClass]="row.gpu == 'Available' ? 'available' : 'not-available' "> {{row.gpu}} </td>
            </ng-container>

            <!-- Wind Column -->
            <ng-container matColumnDef="lastUpdate">
              <th mat-header-cell *matHeaderCellDef class="column-title latam_bold_italic regular-column-size">Last Update</th>
              <td mat-cell *matCellDef="let row" class="td-text regular-column-size"> {{row.lastUpdate | date:"medium"}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </mat-card>
    </div>
  </div>
</div>
