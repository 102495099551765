export class CategoryState {
  name: string;
  selected: boolean;
  totalExpired: number;
  totalNextToExpire: number;

  public constructor(name: string, selected: boolean) {
      this.name = name;
      this.selected = selected;
      this.totalExpired = 0;
      this.totalNextToExpire = 0;
  }
}
