import { Component, Input } from "@angular/core";
import { Document } from "../../../domain/profile/document.model";

@Component({
  selector: 'document-card-list',
  templateUrl: './document-card-list.component.html',
  styleUrls: ['../profile.component.css']
})
export class DocumentCardListComponent {

  @Input()
  documents: Array<Document>;

  @Input()
  state: string;

  documentListIsNotEmpty(): boolean{
    return this.documents.length != 0;
  }
}
