import { FlightLog } from "./flight-log.model";

export class FlightLogState {
  flightLog: FlightLog;
  state: string;

  constructor(flightLog: FlightLog, state: string){
    this.flightLog = flightLog;
    this.state = state;
  }
}