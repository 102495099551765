import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { confirmAlert } from 'src/app/core/utils/utils';
import { INotifications } from 'src/app/modules/pilot/domain/notifications/notifications';
import { NotificationsService } from 'src/app/modules/pilot/services/notifications/notifications.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification-communications',
  templateUrl: './notification-communications.component.html',
  styleUrls: ['./notification-communications.component.scss']
})
export class NotificationCommunicationsComponent implements OnChanges {

  @Input() typeNotification = "";
  @Input() notifications: INotifications[] = [];
  @Output() reDraw: EventEmitter<any> = new EventEmitter();
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  numberNotifications = 0;
  currentUrl = '';
  
  constructor(
    private notificationsService: NotificationsService,
    private router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.notifications = this.notifications.filter(n => (n.typeNotification == this.typeNotification ) && (n.communicationAck == false));
  }

  async marlAllAsRead() {
    let confirm = await confirmAlert({
      title: 'Are you sure you want to mark all messages as read?',
      text: 'If you want to mark all messages as read select save. This action cannot be reversed.',
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel'
    });
    if (confirm) {
      this.notifications.forEach(n => this.updateNotifications(n));
    }
  }

  async updateNotifications(notification: INotifications) {
    if (!notification.view) {
      notification.view = true;
      this.notificationsService.updateNotifications({ view: true, idNotificationByBp: notification.idNotificationByBp}).subscribe({
        next: (resp) => {
          this.reDraw.emit();
        }
      });
    }
    this.onClick.emit(notification);
    if (!this.currentUrl.includes("newsletter")) {
      this.router.navigate(["newsletter"]);
    }
  }

  getClassOf(val) {
    if (val == "Nuestros Casos") {
      return 'waves-light fr-button btn-pilot btn-communication-type-1';
    } else if (val == "Safety Alert") {
      return 'waves-light fr-button btn-pilot btn-communication-type-2';
    } else {
      return 'waves-light fr-button btn-pilot btn-communication-type-3'
    }
  }

}
