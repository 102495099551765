<div class="container-fluid p-0" style="overflow-x: scroll;">
  <mat-toolbar class="header">
    <mat-toolbar-row>
      <span class="material-icons clickable"(click)="closeModal()">chevron_left</span>
      <span class="latam_bold_italic">Limops report</span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="col-12" >
    <mat-card appearance="outlined" >
      <h1>{{reportTitle}}</h1>
      <div class="listcontainer">
        <div class="list" >
        <table class="table" aria-describedby="pilot table">
          <ng-container  *ngFor="let item of columnNames;let i = index">
            <tr style="overflow-y: scroll;">
              <th> {{columnNames[i]}}</th>
              <td> {{columnValues[i]}}</td>
            </tr>
          </ng-container>
        </table>
      </div>
    </div>
    </mat-card>
  </div>
</div>
