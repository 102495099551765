<div *ngIf="licenseListIsNotEmpty()" class="row state-separator">
  <div class="col-12">
    <b>{{state}}</b>
  </div>
</div>
<div class="row">
  <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12" *ngFor="let license of licenses">
    <mat-card appearance="outlined" class="document-card" [ngClass]="{'danger-card': state === 'Expired', 'warning-card': state === 'Next to expire', '': state === 'Active'}">
      <mat-card-title>
        <div class="row">
          <div class="col-6">
             <b class="document-title">{{license.type != null ? license.type : 'LICENSE'}}</b>
          </div>
          <div class="col-6">
            <div class="badge-card-state">
              <span *ngIf="state != 'Active'" class="badge" [ngClass]="state === 'Expired' ? 'badge-danger' : 'badge-warning'">
                {{state}}
              </span>
            </div>
          </div>
        </div>
      </mat-card-title>
      <hr>
      <mat-card-content>
        <table aria-describedby="pilot table">
          <tr>
            <td>Code</td>
            <td class="document-value">{{license.code ? license.code : 'No Info'}}</td>
          </tr>
          <tr>
            <td>Number</td>
            <td class="document-value">{{license.number ? license.number : 'No Info'}}</td>
          </tr>
          <tr>
            <td>Issuing Authority</td>
            <td class="document-value">{{license.issuingAuthority}}</td>
          </tr>
          <tr>
            <td>Issue Date</td>
            <td class="document-value">{{ (license.issueDate !== null) ? (license.issueDate | date: 'd/M/y') : "No Info" }}</td>
          </tr>
          <tr>
            <td>Expiry Date </td>
            <td class="document-value">{{ (license.expirationDate !== null) ? (license.expirationDate | date: 'd/M/y') : "No Info" }}</td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
