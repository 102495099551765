<div class="container-ack">
    <div class="modal-header-dialog">
      <h2 class="modal-title-dialog" mat-dialog-title>Was this comparison useful?</h2>
      <button mat-button (click)="closeClick();" class="button-close"><i class="material-icons"> clear </i></button>
    </div>
  
    <mat-dialog-content>
  
      <div class="" [formGroup]="ackForm">
        <textarea class="commentary col-centered p-1" placeholder="Comments here(Optional)" formControlName="comment" maxlength="150">
        </textarea>
      </div>
    </mat-dialog-content>
  
    <div class="btns-confirm-ack" mat-dialog-actions>
        <button mat-fab class="button-circle" (click)="performAck(1)"
        ><mat-icon class="check-ok">check_circle</mat-icon></button>
     &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;

     <button mat-fab class="button-circle" (click)="performAck(-1)"
        > <mat-icon class="x-circle">cancel</mat-icon></button>
    </div>

  </div>