import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalController, Platform } from '@ionic/angular';
import { AcknowledgeComponent } from './ack-confirm/ack-confirm.component';
import { LastMinuteChangeDialogComponent } from '../last-minute-change-dialog/last-minute-change-dialog.component';
import { LoadSheetACK } from 'src/app/modules/pilot/domain/dispatch/loadsheetACK.model';
import { LoadSheet } from 'src/app/modules/pilot/domain/dispatch/loadsheet.model';
import { LoadsheetLine } from 'src/app/modules/pilot/domain/dispatch/loadsheet-line.model';
import { IUser } from 'src/app/modules/pilot/domain/user/user';
import { ILastMinuteChange } from 'src/app/modules/pilot/domain/dispatch/loadsheet/last-minute-change.interface';
import { DispatchService } from 'src/app/modules/pilot/services/dispatch.service';
import { SecurityService } from 'src/app/core/services/security/security.service';
import { IndexeddbService } from 'src/app/modules/pilot/services/indexeddb.service';
import { confirmAlert, savePdf } from 'src/app/core/utils/utils';
import { PilotConstants } from 'src/app/modules/pilot/commons/pilot-constants';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/core/services/user/user.service';
import { FeedbackComparedLsComponent } from './feedback-compared-ls/feedback-compared-ls.component';
import { LoadSheetsComparedACK } from 'src/app/modules/pilot/domain/dispatch/cosmos/compare-ack-request';
import { CompareResponse } from '../../../domain/dispatch/cosmos/compare-response';

@Component({
  selector: 'message-dispatch-dialog',
  templateUrl: './message-dispatch-dialog.component.html',
  styleUrls: ['./message-dispatch-dialog.component.scss']
})
export class MessageDispatchDialogComponent implements OnInit {

  loadsheetContent: Array<LoadsheetLine>;
  @Input() loadSheet: LoadSheet;
  @Input() allowACK: boolean;
  @Input() allowLMC: boolean;
  @Input() isConnected: boolean = true;

  @Input() compareResponse: CompareResponse;
  @Input() activateCosmosCompare: boolean;
  @Input() listLoadSheet: Array<LoadSheet>;
  @Input() versionLS: number;

  user: IUser;
  
  loadsheetack: LoadSheetACK;
  public dialogRef: any;
  isSubmitBtnDisabled: boolean = false;
  isSubmitDisabledFeedback: boolean = false;
  isTestEnvironment: boolean = !environment.production;
  disabledAckLMCButton = false;
  loadSheetsComparedACK: LoadSheetsComparedACK;
  isViewed = false;
  public cosmosWaiting: string = "Loading";

  regexForACK = ['ZFW ([0-9]{5,6})', 'TOW ([0-9]{5,6})', 'LAW ([0-9]{5,6})',  'UNDLD ([-]?[0-9]+)'];

  
  constructor(
    public dialog: MatDialog,
    private _dispatchService: DispatchService,
    private platform: Platform,
    private _security: SecurityService,
    private indexeddb: IndexeddbService,
    public constants: PilotConstants,
    private modalCtrl: ModalController,
    private _user: UserService
  ) { }

  ngOnInit() {
    this.buildLoadSheet();
    this.loadsheetack = new LoadSheetACK();
    this.loadSheetsComparedACK = new LoadSheetsComparedACK();
    this.loadsheetack.flightTail = this.loadSheet.tail;
    this.loadsheetack.flightDate = this.loadSheet.flightDate;
    this.user = this._user.getUserValue;
    this.disabledAckLMCButton = this.user.roles.includes("BP_CONVERSION") && !this.isTestEnvironment;
    if(this.versionLS>=2){
      this.compareLoadsheet(this.versionLS);
    }

  }

  buildLoadSheet() {
    let rawContent = this.loadSheet?.message.split(/\r\n|\r|\n/, -1);
    rawContent = rawContent?.filter(line => line != null && line != '');
    let {lmcPax, lmcTC, lmcTM} = this.loadSheet;
    this.loadsheetContent = rawContent.map(line => this.checkLineToHighlight(line, (!lmcPax && !lmcTC && !lmcTM)));
  }

  checkLineToHighlight(line: string, notLmc: boolean): LoadsheetLine {
    let loadsheetLine = new LoadsheetLine(line);

    if (notLmc) {
      return loadsheetLine;
    }

    const regexForHighlight = ['ZFW ([0-9]{5,6})', 'TOW ([0-9]{5,6})', 'LAW ([0-9]{5,6})', 'TTL ([0-9]+)', 'TOB[ ]{1,}([0-9]+)', 'UNDLD ([-]?[0-9]+)', '([0-9]{1}[/][0][/][0-9])'];
    const regexForCrew = '([0-9]{1}[/][0][/][0-9])';
    const reg = '(([A-Z]{3} [A-Z]{3} [A-Z]{5})(    [0-9]{1}[/][0][/][0-9]))';

    let splitLine = (regex: string, splitInThreeParts: boolean) => {
      const prefix = line.match('PAX[0-9\/]+ ') ? line.match('PAX[0-9\/]+ ') : '';
      const newValue = line.match('/ ' + regex)?.[0];
      const oldValue = line.match(regex)[0];
      const middleValue = splitInThreeParts ? line.split(newValue)[1]?.split(oldValue)[0] : ' ';
      return { prefix, newValue, middleValue, oldValue };
    }


    for (let [index, regex] of regexForHighlight.entries()) {
      const highlight = new RegExp(regex).test(line);

      if (highlight && index != 6) {
        const result: any = splitLine(regex, ['ZFW', 'TOW', 'LAW'].includes(regex.substring(0, 3)));
        loadsheetLine.highlight = highlight;
        loadsheetLine.newValue = result.newValue;
        loadsheetLine.oldValue = result.oldValue;
        loadsheetLine.prefix = result.prefix;
        loadsheetLine.middleValue = result.middleValue;

        return loadsheetLine;
      } else if (highlight && index == 6 && ((this.loadSheet.lmcTC != null && this.loadSheet.lmcTC != 0) || (this.loadSheet.lmcTM != null && this.loadSheet.lmcTM != 0))) {
        const newValue = line.match('/ ' + regexForCrew)[0];
        const oldValue = line.match(reg)[3];
        const prefix = line.match(reg)[2];

        loadsheetLine.highlight = highlight;
        loadsheetLine.newValue = newValue;
        loadsheetLine.oldValue = '     ' + oldValue;
        loadsheetLine.prefix = prefix + "  ";
        loadsheetLine.middleValue = ' ';
        return loadsheetLine;
      }
    }

    return loadsheetLine;
  }

  async confirmACK() {

    let zfw = this.loadSheet.message.match(this.regexForACK[0])[0];
    let tow = this.loadSheet.message.match(this.regexForACK[1])[0];
    let law = this.loadSheet.message.match(this.regexForACK[2])[0];
    let undld = this.loadSheet.message.match(this.regexForACK[3])[0];


    const modal = await this.modalCtrl.create({
      component: AcknowledgeComponent,
      id: "modal-ack-loadsheet",
      componentProps: { operator: this.loadSheet?.operator, zfw : zfw, tow : tow, law: law, undld: undld, user: (this.user.userName + " "+ this.user.lastName)}
    });
    modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'action') {
      this.loadsheetack.comment = data.comment;
      this.loadsheetack.ack = data.ack;
      this.loadsheetack.id = this.loadSheet.id;
      this.loadsheetack.version = this.loadSheet.version.toString();
      this.saveAck(data?.ack);
      if (this.loadsheetack.ack == -1 || this.loadsheetack.ack == 1) {
        this.isSubmitBtnDisabled = true;
      }
    }
  }

  saveAck(ack: number) {
    this._security.setLoading(true);
    this._dispatchService.setLoadSheetACK(this.loadsheetack)
      .pipe(finalize(() => {
        this._security.setLoading(false);
      }))
      .subscribe({
        next: (response) => {
          this.loadSheet.ack = ack;
          Swal.fire({
            icon: 'success',
            title: 'Updated',
            text: 'Your loadsheet has been updated successfully'
          });
          this.saveLoadSheetInIndexedDB();
        },
        error: error => {
          console.log("Error: " + error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There was a problem saving your acknowledge. Please try again.'
          }).then(r => {
            this.loadSheet.ack = 0;
            this.closeModal();
          });
        }
      });
  }

  lastMinuteChange() {
    this.dialogRef = this.dialog.open(LastMinuteChangeDialogComponent, {
      height: 'auto',
      width: '400px',
      maxWidth: "85vw",
      id: "modal-last-minute-change-loadsheet",
      data: {
        fleet: this.loadSheet.fleet,
        operator: this.loadSheet.operator,
        pax: this.loadSheet.lmcPax,
        tc: this.loadSheet.lmcTC,
        tm: this.loadSheet.lmcTM
      }
    });
    this.dialogRef.afterClosed().subscribe((data: ILastMinuteChange) => {
      if (!data) return;
      this.sendLastMinuteChange(data);
    });
  }

  sendLastMinuteChange(data: ILastMinuteChange) {
    data.id = this.loadSheet.id;
    if (this.isConnected) {
      this._dispatchService.lastMinuteChange(data).subscribe({
        next: (res) => {
          let data = res?.data || res;
          if (data) {
            this.loadSheet.message = data?.message;
            this.loadSheet.lmcPax = data?.lmcPax || 0;
            this.loadSheet.lmcTC = data?.lmcTC || 0;
            this.loadSheet.lmcTM = data?.lmcTM || 0;
            this.buildLoadSheet();
          }
          Swal.fire({
            icon: 'success',
            title: 'Updated',
            text: 'Your loadsheet has been updated successfully'
          });
          this.saveLoadSheetInIndexedDB();
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          if (err?.status == 400) {
            confirmAlert({
              icon: 'error',
              title: 'Error',
              text: err.error?.result,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: "OK",
            }, true); 
            return;
          }

          confirmAlert({
            icon: 'error',
            title: 'Error saving last minute change ',
            text: 'There was a problem saving your change',
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: "OK",
          }, true); 
          
        }
      });
      
      return;
    }
    
    if (this.isTestEnvironment) {
      // save lmc without connection
      this.indexeddb.addToTable('rol', data, "lastMinuteChange");
      confirmAlert({
        icon: 'success',
        title: 'Thank you',
        text: 'Your last minute change will be sent as soon as the network is available',
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: "OK",
      }, true);
    };

  }

  closeModal() {
    return this.modalCtrl.dismiss("close", 'close');
  }

  createPDF(base64Image?: string) {

    let doc = new jsPDF();
    let pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    if (this.isConnected) {
      // add image - only works with connection
      let img = new Image();
      img.src = 'assets/img/icons/icon-192x192.png';
      doc.addImage(img, 'png', pageWidth - 50, 5, 40, 40);
    }

    let LoadSheetName = this.loadSheet.operator + this.loadSheet.flightNumber + " " + this.loadSheet.departureIataCode + " " + this.loadSheet.flightDate + " V" + this.loadSheet.version;

    //Head
    doc.setTextColor('red');
    doc.setFontSize(24);
    doc.text(LoadSheetName, pageWidth / 2, 20, { align: 'center' });


    //Body
    doc.setFontSize(14);
    doc.setTextColor('black');

    let y = 50;
    let incrementY = (y: number) => y + 6;
    let incrementX = (x: number) => x + 37;

    for (let line of this.loadsheetContent) {
      doc.setTextColor('black');
      let x = (pageWidth / 4);

      if (line.highlight) {

        if (line.prefix != '') {
          doc.text(line.prefix, x, y);
          x = incrementX(x);
        }

        doc.setFont(undefined, 'bold')
        doc.text(line.oldValue, x, y);
        x = incrementX(x);

        if (line.middleValue != ' ') {
          doc.setFont(undefined, 'normal')
          doc.text(line.middleValue, x, y);
          x = incrementX(x);
        }

        doc.setFont(undefined, 'bold')
        doc.setTextColor('blue');
        doc.text(line.newValue, x, y);
      }
      else {
        doc.setFont(undefined, 'normal')
        doc.text(line.content, x, y);
      }

      y = incrementY(y);
    }

    if (base64Image) {
      const imageWidth = 50; 
      const imageHeight = 50;
      const imageX = (doc.internal.pageSize.width - imageWidth) / 2;
      const imageY = incrementY(y);
      doc.addImage(base64Image, 'PNG', imageX, imageY, imageWidth, imageHeight);
    }

    // FOOTER
    let str = "The format of this document is subject to change";
    doc.setTextColor(100);
    doc.setFontSize(10);
    doc.text(str, pageWidth / 2, pageHeight - 10, { align: 'center' });

    let name = this.loadSheet.flightNumber + "_" + this.loadSheet.departureIataCode + "_" + this.loadSheet.flightDate + "_V" + this.loadSheet.version + ".pdf";

    // signedDoc
    if (base64Image) return { doc, name };

    this.downloadPDF(doc, name);
  }

  downloadPDF(doc: jsPDF, name: string) {
    name = "loadsheet-" + name;
    if (!this.platform.is('hybrid')) {
      // save doc web
      doc.save(name);
    } else {
      // save doc app mobile iOS | android
      let blob = doc.output("blob");
      let ref = this;
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        let base64 = reader.result.toString();
        ref.savePdfMobile(base64, name);
      }
    }
  }

  savePdfMobile(base64: string, name: string) {
    savePdf(base64, name);
  }

  saveLoadSheetInIndexedDB() {
    if (this.loadSheet.ack == 1) {
      this.indexeddb.addToTable('rol', this.loadSheet, "lastLoadsheet");
    }
  }

  compareLoadsheet(version:number){ 
    let lastTwoLoadsheets = this.listLoadSheet.slice(version-2,version)
    this.cosmosWaiting="Loading";
      this._dispatchService.getCompareLoadsheetByIds( lastTwoLoadsheets[0].id, lastTwoLoadsheets[1].id ).subscribe({
        next: (cosmosResponse) => {
          this.compareResponse = cosmosResponse;
          if(cosmosResponse.message === null){
            this.compareResponse.message = "- No se pudieron obtener las diferencias. Por favor intenta mas tarde."
          }
          if(cosmosResponse.message.includes("No existen diferencias")){
            this.compareResponse.message = "No existen diferencias entre las dos últimas loadsheets"
          } else {
            this.compareResponse.message= cosmosResponse.message.replaceAll("-", "<br>").substring(4);
          }
          this.cosmosWaiting="finish";
        }, 
        error: (error) => {
          console.log("Error at getting getCompareLoadsheetByIds from cosmos: ", error);
          this.compareResponse.message = "No se pudieron obtener las diferencias. Por favor intenta nuevamente."
          this.cosmosWaiting="finish";
        }
      });
}

  comparisonIsViewed() {
    if(!this.isViewed){
      let compareAckRequest = new LoadSheetsComparedACK();
      compareAckRequest.id = this.compareResponse.id;
      compareAckRequest.pilotCommentary = "";
      compareAckRequest.usefulComparison = 0;
      this._dispatchService.setCompareACK(compareAckRequest).subscribe({
          next: (response) => {
          console.log(response);
          },
          error: error => {
            console.log("Error: " + error);
          }
        });
      this.isViewed = true;
    }
  }

  async feedbackComparedLS() {
    const modal = await this.modalCtrl.create({
      component: FeedbackComparedLsComponent,
      id: "modal-feedback-compared-ls",
      componentProps: { operator: this.loadSheet?.operator }
    });
    modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'action') {
      this.loadSheetsComparedACK.pilotCommentary = data.pilotCommentary;
      this.loadSheetsComparedACK.usefulComparison = data.usefulComparison;
      this.loadSheetsComparedACK.id = this.compareResponse.id;
      this.saveFeedbackComparedLS(this.loadSheetsComparedACK);
      if (this.loadsheetack.ack == -1 || this.loadsheetack.ack == 1) {
        this.isSubmitDisabledFeedback = true;
      }
    }
  }

  saveFeedbackComparedLS(request: LoadSheetsComparedACK) {
    this._security.setLoading(true);
    this._dispatchService.setCompareACK(request)
      .pipe(finalize(() => {
        this._security.setLoading(false);
      }))
      .subscribe({
        next: (response) => {
          console.log(response);
          },
          error: error => {
            console.log("Error: " + error);
          }
      });
  }

}