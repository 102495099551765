<div class="full-screen-modal modal-upload-documents">
    <div class="container mb-4 mt-6" [ngClass]="{'pt-5': isIOS}">
        
        <!-- Upload image -->
        <section [hidden]="!steps.uploadImage">
            <div class="header-modal">
                <a class="btn-back" (click)="closeClick()"><i class="material-icons">chevron_left</i> Upload a photo of your </a>
                <p class="type-document">{{ typeDocument }}</p>
            </div>
            <div *ngIf="!loadingImage">
                <div class="input-file-camera">
                    <ng-container *ngIf="!image">
                        <img *ngIf="typeDocument != 'dni'" src="assets/img/profile/camera.svg" alt="" srcset="">
                        <ng-container *ngIf="typeDocument == 'dni'">
                            <img class="img-dni-example" src="assets/img/profile/dni-example.jpg" alt="" srcset="">
                            <span class="text-upload-photo">Please take or upload a photo of the back of your {{ typeDocument }}</span>
                        </ng-container>

                        <div class="btn-center" >
                            <button class="pilot-outline" (click)="takePicture('PHOTOS')">
                                <mat-icon>image_search</mat-icon>
                                Select file
                            </button>
                            <button class="pilot-outline" (click)="takePicture('CAMERA')">
                                <mat-icon>camera_alt</mat-icon>
                                Open Camera & Take Photo
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="imageUrl">
                        <img id="preview-image" [src]="imageUrl | safeUrl" alt="">
                        <div class="btn-center" >
                            <button class="pilot-outline" (click)="deleteImage()">
                                <mat-icon>clear</mat-icon>
                                Delete image
                            </button>
                        </div>
                    </ng-container>
                </div>
                <div class="btn-center">
                    <button (click)="getDocumentInformation()" [disabled]="!image" class="btn-pilot btn-confirm">Continue</button>
                </div>
            </div>
            <div *ngIf="loadingImage" class="loading-image">
                <mat-spinner strokeWidth="10" [diameter]="100" style="margin: 0 auto" class="custom-size"></mat-spinner>
                <p>The image is being processed to obtain the information</p>
            </div>
        </section>

        <!-- Information -->
        <section [hidden]="!steps.information">
            <div class="header-modal" (click)="back()">
                <i class="material-icons">chevron_left</i> 
                Verify that information uploaded is correct
            </div>
            <div class="row" [formGroup]="formDocument">
                <div class="col-12">
                    <label class="input-label">
                        <span>Names</span>
                    </label>
                    <div class="input-pl" >
                        <input formControlName="name" type="text">
                        <mat-icon matSuffix class="icon-display">mode_edit</mat-icon>
                    </div>
                </div>
                <div class="col-12">
                    <label class="input-label">
                        <span>Last names</span>
                    </label>
                    <div class="input-pl" >
                        <input formControlName="lastNames" type="text">
                        <mat-icon matSuffix class="icon-display">mode_edit</mat-icon>
                    </div>
                </div>
                <div class="col-12">
                    <label class="input-label">
                        <span class="number-label">{{ typeDocument }} </span> 
                        <span>number:</span>
                    </label>
                    <div class="input-pl" >
                        <input [formControlName]="typeDocument == 'dni'? 'run': 'number' " type="text">
                        <mat-icon matSuffix class="icon-display">mode_edit</mat-icon>
                    </div>
                </div>
                <div class="col-12">
                    <label class="input-label" style="display: flex;">
                        <mat-icon (click)="showInfo(); $event.stopPropagation();" class="icon-info">info</mat-icon>
                        <span>Place of issuance of the document:</span>
                    </label>
                    <div class="input-pl">
                        <input class="input-country-of-issue" formControlName="countryOfIssue" mask="UUU||LLL" placeholder="Example: CHL" >
                        <mat-icon matSuffix class="icon-display">mode_edit</mat-icon>
                    </div>
                </div>
                <div class="col-12">
                    <label class="input-label">
                        <span>Expiration date:</span>
                    </label>
                    <div class="input-pl" [ngClass]="{'disabled-input': formDocument.get('expirationDate').disabled}">
                        <input formControlName="expirationDate" type="date">
                        <mat-icon matSuffix class="icon-display">mode_edit</mat-icon>
                    </div>
                </div>
            </div>
            <div class="btn-center" (click)="uploadInformation()">
                <button class="btn-pilot btn-confirm">Continue</button>
            </div>
        </section>
    </div>
</div>