import { Injectable, NgZone } from '@angular/core';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { Device } from '@capacitor/device';
import { DeviceService } from '../device/device.service';
import { App } from '@capacitor/app';
import { DeviceInfoRequest } from 'src/app/modules/pilot/domain/profile/device-info.model';
import { NotificationsService } from '../notifications/notifications.service';
import { updateNotificationsRq } from '../../domain/notifications/update-notifications-request';
import { environment } from 'src/environments/environment';

interface PushNotificationListener {
  loadsheet: boolean;
  ofpMessage: boolean;
  communications: boolean;
  notificationsBell: boolean;
  idLoadSheet?: number;
}

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  currentUrl = '';
  token: string = '';
  pushNotificationObs: BehaviorSubject<PushNotificationListener> = new BehaviorSubject({
    ofpMessage: false,
    loadsheet: false,
    idLoadSheet: null,
    communications: false,
    notificationsBell: false
  });
  isTestEnvironment: boolean = !environment.production;

  constructor(
    private platform: Platform, 
    private router: Router,
    private ngZone: NgZone, 
    private _device: DeviceService,
    private notificationsService: NotificationsService
  ) { 
    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.currentUrl = value.url;
      }
    });
  }

  public get getPushNotificationsValue() {
    return this.pushNotificationObs.value;
  }

  getState() {
    return this.pushNotificationObs.asObservable();
  }

  setStatePushNotifications(status: PushNotificationListener) {
    this.pushNotificationObs.next(status);
  }

  initPush() {
    if (this.platform.is('hybrid')) {
      PushNotifications.requestPermissions().then( async result => {
        if (result.receive) {
          // Register with Apple / Google to receive push via APNS/FCM
          await PushNotifications.register();
        } else {
          console.error('Error requestPermission PushNotifications');
        }
      });
      this.registerPush();
      this.listenPush();
    }
  }

  async registerPush() {
    PushNotifications.addListener('registration',
      (token: Token) => {
        if (token.value) {
          console.info('Registration token: ', token.value);
          this.token = token.value;
          this.validateSaveToken(this.token);
        } else {
          console.error('No fue posible obtener el token');
        }
      }
    );
    PushNotifications.addListener('registrationError', err => {
      console.error('Registration error: ', err.error);
    });

    let info = await Device.getInfo();

    if (info.platform == "android") {
      PushNotifications.createChannel({
        id: "fcm_default_channel",
        name: "channel",
        description: "channel android",
        sound: "sound",
        importance: 3,
        vibration: true
      });
    }

  }

  async validateSaveToken(tokenNotification: string) {
    let info = await this._device.getDeviceInfo();
    let { version, build } = await App.getInfo();
    let infoRQ: DeviceInfoRequest = { ...info, tokenNotification, isActiveSession: true, version, build };
    this._device.validateDeviceInfo(infoRQ);
  }

  listenPush() {
    PushNotifications.addListener('pushNotificationReceived',
       (notification ) => {
        console.log('Push notification received: ', notification);
        let update: updateNotificationsRq = { received: true, idNotification: notification?.data?.idNotification };
        this.updateNotificationReceivedOrOpened(update);
        let state = this.getPushNotificationsValue;
        this.setStatePushNotifications({...state, notificationsBell: true });
      }
    );

    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification) => {
        console.log('pushNotificationActionPerformed  ', notification.notification);
        let data = notification.notification?.data;
        if (data) {
          let update: updateNotificationsRq = { opened: true, idNotification: data.idNotification };
          this.updateNotificationReceivedOrOpened(update);
          this.handleNotificationAction(data);
        }
      }
    );
  }
  
  async registerNotifications ()  {
    let permStatus = await PushNotifications.checkPermissions();
    console.log(permStatus);
    
    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }
  
    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }
  
    await PushNotifications.register();
  }

  handleNotificationAction(data: any) {
    let state = this.pushNotificationObs.getValue();
    switch (data?.type) {
      case "loadsheet":
        this.ngZone.run(() => {
          state.loadsheet = true;
          state.idLoadSheet = data?.idLoadSheet;
          if (!this.currentUrl.includes("home")) {
            this.router.navigate([data?.routerLink]);
          }
        });
        break;
      case "OFPMessage":
        state.ofpMessage = true;
        let flightInformation = JSON.parse(data?.flightInformation || null);
        if (flightInformation) {
          let { airlineCodes, tail, aircraft, flightNumber, departure, arrival, flightDate } = flightInformation;
          this.ngZone.run(() => {
            data?.routerLink && this.router.navigate(["dispatch-folder"], {
              queryParams: {
                utcDate: moment(departure.scheduledDateTime.utc, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss'),
                localDate: moment(departure.scheduledDateTime.local, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss'),
                operator: airlineCodes.comercial,
                aircraftSubtype: aircraft,
                departureIataCode: departure.airportIataCode,
                arrivalIataCode: arrival.airportIataCode,
                tail,
                flightNumber,
                carrierOperating: airlineCodes.operating,
                dateOfFlight: moment(flightDate, "DD-MM-YYYY").format("YYYY-MM-DD") // add date of flight
              }
            });
          });
        }
        break;
      case "communications":
        this.ngZone.run(() => {
          state.loadsheet = true;
          if (!this.currentUrl.includes("newsletter")) {
            this.router.navigate(["newsletter"]);
          }
        });
      break;  
      default:
        break;
    }
    this.setStatePushNotifications(state);
  }

  updateNotificationReceivedOrOpened(data: updateNotificationsRq) {
    if (this.isTestEnvironment) {
      this.notificationsService.updateNotifications(data).subscribe({
        next: (resp) => {
          console.log("notification updated...", resp);
        }
      });
    }
  }

}

