<div>
    <mat-toolbar class="header freeze-view">
      <mat-toolbar-row>
        <span class="material-icons clickable"(click)="closeModal()">chevron_left</span>
        <span class="latam_bold_italic">Documents</span>
      </mat-toolbar-row>
    </mat-toolbar>
</div>
<div style=" overflow-x: scroll; overflow-y: scroll; height: 95%; background-color: white;">
  <div class="mt-6">

    <div class="document-container freeze-view ">

      <div class="row justify-content-center">
        <h2 class="col-8 document-title text-center">CREW BRIEFING</h2>
        <img src="assets/img/navbar/logo-latam-pilot.png" class="logo" type="image" alt="">
      </div>

      <div class="row document-date">
        <span >Date: {{nextFlight?.flightLeg.departure.dates.local | date : 'dd/MM/yyyy' }} (Local)</span>
        <br>
      </div>
      <div *ngIf="!hideCrew">
        <div >
          <div class="row crew-info">
            <span id="crewOnBoard">Crew on board: {{totalCrew}}</span>
          </div>
          <div class="row crew-info">
            <span id="pax">Pax: {{totalPax}}</span>
          </div>
        </div>

        <div *ngFor="let key of objectKeys(crewListByType)">
          <div class="row ">
            <span class=" section-title">{{ key == "crewOne"? "Cockpit Crew" : "Cabin Crew"}}</span>
          </div>
          <div class="row">
            <table class="table" aria-describedby="pilot table">
              <thead>
                <tr>
                  <th><b>POS</b></th>
                  <th><b>Name</b></th>
                  <th><b>BP LATAM</b></th>
                  <th *ngIf="isBrasilFlight"><b>ANAC</b></th>
                  <th *ngIf="!isBrasilFlight"><b>DOC</b></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let crew of crewListByType[key]">
                  <td>{{crew.pos == "CP" && crew.order == 1? "CMD": crew.pos}}</td>
                  <td>{{crew.name}} <span class="pax-badge" *ngIf="crew.isPax">PAX</span></td>
                  <td>{{crew.bp}}</td>
                  <td>{{crew.doc}}</td>
                </tr>
            </tbody>
            </table>
          </div>
        </div>
        
      </div>
    </div>

    <div class="freeze-view" style="padding: 2rem; padding-right: 0; padding-top: 0;">
      <div class="row">
        <span class="section-title">Flight information</span>
      </div>

      <div class="row mt-2 mr-4">
        <div class="col-12 flight-info-header text-center py-1">
          <div class="row">
            <div class="col-3">
              <span>Call sing & Fit day</span>
            </div>
            <div class="col-3">
              <span>Acft. Type</span>
            </div>
            <div class="col-3">
              <span>Acft. Regist</span>
            </div>
            <div class="col-3">
              <span>Parking Departure</span>
            </div>
          </div>
          <div class="row middle-values">
            <div class="col-3">
              <span>{{nextFlight?.flightLeg.icaoOperator}}-{{nextFlight?.flightLeg.flightNumber}}</span>
            </div>
            <div class="col-3">
              <span>{{nextFlight?.flightLeg.fleet}}</span>
            </div>
            <div class="col-3">
              <span>{{nextFlight?.flightLeg.aircraftRegistration}}</span>
            </div>
            <div class="col-3">
              <span>{{nextFlight?.flightLeg.gate ? nextFlight?.flightLeg.gate : '----'}}</span>
            </div>
          </div>
          <div class="row bottom-values">
            <div class="col-3">
              <span>{{ nextFlight?.flightLeg.departure.dates.local | date : 'dd-MM-yyyy' }}</span>
            </div>
            <div class="col-3">
              <span></span>
            </div>
            <div class="col-3">
              <span></span>
            </div>
            <div class="col-3">
              <span></span>
            </div>
          </div>
        </div>
        <div class="col-12 flight-info-body">
          <div class="row">
            <div class="col-2">Flight</div>
            <div class="col-2" style="text-align: center;" *ngFor="let flight of aircraftAndFlight">{{flight.number }} <br/>{{flight.aircraft }}</div>
          </div>
          <div class="row">
            <div class="col-2">Route</div>
            <div class="col-1" *ngFor="let route of flightInformation.route">{{route}}</div>
          </div>
          <div class="row">
            <div class="col-2">Schedule UTC</div>
            <div class="col-1" *ngFor="let scheduleUtc of flightInformation.scheduleUtc">{{scheduleUtc | date: 'HH:mm'}}</div>
          </div>
          <div class="row">
            <div class="col-2">Schedule LT</div>
            <div class="col-1" *ngFor="let scheduleLt of flightInformation.scheduleLt">{{scheduleLt | date: 'HH:mm'}}</div>
          </div>
          <div class="row">
            <div class="col-2">Dif {{nextFlight?.flightLeg.departure.iataCode}} & Dest</div>
            <div class="col-1" *ngFor="let diff of flightInformation.diff">{{diff}}</div>
          </div>
          <div class="row">
            <div class="col-2">Dif L.T & UTC</div>
            <div class="col-1" *ngFor="let diffHours of flightInformation.diffLTandUTC">{{diffHours}}</div>
          </div>
          <div class="row">
            <div class="col-2">Presentation time LT</div>
            <div class="col-1">{{flightInformation.presentationTimeLT}}</div>
            <div class="col-2">Time at gate LT</div>
            <div class="col-1">{{flightInformation.timeAtGateLT}}</div>
            <div class="col-2">Pass boarding LT</div>
            <div class="col-1">{{flightInformation.PBLt}}</div>
            <div class="col-1"></div>
            <div class="col-1"></div>
            <div class="col-1"></div>
          </div>
          <div class="row">
            <div class="col-2">Presentation time UTC</div>
            <div class="col-1">{{flightInformation.presentationTimeUTC}}</div>
            <div class="col-2">Time at gate UTC</div>
            <div class="col-1">{{flightInformation.timeAtGateUTC}}</div>
            <div class="col-2">Pass boarding UTC</div>
            <div class="col-1">{{flightInformation.PBUtc}}</div>
            <div class="col-1"></div>
            <div class="col-1"></div>
            <div class="col-1"></div>
          </div>
          <div class="row">
            <div class="col-2">Actf.Config J/Y</div>
            <div class="col-2">{{seatsByFlight.authSeats}}</div>
            <div class="col-2"></div>
            <div class="col-2"></div>
            <div class="col-2"></div>
            <div class="col-2"></div>
          </div>
          <div class="row">
            <div class="col-2">Reserv.Pass J/Y </div>
            <div class="col-2">{{seatsByFlight.bookedSeats}}</div>
            <div class="col-2"></div>
            <div class="col-2"></div>
            <div class="col-2"></div>
            <div class="col-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


