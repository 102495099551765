import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IncidentReportService } from "src/app/modules/pilot/services/incident-report.service";
import { UiService } from "src/app/modules/pilot/services/ui/ui.service";
import Swal from 'sweetalert2';

@Component({
    selector: 'incident-report',
    templateUrl: './incident-report.component.html',
    styleUrls: ['./incident-report.component.css']
})
export class IncidentReportComponent implements OnInit, OnDestroy {

    public incidentForm: FormGroup;
    public selectedFile: File = null;
    public saving: boolean = false;

    constructor(
        private _fb: FormBuilder,
        private incidentReportService: IncidentReportService,
        private _ui: UiService
    ){}

    ngOnInit(): void {
        this.incidentForm = this._fb.group({
            comment:['', Validators.required],
        },{updateOn: 'blur'});
        this._ui.listenKeyboard('modal-feedback');
    }

    ngOnDestroy(): void {
        this._ui.removeKeyboardListeners();
    }

    public onFileSelected(event: any) {
        if (event.target.files.length > 0) {
            this.selectedFile = event.target.files[0] || null;
        }
    }

    sendReport(){
        this.saving = true;
        this.incidentReportService.uploadFile(this.selectedFile, this.incidentForm.get('comment').value)
        .then(() => {
            Swal.fire({
                icon: 'success',
                title: 'Created',
                text: 'Your report has been created successfully'
            });
            document.getElementById('closeButton').click();
        })
        .catch(err => {
            console.log(err);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Ooops! There was a problem saving your feedback. Please try again'
            });
        })
        .finally(() => this.saving = false);
    }
}