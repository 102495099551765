import { Component, OnInit, ViewChild } from "@angular/core";

import { PilotConstants } from "../../commons/pilot-constants";
import { Observable } from "rxjs/internal/Observable";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { startWith, map, finalize } from "rxjs/operators";
import { FlightRQ } from "../../domain/other-flights/flight-request.model";
import { AirlineCode } from "../../domain/other-flights/airline-code.model";
import { FlightDate } from "../../domain/other-flights/flight-date.model";
import { OtherFlightsService } from '../../services/other-flights.service';
import Swal from "sweetalert2";
import * as moment from 'moment';
import { CrewInformationFlightRS } from "../../domain/other-flights/crew-information-flight-rs.model";
import { PreferencesService } from "src/app/core/services/preferences/preferences.service";
import { SecurityService } from "src/app/core/services/security/security.service";

@Component({
  selector: 'other-flights',
  templateUrl: './other-flights.component.html',
  styleUrls: [
    './other-flights.component.css',
    './../../commons/styles/custom-inputs.scss'
  ],
})
export class OtherFlightsComponent implements OnInit {

  otherFlightsData: CrewInformationFlightRS;

  @ViewChild('picker',{static: false}) datePicker;

  ownerList = this.constants.OTHER_DISPATCH_OPERATOR_LIST;
  departureAirports: Observable<any>;
  arrivalAirports: Observable<any>;
  formGroup: UntypedFormGroup;
  airportsList: string[] = [];
  isInvalidForm: boolean = false;
  loading: boolean = false;

  constructor(
    public constants: PilotConstants, 
    private otherFlightsService: OtherFlightsService,
    private _preferences: PreferencesService,
    private security: SecurityService,
  ){}

  ngOnInit() {
    this.otherFlightsData = new CrewInformationFlightRS();
    this._preferences.getItem('airportsList').then( value => {

      this.airportsList = JSON.parse(value);

      this.formGroup = new UntypedFormGroup({
        owner: new UntypedFormControl('', [Validators.required]),
        flightNumber: new UntypedFormControl('', [Validators.pattern('[0-9]{1,4}[a-z]{0,1}')]),
        departure: new UntypedFormControl(''),
        arrival: new UntypedFormControl(''),
        flightDate: new UntypedFormControl(new Date(new Date().setHours(12, 0, 0)), [Validators.required]),
      });
  
      this.loadAutoCompleteFiltersForArrival();
      this.loadAutoCompleteFiltersForDeparture();
    });
  }

  searchFlight(){
    
    this.isInvalidForm = !this.formGroup.valid;
    if(!this.formGroup.valid) return;
    
    let flightRequest = new FlightRQ();
    this.otherFlightsData = new CrewInformationFlightRS();
    this.loading = true;
    
    flightRequest.airlinecode = new AirlineCode();
    flightRequest.airlinecode.comercial = this.ownerList.find(op =>op.name ===this.formGroup.get('owner').value).value;
    flightRequest.flightDate = new FlightDate();
    flightRequest.flightDate.requestTimeStandard = 'LOCAL';
    flightRequest.flightDate.departureDateTime = moment(this.formGroup.get('flightDate').value).format('DD-MM-YYYY h:mm');
    let flightNumberValue: string = this.formGroup.get('flightNumber').value;
    flightNumberValue = flightNumberValue.replace(/^0+/, ""); // se eliminan ceros solo al principio del valor
    flightRequest.flightNumber = flightNumberValue;
    flightRequest.departureAirport = this.formGroup.get('departure').value.toUpperCase();
    flightRequest.arrivalAirport = this.formGroup.get('arrival').value.toUpperCase();

    let hasErrors: boolean = false;
    this.security.setLoading(true);
    this.otherFlightsService.searchFlights(flightRequest, false)
    .pipe(finalize(() => {
      this.loading = false;
      this.security.setLoading(false);
      setTimeout(() => {
        document.getElementById("targetCrewInfo").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }, 500);
      if(hasErrors){
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Ooops! There was a problem. Please try again.'
        });
      }
    }))
    .subscribe({
      next: resp => {
        //Error handling
        if (resp.apiError) {
          console.log(resp.apiError);
          // No flights
          if(resp.apiError.code == '404'){
            Swal.fire({
              icon: 'info',
              title: 'No flights found for this search.',
              text: 'Please try with another flight.'
            });
            return;
          }
          // Any error
          hasErrors = true;
          return;
        }

        //success

        resp.flights.forEach( (item) => {
          let crewNum = 0;
          let paxNum = 0;
          item.crewInformation.forEach( (crew) => {
            if(crew.activity.dutyDesignator == 'PS')
              paxNum += 1;
            else
              crewNum +=1;
          });
          item.crewNum = crewNum;
          item.paxNum = paxNum;
        });

        this.otherFlightsData = resp;

      }, 
      error: err => {
        console.error("searchFlights error: ", err);
        if(err?.status == 403){
          Swal.fire({
            icon: 'info',
            title: 'Flight Not Allowed',
            text: 'By federal LGPD law, JJ and M3 flights are restricted for search purposes or flight is blocked.'
          });
          return;
        } 
        hasErrors = true;
      }
    });
  }

  filterAirport = (value: string): string[] => this.airportsList.filter(option => option.toLowerCase().includes(value.toLowerCase()));

  loadAutoCompleteFiltersForDeparture() {
    this.departureAirports = this.formGroup.controls.departure.valueChanges
      .pipe(
        startWith(''),
        map(this.filterAirport)
      );
  }

  loadAutoCompleteFiltersForArrival() {
    this.arrivalAirports = this.formGroup.controls.arrival.valueChanges
      .pipe(
        startWith(''),
        map(this.filterAirport)
      );
  }

  clickPicker() {
    this.datePicker.open();
  }

  closeRequiredFieldsAlert(){
    this.isInvalidForm = false;
  }
}
