import { Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { Duty } from "src/app/modules/pilot/domain/roster/duty.model";
import * as moment from 'moment';
import { CrewInfo } from "src/app/modules/pilot/domain/dispatch/btm-report/crew-info.model";
import { BtmFlightInformation } from "src/app/modules/pilot/domain/dispatch/btm-report/btm-flight-information.model";
import { CrewBriefingService } from "src/app/modules/pilot/services/crew-briefing.service";
import { FlightId } from "src/app/modules/pilot/domain/flight-id.model";
import { CrewInformation } from "src/app/modules/pilot/domain/crew-information";
import { IndexeddbService } from "src/app/modules/pilot/services/indexeddb.service";
import { Activity } from "src/app/modules/pilot/domain/roster/activity.model";
import { FlightLeg } from "src/app/modules/pilot/domain/roster/flight-leg.model";
import { SeatsByFlightResponse } from "src/app/modules/pilot/domain/dispatch/SeatsByFlightResponse";
import { PilotConstants } from "src/app/modules/pilot/commons/pilot-constants";

@Component({
  selector: 'btm-report-view',
  templateUrl: './btm-report-view.component.html',
  styleUrls: ['./btm-report-view.component.css']
})
export class BtmReportViewComponent implements OnInit{
  
  objectKeys = Object.keys;
  totalPax: number = 0;
  totalCrew: number = 0; 
  nextFlight: Activity;
  flightInformation: BtmFlightInformation = new BtmFlightInformation();
  crewList: Array<CrewInfo>;
  crewListByType: { crewOne: Array<CrewInfo>,  crewTwo: Array<CrewInfo> } = {
    crewOne: [],
    crewTwo: []
  };
  allFlightLegs: Array<FlightLeg> = [];
  allFlightInfo: Array<string> = [];
  isBrasilFlight: boolean = false;

  aircraftAndFlight = [
    { number: '', aircraft: ''},
    { number: '', aircraft: ''},
    { number: '', aircraft: ''},
    { number: '', aircraft: ''},
    { number: '', aircraft: ''}
  ]

  @Input() anotherDispatch: boolean = false;

  @Input() nextFlightToFind: FlightId;

  @Input() hideCrew: boolean;

  @Input() seatsByFlight: SeatsByFlightResponse= new SeatsByFlightResponse();

  @Output() closeModalEvent = new EventEmitter();

  constructor(
    private crewBriefingService: CrewBriefingService,
    private indexeddbService: IndexeddbService,
    private constants: PilotConstants
  ) { }

  ngOnInit(): void {
    this.isBrasilFlight = this.constants.BRASIL_OPERATORS.includes(this.nextFlightToFind.carrierOperating);
    this.indexeddbService.getDataFromTable("rol", "briefing").then((response: Duty[]) => {
      this.fillFlightData(response);
      this.getLegs(response);
    });
    this.indexeddbService.getDataFromTable("rol", "crew").then((response: CrewInformation[]) => {
      this.fillCrew(response);
    });
  }

  private async getLegs(btmData: Array<Duty>) {
    let briefingData: Duty;
    if (btmData.length > 0) {
      briefingData = await this.setBriefingData(btmData);
      if (!briefingData) return;
      briefingData.items.forEach(item => {
        if (item.flightLeg != null) {
          if (!item.flightLeg.icaoOperator) {
            item.flightLeg.icaoOperator = item.flightLeg.operator;
          }
          this.allFlightLegs.push(item.flightLeg);
        }
      });
      for (let i = 0; i < this.allFlightLegs.length; i++) {
        this.aircraftAndFlight[i].number = this.allFlightLegs[i].icaoOperator ?
          this.allFlightLegs[i].icaoOperator + "-" + this.allFlightLegs[i].flightNumber
          : this.allFlightLegs[i].operator + "-" + this.allFlightLegs[i].flightNumber;
        this.aircraftAndFlight[i].aircraft = this.allFlightLegs[i].aircraftRegistration;
      }
    }
  }

  setBriefingData(btmData: Array<Duty>) {
    let briefingData: Duty;
    return new Promise<Duty>((resolve, reject) => {
      btmData.forEach(duty =>{
        duty.items.forEach(item=>{
          if (!item.flightLeg) return;
          if (!this.anotherDispatch){
            if (item.flightLeg.flightNumber == this.nextFlightToFind.flightNumber && item.flightLeg.departure.iataCode == this.nextFlightToFind.departureIataCode
              && item.flightLeg.departure.dates.utc == this.nextFlightToFind.flightDate){
                briefingData = duty;
            }
          } else if (
            item.flightLeg.flightNumber == this.nextFlightToFind.flightNumber && 
            item.flightLeg.departure.iataCode == this.nextFlightToFind.departureIataCode &&  
            moment(item.flightLeg.flightDate).format('DD-MM-YYYY') ==  moment(this.nextFlightToFind.flightDate).format('DD-MM-YYYY')
          ){
            briefingData = duty;
          }
        });
      });
      resolve(briefingData);
    });
  }

  closeModal() {
    this.closeModalEvent.emit();
  }

  private fillFlightData(btmData: Array<Duty>){

    let firstDuty: Duty = null;

    for(let duty of btmData) {
      const dutyToCheck: Array<Activity> = duty.items
      .filter(flight => flight.flightLeg.departure.iataCode == this.nextFlightToFind.departureIataCode && 
        flight.flightLeg.flightNumber == this.nextFlightToFind.flightNumber &&
        moment(flight.flightLeg.departure.dates.utc).format('DD-MM-YYYY') == moment(this.nextFlightToFind.flightDate).format('DD-MM-YYYY') &&
        flight.flightLeg.carrier == this.nextFlightToFind.operator);

      if(dutyToCheck.length != 0){
        firstDuty = duty;
        this.nextFlight = dutyToCheck[0];
        break;
      }
    }

    if(!firstDuty || !this.nextFlight){
      throw new Error('No duty or flight found');
    }
    this.setCrewBriefingTimes(this.nextFlight);

    const nextFlightLocalDate = moment(this.nextFlight.flightLeg.departure.dates.local);
    const nextFlightUtcDate = moment(this.nextFlight.flightLeg.departure.dates.utc);
    const nextFlightDuration = moment.duration(nextFlightLocalDate.diff(nextFlightUtcDate));
    const nextFlightHourDiff = nextFlightDuration.asHours();

    firstDuty.items.forEach( (flight, index) => {
    
        index = index *2;

      this.flightInformation.route[index] = flight.flightLeg.departure.iataCode;
      this.flightInformation.scheduleUtc[index] = flight.flightLeg.departure.dates.utc;
      this.flightInformation.scheduleLt[index] = flight.flightLeg.departure.dates.local;
      this.flightInformation.diff[index] = this.crewBriefingService.getFlightDiff(flight.flightLeg.departure.dates.local,flight.flightLeg.departure.dates.utc, nextFlightHourDiff);

      this.flightInformation.diffLTandUTC[index] = this.crewBriefingService.calculateDiff(flight.flightLeg.departure.dates.local,flight.flightLeg.departure.dates.utc);

      this.flightInformation.route[index+1] = flight.flightLeg.arrival.iataCode;
      this.flightInformation.scheduleUtc[index+1] = flight.flightLeg.arrival.dates.utc;
      this.flightInformation.scheduleLt[index+1] = flight.flightLeg.arrival.dates.local;
      this.flightInformation.diff[index+1] = this.crewBriefingService.getFlightDiff(flight.flightLeg.arrival.dates.local,flight.flightLeg.arrival.dates.utc, nextFlightHourDiff);


      this.flightInformation.diffLTandUTC[index+1] = this.crewBriefingService.calculateDiff(flight.flightLeg.arrival.dates.local,flight.flightLeg.arrival.dates.utc);
     
    });
  }

  private fillCrew(crew: Array<CrewInformation>){
    let { crewList, totalCrew, totalPax } = this.crewBriefingService.fillCrew(crew, this.isBrasilFlight);
    this.totalCrew = totalCrew;
    this.totalPax = totalPax;
    this.crewList = crewList;
    this.crewListByType.crewOne = this.crewList.filter( cL => cL.pos == "CP" || cL.pos == "FO");
    this.crewListByType.crewTwo = this.crewList.filter( cL => cL.pos != "CP" && cL.pos != "FO");
  }

  private async setCrewBriefingTimes(flight) {
    let { presentationTimeLT, timeAtGateLT, PBLt, presentationTimeUTC, timeAtGateUTC, PBUtc} = await this.crewBriefingService.setCrewBriefingTimes(flight);
    this.flightInformation.timeAtGateLT = timeAtGateLT;
    this.flightInformation.PBLt = PBLt
    this.flightInformation.presentationTimeLT = presentationTimeLT;

    this.flightInformation.timeAtGateUTC = timeAtGateUTC;
    this.flightInformation.PBUtc = PBUtc
    this.flightInformation.presentationTimeUTC = presentationTimeUTC;
  }

}
