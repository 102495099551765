import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent {
  color = 'primary';
  mode = 'determinate';
  value = 50;

  @Input() text: string = "";


}
