export class BtmFlightInformation{
    route: Array<string>;
    scheduleUtc: Array<string>;
    scheduleLt: Array<string>;
    diff: Array<string>;
    diffLTandUTC: Array<string>;
    presentationTimeLT: string;
    timeAtGateLT: string;
    PBLt: string;
    presentationTimeUTC: string;
    timeAtGateUTC: string;
    PBUtc: string;


    constructor(){
        this.route = ['', '', '', '', '', '', '', '', '',''];
        this.scheduleUtc = ['', '', '', '', '', '', '', '', '',''];
        this.scheduleLt = ['', '', '', '', '', '', '', '', '',''];
        this.diff = ['', '', '', '', '', '', '', '', '',''];
        this.diffLTandUTC = ['', '', '', '', '', '', '', '', '',''];
    }
}