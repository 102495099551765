import { Component, OnInit, HostListener } from '@angular/core';
import { SecurityService } from 'src/app/core/services/security/security.service';
import Swal from 'sweetalert2';
import { PilotConstants } from '../../commons/pilot-constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GoogleAuth, User } from '@codetrix-studio/capacitor-google-auth';
import { openBrowser } from 'src/app/core/utils/utils';
import { Device } from '@capacitor/device';
import { environment } from 'src/environments/environment';
import { App } from '@capacitor/app';
import { BarcodeScanner, StartScanOptions, LensFacing, BarcodeFormat } from '@capacitor-mlkit/barcode-scanning';
import { AlertController } from '@ionic/angular';

declare const gapi: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isPilot: boolean;
  version = "";
  isWeb: boolean = true;
  heightWindows: number;
  VERSION_WEB = this.constants.VERSION_WEB;
  isSupported = false;
  isTestEnvironment: boolean = !environment.production;
  allowApp: boolean = false;
  platform: "ios" | "android" | "web";

  public alertButtons = [
    {
      text: 'BACK',
      role: LensFacing.Back,
      handler: () => this.scanQRCode(LensFacing.Back),
    },
    {
      text: 'FRONT',
      role: LensFacing.Front,
      handler: () => this.scanQRCode(LensFacing.Front),
    },
  ];

  constructor(
    private constants: PilotConstants, 
    private securityService: SecurityService,
    public snackBar: MatSnackBar,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    this.heightWindows = document.documentElement.clientHeight;
    Device.getInfo().then(async info => {
      this.platform = info.platform;
      if (this.platform == "web") {
        this.googleInit();
        this.version =  'v.' + this.VERSION_WEB;
      } else {
        this.isWeb = false;
        App.getInfo().then(app => {
          this.version = 'v.' + app.version + `(${app.build})`;
          this.allowApp = app.id == this.constants.IDS_IOS.ID_APP_STORE_ENTERPRISE;
        });

        BarcodeScanner.isSupported().then((result) => {
          this.isSupported = result.supported;
        });       
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.heightWindows = document.documentElement.clientHeight;
  }

  public auth2: any;
  public googleInit() {
    let that = this;
    gapi?.load('auth2', () => {
      that.auth2 = gapi.auth2.init(this.constants.GOOGLE_AUTH_WEB_CONFIG);
      that.attachSignin(document.getElementById('googleBtn'));
    });
  }

  public attachSignin(element) {
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {
        if (googleUser.getBasicProfile() != undefined) {
          this.securityService.setUserLogin(googleUser.getBasicProfile(), googleUser.getAuthResponse().id_token, googleUser.getAuthResponse().access_token);
        } else {
          this.attachSignin(document.getElementById('googleBtn'));
        }
      }, (error) => {
        console.log("error ", error);
        Swal.fire({
          title: 'Error',
          text: 'Oooops! There was a problem in login. Please try again',
          icon: 'error'
        })
      });
  }

  toCreateAccount() {
    openBrowser(this.constants.HOW_CREATE_AN_ACCOUNT);
  }

  async logingGoogle() {
    const googleUser: User = await GoogleAuth.signIn();
    if (googleUser) {
      this.securityService.setUserLogin(googleUser, googleUser.authentication.idToken, null);
    }
  }

  async scanQRCode(lensFacing: LensFacing) {

    if (this.platform == "android") {
      const { available } = await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable();
      if (!available) {
        this.securityService.setLoading(true);
        await BarcodeScanner.installGoogleBarcodeScannerModule();
        this.securityService.setLoading(false);
      }
    }

    const granted = await this.requestPermissions();

    if (!granted) {
      this.presentAlertPermissionsDenied();
      return;
    }

    let startScanOptions: StartScanOptions = { lensFacing, formats: [BarcodeFormat.QrCode] };
    const { barcodes } = await BarcodeScanner.scan(startScanOptions);
    let value = barcodes[0].displayValue;
    this.securityService.getSessionUser({ jwt: value });
  }

  async requestPermissions(): Promise<boolean> {
    const { camera } = await BarcodeScanner.requestPermissions();
    return camera === 'granted' || camera === 'limited';
  }

  async presentAlertPermissionsDenied(): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Permission denied',
      message: 'Please grant camera permission to use the barcode scanner.',
      buttons: ["OK"],
      backdropDismiss: false,
    });
    await alert.present();
    alert.onDidDismiss().then(async d => {
      await BarcodeScanner.openSettings();
    });
  }

}

