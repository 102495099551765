import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-safety-indicators',
  templateUrl: './safety-indicators.component.html'
})
export class SafetyIndicatorsComponent {

  @Input("dataGraphicsIndicators") dataGraphicsIndicators = {};
  @Input("showLoading") showLoading: any;
  @Input("isHiddenAlpha") isHiddenAlpha: any;
  @Input("isHiddenStick") isHiddenStick: any;
  @Input("isConnected") isConnected: boolean;

  isTestEnvironment: boolean = !environment.production;
}
