<div *ngIf="visaListIsNotEmpty()" class="row state-separator">
  <div class="col-12">
    <b>{{state}}</b>
  </div>
</div>
<div class="row">
  <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12" *ngFor="let visa of visas">
    <mat-card appearance="outlined" class="document-card" [ngClass]="{'danger-card': state === 'Expired', 'warning-card': state === 'Next to expire', '': state === 'Active'}">
      <mat-card-title>
        <div class="row">
          <div class="col-6">
             <b class="document-title">VISA</b>
          </div>
          <div class="col-6">
            <div class="badge-card-state">
              <span *ngIf="state != 'Active'" class="badge" [ngClass]="state === 'Expired' ? 'badge-danger' : 'badge-warning'">
                {{state}}
              </span>
            </div>
          </div>
        </div>
      </mat-card-title>
      <hr>
      <mat-card-content>
        <table aria-describedby="pilot table">
          <tr>
            <td>N° Visa</td>
            <td class="document-value">{{visa?.value?.replace("null", "")}}</td>
          </tr>
          <tr>
            <td>Country</td>
            <td class="document-value">{{visa.isoCountryCode}}</td>
          </tr>
          <tr>
            <td>Visa Type</td>
            <td class="document-value">{{visa.typeSubCode}}</td>
          </tr>
          <tr>
            <td>Expiration Date</td>
            <td class="document-value">{{ (visa.expirationDate !== null) ? (visa.expirationDate | date: 'd/M/y') : "No Info" }}</td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
