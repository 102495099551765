

<div class="container mt-4 mb-4">
  <h3 class="text-center" style="color: #10004f;">Last Minute Change</h3>
  
  <div class="reset-button" >
    <button class="reset-btn" (click)="resetValues()">Reset to original</button>
  </div>

  <div [formGroup]="lastMinuteChangeForm">
    <div class="mb-3 mt-2 row">
      <span class="pax-sign text-center mt-2 col-1">PAX</span>
      <div class="col-10">
        <mat-button-toggle-group name="paxSign" aria-label="Pax Sign" style="flex-wrap: nowrap;" formControlName="sign">
          <mat-button-toggle value="-" class="w-50 px-3"
            [checked]="this.data.pax < 0">-</mat-button-toggle>
          <mat-button-toggle value=" " class="w-50 px-3"
            [checked]="this.data.pax > 0">+</mat-button-toggle>
        </mat-button-toggle-group>
        <mat-button-toggle-group name="paxNumber" aria-label="Pax Number" formControlName="pax" class="w-100">
          <mat-button-toggle value="1" [checked]="this.initialPaxData == 1">1</mat-button-toggle>
          <mat-button-toggle value="2" [checked]="this.initialPaxData == 2">2</mat-button-toggle>
          <mat-button-toggle value="3" [checked]="this.initialPaxData == 3">3</mat-button-toggle>
          <mat-button-toggle value="4" [checked]="this.initialPaxData == 4">4</mat-button-toggle>
          <mat-button-toggle value="5" [checked]="this.initialPaxData == 5">5</mat-button-toggle>
          <ng-container *ngIf="isWB">
            <mat-button-toggle value="6" [checked]="this.initialPaxData == 6">6</mat-button-toggle>
            <mat-button-toggle value="7" [checked]="this.initialPaxData == 7">7</mat-button-toggle>
            <mat-button-toggle value="8" [checked]="this.initialPaxData == 8">8</mat-button-toggle>
            <mat-button-toggle value="9" [checked]="this.initialPaxData == 9">9</mat-button-toggle>
            <mat-button-toggle value="10" [checked]="this.initialPaxData == 10">10</mat-button-toggle>
          </ng-container>
        </mat-button-toggle-group>
      </div>
    </div>
  
    
    <ng-container *ngIf="is4C">
        <div>
          <hr class="middle-line">
        </div>
        <div class="mb-3 row">
          <span class="pax-sign mt-3 col-1">CREW</span>
        </div>
        <div >
        
          <div class="mb-3 row">
            <span class="pax-sign mt-2 col-1">TM</span>
            <div class="col-10">
              <mat-button-toggle-group name="tmSign" aria-label="TM Sign" style="flex-wrap: nowrap;" formControlName="tmsign">
                <mat-button-toggle value="-" class="w-50 px-3"
                  [checked]="this.data.tm < 0">-</mat-button-toggle>
                <mat-button-toggle value=" " class="w-50 px-3"
                  [checked]="this.data.tm > 0">+</mat-button-toggle>
              </mat-button-toggle-group>
              <mat-button-toggle-group name="tmNumber" aria-label="TM Number" formControlName="tm" class="w-100">
                <mat-button-toggle value="1" [checked]="this.initialTMData == 1">1</mat-button-toggle>
                <mat-button-toggle value="2" [checked]="this.initialTMData == 2">2</mat-button-toggle>
                <mat-button-toggle value="3" [checked]="this.initialTMData == 3">3</mat-button-toggle>
                <mat-button-toggle value="4" [checked]="this.initialTMData == 4">4</mat-button-toggle>
                <mat-button-toggle value="5" [checked]="this.initialTMData == 5">5</mat-button-toggle>
                <ng-container *ngIf="isWB">
                  <mat-button-toggle value="6" [checked]="this.initialTMData == 6">6</mat-button-toggle>
                  <mat-button-toggle value="7" [checked]="this.initialTMData == 7">7</mat-button-toggle>
                  <mat-button-toggle value="8" [checked]="this.initialTMData == 8">8</mat-button-toggle>
                  <mat-button-toggle value="9" [checked]="this.initialTMData == 9">9</mat-button-toggle>
                  <mat-button-toggle value="10" [checked]="this.initialTMData == 10">10</mat-button-toggle>
                </ng-container>
              </mat-button-toggle-group>
            </div>
          </div>
        </div>
        
        <div>
        
          <div class="mb-3 row">
            <span class="pax-sign mt-2 col-1">TC</span>
            <div class="col-10">
              <mat-button-toggle-group name="tcSign" aria-label="Crew TC Sign" style="flex-wrap: nowrap;"
                formControlName="tcsign">
                <mat-button-toggle value="-" class="w-50 px-3"
                  [checked]="this.data.tc < 0">-</mat-button-toggle>
                <mat-button-toggle value=" " class="w-50 px-3"
                  [checked]="this.data.tc > 0">+</mat-button-toggle>
              </mat-button-toggle-group>
              <mat-button-toggle-group name="tcNumber" aria-label="TC Number" formControlName="tc" class="w-100">
                <mat-button-toggle value="1" [checked]="this.initialTCData == 1">1</mat-button-toggle>
                <mat-button-toggle value="2" [checked]="this.initialTCData == 2">2</mat-button-toggle>
                <mat-button-toggle value="3" [checked]="this.initialTCData == 3">3</mat-button-toggle>
                <mat-button-toggle value="4" [checked]="this.initialTCData == 4">4</mat-button-toggle>
                <mat-button-toggle value="5" [checked]="this.data.tc == 5">5</mat-button-toggle>
                <ng-container *ngIf="isWB">
                  <mat-button-toggle value="6" [checked]="this.initialTCData == 6">6</mat-button-toggle>
                  <mat-button-toggle value="7" [checked]="this.initialTCData == 7">7</mat-button-toggle>
                  <mat-button-toggle value="8" [checked]="this.initialTCData == 8">8</mat-button-toggle>
                  <mat-button-toggle value="9" [checked]="this.initialTCData == 9">9</mat-button-toggle>
                  <mat-button-toggle value="10" [checked]="this.initialTCData == 10">10</mat-button-toggle>
                </ng-container>
              </mat-button-toggle-group>
            </div>
          </div>
        </div>
    </ng-container>
  
    <div class="mt-3 mx-4 d-flex justify-content-center">
      <button mat-stroked-button color="primary" class="w-50 mr-3" style="color: #1b0088;border-color: #1b0088;"
        (click)="cancel()">Cancel</button>
      <button mat-raised-button color="primary" class="w-50" 
        (click)="save()">Save</button>
    </div>
  </div>
</div>




<div class="overlay" *ngIf="this.popup">
  <div class="popup">
    <h2>ERROR</h2>
    <a class="close" (click)="this.popup = false">&times;</a>
    <div class="content">
      {{this.messageAlert}}
    </div>
    <br>
    <div class="row">
      <button mat-raised-button color="primary" class="close-btn" (click)="this.popup = false">OK</button>
    </div>
  </div>
</div>