<mat-expansion-panel *ngIf="type == 'primary'" class="dispatch-card mb-3" [expanded] ="title == 'Loadsheet'">
  <mat-expansion-panel-header [collapsedHeight]="'4rem'" [expandedHeight]="'4rem'">
    <mat-panel-title>
      <img src="/assets/img/rol/flight_cycle.svg" alt="">
      <span class="dispatch-section-title truncate-section-text mt-1 ml-2">{{title}}</span>
      <span *ngIf="newSections.includes(title)" class="new-badge">New</span>
    </mat-panel-title>
    <button mat-button *ngIf="sectionsThatNeedRefresh.includes(title)" style="margin-right: 20px; color:#1B0088;"  (click)=$event.stopImmediatePropagation() (click)="emitRefreshEvent()">
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-expansion-panel-header>
  <ng-content select="[primarySectionBody]"></ng-content>
  <hr>
</mat-expansion-panel>

<mat-expansion-panel *ngIf="type == 'secondary'" class="dispatch-card subcategory mb-3" hideToggle>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <img src="/assets/img/dispatch/report-icon.svg" alt="">
      <span class="dispatch-section-title truncate-section-text ml-2">{{title}}</span>
    </mat-panel-title>
    <mat-icon class="subcategory-option mt-1">chevron_right</mat-icon>
  </mat-expansion-panel-header>
  <ng-content select="[secondarySectionBody]"></ng-content>
 </mat-expansion-panel>

<mat-expansion-panel *ngIf="type == 'secondary with redirection'" class="dispatch-card subcategory mb-3" hideToggle>
  <mat-expansion-panel-header #panelHeaderSR (click)="panelHeaderSR._toggle();navigate();">
    <mat-panel-title>
      <img src="/assets/img/dispatch/report-icon.svg" alt="">
      <mat-checkbox *ngIf="documentState == 'success'" (click)="emitSelectionEvent()"></mat-checkbox>
      <span class="dispatch-section-title truncate-section-text ml-2 truncate-section-text">{{title}}</span>
      <mat-icon *ngIf="documentState == 'error'" class="clickable ml-2 dark-blue-icon alert-icon" [matTooltip]="'There was an error at getting your document'" matTooltipPosition="above">error</mat-icon>
      <mat-progress-spinner class="mt-1 ml-1" *ngIf="documentState == 'waiting'" color="accent" mode="indeterminate" value="50" [diameter]="25" [matTooltip]="'Your document is being preparing'" matTooltipPosition="above"></mat-progress-spinner>
    </mat-panel-title>
    <mat-icon class="subcategory-option mt-1 clickable">chevron_right</mat-icon>
  </mat-expansion-panel-header>
 </mat-expansion-panel>

<mat-expansion-panel *ngIf="type == 'secondary with modal'" class="dispatch-card subcategory mb-3" (opened)="emitOpenModalEvent()" hideToggle>
  <mat-expansion-panel-header #panelHeaderSM (click)="panelHeaderSM._toggle();" [collapsedHeight]="'4rem'">
    <mat-panel-title>
      <div class="position-relative">
        <img src="/assets/img/dispatch/report-icon.svg" alt="">
        <mat-icon *ngIf="documentState == 'error'" class="clickable dark-blue-icon alert-icon" [matTooltip]="'There was an error at getting your document'" matTooltipPosition="above">error</mat-icon>
        <mat-icon *ngIf="documentState == 'not-available'" class="clickable dark-blue-icon alert-icon" [matTooltip]="'It is not available yet or it does not exist'" matTooltipPosition="above">info</mat-icon>
      </div>

      <mat-checkbox *ngIf="documentState == 'success' && !title.includes('Version')" (click)="emitSelectionEvent()"></mat-checkbox>

      <div class="text-title w-100">
        <span *ngIf="title != 'Version'" class="dispatch-section-title truncate-section-text ml-2">{{title}}</span>
        <span *ngIf="alertMessage" class="alert-message mt-3 ml-2">{{alertMessage}}</span>
      </div>
      <mat-progress-spinner class="mt-1 ml-1" *ngIf="documentState == 'waiting'" color="accent" mode="indeterminate" value="50" [diameter]="25" [matTooltip]="'Your document is being preparing'" matTooltipPosition="above"></mat-progress-spinner>

      <ng-container *ngIf="title.includes('Version') && documentState == 'success'">
        <span class="lmc-pax-badge" *ngIf="loadsheet.lmcPax">LMC</span>
        <mat-icon class="waiting" *ngIf="loadsheet.ack == 0" [matTooltip]="'Waiting for your reply'" matTooltipPosition="above">watch_later</mat-icon>
        <mat-icon class="check-ok" *ngIf="loadsheet.ack == 1" [matTooltip]="'This loadsheet was accepted'" matTooltipPosition="above">check_circle</mat-icon>
        <mat-icon class="x-circle" *ngIf="loadsheet.ack == -1" [matTooltip]="'This loadsheet was refused'" matTooltipPosition="above">cancel</mat-icon>
      </ng-container>
    </mat-panel-title>
    <mat-icon *ngIf="documentState == 'success'" class="subcategory-option mt-1">chevron_right</mat-icon>
  </mat-expansion-panel-header>
</mat-expansion-panel>

<mat-expansion-panel *ngIf="type == 'cosmos'" class="dispatch-card mb-2" [expanded] ="title == 'Cosmos'">
  <mat-expansion-panel-header [collapsedHeight]="'4rem'" [expandedHeight]="'4rem'">
    <mat-panel-title>
      <img src="/assets/img/rol/gpt_logo.svg" alt="">
      <span class="dispatch-section-title truncate-section-text mt-1 ml-2">{{title}}</span>
      <span *ngIf="newSections.includes(title)" class="new-badge">New</span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-content select="[CosmosBody]"></ng-content>
</mat-expansion-panel>
