<div>
  <mat-toolbar class="header">
    <mat-toolbar-row>
      <span class="latam_bold_italic">Flight Report Coding Guide</span>
      <span class="material-icons clickable header-button-close" (click)="closeModal()">close</span>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<br>
<div class="body ">

  <div *ngFor="let line of reasonList">

    <div>
      <div class="reason">
        <span class="reason-tittle">{{line.name}}: </span>
        <span class="reason-description">{{line.reasonMeaning}}</span>
      </div>

      <div *ngFor="let sub of line.subReason">
        <div class="sub-reason">

          <span class="sub-reason-tittle">- {{sub.name}}: </span>
          <span class="sub-reason-description">{{sub.subReasonMeaning}}</span>

        </div>
      </div>
    </div>
  </div>
</div>

<br>