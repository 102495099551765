import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PilotConstants } from '../commons/pilot-constants';
import { BTMReportData } from '../domain/dispatch/btm-report/btm-report-data.model';
import { Duty } from '../domain/roster/duty.model';

@Injectable({
  providedIn: 'root'
})
export class BriefingTmService {

  constructor(private httpClient: HttpClient, private constants: PilotConstants) {}

  getBriefingTM(from: string, to: string): Observable<Array<Duty>> {   
    let params = new HttpParams()
      .set('startDate', from)
      .set('endDate', to);
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.BRIEFING_TM}`;
    return this.httpClient.get<Array<Duty>>(URL, { params });
  }

  getBriefingByBp(from: string, to: string): Observable<BTMReportData> {
    let params = new HttpParams()
      .set('startDate', from)
      .set('endDate', to);
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.BRIEFING_TM}${this.constants.BRIEFING_TM_BY_BP}`;
    return this.httpClient.get<BTMReportData>(URL, { params });
  }

  getBriefingByFlight(request: any): Observable<BTMReportData> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.BRIEFING_TM}${this.constants.BRIEFING_TM_BY_FLIGHT}`;
    return this.httpClient.post<BTMReportData>(URL, request);
  }
}
