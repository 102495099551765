<div class="message">
  <app-alerts *ngIf="flightLogSuccess" [text]="'Your flighlog has been sent successfully'" [class]="'w-100'"
    [type]="'success'">
  </app-alerts>
</div>

<div *ngIf="!loadingFlights">
  <ng-container>
    <mat-accordion *ngFor="let key of objectKeys(flightsByDays)">
      <mat-expansion-panel class="duty-panel mt-3 mx-1">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <img src="/assets/img/rol/flight_cycle.svg" alt="">
            <div class="title-by-day">
              <b class="duty-title ml-1">{{ key }} </b>
              <ng-container *ngIf="flightsByDays[key].complete">
                <img class="ok-fl" src="/assets/img/flight-log/check-fl.svg" alt="" srcset="">
              </ng-container>
              <ng-container *ngIf="!flightsByDays[key].complete">
                <div class="pending-fl">
                  PENDING
                </div>
              </ng-container>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let flight of flightsByDays[key].flights">
          <div class="flight-row" >
            <mat-checkbox class="flight-checkbox"
              [(ngModel)]="flight.selected" *ngIf="!flight.flightCreated">
            </mat-checkbox>
            <roster-fcard-sm (click)="flight.flightCreated? null: selectFlight(flight)" [flight]="flight" class="flight-card"></roster-fcard-sm>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
  <div class="d-flex justify-content-center w-100 mb-4 mt-3" *ngIf="objectKeys(flightsByDays).length > 0 && flightlogsToSend < 2" >
    <button matButton class="btn-flat btn-accept" (click)="redirectToCreation()">Create Flight Log
    </button>
  </div>
</div>

<div class="no-data-yet" *ngIf="objectKeys(flightsByDays).length == 0 && !loadingFlights">
  <app-alerts
    [text]="'No data yet. If this is a mistake, please inform us to take your case'" [class]="'w-100'"
    [type]="'warning'">
  </app-alerts>
</div>

<div class="loading-spinner" *ngIf="loadingFlights">
  <mat-spinner strokeWidth="10" [diameter]="100" style="margin: 0 auto" class="custom-size"></mat-spinner>
</div>
