import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import { IUser } from 'src/app/modules/pilot/domain/user/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public userObs: BehaviorSubject<IUser> = new BehaviorSubject(null);
  public user: IUser;

  constructor() {
    Preferences.get({ key: 'user' }).then((storage) => {
      if (storage.value) {
        this.updateLocalUser(JSON.parse(storage.value));
      }
    }).catch((err) => console.log(err));
  }

  public get getUserValue() {
    return this.userObs.value;
  }

  getUser() {
    return this.userObs.asObservable();
  }

  setUser(value: IUser) {
    this.user = value;
    this.userObs.next(value);
  }

  updateLocalUser(user: IUser, storage = true, obs = true) {
    if (obs) {
      this.setUser(user);
    }
    if (user) {
      if (storage) {
        try {
          Preferences.set({ key: 'user', value: JSON.stringify(user) }).then();
        }
        catch (err) {
          console.log('Error al manipular el Session Storage, funcion: updateLocalUser\n', err);
        }
      }
    } else {
      Preferences.remove({ key: 'user' }).then();
    }
  }

  get isLogged() {
    return !!this.user?.idToken;
  }
}