import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { confirmAlert } from 'src/app/core/utils/utils';
import { INotifications } from 'src/app/modules/pilot/domain/notifications/notifications';
import { NotificationsService } from 'src/app/modules/pilot/services/notifications/notifications.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnChanges {

  @Input() typeNotification = "";
  @Input() notifications: INotifications[] = [];
  @Output() reDraw: EventEmitter<any> = new EventEmitter();
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  numberNotifications = 0;
  constructor(private notificationsService: NotificationsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.notifications = this.notifications.filter(n => n.typeNotification == this.typeNotification);
  }

  async marlAllAsRead() {
    let confirm = await confirmAlert({
      title: 'Are you sure you want to mark all messages as read?',
      text: 'If you want to mark all messages as read select save. This action cannot be reversed.',
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel'
    });
    if (confirm) {
      this.notifications.forEach(n => this.updateNotifications(n, false));
    }
  }

  async updateNotifications(notification: INotifications, emit: boolean) {
    if (!notification.view) {
      notification.view = true;
      this.notificationsService.updateNotifications({ view: true, idNotificationByBp: notification.idNotificationByBp}).subscribe({
        next: (resp) => {
          this.reDraw.emit();
        }
      });
    }
    emit && this.onClick.emit(notification);
  }

}
