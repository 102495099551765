import { Component, OnInit, Input, HostListener, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'roster-actcard',
  templateUrl: './roster-actcard.component.html',
  styleUrls: ['./roster-actcard.component.css']
})
export class RosterActcard implements OnInit, OnChanges {

  @Input('activity') activity: any;
  innerWidth: any;

  @Input('isFromBrasil') isFromBrasil: any;
  @Input('checked') checked: any;

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
}
