import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { DataEnvironment } from './app/core/utils/data-env';

if (environment.production) {
  enableProdMode();
}

const buildApp = () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
} 

const dataEnv = new DataEnvironment();

Promise.all([dataEnv.getEnvironment()])
  .then((promises: any) => {
    buildApp();
  })
  .catch((err) => {
    console.error(err);
  });