import { Component, Input } from "@angular/core";
import { License } from "../../../domain/profile/license.model";

@Component({
  selector: 'license-card-list',
  templateUrl: './license-card-list.component.html',
  styleUrls: ['../profile.component.css']
})
export class LicenseCardListComponent {

  @Input()
  licenses: Array<License>;

  @Input()
  state: string;

  licenseListIsNotEmpty(): boolean{
    return this.licenses.length != 0;
  }
}
