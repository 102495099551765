<div class="container-fluid p-0" [@enterAnimation]>
  <mat-toolbar class="header">
    <mat-toolbar-row>
      <span class="material-icons clickable"(click)="closeModal()">chevron_left</span>
      <span class="latam_bold_italic">Route report</span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="row h-100">
    <div class="col-12">
      <mat-card appearance="outlined" class="table-container">
        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" matSort aria-describedby="pilot table">

            <!-- Hour Column -->
            <ng-container matColumnDef="hour">
                <th mat-header-cell *matHeaderCellDef class="column-title latam_bold_italic regular-column-size"> Hour </th>
                <td mat-cell *matCellDef="let row" class="td-text regular-column-size"> {{row.hour}} </td>
            </ng-container>

            <!-- Flight Column -->
            <ng-container matColumnDef="flight">
                <th mat-header-cell *matHeaderCellDef class="column-title latam_bold_italic regular-column-size"> Flight</th>
                <td mat-cell *matCellDef="let row" class="td-text regular-column-size"> {{row.flight}}</td>
            </ng-container>

            <!-- Via Column -->
            <ng-container matColumnDef="via">
                <th mat-header-cell *matHeaderCellDef class="column-title latam_bold_italic regular-column-size"> Via</th>
                <td mat-cell *matCellDef="let row" class="td-text regular-column-size"> {{row.via}} </td>
            </ng-container>

            <!-- Flight Level Column -->
            <ng-container matColumnDef="flightLevel">
              <th mat-header-cell *matHeaderCellDef class="column-title latam_bold_italic regular-column-size"> Flight Level</th>
              <td mat-cell *matCellDef="let row" class="td-text regular-column-size"> {{row.fl}} </td>
            </ng-container>

            <!-- Wind Column -->
            <ng-container matColumnDef="wind">
              <th mat-header-cell *matHeaderCellDef class="column-title latam_bold_italic regular-column-size"> Wind</th>
              <td mat-cell *matCellDef="let row" class="td-text regular-column-size"> {{row.wind}} </td>
            </ng-container>

            <!-- Temperature Column -->
            <ng-container matColumnDef="temperature">
              <th mat-header-cell *matHeaderCellDef class="column-title latam_bold_italic regular-column-size"> Temp</th>
              <td mat-cell *matCellDef="let row" class="td-text regular-column-size"> {{row.temp}} </td>
            </ng-container>

            <!-- Turbulence Column -->
            <ng-container matColumnDef="turbulence">
              <th mat-header-cell *matHeaderCellDef class="column-title latam_bold_italic regular-column-size"> Turb</th>
              <td mat-cell *matCellDef="let row" class="td-text regular-column-size"> {{row.turb}} </td>
            </ng-container>

            <!-- Ice Column -->
            <ng-container matColumnDef="ice">
              <th mat-header-cell *matHeaderCellDef class="column-title latam_bold_italic regular-column-size"> Ice</th>
              <td mat-cell *matCellDef="let row" class="td-text regular-column-size"> {{row.ice}} </td>
            </ng-container>

            <!-- Subsidence Column -->
            <ng-container matColumnDef="subsidence">
              <th mat-header-cell *matHeaderCellDef class="column-title latam_bold_italic regular-column-size"> Subsidence</th>
              <td mat-cell *matCellDef="let row" class="td-text regular-column-size"> {{row.subsidence}} </td>
            </ng-container>

            <!-- Remarks Column -->
            <ng-container matColumnDef="remarks">
              <th mat-header-cell *matHeaderCellDef class="column-title latam_bold_italic regular-column-size"> Remarks</th>
              <td mat-cell *matCellDef="let row" class="td-text truncate-cell" [matTooltip]="row.remarks" matTooltipPosition="above"> {{row.remarks}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
        </div>
      </mat-card>
    </div>
  </div>
</div>
