<h3 mat-dialog-title class="title-settings">{{title}}</h3>
<mat-dialog-content>
    <div class="modal-body">
        <form [formGroup]="profileFormGroup" (ngSubmit)="setProfile()">
            <div class="row">
                <div class="col-sm-6 col-6">
                    <mat-form-field class="full-width">
                        <mat-select class="mat-select-fl" placeholder="Operator" formControlName="operator" required>
                            <mat-option *ngFor="let owner of ownerList" [value]="owner.name">{{ owner.name
                                }}</mat-option>
                        </mat-select>
                        <mat-error>
                            Operator is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-6 col-6">
                    <mat-form-field class="full-width">
                        <mat-select class="mat-select-fl" placeholder="Fleet" formControlName="fleet" required>
                            <mat-option *ngFor="let fleet of fleetList" [value]="fleet.name">{{ fleet.name
                                }}</mat-option>
                        </mat-select>
                        <mat-error>
                            Fleet is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>

            <div class="row">
                <div class="pt-3 col-sm-6 col-6" *ngIf="title == 'Settings'">
                    <ion-button *ngIf="title == 'Settings'" (click)="closeClick(false)" expand="block" fill="outline">
                        Cancel
                    </ion-button>
                </div>
                <div class="pt-3 col-sm-6 col-6 m-auto">
                    <ion-button type="submit" expand="block" [disabled]="loadData">
                        Save
                    </ion-button>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>