import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import { IUser } from 'src/app/modules/pilot/domain/user/user';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard  {
  constructor(public router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return Preferences.get({ key: 'user' }).then((storage) => {
        let user: IUser = JSON.parse(storage.value);
        
        if (state.url.includes("login") && user?.idToken) {
          this.router.navigate(['/home']);
          return false;
        }
        return true;
      }).catch((err) => {
        return false;
      });
  }

}
