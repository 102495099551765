<div class="container">
    <div class="row">
        <div class="col-md-6 col-lg-4">
            <app-top-ua [showLoading]="showLoading" [topUa]="dataGraphicsIndicators['topUa']"></app-top-ua>
        </div>
        <div class="col-md-6 col-lg-4">
            <app-safety-graphic [title]="'Unstabilized Approach'" [showLoading]="showLoading" [data]="dataGraphicsIndicators['ua']"></app-safety-graphic>
        </div>
        <div class="col-md-6 col-lg-4">
            <app-ranking-airports [showLoading]="showLoading" [rankingOfAirports]="dataGraphicsIndicators['rankingOfAirports']"></app-ranking-airports>
        </div>
        <div class="col-md-6 col-lg-4">
            <app-safety-graphic [title]="'Short Landing'" [showLoading]="showLoading" [data]="dataGraphicsIndicators['shortLanding']"></app-safety-graphic>
        </div>
        <div class="col-md-6 col-lg-4">
            <app-safety-graphic [title]="'Hard Landing'" [showLoading]="showLoading" [data]="dataGraphicsIndicators['hardGraphic']"></app-safety-graphic>
        </div>
        <div class="col-md-6 col-lg-4">
            <app-safety-graphic [title]="'Deep Landing'" [showLoading]="showLoading" [data]="dataGraphicsIndicators['landingGraphic']"></app-safety-graphic>
        </div>
        <div class="col-md-6 col-lg-4">
            <app-safety-graphic [title]="'Abnormal Go Around'" [showLoading]="showLoading" [data]="dataGraphicsIndicators['abnormalGoAround']"></app-safety-graphic>
        </div>
        <div class="col-md-6 col-lg-4">
            <app-safety-graphic [title]="'TCAS RA'" [showLoading]="showLoading" [data]="dataGraphicsIndicators['tcas']"></app-safety-graphic>
        </div>
        <div class="col-md-6 col-lg-4">
            <app-safety-graphic [title]="'Roll Exceedance In flight'" [showLoading]="showLoading" [data]="dataGraphicsIndicators['rollExFlight']"></app-safety-graphic>
        </div>
        <div class="col-md-6 col-lg-4">
            <app-safety-graphic [title]="'Rejected Take Off'" [showLoading]="showLoading" [data]="dataGraphicsIndicators['rejTakeOff']"></app-safety-graphic>
        </div>
        <div class="col-md-6 col-lg-4" *ngIf="isHiddenAlpha">
            <app-safety-graphic [title]="'Alpha Floor'" [showLoading]="showLoading" [data]="dataGraphicsIndicators['alphaFloor']"></app-safety-graphic>
        </div>
        <div class="col-md-6 col-lg-4" *ngIf="isHiddenStick">
            <app-safety-graphic [title]="'Stick Shaker'" [showLoading]="showLoading" [data]="dataGraphicsIndicators['stickShaker']"></app-safety-graphic>
        </div>
        <div class="col-md-6 col-lg-4">
            <app-safety-graphic [title]="'Stall Warning'" [showLoading]="showLoading" [data]="dataGraphicsIndicators['stallWarning']"></app-safety-graphic>
        </div>
        <div class="col-md-6 col-lg-4">
                <app-safety-graphic [title]="'No Idle Reverse'" [showLoading]="showLoading" [data]="dataGraphicsIndicators['noIdleReverse']"></app-safety-graphic>
            </div>
        <div class="col-md-6 col-lg-4">
            <app-safety-graphic [title]="'Speed High Below FL100'" [showLoading]="showLoading" [data]="dataGraphicsIndicators['spdHighMinor1000']"></app-safety-graphic>
        </div>
        <div class="col-md-6 col-lg-4">
            <app-safety-graphic [title]="'Spoiler Not Armed'" [showLoading]="showLoading" [data]="dataGraphicsIndicators['spoilerNotArmed']"></app-safety-graphic>
        </div>
        <div class="col-md-6 col-lg-4">
            <app-safety-graphic [title]="'Speed High Taxi Out (Only info)'" [showLoading]="showLoading" [data]="dataGraphicsIndicators['spdHighTaxiOut']"></app-safety-graphic>
        </div>
        <div class="col-md-6 col-lg-4">
            <app-safety-graphic [title]="'Speed High Taxi In (Only info)'" [showLoading]="showLoading" [data]="dataGraphicsIndicators['spdHighTaxiIn']"></app-safety-graphic>
        </div>
        <div class="col-md-6 col-lg-4" *ngIf="isTestEnvironment">
            <app-safety-graphic [title]="'Stab Take Off'" [showLoading]="showLoading" [data]="dataGraphicsIndicators['stabTakeOff']"></app-safety-graphic>
        </div>
        <div class="col-md-12 col-lg-8">
            <app-gpws [showLoading]="showLoading" [gpwsMode1]="dataGraphicsIndicators['gpwsMode1']"
                [gpwsMode2]="dataGraphicsIndicators['gpwsMode2']" [gpwsMode3]="dataGraphicsIndicators['gpwsMode3']"
                [gpwsMode4]="dataGraphicsIndicators['gpwsMode4']" [gpwsMode5]="dataGraphicsIndicators['gpwsMode5']"
                [gpwsTadCaution]="dataGraphicsIndicators['gpwsTadCaution']"
                [gpwsTadWarning]="dataGraphicsIndicators['gpwsTadWarning']"
                [gpwsTCF]="dataGraphicsIndicators['gpwsTCF']"></app-gpws>
        </div>
    </div>
</div>