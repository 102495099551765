import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PilotConstants } from "../commons/pilot-constants";
import { environment } from "src/environments/environment";
import { firstValueFrom } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class IncidentReportService {

    constructor(
        private httpClient: HttpClient,
        private constants: PilotConstants
    ) { }

    async uploadFile(file: File,  comment: string){
        const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.INCIDENT_REPORT_BASE_PATH}`;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('comment', comment);
        return await firstValueFrom(this.httpClient.post<void>(URL, formData));
    }
}