<mat-card appearance="outlined" class="dashboard rounded p-2 mt-2">
  <div class="card-title-roster mb-2 mb-sm-0">
    CREW BRIEFING TIMES
    <mat-icon (click)="showInfo(); $event.stopPropagation();" class="icon-info">info</mat-icon>
  </div>
  <mat-card-content *ngIf="loadingCrewBriefing">
    <mat-progress-spinner style="margin:0 auto;" [diameter]="'40'" [color]="'accent'" [mode]="'indeterminate'" [value]="50">
    </mat-progress-spinner>
  </mat-card-content>
  <mat-card-content class="h-100 m-0" *ngIf="!loadingCrewBriefing">
    <div class="row time-crew-briefing">
      <div class="col-3 px-0" *ngIf="!isFromBrasil">
        <label>Dispatch Brf</label>
        <span>{{dataCrewBriefing?.dispatch}}</span>
      </div>
      <div class="col-3 px-0">
        <label>Brf. FC/CC</label>
        <span>{{dataCrewBriefing?.brf}}</span>
      </div>
      <div class="col-3 px-0">
        <label>Time at Gate</label>
        <span>{{dataCrewBriefing?.timeAtGate}}</span>
      </div>
      <div class="col-3 px-0">
        <label>Pass Board</label>
        <span>{{dataCrewBriefing?.pBoard}}</span>
      </div>
    </div>
  </mat-card-content>
</mat-card> <br>
