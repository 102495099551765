export class CrewInfo{
    pos: string;
    name: string;
    bp: string;
    doc: string;
    mail: string;
    isPax: boolean;
    order: number;

    constructor(pos: string, name: string, bp: string, doc: string, mail: string, isPax: boolean = false, order: number = null){
        this.pos = pos;
        this.name = name;
        this.bp = bp;
        this.doc = doc;
        this.mail = mail;
        this.isPax = isPax;
        this.order = order;
    }
}