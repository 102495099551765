<mat-dialog-content>
  <div class="containers mt-3 mb-3">
      <div class="modal-body">
        <div class="grid-feedback">
          <div class="feedback">
            <label class="title-settings"> {{title}} </label>
          </div>
          <div class="cancel-button">
            <button type="button" class="close" (click)="closeClick()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="text-rate">
            <p> Rate your recent experience </p>
          </div>
          <div class="stars">
            <app-stars-rating [initValue]="starRating" (changeValue)="starRateSelection($event)"></app-stars-rating>
          </div>
          <div class="text-comment">
            <mat-form-field class="text-box" appearance="outline">
              <textarea #freeTextInput (keyup)="commentaryText(freeTextInput.value);" matInput placeholder="Write you review" maxlength="500"></textarea>
            </mat-form-field>
          </div>
          
        </div>
      </div>
    <div class="save-button" mat-dialog-actions>
      <button type="button" class="w-100 btn-pilot m-0 save-btn" (click)="sendFeedback()" [disabled]="!disableSend">Send</button>
    </div>
  </div>
</mat-dialog-content>


<div class="full-screen-modal" *ngIf="feedbackFlag">
  <div id="modal-feedback-low-rating" class="container mb-4 mt-6" [ngClass]="{'pt-5': isIOS}">
    <div class="header-modal">
      <a class="btn-back" (click)="back()"><i class="material-icons">chevron_left</i> Feedback </a>
      <button type="button" class="close" (click)="closeClick()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="text-rate-sections">
      <label> Rate your recent experience </label>
    </div>
    <div class="row justify-content-center">
      <app-stars-rating [class]="'large'" [initValue]="starRating" (changeValue)="starRateSelection($event)">
      </app-stars-rating>
    </div>
    <br>
    <div class="text-sections">
      Tell us, which section did you have problems with?
    </div>

    <mat-chip-listbox #chipList class="feedback-matchips" multiple="false">
      <mat-chip-option *ngFor="let section of sections" [selected]="isSelected(section)" [disableRipple]="true"
        (click)="selectedSection = section">
        {{section.name}}&nbsp;
      </mat-chip-option>
    </mat-chip-listbox>


    <div class="text-comment-sections">
      Tell us your reason
    </div>
    <mat-form-field class="text-box-sections" appearance="outline">
      <textarea #freeTextInput (keyup)="reasonText(freeTextInput.value);" matInput placeholder="Write your reason"
        maxlength="500" matTextareaAutosize matAutosizeMinRows=1 matAutosizeMaxRows=5></textarea>
      <mat-hint *ngIf="!disableSend" class="required-reason">*Reason required</mat-hint>
    </mat-form-field>
    <br>

    <div class="save-button-sections">
      <button type="submit" [disabled]="!disableSend" class="w-100 btn-pilot m-0 save-btn"
        (click)="sendFeedback()">
        Send
      </button>
    </div>
  </div>
</div>
