<div class="content-title" mat-dialog-title>
    <div class="content-img">
        <mat-icon class="icon-info">info</mat-icon>
        <span>{{data?.title?.toUpperCase()}}</span>
    </div>
</div>
<mat-dialog-content class="content-text" >
    <div class="modal-body" [innerHtml]="data.body">
    </div>
</mat-dialog-content>
<div mat-dialog-actions>
    <button (click)="closeClick()" class="btn-actions btn-pilot pilot-primary">OK</button>
</div>
