import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Keyboard, KeyboardInfo } from "@capacitor/keyboard";
import { BehaviorSubject } from "rxjs";
import { StatusBar, Style } from "@capacitor/status-bar";
interface IrefreshState {refresh: boolean,finishEvent: boolean}
@Injectable({
  providedIn: "root",
})
export class UiService {

  public refreshState: BehaviorSubject<IrefreshState> = new BehaviorSubject({
    refresh: false,
    finishEvent: false
  });

  private mainElement: HTMLElement;
  y: number;
  h: number;
  offsetY: number;

  constructor(
    private platform: Platform
  ) {}

  public getStateRefresh() {
    return this.refreshState.asObservable();
  }

  public setStateRefresh(status: IrefreshState) {
    this.refreshState.next(status);
  }

  async listenKeyboard(id: string) {
    if (this.platform.is("hybrid")) {
      this.removeKeyboardListeners();
      this.mainElement = null;
      this.mainElement = await this.getElement(id);
      Keyboard.addListener("keyboardWillShow", (info: KeyboardInfo) => {
        this.keyboardShowHandler(info);
      });
      Keyboard.addListener("keyboardWillHide", () => {
        if (this.mainElement) {
          this.mainElement.removeAttribute("style");
        }
      });
      window.addEventListener("touchstart", (e) => {
        this.tapCoordinates(e);
      });
    }
  }

  getElement(id: string) { 
    return new Promise<HTMLElement>(async (resolve, reject) => {
      let element = document.getElementById(id);
      resolve(element);
    });
  }

  // Escucha cuando el usuario toca la pantalla y establece las medidas
  tapCoordinates(e) {
    this.y = e.touches[0].clientY;
    this.h = window.innerHeight;
    this.offsetY = this.h - this.y;   
  }

  keyboardShowHandler(e: KeyboardInfo) {
    let kH = e.keyboardHeight;
    if (this.mainElement) {
      if (this.offsetY < kH + 40) {
        this.mainElement.style.top = "-" + kH + "px";
        this.mainElement.style.position = "relative";
      }
    }
  }

  closeKeyboard() {
    if (this.platform.is("hybrid")) {
      Keyboard.hide();
    }
  }

  removeKeyboardListeners() {
    if (this.platform.is("hybrid")) {
      Keyboard?.removeAllListeners();
      window?.removeAllListeners();
    }
  }

  // Status Bar

  setStatusBar() {
    if (this.platform.is("hybrid")) {
      StatusBar.setStyle({ style: Style.Light });
    }
  }

}
