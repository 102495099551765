import { Injectable } from '@angular/core';
import { PilotConstants } from '../commons/pilot-constants';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { FeedbackRequest } from '../domain/feedback-request';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor( 
    private httpClient: HttpClient,
    private Constants: PilotConstants
    ) {}

  sendFeedback(feedbackRequest: FeedbackRequest): Observable<any> {
    const URL = `${environment.apiUrl}${this.Constants.BFF_PATH}${this.Constants.DISPATCH_BASE_PATH}${this.Constants.DISPATCH_FEEDBACK}`;
    return this.httpClient.post(URL, feedbackRequest)
  }
}
