import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  async migrate() {
    await Preferences.migrate()
  }

  async setItem(key: string, value: any) {
    await Preferences.set({ key, value: typeof value == 'string'? value : JSON.stringify(value) });
  };

  async getItem(key: string){
    const { value } = await Preferences.get({ key });
    return value;
  };
  
  async removeItem(key: string) {
    await Preferences.remove({ key });
  };

  async clearStorage() {
    await Preferences.clear();
    localStorage.clear();
  }
}
