<div class="card-margin">
  <mat-card appearance="outlined">
    <div class="row" style="background-color: #eceff5;border-radius: 6px;    margin-right: 0px;
    margin-left: 0px;">
      <div class="flight-card-left flight-card-left-fl" >
        <div class="ticket-shadow ticket-shadow-top-left"></div>
        <div class="ticket-shadow ticket-shadow-bottom-left"></div>

        <div class="d-inline-flex center-card-content">
          <div class="flight-datetime">
            <span class="airport">{{ activity.departure }}</span>
            <span class="airport-datetime mt-1 ml-1" >{{ activity.dates.localScheduledDatetimeDeparture | date : 'HH:mm' }}</span>
          </div>
          <div class="mx-3">
            <img src="/assets/img/home/new-home/flight.svg" alt="">
          </div>
          <div class="flight-datetime">
            <span class="airport">{{ activity.arrival }}</span>
            <span class="airport-datetime mt-1 ml-1" >{{ activity.dates.localScheduledDatetimeArrival | date : 'HH:mm' }}</span>
          </div>
        </div>

        <span class="row col-12 airport-diff center-card-content ml-1">
          Tail: {{ activity.aircraftRegistrationNumber }}
        </span>

        <span class="ticket-border ticket-border-top"></span>
        <span class="ticket-border ticket-border-bottom"></span>
      </div>
      <div [ngClass]="{'incomplete-flight': activity.flightCreated}" class="flight-card-right flight-card-right-fl col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4">
        <div class="ticket-shadow ticket-shadow-top-right"></div>
        <div class="ticket-shadow ticket-shadow-bottom-right"></div>

        <div class="flight-date-and-catering right-card-sm-margin">
          <span class="flight-date">
            {{activity.dates.scheduledDatetimeDeparture | date : 'MMMM dd' }}
          </span>
        </div>

        <span class="flight-number-fl right-card-sm-margin">
          {{ activity.comercialAirlineCode }}{{innerWidth > 400 ? '-' : ''}}{{ activity.flightNumber }}
        </span>
      </div>
    </div>
  </mat-card>
</div>
