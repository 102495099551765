<main class="pt-5 mt-4">
  <div class="mt-3 container">
    <!--Request-->
    <div class="title-section row">
      <app-back-home> </app-back-home>
    </div>
    <div *ngIf="formGroup" class=" latam_regular form-find-another-dispatch" [formGroup]="formGroup">
      <div class="col-lg-12 mb-3">
        <div class="col-auto m-auto">
          <div class="row">
            <mat-form-field  class="input-box" >
              <input type="text" maxlength="6"
                placeholder="Tail" style="text-transform: uppercase;" matInput
                [matAutocomplete]="tailInput" formControlName="tail" required >
              <mat-autocomplete #tailInput="matAutocomplete">
                <mat-option *ngFor="let option of tailSelect | async" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-auto m-auto">
            <button (click)="searchMR2Report()" 
            class="waves-light fr-button btn-pilot btn-search" [disabled]="this.searchingState =='loading'">Search</button>
          </div>
          <br>
          <div class="col-sm-12">
            <app-alerts 
              *ngIf="isInvalidForm"
              [text]="'All fields are required.'"
              [class]="'w-100'"
              [type]="'danger'"
            >
            </app-alerts>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="">
        <mat-progress-spinner *ngIf="this.searchingState =='loading'" style="margin: 0 auto;" color="accent" mode="indeterminate" value="50" [diameter]="50" ></mat-progress-spinner>
        <maintenix-report *ngIf="reportFoundInfo"   [hidden]="this.searchingState != 'success'" (loadIsFinished)="maintenixReady($event)" [tail]="this.tailToSearch" ></maintenix-report>
      </div>
      <div class="error-icon" *ngIf="this.searchingState == 'error'">
        <mat-icon >error</mat-icon>
        <br>
        <span>There was a problem getting your Report</span>
      </div>
    </div>
  </div>
</main>
  