<div class="container mt-6 mb-4">
 

  <div *ngIf="!this.onSearchMode">
    
    <div>
      <div class="info-row">
    
    
        <ion-button (click)="feedback()" expand="block" fill="outline" size="small">
          <svg width="20" height="20" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.73975 22.0042C7.21625 20.9829 5.39623 20.5976 5.27563 20.6583C5.27563 20.6583 5.21532 21.7502 5.99928 23.0847C6.38106 23.7347 7.70299 24.0611 8.89379 24.7608C10.0846 25.4604 11.3838 24.5281 11.3838 24.5281C11.3838 24.5281 10.5855 23.2416 8.73975 22.0042Z"
              fill="#10004F" />
            <path
              d="M24.8484 20.0473C23.1361 20.5347 22.2069 21.368 21.6953 22.2885C20.9869 23.563 22.5199 24.8024 23.8475 24.1995C25.4786 23.4587 26.2048 22.5258 26.0053 20.8009C25.941 20.245 25.3862 19.8943 24.8484 20.0473Z"
              fill="#1B0088" />
            <ellipse cx="17.8177" cy="19.6302" rx="3.09113" ry="3.14437" fill="#F9D7D7" />
            <ellipse cx="15.3449" cy="8.93966" rx="3.46206" ry="3.52169" fill="#CDD1F1" />
            <ellipse cx="6.50493" cy="8.62512" rx="1.42192" ry="1.44641" fill="#DFE2F6" />
            <ellipse cx="24.0619" cy="9.63121" rx="0.680048" ry="0.691761" fill="#DFE2F6" />
            <ellipse cx="10.2471" cy="5.79489" rx="0.618222" ry="0.628874" fill="#EEEDF9" />
            <path
              d="M17.2478 9.8285C17.1753 9.47778 17.3269 8.50207 17.9569 8.39132C18.502 8.29551 18.7294 8.49671 18.7516 8.65433C18.779 8.42118 18.9499 8.05908 19.5391 8.05908C20.1908 8.05908 20.3224 8.63131 20.2952 8.91742C20.3133 8.74205 20.4799 8.39132 21.0013 8.39132C21.653 8.39132 21.7868 8.8537 21.651 9.37978C21.5153 9.90586 21.4038 11.9582 21.4656 12.7938C21.5293 13.6555 21.7163 13.7998 21.7887 14.0121C22.0784 13.4491 22.8261 12.4172 23.4995 12.7938C24.1729 13.1703 23.8254 13.6261 23.4995 14.3073C23.1736 14.9885 22.8875 15.417 22.8875 17.618C22.8875 19.8191 26.6332 21.1701 26.906 21.3913L23.0306 26.2336C20.9966 22.2089 19.9818 21.0768 18.5599 20.3222C17.138 19.5675 16.8603 17.4294 16.5816 15.7943C16.0633 12.7531 16.1488 11.6437 16.1488 10.5746C16.1488 9.86618 16.8113 9.6347 17.2478 9.8285Z"
              fill="#F9B7DF" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M18.6445 9.34668C18.6964 9.34668 18.7385 9.38947 18.7385 9.44226C18.7385 9.45532 18.7356 9.47198 18.734 9.48074C18.7339 9.48138 18.7338 9.48198 18.7337 9.48253C18.7327 9.48864 18.7312 9.49653 18.7297 9.50544C18.7281 9.51396 18.7264 9.52342 18.7247 9.53312C18.7174 9.57485 18.7074 9.63424 18.6968 9.71212C18.6757 9.86788 18.652 10.0986 18.6425 10.4095C18.6235 11.0313 18.6613 11.975 18.8905 13.2826C18.8996 13.3346 18.8656 13.3842 18.8144 13.3934C18.7633 13.4027 18.7145 13.3681 18.7054 13.3161C18.4741 11.9964 18.4351 11.0395 18.4545 10.4036C18.4642 10.0856 18.4885 9.84815 18.5105 9.68598C18.5216 9.60489 18.532 9.54288 18.5396 9.49945C18.5416 9.488 18.5433 9.47858 18.5448 9.47044C18.5462 9.46241 18.5474 9.45561 18.5485 9.44932C18.5501 9.44028 18.5505 9.43691 18.5506 9.43687C18.5533 9.38659 18.5943 9.34668 18.6445 9.34668Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M20.3142 9.03528C20.3645 9.04846 20.3947 9.10058 20.3818 9.1517C19.8918 11.0829 20.0758 12.7646 20.3108 13.7591C20.3229 13.8104 20.2918 13.862 20.2413 13.8744C20.1909 13.8867 20.1401 13.8551 20.128 13.8038C19.8874 12.786 19.7004 11.0718 20.1997 9.10396C20.2127 9.05285 20.2639 9.0221 20.3142 9.03528Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M16.9957 14.9986C16.9687 14.9534 16.9828 14.8946 17.0272 14.8672C18.0151 14.257 18.7178 14.0138 19.298 13.986C19.8803 13.9581 20.3269 14.1481 20.7941 14.3724C20.841 14.395 20.8611 14.452 20.839 14.4997C20.8168 14.5475 20.7608 14.5679 20.7138 14.5454C20.2506 14.3229 19.84 14.1514 19.3069 14.177C18.7717 14.2026 18.1006 14.4279 17.1248 15.0307C17.0804 15.0581 17.0226 15.0437 16.9957 14.9986Z"
              fill="#2D34CE" />
            <path
              d="M5.08301 22.2085C5.22892 22.0352 7.82934 20.3848 8.0835 18.0579C8.17111 17.2558 7.93722 15.1418 7.59677 14.399C7.10006 13.3154 6.89155 12.6651 7.37791 12.4422C7.98586 12.1637 8.78835 13.2345 8.78835 14.0026C8.90183 13.3093 8.98289 12.1213 8.8613 10.8586C8.72415 9.43424 8.8613 8.87799 9.22607 8.75411C9.59084 8.63023 9.85834 8.70451 9.95561 8.90258C9.95561 8.58123 10.0286 8.18454 10.5879 8.18457C11.1008 8.1846 11.2704 8.56921 11.2912 8.7976C11.2947 8.58639 11.6601 8.39868 12.1685 8.50653C12.7522 8.63032 12.7035 9.64533 12.7035 9.64533C12.9953 9.60405 13.5173 9.69098 13.6145 11.0775C13.7118 12.4639 14.1709 14.0026 13.4909 17.8064C13.3839 18.2167 13.2436 18.8755 12.9345 19.2528C12.6254 19.6301 11.7599 20.1332 11.7599 20.1332C11.0798 20.6363 10.9562 21.1394 10.7707 21.7683C10.0906 23.4663 10.0866 25.4158 8.78835 27.6168L6.61909 24.2837L5.08301 22.2085Z"
              fill="#F9B7DF" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M11.2558 8.84375C11.3078 8.84375 11.3499 8.88654 11.3499 8.93933H11.2558H11.1618C11.1618 8.88654 11.2039 8.84375 11.2558 8.84375ZM11.1321 13.3414C11.0385 13.3327 11.0385 13.3327 11.0385 13.3327L11.0399 13.3173L11.0438 13.2723C11.0471 13.2331 11.052 13.1761 11.0577 13.1049C11.0693 12.9625 11.0847 12.7634 11.1002 12.537C11.1311 12.0834 11.1618 11.5221 11.1618 11.0854V8.93933H11.2558H11.3499V11.0854C11.3499 11.529 11.3187 12.0958 11.2878 12.5502C11.2723 12.7777 11.2568 12.9776 11.2452 13.1206C11.2393 13.1921 11.2345 13.2495 11.2311 13.2889L11.2272 13.3343L11.2258 13.3501C11.2258 13.3501 11.2258 13.3501 11.1321 13.3414ZM11.1321 13.3414L11.0385 13.3327C11.0338 13.3853 11.0718 13.4318 11.1236 13.4366C11.1753 13.4414 11.221 13.4026 11.2258 13.3501L11.1321 13.3414Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M10.0195 9.09521C10.0715 9.09521 10.1136 9.13801 10.1136 9.19079H10.0195H9.9255C9.9255 9.13801 9.9676 9.09521 10.0195 9.09521ZM9.8028 13.3272C9.8028 13.3272 9.8028 13.3272 9.8028 13.3272C9.79513 13.3794 9.83054 13.4281 9.88189 13.4359C9.93325 13.4437 9.98111 13.4077 9.98879 13.3555L9.91216 13.3438C9.98879 13.3555 9.98879 13.3555 9.98879 13.3555L9.9889 13.3547L9.98919 13.3527L9.99027 13.3451C9.9912 13.3385 9.99256 13.3287 9.99428 13.3158C9.99772 13.29 10.0026 13.2521 10.0085 13.203C10.0202 13.1048 10.0358 12.9618 10.0513 12.7818C10.0824 12.4221 10.1136 11.9144 10.1136 11.3222V9.19079H10.0195H9.9255V11.3222C9.9255 11.9078 9.89474 12.4097 9.864 12.7651C9.84863 12.9427 9.83327 13.0837 9.82179 13.18C9.81604 13.2282 9.81127 13.2652 9.80794 13.29C9.80628 13.3025 9.80498 13.3119 9.8041 13.3181L9.80311 13.3251L9.80287 13.3268L9.8028 13.3272Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12.5753 9.90753C12.4786 9.98313 12.4277 10.1103 12.4277 10.2714V10.2735L12.4277 10.2735L12.3482 14.0982C12.3471 14.151 12.3041 14.1929 12.2522 14.1918C12.2003 14.1907 12.1591 14.147 12.1602 14.0942L12.2396 10.2704C12.2399 10.0711 12.3038 9.87862 12.4607 9.75599C12.6177 9.63326 12.8464 9.59733 13.1401 9.66371C13.1908 9.67516 13.2228 9.72622 13.2115 9.77775C13.2002 9.82928 13.15 9.86177 13.0993 9.85031C12.8366 9.79094 12.6722 9.83181 12.5753 9.90753Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M7.6006 12.5051C7.64498 12.4777 7.70281 12.4921 7.72978 12.5372C7.93938 12.8877 7.96808 13.1683 7.89134 13.3867C7.81586 13.6015 7.64645 13.7325 7.50768 13.804C7.46137 13.8279 7.40478 13.8091 7.38129 13.762C7.35779 13.7149 7.37629 13.6574 7.4226 13.6335C7.53833 13.5739 7.66115 13.4735 7.71425 13.3224C7.76607 13.175 7.75967 12.9552 7.56908 12.6364C7.54211 12.5913 7.55622 12.5325 7.6006 12.5051Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8.82025 14.1349C8.87199 14.1394 8.91035 14.1857 8.90593 14.2383C8.89816 14.3306 8.89454 14.432 8.89052 14.5446C8.87636 14.9407 8.85721 15.4767 8.6353 16.2533C8.62082 16.304 8.56865 16.3331 8.51878 16.3184C8.46891 16.3037 8.44023 16.2507 8.45471 16.2C8.66938 15.4487 8.68769 14.9421 8.70199 14.5468C8.70621 14.4299 8.71008 14.3228 8.71856 14.222C8.72299 14.1694 8.76851 14.1304 8.82025 14.1349Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M9.65027 8.7183C9.70192 8.71282 9.74815 8.75095 9.75353 8.80345C9.80391 9.2948 9.81069 9.55109 9.63527 9.80453C9.44756 10.0757 9.13897 10.0652 8.92433 10.0071C8.87415 9.9935 8.84431 9.94113 8.85769 9.89013C8.87106 9.83912 8.92258 9.80879 8.97275 9.82238C9.16467 9.87438 9.36427 9.86387 9.48148 9.69453C9.61098 9.50742 9.61775 9.32316 9.56649 8.82327C9.56111 8.77077 9.59862 8.72377 9.65027 8.7183Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12.0924 8.5931C12.1439 8.58626 12.1911 8.62314 12.1978 8.67548C12.2537 9.1102 12.2282 9.3947 12.0611 9.57512C11.798 9.85907 11.4668 9.7959 11.2454 9.7481C11.1946 9.73714 11.1622 9.68639 11.173 9.63475C11.1838 9.58311 11.2337 9.55015 11.2845 9.56111C11.5109 9.60999 11.7394 9.64349 11.9242 9.44407C12.0257 9.33447 12.0673 9.13561 12.0113 8.70027C12.0046 8.64793 12.0409 8.59995 12.0924 8.5931Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M13.1117 9.66144C13.1634 9.65727 13.2087 9.69654 13.2128 9.74917C13.2391 10.0863 13.2262 10.408 13.1069 10.6481C13.0459 10.7707 12.9571 10.8724 12.8334 10.9424C12.7105 11.012 12.5591 11.0472 12.3772 11.0472C12.3253 11.0472 12.2832 11.0044 12.2832 10.9516C12.2832 10.8988 12.3253 10.856 12.3772 10.856C12.5357 10.856 12.6537 10.8254 12.7419 10.7754C12.8292 10.726 12.8931 10.6543 12.939 10.5619C13.0335 10.3718 13.0512 10.096 13.0254 9.76427C13.0213 9.71165 13.0599 9.66561 13.1117 9.66144Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M10.8856 8.27825C10.9373 8.27334 10.9831 8.31196 10.9879 8.36452C11.0078 8.57992 11.0069 8.76855 10.9764 8.92598C10.9458 9.0842 10.884 9.21712 10.7773 9.31161C10.5105 9.54791 10.2152 9.57495 10.0285 9.50311C9.97997 9.48442 9.9555 9.42925 9.97389 9.37988C9.99228 9.33052 10.0466 9.30565 10.0951 9.32434C10.2102 9.36863 10.4335 9.36255 10.6538 9.16747C10.7196 9.10922 10.7666 9.02019 10.7919 8.88908C10.8174 8.75719 10.8197 8.58912 10.8007 8.38232C10.7959 8.32976 10.8339 8.28317 10.8856 8.27825Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M21.7374 14.1426C21.7802 14.1724 21.7912 14.2319 21.7619 14.2755C21.6947 14.3756 21.5743 14.6127 21.4706 14.9077C21.3671 15.2023 21.2838 15.545 21.2838 15.8566C21.2838 15.9094 21.2417 15.9522 21.1897 15.9522C21.1378 15.9522 21.0957 15.9094 21.0957 15.8566C21.0957 15.5143 21.1864 15.1484 21.2935 14.8434C21.4005 14.5389 21.5274 14.2856 21.6067 14.1676C21.636 14.1241 21.6945 14.1129 21.7374 14.1426Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M23.7064 13.0354C23.7359 13.0789 23.7252 13.1384 23.6825 13.1684C23.6546 13.1879 23.6099 13.2355 23.5603 13.3102C23.5121 13.3828 23.4636 13.4743 23.4255 13.575C23.3481 13.7799 23.3204 14.0039 23.3998 14.1823C23.4212 14.2304 23.4002 14.287 23.3529 14.3088C23.3056 14.3305 23.2499 14.3092 23.2285 14.2611C23.1189 14.015 23.1647 13.732 23.25 13.5065C23.2933 13.392 23.3484 13.2875 23.4045 13.2031C23.4592 13.1208 23.5195 13.0505 23.5756 13.0111C23.6183 12.9811 23.6769 12.992 23.7064 13.0354Z"
              fill="#2D34CE" />
            <ellipse cx="0.556403" cy="0.565986" rx="0.556403" ry="0.565986" transform="matrix(-1 0 0 1 20.7852 3.65674)"
              fill="#EEEDF9" />
            <ellipse cx="0.5564" cy="0.565986" rx="0.5564" ry="0.565986" transform="matrix(-1 0 0 1 6.07129 12.2095)"
              fill="#EEEDF9" />
            <path
              d="M25.8163 19.8883C25.6926 20.8316 25.6051 21.8566 24.536 23.0243C23.4032 24.2533 21.8046 24.8972 21.1229 24.9839C22.5218 26.524 22.8477 26.8083 24.4557 28.381C25.136 27.2374 25.9626 27.2898 26.6821 26.997C27.6098 26.6196 28.7915 26.6449 29.5905 25.8206C30.0264 25.3727 30.4663 24.5332 30.3355 22.8354C29.7541 22.4375 29.1542 22.0501 28.5539 21.653C27.6051 21.0249 26.7178 20.4557 25.8163 19.8883Z"
              fill="#4658DF" />
            <ellipse cx="20.9461" cy="6.67966" rx="0.745919" ry="0.758764" fill="#FBEEF2" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M15.2677 3.68629C15.3709 3.69931 15.4565 3.79497 15.4588 3.89996L15.4657 4.20848C15.468 4.31347 15.3862 4.38803 15.283 4.37502C15.1798 4.362 15.0942 4.26634 15.0918 4.16135L15.085 3.85283C15.0826 3.74784 15.1644 3.67328 15.2677 3.68629Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M15.2677 2.58619C15.3709 2.59921 15.4565 2.69487 15.4588 2.79986L15.4657 3.10838C15.468 3.21337 15.3862 3.28794 15.283 3.27492C15.1798 3.26191 15.0942 3.16624 15.0918 3.06125L15.085 2.75273C15.0826 2.64774 15.1644 2.57318 15.2677 2.58619Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12.5922 4.65195C12.6631 4.58702 12.7813 4.60139 12.8563 4.68406L14.5062 6.50241C14.5812 6.58508 14.5846 6.70474 14.5137 6.76967C14.4428 6.83461 14.3246 6.82024 14.2496 6.73757L12.5997 4.91921C12.5247 4.83655 12.5213 4.71689 12.5922 4.65195Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M18.1207 4.69069C18.1949 4.77443 18.1981 4.89575 18.1279 4.96167L16.3403 6.63996C16.2701 6.70587 16.1531 6.69143 16.0789 6.60769C16.0047 6.52395 16.0014 6.40262 16.0716 6.33671L17.8592 4.65842C17.9294 4.5925 18.0465 4.60695 18.1207 4.69069Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M15.3041 4.79987C15.3992 4.80503 15.4799 4.9021 15.4845 5.01668L15.5412 6.45647C15.5457 6.57105 15.4722 6.65975 15.3771 6.65459C15.282 6.64943 15.2012 6.55236 15.1967 6.43779L15.14 4.99799C15.1355 4.88341 15.2089 4.79471 15.3041 4.79987Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M11.6556 3.62109C11.715 3.53994 11.8256 3.53754 11.9024 3.61572L12.1299 3.84713C12.2068 3.92531 12.2209 4.05448 12.1614 4.13563C12.1019 4.21678 11.9914 4.21918 11.9145 4.141L11.687 3.90959C11.6102 3.83141 11.5961 3.70224 11.6556 3.62109Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M19.0332 3.81741C19.0968 3.91125 19.0888 4.03826 19.0153 4.10108L18.8043 4.28149C18.7308 4.34432 18.6197 4.31917 18.5561 4.22533C18.4925 4.13149 18.5006 4.00448 18.574 3.94166L18.7851 3.76125C18.8585 3.69842 18.9697 3.72357 19.0332 3.81741Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M18.6574 6.49762C18.7056 6.59864 18.6744 6.71661 18.5877 6.76112L17.173 7.48701C17.0862 7.53152 16.9768 7.48571 16.9285 7.3847C16.8802 7.28369 16.9114 7.16572 16.9982 7.1212L18.4129 6.39532C18.4996 6.35081 18.6091 6.39661 18.6574 6.49762Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12.0127 6.36704C12.0589 6.278 12.1725 6.24983 12.2664 6.30411L13.6379 7.0969C13.7318 7.15118 13.7705 7.26737 13.7243 7.35641C13.6781 7.44545 13.5645 7.47363 13.4706 7.41934L12.0991 6.62656C12.0051 6.57227 11.9665 6.45608 12.0127 6.36704Z"
              fill="#2D34CE" />
            <path
              d="M5.30556 20.6593C6.93068 24.2903 10.0614 24.7493 11.3833 24.5285L9.87413 27.9988C5.34825 29.0104 2.08234 27.0076 0.653468 25.7392L5.30556 20.6593Z"
              fill="#2D34CE" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M17.2827 10.6842C17.3345 10.6821 17.3783 10.7231 17.3804 10.7758C17.396 11.1725 17.4335 11.8012 17.4951 12.3969C17.5259 12.6948 17.5627 12.9833 17.6055 13.2299C17.6488 13.4785 17.6971 13.6775 17.7489 13.8006C17.7694 13.8491 17.7473 13.9053 17.6996 13.9261C17.6518 13.9469 17.5965 13.9244 17.5761 13.8759C17.5151 13.7313 17.4639 13.5137 17.4204 13.2632C17.3764 13.0105 17.3392 12.7172 17.3081 12.4169C17.2459 11.8162 17.2082 11.1833 17.1925 10.7835C17.1904 10.7307 17.2308 10.6863 17.2827 10.6842Z"
              fill="#2D34CE" />
          </svg>
          Feedback
        </ion-button>
      </div>
    
    
      <div class="last-update">
        <div>
          <ion-button (click)="refreshAll()" expand="block" fill="outline" size="small">
            <ion-icon slot="start" name="reload-circle-outline"></ion-icon>
            &nbsp;Update Documents &nbsp;
          </ion-button>
          <div class="text-update">
            <span>Last update at {{dispatchLastUpdate}} (UTC)</span>
          </div>
        </div>
      </div>
    
    </div>
  <br>
  <br>


  <div class="flight-info info-row" >
    <mat-icon class="flight-info-icon">info</mat-icon>
    <div class="info-row">
      <span class="flight-info-text">&nbsp; {{ flightInfo }}</span>
    </div>
    <div  class="info-row">
      <span class="flight-info-text">&nbsp; {{ flightInfoDate}}</span>
    </div>
    <div  class="info-row">
      <span class="flight-info-text"*ngIf="!hideZFW">&nbsp; ZFW:</span>
      <span class="zfw" *ngIf="!hideZFW">&nbsp;{{ flightInfoZFW}}</span>
    </div>
    <br>
    <br>

  </div>

    <div class="title-container">
      <h5 class="dispatch-title latam_bold_italic">Priority</h5>      
    </div>
    <ng-container *ngIf="showDisclaimerLoadSheet">
      <app-alerts 
        [text]="constants.MESSAGES_APP.DISPATCH_FOLDER.LOADSHEET.MAIN"
        [class]="'mx-auto w-responsive'"
      >
      </app-alerts>
    </ng-container>

    <dispatch-section [type]="'primary'" [title]="'Loadsheet'" (refresh)="getLoadsheet()" >
      <div primarySectionBody >
        <mat-progress-spinner *ngIf="loadsheetState == 'waiting'" style="margin: 0 auto;" color="accent" mode="indeterminate" value="50" [diameter]="50" [matTooltip]="'Your document is being preparing'" ></mat-progress-spinner>
        <div *ngIf="loadsheetState == 'success'">
          <dispatch-section *ngFor="let loadsheet of listLoadSheet"
            [type]="'secondary with modal'"  
            [documentState]="loadsheetState"
            [loadsheet]="loadsheet"
            [title]="'Version: ' + loadsheet.version" (openModal)="openLoadSheetModal(loadsheet.version)">
          </dispatch-section>

          
        </div>
        <div class="no-reports" *ngIf="(!listLoadSheet || listLoadSheet?.length == 0) && loadsheetState != 'waiting'">
          <ng-container *ngIf="loadsheetState == 'success'">
            <mat-icon class="info-icon">info</mat-icon>
            <br>
            No loadsheets available
          </ng-container>
          <ng-container *ngIf="loadsheetState == 'error'">
            <mat-icon class="error-icon">error</mat-icon>
            <br>
            <span>There was a problem getting your loadsheet</span>
          </ng-container>
          <ng-container *ngIf="loadsheetState == loadsheetStatus.FORBIDDEN">
            <mat-icon class="info-icon">info</mat-icon>
            <br>
            <span>Not Available information 
            </span>
          </ng-container>
        </div>
      </div>
    </dispatch-section>
    
    <br>

  </div>

  <div [ngClass]="{'scrollable-docs': !onSearchMode}">
    <div class="title-container">
      <h5 class="dispatch-title latam_bold_italic">Dispatch Documents</h5>
    </div>
    
    <ng-container *ngIf="showDisclaimerDispatchDocuments">
      <app-alerts 
        [text]="constants.MESSAGES_APP.DISPATCH_FOLDER.DOCUMENTS.MAIN"
        [class]="'mx-auto w-responsive'"
      >
      </app-alerts>
    </ng-container>

    <ng-container *ngIf="caeDocsLoad != caeStatus.FORBIDDEN">
      <dispatch-section [type]="'primary'" [title]="'Crew Briefing'" (refresh)="subcomponentManager.refreshBTMReport()">
        <div primarySectionBody>
          <mat-progress-spinner *ngIf="subcomponentManager.briefing.isWaiting()" style="margin: 0 auto;" color="accent" mode="indeterminate" value="50" [diameter]="50" [matTooltip]="'Your document is being preparing'" ></mat-progress-spinner>
          <dispatch-section 
            *ngIf="!subcomponentManager.briefing.isWaiting()"
            [type]="'secondary with modal'" 
            [title]="'Briefing TM/Crew briefing'"
            [alertMessage]="subcomponentManager.briefing.getAlertMessage()"
            (openModal)="subcomponentManager.openBtmViewModal()"
            [documentState]="subcomponentManager.briefing.getState()"
            (onSelection)="dispatchFolder.get('Briefing extra').get('BTM').isSelected = $event">
          </dispatch-section>
  
          <ng-container *ngIf="dispatchFolder.get('Briefing')">
            <div *ngFor="let doc of dispatchFolder.get('Briefing') | mapReader">
              <mat-checkbox [(ngModel)]="dispatchFolder.get('Briefing').get(doc.name).isSelected"></mat-checkbox>&nbsp;
              <span>{{doc.name}}</span>
            </div>
          </ng-container>
        </div>
      </dispatch-section>
    </ng-container>

      <dispatch-section [type]="'primary'" [title]="'Maintenance condition'" (refresh)="subcomponentManager.reloadMaintenixReport()">
        <div primarySectionBody>
        <mat-progress-spinner *ngIf="subcomponentManager.maintenix.isWaiting()" style="margin: 0 auto;" color="accent" mode="indeterminate" value="50" [diameter]="50"></mat-progress-spinner>
          <dispatch-section 
            *ngIf="!subcomponentManager.maintenix.isWaiting()"
            [type]="'secondary with modal'" 
            [title]="'Maintenix Report'" 
            [alertMessage]="subcomponentManager.maintenix.getAlertMessage()"
            (openModal)="subcomponentManager.openMaintenixViewModal()"
            [documentState]="subcomponentManager.maintenix.getState()"
            (onSelection)="dispatchFolder.get('Maintenance condition extra').get('Maintenix').isSelected = $event">
          </dispatch-section>
        
          <ng-container *ngIf="dispatchFolder.get('Maintenance condition')">
            <div *ngFor="let doc of dispatchFolder.get('Maintenance condition') | mapReader">
              <mat-checkbox [(ngModel)]="dispatchFolder.get('Maintenance condition').get(doc.name).isSelected"></mat-checkbox>&nbsp;
              <span>{{doc.name}}</span>
            </div>
          </ng-container>
        </div>
      </dispatch-section>

  
    <dispatch-section [type]="'primary'" [title]="'Weather'" *ngIf="this.dispatchFolder.get('Weather').size != 0">
      <div primarySectionBody>
        <ng-container [ngTemplateOutlet]="docAvailabilityInfo" [ngTemplateOutletContext]="{document:dispatchFolder.get('Weather')}"></ng-container>
        <div *ngFor="let doc of dispatchFolder.get('Weather') | mapReader">
          <mat-checkbox [(ngModel)]="dispatchFolder.get('Weather').get(doc.name).isSelected"></mat-checkbox>&nbsp;
          <span>{{doc.name}}</span>
        </div>
      </div>
    </dispatch-section>
  
    <dispatch-section [type]="'primary'" [title]="'NOTAM'" *ngIf="this.dispatchFolder.get('NOTAM').size != 0">
      <div primarySectionBody>
        <ng-container [ngTemplateOutlet]="docAvailabilityInfo" [ngTemplateOutletContext]="{document:dispatchFolder.get('NOTAM')}"></ng-container>
        <div *ngFor="let doc of dispatchFolder.get('NOTAM') | mapReader">
          <mat-checkbox [(ngModel)]="dispatchFolder.get('NOTAM').get(doc.name).isSelected"></mat-checkbox>&nbsp;
          <span>{{doc.name}}</span>
        </div>
      </div>
    </dispatch-section>
  
    <dispatch-section [type]="'primary'" [title]="'Flight plan'" *ngIf="this.dispatchFolder.get('Flight plan').size != 0">
      <div primarySectionBody>
        <ng-container [ngTemplateOutlet]="docAvailabilityInfo" [ngTemplateOutletContext]="{document:dispatchFolder.get('Flight plan')}"></ng-container>
        <div *ngFor="let doc of dispatchFolder.get('Flight plan') | mapReader">
          <mat-checkbox [(ngModel)]="dispatchFolder.get('Flight plan').get(doc.name).isSelected"></mat-checkbox>&nbsp;
          <span>{{doc.name}}</span>
        </div>
      </div> 
    </dispatch-section> 

    <dispatch-section [type]="'primary'" [title]="'Other Documents'" *ngIf="this.dispatchFolder.get('Other documents').size != 0">
      <div primarySectionBody>
        <!--<dispatch-section 
          [type]="'secondary with modal'" 
          [title]="'Route Report'" 
          (openModal)="subcomponentManager.routeReport.openRouteReportModal()"
          [documentState]="subcomponentManager.routeReport.getState()"
          (onSelection)="dispatchFolder['Other documents extra']['Route Report'].isSelected = $event">
        </dispatch-section>-->
        
        <dispatch-section 
          *ngIf="!hiddenGESOperators.includes(flight.carrierOperating)"
          [type]="'secondary with modal'" 
          [title]="'Ground Equipment Status'"
          [alertMessage]="subcomponentManager.groundSupportStatus.getAlertMessage()"
          (openModal)="subcomponentManager.openGroundSupportStatusReportModal()"
          [documentState]="subcomponentManager.groundSupportStatus.getState()"
          (onSelection)="dispatchFolder.get('Other documents extra').get('Ground Support Status').isSelected = $event">
        </dispatch-section>
      

        <div *ngFor="let doc of dispatchFolder.get('Other documents') | mapReader">
          <mat-checkbox [(ngModel)]="dispatchFolder.get('Other documents').get(doc.name).isSelected"></mat-checkbox>&nbsp;
          <span>{{doc.name}}</span>
        </div>
        </div> 
    </dispatch-section>
  
    <dispatch-section [type]="'primary'" [title]="'Others'">
      <div primarySectionBody>
        <dispatch-section [type]="'secondary'" [title]="'Useful links'">
          <div secondarySectionBody>
            <ng-container *ngFor="let other of useFulLinks">
              &nbsp;<a [href]="other.link" target="_blank" rel="noopener noreferrer">{{ other.text }}</a><br>
            </ng-container>
          </div>
        </dispatch-section>
        <br>
      </div>
    </dispatch-section>
    
    <div *ngIf="this.subcomponentManager.directos.success()">
    <div class="title-container">
      <h5 class="dispatch-title latam_bold_italic"> Operational recommendations</h5>
    </div>

    <ng-container>
      <dispatch-section [type]="'primary'" [title]="'Directos'" (refresh)="subcomponentManager.reloadDirectosReport()">
        <div primarySectionBody>
        <mat-progress-spinner *ngIf="subcomponentManager.directos.isWaiting()" style="margin: 0 auto;" color="accent" mode="indeterminate" value="50" [diameter]="50"></mat-progress-spinner>
          <dispatch-section 
            *ngIf="!subcomponentManager.directos.isWaiting()"
            [type]="'secondary with modal'" 
            [title]="'Directos'" 
            [alertMessage]="subcomponentManager.directos.getAlertMessage()"
            (openModal)="subcomponentManager.openDirectosViewModal()"
            [documentState]="subcomponentManager.directos.getState()"
            (onSelection)="dispatchFolder.get('Operational recommendations').get('Directos').isSelected = $event">
          </dispatch-section>
        </div>
      </dispatch-section>
    </ng-container>
    </div>
    

    <div *ngIf=" this.caeDocsLoad=='Loading'">
      <div class="row col-centered">
        <span class="col-centered" style=" color: #E8114B;
        font-size: 18px;" >Information is being loaded from CAE </span>
      </div>
      <div>
        <mat-progress-spinner style="margin: 0 auto;" color="accent" mode="indeterminate" value="50" [diameter]="50"></mat-progress-spinner>
      </div>
    </div>

    <div *ngIf="this.caeDocsError && this.caeDocsLoad !='Loading' && this.caeDocsLoad != caeStatus.FORBIDDEN">
      <div class="row col-centered">
        <span class="col-centered" style=" color: #E8114B;
            font-size: 18px; text-align: center;">{{this.caeDocsErrorMessage}}</span>
      </div>
    </div>

    <div *ngIf=" this.caeDocsLoad == caeStatus.FORBIDDEN">
      <div class="row col-centered">
        <span class="col-centered" style=" color: #E8114B;
        font-size: 18px;" >Not Available information </span>
      </div>
    </div>
    
    <div class="row">
      <mat-checkbox  class="example-margin col-centered" [(ngModel)]="downloadAllIsSelected">
        All available documents
      </mat-checkbox>
    </div>
    <div class="row scrollable-docs-margin">   
      <button class="col-centered btn-pilot pilot-primary" (click)="download()" [disabled]="subcomponentManager.atLeastOneMainComponentIsWaiting() || downloading || caeDocsLoad == caeStatus.FORBIDDEN">
        {{downloading ? 'Downloading' : 'Download'}}
        <mat-progress-spinner *ngIf="downloading" [color]="'accent'" [mode]="'indeterminate'" [diameter]="25" class="downloading-spinner"></mat-progress-spinner>
      </button>
    </div>      
  </div>
</div>

<div class="full-screen-modal" [hidden]="!subcomponentManager.btmView.isModalBeingShown()" >
  <btm-report [hidden]="!subcomponentManager.briefing.isModalBeingShown()" [nextFlightToFind]="flight" [searchByFlight]="onSearchMode" [hideCrew]="onSearchMode" [anotherDispatch]="this.anotherDispatch" (loadIsFinished)="btmPDFIsReady($event)" (flightFound)="completeFlightWasFound($event)" (seatsByFlightFromBtm)="seatsByFlightIsReady($event)"></btm-report>
</div>

<div class="full-screen-modal" [hidden]="!subcomponentManager.btmView.isModalBeingShown()" *ngIf="!subcomponentManager.briefing.isWaiting()">
  <btm-report-view [hidden]="!subcomponentManager.btmView.isModalBeingShown()" (closeModalEvent)="subcomponentManager.closeBtmViewModal($event)" [nextFlightToFind]="flight" [hideCrew]="onSearchMode" [anotherDispatch]="this.anotherDispatch" [seatsByFlight]="this.seats"></btm-report-view>
</div>

  <div class="full-screen-modal" [hidden]="!subcomponentManager.maintenix.isModalBeingShown()" >
    <maintenix-report [hidden]="!subcomponentManager.maintenix.isModalBeingShown()"  (closeModalEvent)="subcomponentManager.closeMaintenixViewModal($event)"  [tail]="this.flight.tail" [dispatch]="true" (loadIsFinished)="maintenixIsReady($event)"></maintenix-report>
  </div>

  <div class="full-screen-modal" [hidden]="!subcomponentManager.directos.isModalBeingShown()" >
    <directos-recommendation [hidden]="!subcomponentManager.directos.isModalBeingShown()"  (closeModalEvent)="subcomponentManager.closeDirectosViewModal($event)"  [flight]="flight" (loadIsFinished)="directosIsReady($event)"></directos-recommendation>
  </div>


<div class="full-screen-modal" [hidden]="!subcomponentManager.routeReport.isModalBeingShown()">
  <route-report *ngIf="!onSearchModeLoading" (closeModalEvent)="subcomponentManager.closeRouteReportModal($event)" id="routeReportModal" (loadIsFinished)="routeReportPDFIsReady($event)"></route-report>
</div>
<!---
<div class="full-screen-modal" [hidden]="!subcomponentManager.limops.isModalBeingShown()">
  <limops-report *ngIf="!onSearchModeLoading" (closeModalEvent)="subcomponentManager.closeLimopsReportModal($event)" [aircraftRegistration]="this.flight.tail" [aircraftSubtype]="this.flight.aircraftSubtype" id="limopsReportModal" (loadIsFinished)="limopsReportPDFIsReady($event)"></limops-report>
</div>
-->
<div class="full-screen-modal" [hidden]="!subcomponentManager.groundSupportStatus.isModalBeingShown() || hiddenGESOperators.includes(flight.carrierOperating)">
  <ground-support-status *ngIf="!subcomponentManager.briefing.isWaiting() && !onSearchModeLoading" (closeModalEvent)="subcomponentManager.closeGroundSupportStatusReportModal($event)" (loadIsFinished)="groundSupportStatusIsReady($event)" id="groundSupportStatusReportModal" [arrival]="this.flight.arrivalIataCode" [departure]="this.flight.departureIataCode"  [fleet]="this.flight.aircraftSubtype"></ground-support-status>
</div>

<ng-template #docAvailabilityInfo let-document="document">
  <div class="no-reports">
    <span *ngIf="(!document || document.size == 0) && !dispatchFolderHasErrors">
      <mat-icon class="info-icon">info</mat-icon>
      <br>
      No documents available
    </span>
    <span *ngIf="dispatchFolderHasErrors">
      <mat-icon class="error-icon">error</mat-icon>
      <br>
      There was a problem getting your documents
    </span>
  </div>
</ng-template>