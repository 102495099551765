import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PilotConstants } from 'src/app/modules/pilot/commons/pilot-constants';
import { INotifications } from 'src/app/modules/pilot/domain/notifications/notifications';
import { environment } from 'src/environments/environment';
import { updateNotificationsRq } from '../../domain/notifications/update-notifications-request';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private httpClient: HttpClient, 
    private constants: PilotConstants
  ) { }

  getNotifications(): Observable<INotifications[]> {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.NOTIFICATIONS_PATH}`;
    return this.httpClient.get<INotifications[]>(URL);
  }

  updateNotifications(data: updateNotificationsRq) {
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.NOTIFICATIONS_PATH}/update`;
    return this.httpClient.put(URL, data, { responseType: 'text'});
  }
}
