export class LoadsheetLine{
    prefix?: string;
    newValue?: string;
    oldValue?: string;
    middleValue?: string;
    content?: string;
    highlight: boolean;

    constructor(line: string){
        this.highlight = false;
        this.content = line;
    }
}