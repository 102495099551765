import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PilotConstants } from '../commons/pilot-constants';
import { RouteReport } from '../domain/dispatch/route-report.model';

@Injectable({
  providedIn: 'root'
})
export class RouteReportService {

  constructor(private httpClient: HttpClient, private constants: PilotConstants) {}

  getAll(start: string, end: string): Observable<Array<RouteReport>> {
    let params = new HttpParams()
      .set('startDate', start)
      .set('endDate', end);
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.ROUTE_REPORT_BASE_PATH}`;

    return this.httpClient.get<Array<RouteReport>>(URL, { params });
  }
}
