import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PilotConstants } from '../commons/pilot-constants';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CrewAssignmentRequest } from '../domain/crew-assignment-request';
import * as moment from 'moment';
import { FlightId } from '../domain/flight-id.model';
import { SigaInformation } from '../domain/roster/siga-information.model';
import { CateringByFlight } from '../domain/roster/catering-by-flight.model';
import { Activity } from '../domain/roster/activity.model';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  constructor(
    private httpClient: HttpClient, 
    private Constants: PilotConstants
  ) { }

  getRoster(operator: string, date): Observable<Array<Activity>> {
    let params = new HttpParams()
      .set('operator', operator)
      .set('startDate', moment(date.startDate, "YYYY-MM-DD").utc().format('DD-MM-YYYY') + ' 00:00:00')
      .set('endDate', moment(date.endDate, "YYYY-MM-DD").utc().format('DD-MM-YYYY') + ' 23:59:59');
      
    const URL = `${environment.apiUrl}${this.Constants.BFF_PATH}${this.Constants.ROSTER_BASE_PATH}`;
    return this.httpClient.get<Array<Activity>>(URL, { params })
      .pipe( map(res => res.map(f => Object.assign(new Activity(), f))));
  }

  getCrewRosterAssignment(crewAssignmentRequest: CrewAssignmentRequest): Observable<any> {    
    let params = new HttpParams()
      .set('flightNumber', crewAssignmentRequest?.flightNumber)
      .set('departureDate', crewAssignmentRequest?.departureDate)
      .set('carrier', crewAssignmentRequest?.carrier)
      .set('departure', crewAssignmentRequest?.departure)
      .set('arrival', crewAssignmentRequest?.arrival);
    const URL = `${environment.apiUrl}${this.Constants.BFF_PATH}${this.Constants.ROSTER_BASE_PATH}${this.Constants.ROSTER_CREW_ASSIGNMENT}`;
    return this.httpClient.get(URL, { params });
  }

  getGateAndBridge(flights : Array<FlightId>): Observable<Array<SigaInformation>>{
    const URL = `${environment.apiUrl}${this.Constants.BFF_PATH}${this.Constants.ROSTER_BASE_PATH}${this.Constants.ROSTER_GATE_AND_BRIDGE}`;
    return this.httpClient.put<Array<SigaInformation>>(URL, flights);
  }

  getCatering(flightDate: string): Observable<Array<CateringByFlight>>{
    let params = new HttpParams().set("flightDate", flightDate);
    const URL = `${environment.apiUrl}${this.Constants.BFF_PATH}${this.Constants.ROSTER_BASE_PATH}${this.Constants.ROSTER_CATERING}`;
    return this.httpClient.get<Array<CateringByFlight>>(URL, { params });
  }
}
