
<div>
  <mat-toolbar class="header">
    <mat-toolbar-row>
      <span class="material-icons clickable" (click)="closeModal()">chevron_left</span>
      <span class="latam_bold_italic">Documents</span>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div id="cab">
  <h1 class="title">Loadsheet Version {{loadSheet?.version}}</h1>
  <input class="icon" src="assets/img/navbar/logo-latam-pilot.png" type="image" alt="">
</div>

<ion-content class="ion-padding">

  <div class="lmc-pax" *ngIf="loadSheet.lmcPax || loadSheet.lmcTM || loadSheet.lmcTC">
    <strong>LMC</strong> 
    <span *ngIf="loadSheet.lmcPax"> PAX: {{ loadSheet.lmcPax }} </span> 
    <span *ngIf="loadSheet.lmcTM">, TM: {{ loadSheet.lmcTM }} </span> 
    <span *ngIf="loadSheet.lmcTC">, TC: {{ loadSheet.lmcTC }} </span>
  </div>

  <!-- Start Section AI COSMOS Compare -->
  <ng-container *ngIf="cosmosWaiting=='Loading' && activateCosmosCompare && versionLS>=2">
    <mat-progress-spinner style="margin: 0 auto;" color="accent" mode="indeterminate" value="50" [diameter]="50" [matTooltip]="'AI is comparing last two loadsheets'"></mat-progress-spinner>
  </ng-container>
  <ng-container *ngIf="activateCosmosCompare && cosmosWaiting=='finish' && versionLS>=2">
    <div class="container mt-6 mb-4" >
      <dispatch-section [type]="'cosmos'" [title]="'See the differences using AI'" (click)="comparisonIsViewed()">
        <div CosmosBody>
          <ng-container>
            <div class="cosmos-info" >
                <span class="cosmos-info-text" [innerHTML]="compareResponse.message">  </span>
            </div>
            <hr>
            <div class="btns-loadsheet">
              <ng-container *ngIf="isConnected">
                <div class="btn-action">
                    <ion-button color="secondary" (click)="feedbackComparedLS()" expand="block" fill="outline" size="small" [disabled]=isSubmitBtnDisabled >
                      Feedback
                    </ion-button>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </dispatch-section>

    </div>
  </ng-container>
  <!-- End Section AI COSMOS Compare   -->

  <div class="body-loadsheet">
    <div id="loadsheet-message">
      <div *ngFor="let line of loadsheetContent">
        <ng-container *ngIf="!line.highlight">
          <div class="line-ls">&nbsp; {{line.content}} </div>
        </ng-container>
        <ng-container *ngIf="line.highlight">
          <div class="line-ls">
            <span>&nbsp;</span>
            <span>{{line.prefix}}</span>
            <span class="font-weight-bold" style="color: black;">{{line.oldValue}}</span>
            <span>{{line.middleValue}}</span>
            <span class="font-weight-bold" style="color: #61a8e7">{{line.newValue}}</span>
          </div>
        </ng-container>
        <hr style="margin-top: 0.5em; margin-bottom: 0.5em;">
      </div>
    </div>
  </div>
</ion-content>

<ion-footer class="ion-padding">

  <div class="btns-loadsheet">
    <ng-container *ngIf="isConnected">
      <div class="btn-action">
          <ion-button color="secondary" (click)="confirmACK()" expand="block" fill="outline" size="small" [disabled]="loadSheet?.ack != 0 || isSubmitBtnDisabled || !this.allowACK || disabledAckLMCButton">
            Acknowledge
          </ion-button>
      </div>
    </ng-container>
    <ng-container  *ngIf="loadSheet?.ack == 1 && allowLMC && !disabledAckLMCButton"> 
      <div class="btn-action">
        <ion-button color="warning" (click)="lastMinuteChange()" expand="block" fill="outline" size="small">
          Last Minute Change
        </ion-button>
      </div>
    </ng-container>
    <div class="btn-action">
      <ion-button color="success" (click)="createPDF()" expand="block" fill="outline" size="small">
        Download
      </ion-button>
    </div>
  </div>

</ion-footer>
