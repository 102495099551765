<div class="no-notifications" *ngIf="notifications.length == 0">
    You have no pending notifications to read or view at the moment.
</div>

<section *ngIf="notifications.length > 0" class="notifications-content">
    <div class="btns-mark">
        <button (click)="marlAllAsRead()" type="button" class="btn-pilot btn-mark">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
                <path d="M6.04 8.71002L1.36 3.91003L0 5.34003L6.04 11.43L16 1.35999L14.59 0L6.04 8.71002Z"
                    fill="white" />
            </svg>
            Mark all as read
        </button>
    </div>
    <ng-container *ngFor="let notification of notifications">
        <div class="notification" [ngClass]="{'notification-view': notification.view, 'notification-no-view': !notification.view}"
            (click)="updateNotifications(notification, true)">

            <div class="notification-img">
                <img src="assets/img/notifications/email.svg" alt="" srcset="">
            </div>
            <div class="notification-text">
                <h3 class="notification-title">
                    {{ notification.title }}
                    <img *ngIf="notification.view" src="assets/img/notifications/check.svg" alt="" srcset="">
                </h3>
                <p class="notification-description">
                    {{ notification.description }}
                </p>
            </div>
            <div>
                <span class="createdAt">
                    {{ notification.createdAt | date: 'shortTime' }}
                    <small>(UTC)</small>
                </span>
            </div>
        </div>
    </ng-container>
</section>