import * as moment from 'moment';
import { FlightId } from '../domain/flight-id.model';
import { CateringByFlight } from '../domain/roster/catering-by-flight.model';
import { SigaInformation } from '../domain/roster/siga-information.model';
import { NetworkService } from '../services/network/network.service';
import { RolService } from '../services/rol.service';
import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DispatchService } from '../services/dispatch.service';
import { Activity } from '../domain/roster/activity.model';
import { IndexeddbService } from '../services/indexeddb.service';
import { CrewAssignmentRequest } from '../domain/crew-assignment-request';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RosterCommon {
  public isConnected: boolean;

  constructor(
    private _network: NetworkService,
    private rolService: RolService,
    private dispatchService: DispatchService,
    private indexeddbService: IndexeddbService
  ) { }

  public async monitorNetWorkState() {
    this.isConnected = await this._network.getStatusNetwork();
  }

  public getSearchDates(datePattern: string, isFromHome = false) {
    let today = moment().utc(true);
    let addDays = moment().add(isFromHome? 7:30, 'days');

    let startDate: string = today.format(datePattern);
    let endDate: string;
    
    if (isFromHome) {
      endDate = moment(addDays, datePattern).format(datePattern);
    } else if (parseInt(moment(today, datePattern).format("DD")) >= 27) {
      endDate = moment(addDays, datePattern).endOf('month').format(datePattern);
    } else {
      endDate = moment(today, datePattern).endOf('month').format(datePattern);
    }

    return { startDate, endDate };
  }

  public addExtraDataToRoster(roster: Array<Activity>, loadDispatchIds = true) {
    let cateringIsReady: boolean = false;
    let sigaIsReady: boolean = false;

    roster = roster.map(f => Object.assign(new Activity(), f));

    if (loadDispatchIds) {
      this.loadDispatchIds(roster);
    }

    const checkLoads = (resolve: any) => {
      if (cateringIsReady && sigaIsReady) resolve(roster);
    }

    return new Promise((resolve, reject) => {

      //Gate and bridge
      let flightsToQuery: Array<FlightId> = this.filterFlights(roster, moment().add(2, 'days'));

      if (!flightsToQuery || flightsToQuery?.length == 0) {
        sigaIsReady = true;
        cateringIsReady = true;
        checkLoads(resolve);
        return;
      }

      //Catering
      this.rolService.getCatering(moment().utc().format('YYYY-MM-DD'))
        .pipe(finalize(() => {
          cateringIsReady = true;
          checkLoads(resolve);
        }))
        .subscribe((cateringList: Array<CateringByFlight>) => {
          cateringList.forEach(c =>
            roster.filter((f: Activity) => f.equalFlight(c.flightNumber, c.departure, c.utcDate, c.operator))
              .forEach((f: Activity) => f.catering = c.catering)
          )
        });

      this.rolService.getGateAndBridge(flightsToQuery)
        .pipe(finalize(() => {
          sigaIsReady = true;
          checkLoads(resolve);
        }))
        .subscribe((sigaInfo: Array<SigaInformation>) => {
          sigaInfo.filter(sFlight => sFlight.flight != null)
            .forEach(sFlight => {
              roster.filter((f: Activity) => f.equalFlight(sFlight.flight.flightNumber, sFlight.flight.departureIataCode, sFlight.flight.flightDate, sFlight.flight.operator))
                .forEach((f: Activity) => {
                  if (f?.flightLeg) {
                    f.flightLeg.gate = sFlight.gate;
                    f.flightLeg.bridge = sFlight.bridge;
                  }
                })
            })
        })
    })
  }

  private loadDispatchIds(roster: Array<Activity>) {
    let flightsForDispatch: Array<Activity> = roster.filter((f: Activity) => f.isFlight() && moment(f.flightLeg.departure.dates.utc).isAfter(moment()))
    if (flightsForDispatch && flightsForDispatch.length > 0) {
      let searchDateForFilterFlights: any = moment(moment(flightsForDispatch[0].flightLeg.departure.dates.utc).format('YYYY-MM-DD') + 'T23:59:59');
      let searchDateFormatForCAEList = searchDateForFilterFlights.format('YYYY-MM-DD');
      this.indexeddbService.getDataFromTable('rol', 'caeFlightsIds').then((data: any) => {
        if (data?.[searchDateFormatForCAEList]) {
          this.setFlightIdsRol(roster, data[searchDateFormatForCAEList], searchDateFormatForCAEList);
          return;
        } else {
          let filterFlights = this.filterFlights(flightsForDispatch, searchDateForFilterFlights);
          this.dispatchService.getFlightIds(filterFlights, searchDateFormatForCAEList)
          .subscribe({
            next: (caeFlights: any[]) => {
              if (caeFlights?.length > 0) {
                this.indexeddbService.addToTable('rol', { [searchDateFormatForCAEList]: caeFlights }, "caeFlightsIds");
                this.setFlightIdsRol(roster, caeFlights, searchDateFormatForCAEList);
              }
            },
            error: (err) => console.error("error getFlightIds ", err)
          });
        }
      });
    }
  }

  setFlightIdsRol(roster: Array<Activity>, caeFlights: any[], searchDateFormat: string) {
    caeFlights?.forEach(caeFlight => {
      roster.filter( f => {
        return f.flightLeg?.flightNumber == caeFlight.flnr
         && f.flightLeg?.departure.iataCode == caeFlight.departureAerodrome.iataID 
         && f.flightLeg?.aircraftRegistration.replace("-", "") == caeFlight.acreg 
         && f.flightLeg?.flightDate == searchDateFormat
      }).forEach(f => f.dispatchId = caeFlight.fltLegId);
    });
  }

  private filterFlights(roster: Array<Activity>, daysForward: any) {
    return roster.filter(f => f.isFlight() && moment(f.flightLeg.departure.dates.utc).isBefore(daysForward))
      .map(f => {
        let flightId = new FlightId({ 
          flightNumber: f.flightLeg.flightNumber,
          tail: f.flightLeg.aircraftRegistration,
          operator: f.flightLeg.carrier,
          flightDate: f.flightLeg.departure.dates.utc,
          departureIataCode: f.flightLeg.departure.iataCode,
          arrivalIataCode: f.flightLeg.arrival.iataCode
        });
        return flightId;
      });
  }

  saveCrewInformation(roster: Array<Activity>) {
    let flightsToQuery: Array<FlightId> = this.filterFlights(roster, moment().add(2, 'days'));
    this.getNextCrewFlights(flightsToQuery);
  }

  async getNextCrewFlights(flights: Array<FlightId>) {
    let flightsCrew = [];
    for (const iterator of flights) {
      let flightCrewRequest = new CrewAssignmentRequest();
      flightCrewRequest.flightNumber = iterator.flightNumber.toString();;
      flightCrewRequest.carrier = iterator.operator;
      flightCrewRequest.departureDate = moment(iterator.flightDate).format('DD-MM-YYYY');
      flightCrewRequest.flightDate = moment(iterator.flightDate).format('YYYY-MM-DD');
      flightCrewRequest.departure = iterator.departureIataCode;
      flightCrewRequest.arrival = iterator.arrivalIataCode;
      let response = await firstValueFrom(this.rolService.getCrewRosterAssignment(flightCrewRequest));
      let data = { ...flightCrewRequest, crew: response };
      flightsCrew = [...flightsCrew, data];
    }
    this.indexeddbService.addToTable('rol', flightsCrew, "flightsCrew");
  }
}