<main class="pt-5 mt-4">

  <div class="mt-3 container">
    <app-back-home> </app-back-home>
    <div class="title-my-documents">
      <h6 class="title-profile latam_bold_italic">MY DOCUMENTS</h6>
        <div class="action-update">
          <ion-button  (click)="init(true)" expand="block" fill="outline" size="small">
            <ion-icon slot="start"name="reload-circle-outline"></ion-icon>
            &nbsp;Update Documents &nbsp;
          </ion-button>
        </div>
    </div>
    <app-alerts *ngIf="showAlertDocumentsUploaded" [text]="constants.MESSAGES_APP.PROFILE.DOCUMENTS_MESSAGE" [class]="'w-100'">
    </app-alerts>
    <mat-progress-spinner *ngIf="loading" class="loading-spinner" [color]="'accent'" [mode]="'indeterminate'" [value]="50">
    </mat-progress-spinner>
    
    <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedIndex" (selectedTabChange)="onSelectedTabChange($event)" [hidden]="loading">
      <mat-tab *ngFor="let category of categories" label={{category.name}}>
        <ng-template mat-tab-label *ngIf="category.name == 'PASSPORT'" [ngTemplateOutlet]="passport">
          <ng-container [ngTemplateOutlet]="documentTab" [ngTemplateOutletContext]="{category:category}"></ng-container>
        </ng-template>
        <ng-template mat-tab-label *ngIf="category.name == 'VISA'" [ngTemplateOutlet]="visas">
          <ng-container [ngTemplateOutlet]="documentTab" [ngTemplateOutletContext]="{category:category}"></ng-container>
        </ng-template>
        <ng-template mat-tab-label *ngIf="category.name == 'DOCUMENTS'" [ngTemplateOutlet]="documents">
          <ng-container [ngTemplateOutlet]="documentTab" [ngTemplateOutletContext]="{category:category}"></ng-container>
        </ng-template>
        <ng-template mat-tab-label *ngIf="category.name == 'LICENSES'" [ngTemplateOutlet]="licenses">
          <ng-container [ngTemplateOutlet]="documentTab" [ngTemplateOutletContext]="{category:category}"></ng-container>
        </ng-template>
     </mat-tab>
    </mat-tab-group>    
   
  </div>

  <ng-container *ngIf="showUploadModal">
    <app-upload-documents (close)="showModal(null)" [typeDocument]="typeDocument"></app-upload-documents>
  </ng-container>
</main>


<ng-template #passport>
  <ng-container *ngIf="documentsAllowedByOperators['passport']">
    <div class="d-flex justify-content-center">
      <ion-button class="btn-upload" (click)="showModal('passport')" expand="block" fill="solid" size="small">
        UPLOAD PASSPORT
        <ion-icon slot="end" name="cloud-upload-outline"></ion-icon>
      </ion-button>
    </div>
  </ng-container>
  <no-data *ngIf="orderedListIsEmpty(orderedPassports)" [title]="'No Information'" [icon]="'announcement'"></no-data>
  <document-card-list [documents]="orderedPassports.expired" [state]="'Expired'"></document-card-list>
  <document-card-list [documents]="orderedPassports.nextToExpire" [state]="'Next to expire'"></document-card-list>
  <document-card-list [documents]="orderedPassports.active" [state]="'Active'"></document-card-list>
</ng-template>

<ng-template #visas>

  <ng-container *ngIf="documentsAllowedByOperators['visa']">
    <div class="d-flex justify-content-center">
      <ion-button class="btn-upload" (click)="showModal('visa')" expand="block" fill="solid" size="small">
        UPLOAD VISA
        <ion-icon slot="end" name="cloud-upload-outline"></ion-icon>
      </ion-button>
    </div>
  </ng-container>
  
  <no-data *ngIf="orderedListIsEmpty(orderedVisas)" [title]="'No Information'" [icon]="'announcement'"></no-data>
  <visa-card-list [visas]="orderedVisas.expired" [state]="'Expired'"></visa-card-list>
  <visa-card-list [visas]="orderedVisas.nextToExpire" [state]="'Next to expire'"></visa-card-list>
  <visa-card-list [visas]="orderedVisas.active" [state]="'Active'"></visa-card-list>
</ng-template>

<ng-template #documents>

  <ng-container *ngIf="documentsAllowedByOperators['dni']">
    <div class="d-flex justify-content-center">
      <ion-button class="btn-upload" (click)="showModal('dni')" expand="block" fill="solid" size="small">
        UPLOAD DNI
        <ion-icon slot="end" name="cloud-upload-outline"></ion-icon>
      </ion-button>
    </div>
  </ng-container>
  
  <no-data *ngIf="orderedListIsEmpty(orderedDocuments)" [title]="'No Information'" [icon]="'announcement'"></no-data>

  <document-card-list [documents]="orderedDocuments.expired" [state]="'Expired'"></document-card-list>
  <document-card-list [documents]="orderedDocuments.nextToExpire" [state]="'Next to expire'"></document-card-list>
  <document-card-list [documents]="orderedDocuments.active" [state]="'Active'"></document-card-list>
</ng-template>

<ng-template #licenses>
  <no-data *ngIf="orderedListIsEmpty(orderedLicenses)" [title]="'No Information'" [icon]="'announcement'"></no-data>

  <license-card-list [licenses]="orderedLicenses.expired" [state]="'Expired'"></license-card-list>
  <license-card-list [licenses]="orderedLicenses.nextToExpire" [state]="'Next to expire'"></license-card-list>
  <license-card-list [licenses]="orderedLicenses.active" [state]="'Active'"></license-card-list>
</ng-template>


<ng-template #documentTab let-category="category">
  <span>{{category.name}}</span>
  <span [matBadgeHidden]="category.totalExpired === 0" [matBadge]="category.totalExpired" matBadgePosition="after" matBadgeColor="accent" matBadgeSize="small" matBadgeOverlap="false" ></span>
  <span [matBadgeHidden]="category.totalNextToExpire === 0" [matBadge]="category.totalNextToExpire" matBadgePosition="below after" matBadgeColor="warn" matBadgeSize="small" matBadgeOverlap="false"></span>
</ng-template>



