import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PilotConstants } from 'src/app/modules/pilot/commons/pilot-constants';
import { ProfileService } from 'src/app/modules/pilot/services/profile.service';
import { SecurityService } from 'src/app/core/services/security/security.service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-on-boarding',
  templateUrl: './on-boarding.component.html',
  styleUrls: ['./on-boarding.component.scss']
})
export class OnBoardingComponent implements OnInit {

  screensOnBoarding = this.constants.SCREENS_ON_BOARDING;

  swiperEl: any;
  swiper: any;

  constructor(
    private router: Router,
    private pilotProfileService: ProfileService,
    private securityService: SecurityService,
    private constants: PilotConstants,
    private _user: UserService
  ) { }

  ngOnInit(): void {
    this.swiperEl = document.querySelector('swiper-container');
    this.swiperEl.initialize();
    this.swiper = this.swiperEl.swiper;
  }

  nextScreen(screen: number) {
    this.swiper.slideTo(screen, 800);
  }

  saveOnBoarding() {
    console.log("Save OB");
    this.securityService.setLoading(true);
    this.pilotProfileService.savePilotProfile({ onBoarding: true }).subscribe( {
      next: res => {
        console.log("onBoarding updated");
        let user = this._user.getUserValue;
        user.profile.onBoarding = true;
        this._user.updateLocalUser(user);
      },
      error: err => console.error("save onBoarding error", err),
      complete: () => {
        console.log("Save onboarding complete...");
        this.securityService.setLoading(false);
        this.router.navigate(["home"]);
      },
    });
  }

}
