import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PilotConstants } from '../commons/pilot-constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SafetyIndicatorsService {

  constructor(
    private http: HttpClient,
    private Constants: PilotConstants
  ) { }

  getDataSafety(url: string) {
    return this.http.get(url);
  }

  getUrlEndpointIndicator(key: string, fleet, operator, dates, type?) {
    let url = `${environment.apiUrl}${this.Constants.BFF_PATH}`;
    switch (key) {
      case "abnormalGoAround":
        url += `${this.Constants.SAFETY_INDICATORS}${this.Constants.SAFETY_AGA}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "landingGraphic":
        url += `${this.Constants.SAFETY_INDICATORS}${this.Constants.SAFETY_LANDING}${type}${"/"}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "hardGraphic":
        url += `${this.Constants.SAFETY_INDICATORS}${this.Constants.SAFETY_LANDING}${type}${"/"}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "rankingOfAirports":
        url += `${this.Constants.SAFETY_INDICATORS}${this.Constants.SAFETY_RANKING}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "shortLanding":
        url += `${this.Constants.SAFETY_INDICATORS}${this.Constants.SAFETY_LANDING}${type}${"/"}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "tcas":
        url += `${this.Constants.SAFETY_INDICATORS}${type}${this.Constants.SAFETY_TCAS_PILOT}${"/"}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "topUa":
        url += `${this.Constants.SAFETY_INDICATORS}${this.Constants.SAFETY_TOPUA}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "ua":
        url += `${this.Constants.SAFETY_INDICATORS}${this.Constants.SAFETY_UA}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "rollExFlight":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_ROLL_EX_FLIGHT_PILOT}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "rejTakeOff":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_REJ_TAKE_OFF_PILOT}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "alphaFloor":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_ALPHA_FLOOR_PILOT}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "stickShaker":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_STICK_SHAKER_PILOT}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "stallWarning":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_STALL_WARNING_PILOT}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "noIdleReverse":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_NO_IDLE_REVERSE_PILOT}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "spdHighMinor1000":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_SPD_HIGH_MINOR_1000}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "spoilerNotArmed":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_SPOILER_NOT_ARMED}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "spdHighTaxiOut":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_SPD_HIGH_TAXI_OUT}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "spdHighTaxiIn":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_SPD_HIGH_TAXI_IN}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "gpwsMode1":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_GPWS_MODE_1}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "gpwsMode2":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_GPWS_MODE_2}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "gpwsMode3":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_GPWS_MODE_3}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "gpwsMode4":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_GPWS_MODE_4}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "gpwsMode5":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_GPWS_MODE_5}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "gpwsTadCaution":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_GPWS_TAD_CAUTION}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "gpwsTadWarning":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_GPWS_TAD_WARNING}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "gpwsTCF":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_GPWS_TCF}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      case "stabTakeOff":
        url += `${this.Constants.SAFETY_INDICATORS_EXTEND}${this.Constants.SAFETY_STAB_TAKEOFF}${fleet}${"/"}${operator}${"/"}${dates.startDate}${"/"}${dates.endDate}`;
        break;  
      case "events":
        url += `${this.Constants.SAFETY_API}${this.Constants.FLIGHT_LIST}${dates.startDate}${"/"}${dates.endDate}`;
        break;
      default:
        break;
    }
    return url;
  }

}
