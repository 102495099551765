import { Device } from "@capacitor/device";
import { environment, environment as environmentDev } from "src/environments/environment";
import { environment as environmentIntg } from "src/environments/environment.intg";
import { environment as environmentProd } from "src/environments/environment.prod";


export class DataEnvironment {

    public async getEnvironment() {
        let info = await Device.getInfo();
        return new Promise<void>((resolve, reject) => {
            if (info.platform == "web") {
                let hostname = window.location.hostname;
                let isProd = hostname == "pilot.appslatam.com";
                let isIntg = hostname == "pilot.intg.appslatam.com";
                let isDev = hostname == "pilot.dev.appslatam.com";
                let isLocal = hostname == "localhost";
                isProd && this.assingVariables(environmentProd);
                isIntg && this.assingVariables(environmentIntg);
                isDev && this.assingVariables(environmentDev);
                isLocal && this.assingVariables(environmentDev);
            }
            resolve();
        });
    }

    assingVariables(env: any) {
        environment.clientId = env.clientId;
        environment.apiUrl = env.apiUrl;
        environment.envName = env.envName;
        environment.production = env.production;
    }
}