<main class="pt-5 mt-4">
  <div class="mt-3 container">
    <!--Request-->
    <div class="title-section-otherflights row">
      <app-back-home> </app-back-home>
    </div>

    <div class="row light-title">
      <div class="col-12 message-header">
        Check the crew of another flight by completing the fields
      </div>
    </div>
    <div *ngIf="formGroup" class="w-100 latam_regular form-find-another-flight" [formGroup]="formGroup">
      <div class="col-lg-12 mb-3">
        <div class="card-generic no-elevation p-4 small-screens-alignment">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <mat-form-field class="input-100 custom-mat-input">
                <mat-select placeholder="Op. Comercial" formControlName="owner" required>
                  <mat-option *ngFor="let owner of ownerList" [value]="owner.name">
                    {{ owner.name }}</mat-option>
                </mat-select>
                <mat-error>
                  Operator is
                  <strong>required</strong>
                </mat-error>
                <mat-hint>Select between Commercial and Cargo</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6">
              <mat-form-field class="input-100">
                <input type="text" matInput placeholder="Flight number" maxlength="5" pattern="([0-9]{1,4}[a-z]{0,1})"
                  formControlName="flightNumber" required>
                <mat-hint>Example: 1 or 500</mat-hint>
                <mat-error>
                  Format is
                  <strong> max 4 number </strong>(example: 1000)
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <mat-form-field class="input-100">
                <input type="text" maxlength="3" placeholder="Departure" style="text-transform: uppercase;" matInput
                  [matAutocomplete]="departureAC" formControlName="departure" required>
                <mat-autocomplete #departureAC="matAutocomplete">
                  <mat-option *ngFor="let option of departureAirports | async" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6">
              <mat-form-field class="input-100">
                <input type="text" maxlength="3" placeholder="Arrival" style="text-transform: uppercase;"
                  [matAutocomplete]="arrivalAC" matInput formControlName="arrival">
                <mat-autocomplete #arrivalAC="matAutocomplete">
                  <mat-option *ngFor="let option of arrivalAirports | async" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <mat-form-field class="input-100">
                <input matInput #picker1 [matDatepicker]="picker" placeholder="Flight date (LOCAL)"
                  formControlName="flightDate" (click)="clickPicker()" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker></mat-datepicker>
                <mat-error>
                  Flight date is
                  <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-12"><br></div>
            <div class="col-sm-12">
              <app-alerts [text]="constants.MESSAGES_APP.FIND_ANOTHER_CREW.MAIN" [class]="'w-100'">
              </app-alerts>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="md-form">
                <button color="primary" class="btn-pilot pilot-primary" (click)="searchFlight()">Search</button>
              </div>
            </div>
            <div class="col-sm-12">
              <app-alerts *ngIf="isInvalidForm" [text]="'Please fill out all required fields.'" [class]="'w-100'"
                [type]="'danger'">
              </app-alerts>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>

      <mat-progress-spinner *ngIf="loading" class="mx-auto mt-5 mb-5" color="accent" mode="indeterminate"
        value="50"></mat-progress-spinner>

      <!--Response-->
      <ng-container *ngIf="!loading">
        <mat-expansion-panel id="targetCrewInfo" *ngFor="let flight of otherFlightsData.flights; let i = index"
          class="flight-expandable" expanded="true">
          <mat-expansion-panel-header collapsedHeight="2.2em" expandedHeight="2.2rem">
            <mat-panel-title>
              {{flight.departure.airportIataCode}} / {{flight.arrival.airportIataCode}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="crew-info">
            <p>Crew on board : {{flight.crewNum}}</p>
            <p>Pax : {{flight.paxNum}}</p>
          </div>

          <div *ngFor="let crew of flight.crewInformation" class="crew-unit">
            <div class="crew-unit-acro">
              <div class="circle">
                {{ crew.rank }}
              </div>
              <div *ngIf="crew.activity.dutyDesignator == 'PS'" class="circle-pax">PAX</div>
              <div *ngIf="crew.activity.dutyDesignator != 'PS'" style="visibility:hidden" class="circle-pax">PAX</div>
            </div>
            <!--
             -->
            <div class="crew-unit-data">
              <p class="crew-unit-name"> {{ crew.name.firstName }} {{ crew.name.lastName }} </p>
              <p class="crew-unit-nums">BP {{ crew.bp }}</p>
              <ng-container *ngFor="let contact of crew.personalContacts">
                <p *ngIf="contact.type == 'OFFICE_EMAIL'" class="crew-unit-email"><i><u><a style="color: black;"
                        href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to={{ contact.value }}&su="
                        target="_blank" rel="noopener noreferrer">{{ contact.value }}</a></u></i></p>
              </ng-container>
            </div>
          </div>
        </mat-expansion-panel>


      </ng-container>

    </div>
  </div>
</main>