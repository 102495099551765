import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanTranslator'
})
export class BooleanTranslatorPipe implements PipeTransform {

  transform(value: string): string {
    if(value == "true"){
      return "Yes"
    }else if(value == null){
      return "-"
    }else {
      return "No"
    }
  }

}
