import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-top-ua',
  templateUrl: './top-ua.component.html'
})
export class TopUaComponent {
  
  @Input("topUa") topUadata: any;
  @Input("showLoading") showLoading: any;

  topUA: Array<any>;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.topUadata != undefined && this.topUadata != null) {
      this.setTopUA();
    }
  }

  setTopUA() {
    this.topUA = this.topUadata;
  }

}
