<div class="container mt-5 mb-5">
    <div class="content-icon">
        <mat-icon aria-hidden="false" class="info" *ngIf="data.type=='info'">warning</mat-icon>
        <mat-icon aria-hidden="false" class="error" *ngIf="data.type=='error'">cancel</mat-icon>
        <mat-icon aria-hidden="false" class="success" *ngIf="data.type=='success'">check_circle_outline</mat-icon>
    </div>
    <p class="title">
        {{data.title}}
    </p>
    <p class="message">
        {{data.message}}
    </p>
    <div class="content-button">
        <button type="button" *ngIf="data.type=='info'" class="btn-cancel-dialog" (click)="status(false)">No</button>
        <button type="button" *ngIf="data.type=='info'" class="btn-submit-dialog" (click)="status(true)">Yes</button>
        <button type="button" *ngIf="data.type=='error'" class="btn-submit-dialog" (click)="status(false)">Ok</button>
        <button type="button" *ngIf="data.type=='success'" class="btn-submit-dialog" (click)="status(true)">Ok</button>
    </div>
</div>