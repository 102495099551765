import { FlightLogDatesInfo } from "./flight-log-dates-info.model";
import { FlightLogTrip } from "./flight-log-trip.model";

export class FlightLogInfo {
  id?: number;
  flightDate: string;
  operatingAirlineCode: string;
  comercialAirlineCode: string;
  flightNumber: string;
  fleet: string;
  aircraftRegistrationNumber: string;
  departure: string;
  arrival: string;
  dates: FlightLogDatesInfo;
  removable?: boolean;
  trip: FlightLogTrip[];
  multipleFlights?: boolean;
  flightCreated?: boolean;
  selected?: boolean;
  flightInfo?: string;
  pendingSend?: boolean;

  constructor(){
    this.id = null;
    this.flightDate = null;
    this.operatingAirlineCode = null;
    this.comercialAirlineCode = null;
    this.flightNumber = null;
    this.fleet = null;
    this.aircraftRegistrationNumber = null;
    this.departure = null;
    this.arrival = null;
    this.dates = new FlightLogDatesInfo();
    this.trip = [];
    this.removable = true;
  }
}