import { Component, OnInit, Inject, OnDestroy,  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FeedbackRequest } from 'src/app/modules/pilot/domain/feedback-request';
import { PilotConstants } from '../../../commons/pilot-constants';
import { FeedbackService } from '../../../services/feedback.service';
import { UiService } from 'src/app/modules/pilot/services/ui/ui.service';
import Swal from 'sweetalert2';
import { Device } from '@capacitor/device';


@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.css']
})
export class FeedbackModalComponent implements OnInit, OnDestroy {

  public feedbackData: FeedbackRequest;
  public starRating : number = 0;
  public title: string;
  disableSend : boolean = false;
  feedbackFlag : boolean;
  public sections : any = this.constants.DISPATCH_SECTIONS;
  public selectedSection: any["name"] = [];

  isIOS: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FeedbackModalComponent>,
    private constants: PilotConstants,
    private feedbackService: FeedbackService,
    private _ui: UiService
  ) {}

  ngOnInit() {
    Device.getInfo().then(info => this.isIOS = (info.platform == "ios"));
    this._ui.listenKeyboard('modal-feedback-dispatch');
    this.title = this.data.message ? this.data.message : null;
    this.feedbackData = new FeedbackRequest();
    this.feedbackData.bp = this.data.bp;
    this.feedbackData.operator = this.data.operator;
    this.feedbackData.flightNumber = this.data.flightNumber;
    this.feedbackData.flightDate = this.data.flightDate;
    this.feedbackData.departureIataCode = this.data.departureIataCode;
    this.feedbackData.section = "";
    this.feedbackData.reason = "";
    this.feedbackFlag = false;  
  }

  ngOnDestroy(): void {
    this._ui.removeKeyboardListeners();
  }

  isSelected(section: any): boolean {
    return this.selectedSection === section;
  }

  commentaryText(text) {
    this.feedbackData.commentary = text;
  }
  
  reasonText(text) {
    this.feedbackData.reason = text;
    this.checkDisable();
  }

  checkDisable(){
    if((this.starRating < 5 || this.starRating !=0 ) && this.feedbackData.reason != "" && this.selectedSection!= null ){
      this.disableSend = true;
    } else {
      this.disableSend = false;
    }
  }

  writeReason(){
    Swal.fire({
      icon: 'info',
      title: 'Please your reason is important for improve the application',
      text: ''
    })
  }

  sendFeedback() {
    this.disableSend = false;
    this.feedbackData.score = this.starRating;
    this.feedbackData.section = this.selectedSection.name

    this.feedbackService.sendFeedback(this.feedbackData).subscribe({
      next: resp => {
        Swal.fire({
          icon: 'success',
          title: 'Thanks for your feedback',
          text: ''
        }).then(resp => {
          this.closeClick();
        });
      },
      error: err =>
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `There was a problem`
        })
    });
  }

  back() {
    this._ui.listenKeyboard('modal-feedback-dispatch');
    this.feedbackFlag = false;
  }

  closeClick(): void {
    this.dialogRef.close();
  }

  starRateSelection(value: number){ 
    this.starRating = value;
    if(this.starRating < 5) {
      this.feedbackFlag = true;
      this.disableSend = false;
      setTimeout(() => {
        this._ui.listenKeyboard("modal-feedback-low-rating");
      }, 1000);
    }

    if(this.starRating == 5){
      this.feedbackFlag = false;
      this.disableSend = true;
      this._ui.listenKeyboard('modal-feedback-dispatch');
    }
  }

}
