<main class="pt-5 mt-4">
  <div class="mt-3 container">
    <div class="faq-header-bkgd">
      <mat-card appearance="outlined" class="faq-header">
        FAQ
      </mat-card>
    </div>
    <mat-progress-spinner
      *ngIf="faqLoading"
      class="faq-progress-spinner"
      color="accent"
      mode="indeterminate"
      value="50">
    </mat-progress-spinner>
    <mat-form-field class="example-form-field" appearance="fill" *ngIf="!faqLoading">
      <mat-label>Search for keywords...</mat-label>
      <input matInput type="text" [(ngModel)]="value">
    </mat-form-field>
    <mat-tab-group *ngIf="!faqLoading" class="customTabs">

      <mat-tab label="All">
        <br>
        <div *ngFor="let item of faqRes; let i = index" >
          <p class="faq-all-title">{{item.category}}</p>
          <div *ngFor="let question of item.questions" class="container">

            <mat-expansion-panel>
              <mat-expansion-panel-header [collapsedHeight]="'auto'" expandedHeight="fit-content">
                <mat-panel-title>
                  {{question.question}}
                </mat-panel-title>
              </mat-expansion-panel-header>
                <span *ngFor="let response of question.responses">
                  <p class="faq-answers" *ngIf="response.startsWith('http://') || response.startsWith('https://') "  >En el siguiente enlace:
                    <u><a class="faq-answers"  href={{response}}>link</a>
                    </u>
                  </p>
                  <p class="faq-answers" *ngIf="!response.startsWith('http://') && !response.startsWith('https://') " >{{response}}</p>
                </span>
              </mat-expansion-panel>
            <br>

          </div>
        </div>
      </mat-tab>

      <div *ngFor="let item of faqRes; let i = index">

        <mat-tab [label]="item.category">
          <main class="latam_regular mt-20">
            <div *ngFor="let question of item.questions" class="container">

              <br>
              <mat-expansion-panel >
                <mat-expansion-panel-header [collapsedHeight]="'auto'" expandedHeight="fit-content">
                  <mat-panel-title>
                    {{question.question}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                    <span *ngFor="let response of question.responses">
                     <p class="faq-answers" *ngIf="response.startsWith('http://') || response.startsWith('https://') "  >En el siguiente enlace:
                        <u><a class="faq-answers"  href={{response}}>link</a>
                        </u>
                      </p>
                     <p class="faq-answers" *ngIf="!response.startsWith('http://') && !response.startsWith('https://') " >{{response}}</p>
                    </span>
              </mat-expansion-panel>

            </div>
          </main>
        </mat-tab>
      </div>

    </mat-tab-group>
  </div>
</main>
