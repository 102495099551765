import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PilotConstants } from '../commons/pilot-constants';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import { FlightLogInfo } from '../domain/flight-log/flight-log-info.model';
import { SortedFlightLog } from '../domain/flight-log/sorted-flight-log.model';
import { FlightLogDatesInfo } from '../domain/flight-log/flight-log-dates-info.model';
import { FlightLogRulesDates } from '../domain/flight-log/flight-log-rules-dates';
import { FlightLog } from '../domain/flight-log/flight-log.model';

@Injectable({
    providedIn: 'root'
})
export class FlightLogService {
    public reDraw: BehaviorSubject<boolean> = new BehaviorSubject(false);
    bffUrl = `${environment.apiUrl}${this.constants.BFF_PATH}`;
    constructor(
        private httpClient: HttpClient,
        private constants: PilotConstants
    ) { }

    public getState() {
        return this.reDraw.asObservable();
    }
    
    public setState(status: boolean) {
        this.reDraw.next(status);
    }

    getFlightLogInfo(operator: string, flightNumber: number, departure: string, flightDate: string) {
        let params = new HttpParams()
        .set('operator', operator)
        .set('flightNumber', flightNumber.toString())
        .set('departure', departure)
        .set('flightDate', flightDate);
        const URL = `${this.bffUrl}${this.constants.FLIGHT_LOG_BASE_PATH}/find-flight`;
        return this.httpClient.get<FlightLogInfo[]>(URL, { params });
    }

    getPastFlights() {
        const URL = `${this.bffUrl}${this.constants.FLIGHT_LOG_BASE_PATH}/find-past-flights`;
        return this.httpClient.get<FlightLogInfo[]>(URL).pipe(
            map(f => {
                return f? f.map(f => ({
                    ...f,
                    flightInfo: `${f.comercialAirlineCode}${f.flightNumber} ${f.flightDate} ${f.departure}-${f.arrival}`,
                    selected: false
                })) : [];
            })
        );
    }
    
    getFlightLogsSorted() {
        const URL = `${this.bffUrl}${this.constants.FLIGHT_LOG_BASE_PATH}/sort-by-date`;
        return this.httpClient.get<SortedFlightLog>(URL);
    }

    checkIfFlightLogExists(flightLogNumber: number) {
        const URL = `${this.bffUrl}${this.constants.FLIGHT_LOG_BASE_PATH}/is-already-created/${flightLogNumber}`;
        return this.httpClient.get<boolean>(URL);
    }
    
    createFlightLog(data: FlightLog): Observable<any> {
        const URL = `${this.bffUrl}${this.constants.FLIGHT_LOG_BASE_PATH}/create`;
        const formData = new FormData();
        formData.append('file', data.bookImage);
        data.bookImage = null;
        formData.append('flightLog', new Blob([JSON.stringify(data)], {type: "application/json"}));
        return this.httpClient.post(URL, formData);
    }

    updateFlightLog(data: FlightLogDatesInfo): Observable<any> {
        const URL = `${this.bffUrl}${this.constants.FLIGHT_LOG_BASE_PATH}/update`;
        return this.httpClient.put(URL, data);
    }

    addFlights(flightLogNumber: string, data: Array<FlightLogInfo>): Observable<any> {
        const URL = `${this.bffUrl}${this.constants.FLIGHT_LOG_BASE_PATH}/add-flights/${flightLogNumber}`;
        return this.httpClient.put(URL, data);
    }

    deleteFlights(ids: Array<number>) {
        const URL = `${this.bffUrl}${this.constants.FLIGHT_LOG_BASE_PATH}/delete-flights`;
        return this.httpClient.request('delete', URL, {body: ids});
    }

    deleteFlightLog(flightLogNumber: string) {
        const URL = `${this.bffUrl}${this.constants.FLIGHT_LOG_BASE_PATH}/${flightLogNumber}`;
        return this.httpClient.request('delete', URL);
    }

    async uploadFile(file: File, flightLogNumber: string){
        const URL = `${this.bffUrl}${this.constants.FLIGHT_LOG_BASE_PATH}/upload-file/${flightLogNumber}`;
        const formData = new FormData();
        formData.append('file', file);
        return await firstValueFrom(this.httpClient.post<void>(URL, formData));
    }

    getDatesRules() {
        const URL = `${this.bffUrl}${this.constants.FLIGHT_LOG_BASE_PATH}/rules-dates`;
        return this.httpClient.get<FlightLogRulesDates>(URL);
    }

}