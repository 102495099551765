<div class="form-flight-log ml-2 mt-4 mr-2" [formGroup]="formflightLog">
    <div class="row">
        <div class="col-12">
            <mat-form-field floatLabel="always" class="full-width">
                <mat-label>Flight log number</mat-label>
                <input matInput placeholder="Ej: 0112200017" formControlName="flightLogNumber" type="number">
            </mat-form-field>
        </div>
    </div>

    <hr>

    <ng-container formArrayName="flights">
        <ng-container *ngFor="let flightForm of flightInfo.controls; let i = index">

            <div class="flight-info">
                {{ selectedFlights[i].flightInfo }}
            </div>

            <div class="inputs-rows" [formGroupName]="i">
                <mat-form-field floatLabel="always" class="width-input">
                    <mat-label>Start time</mat-label>
                    <input autocomplete="off" type="text" mask="Hh:m0" [leadZeroDateTime]="true" matInput
                        placeholder="Start time" formControlName="startTime">
                </mat-form-field>
                <mat-form-field floatLabel="always" class="width-input">
                    <mat-label>End time</mat-label>
                    <input autocomplete="off" type="text" mask="Hh:m0" [leadZeroDateTime]="true" matInput
                        placeholder="End time" formControlName="endTime">
                </mat-form-field>
                <mat-form-field floatLabel="always" class="width-input">
                    <mat-label>Block time</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="Block time" formControlName="blockTime"
                        [disabled]="true">
                </mat-form-field>
            </div>

            <hr>

        </ng-container>
    </ng-container>
    <div class="row">
        <div class="col-12">
            <label class="label-input-file">
                <span>Upload Flight Log book image (max 5mb)</span>
            </label>
            <button class="input-file" type="button" (click)="fileInput.click()">{{this.selectedFile ?
                this.selectedFile.name : filePlaceholder}}
                <mat-icon matSuffix class="icon-display">attach_file</mat-icon>
            </button>
            <input hidden (change)="onAttachmentSelected($event)" #fileInput type="file">
        </div>
    </div>
</div>
<div class="btn-actions">
    <button [disabled]="saving || !formflightLog.valid" class="btn-pilot pilot-primary" (click)="saveFlightLog()">
        {{saving ? 'Saving' : 'Load Data'}}
        <mat-progress-spinner *ngIf="saving" [color]="'accent'" [mode]="'indeterminate'" [diameter]="25"
            style="float: right;"></mat-progress-spinner>
    </button>
</div>