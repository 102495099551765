import { Injectable, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MessageComponent } from 'src/app/standalone-modules/message/message.component';

@Injectable({
  providedIn: 'root'
})
export class SimpleDialog implements OnInit{

  private dialogWidth: string;

  constructor(private dialog: MatDialog,
    private snackBar: MatSnackBar){}

  ngOnInit(): void {
    this.dialogWidth = '400px';
  }

  info(title: string = 'Info', message: string = ''){
    return this.dialog.open(MessageComponent, {
      width: this.dialogWidth,
      data: { type: "info", message, title }
    });
  }

  success(title: string = 'Success', message: string = 'Record has been saved successfully'){
    return this.dialog.open(MessageComponent, {
      width: this.dialogWidth,
      data: { type: "success", message, title }
    });
  }

  error(title: string = 'Error', message: string = 'Ooops! There was a problem. Please try again'){
    return this.dialog.open(MessageComponent, {
      width: this.dialogWidth,
      data: { type: "error", message, title }
    });
  }

  snackBarMessage(message: string){
    let options: MatSnackBarConfig = {
      duration: 3000,
      verticalPosition: "top",
      panelClass: 'app-notification-pilot'
    }
    this.snackBar.open(message, '', options);
  }

}
