<main class="latam_regular" class="mt-20">
  <div class="container">
    <mat-progress-spinner *ngIf="showLoading" style="margin: 0 auto" [color]="'accent'" [mode]="'indeterminate'" [value]="50">
    </mat-progress-spinner>

    <div class="not-found" *ngIf="isConnected&&(!showLoading)&&flightDetailList.length==0">
      <p class="t-gray-3">Currently you don't have flights with safety events</p>
    </div>
    <br>
    <mat-accordion >

      <mat-expansion-panel *ngFor="let flightDetail of flightDetailList; index as i;">
        <mat-expansion-panel-header collapsedHeight="6.2rem" expandedHeight="6.2rem">
          <mat-panel-title>
            <p><span>FLIGHT N°</span> <span>{{ flightDetail.flightNumber}}</span></p>
            <p><span>DATE</span> <span>{{ flightDetail.flightDate}}</span></p>
            <div *ngIf="flightDetail.url != null" class="video-tag" [ngClass]="flightDetail.seen == 0 ? 'unseen-video-tag' : 'seen-video-tag'">
              <img alt="" src="assets/img/safety/safety-video-{{flightDetail.seen == 0 ? 'unseen.svg' : 'seen.svg'}}">&emsp;Video
              <span class="video-status-badge" [ngClass]="flightDetail.seen == 0 ? 'unseen-status' : 'seen-status'">{{ flightDetail.seen == 0 ? 'Pending' : 'Seen'}}</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- content -->
        <div class="flight-data">
          <div class="">
            <p class="label">Departure</p>
            <p class="data">{{ flightDetail.departure}}</p>
            <p class="label">Arrival</p>
            <p class="data">{{ flightDetail.arrived}}</p>
            <p class="label">Date</p>
            <p class="data">{{ flightDetail.flightDate}}</p>
          </div>
          <div class="">
            <p class="label">Flight Number</p>
            <p class="data">{{ flightDetail.airline}} {{ flightDetail.flightNumber}}</p>
            <p class="label">Fleet</p>
            <p class="data">{{ flightDetail.fleet}}</p>
            <p class="label">Tail Number</p>
            <p class="data">{{ flightDetail.plateNumber}}</p>
          </div>
        </div>
        <div class="evph-section">
          <div class="left"><p>Event</p></div><div class="right"><p>Phase</p></div>
          <ng-container *ngFor="let item of flightDetail.eventList" >
            <div class="left-data"><p>{{ item.event}}</p></div><div class="right-data"><p>{{ item.phase}}</p></div>
          </ng-container>

        </div>
        <br>
        <video id="safety-video-{{flightDetail.number}}" *ngIf="flightDetail.url != null" width="320" height="240" controls="true" controlslist="nodownload" playsinline>
          <source src="{{flightDetail.url}}" type="video/mp4">
        </video>
        <br>
      </mat-expansion-panel>

    </mat-accordion>

  </div>
</main>
