import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PilotConstants } from '../commons/pilot-constants';
import { LimopsRequest } from '../domain/dispatch/limops-request';


@Injectable({
  providedIn: 'root'
})
export class LimopsReportService {

  constructor(private httpClient: HttpClient, private constants: PilotConstants) {}
  
  getLimopsReport(limopsRequest :LimopsRequest):Observable<any>{
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.LIMOPS_REPORT}`;
    return this.httpClient.post(URL, limopsRequest);
  }
}
