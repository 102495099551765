export class FeedbackRequest {
  bp: number;
  operator: string;
  flightNumber: number;
  flightDate: string;
  departureIataCode: string;
  score: number;
  section: string;
  reason: string;
  commentary: string;
}
