<div class="container-ack">
  <div class="modal-header-dialog">
    <h2 class="modal-title-dialog" mat-dialog-title>Confirm Acknowledge</h2>
    <button mat-button (click)="closeClick();" class="button-close"><i class="material-icons"> clear </i></button>
  </div>

  <div class="confirm-data">
    <span>You will confirm the loadsheet with the following information:</span>
    <br>
    <div class="confirm-data-values">
      <span>{{this.zfw}} <br></span>
      <span>{{this.tow}} <br></span>
      <span>{{this.law}} <br></span>
      <span>{{this.undld}} <br></span>
    </div>
    <span>Accepted by {{this.user}}</span>
  </div>

  <mat-dialog-content>
    <app-alerts *ngIf="showDisclaimer" class="alert" [text]="disclaimer" [class]="'mx-auto w-responsive'">
    </app-alerts>

    <div class="" [formGroup]="ackForm">
      <textarea class="commentary col-centered p-1" placeholder="Comments here(Optional)" formControlName="comment" maxlength="150">
      </textarea>
    </div>
  </mat-dialog-content>

  <div class="btns-confirm-ack" mat-dialog-actions>
    <ion-button color="success" (click)="performAck(1)" expand="block" fill="outline" size="small">
      CONFIRM
    </ion-button>
    <ion-button color="secondary" (click)="performAck(-1)" expand="block" fill="outline" size="small">
      REJECT
    </ion-button>
  </div>
</div>