
<swiper-container class="mySwiper" pagination="true" pagination-dynamic-bullets="false">
    <ng-container *ngFor="let screen of screensOnBoarding">
        <swiper-slide>
            <div [class]="screen.class">
                <section class="text-ob">
                    <h1 class="title-ob">{{ screen.title }}</h1>
                    <p class="subtitle-ob">{{ screen.subtitle}}</p>
                </section>

                <ng-container *ngIf="screen.screen == 0">
                    <img class="cloud-one" src="assets/img/on-boarding/cloud-one.svg" alt="" srcset="">
                    <img class="cloud-two" src="assets/img/on-boarding/cloud-two.svg" alt="" srcset="">
                </ng-container>

                <ng-container *ngIf="screen.screen != 0">
                    <img class="cloud-three" src="assets/img/on-boarding/cloud-three.svg" alt="" srcset="">
                    <img class="cloud-four" src="assets/img/on-boarding/cloud-four.svg" alt="" srcset="">
                </ng-container>

                <section class="img-ob">
                    <img [src]="'assets/img/on-boarding/' + screen.logo" alt="" srcset="">
                </section>

                <section class="btns-ob">
                    <button class="btn-ob" (click)="screen.screen != 2? saveOnBoarding(): null">
                        {{screen.screen != 2? "Skip": ''}}
                    </button>
                    <button class="btn-ob" (click)="screen.screen != 2? nextScreen(screen.screen + 1) : saveOnBoarding()">
                        {{screen.screen != 2? "Continue": 'Get started'}} 
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="14" viewBox="0 0 34 14" fill="none">
                            <path d="M25.873 4.88422H2.15428C0.969428 4.88422 0 5.84008 0 7.00835C0 8.17662 0.969428 9.13248 2.15428 9.13248H25.873V12.9347C25.873 13.8905 27.0363 14.3578 27.7041 13.6781L33.693 7.75179C34.1023 7.32697 34.1023 6.66849 33.693 6.24366L27.7041 0.317334C27.0363 -0.362388 25.873 0.126161 25.873 1.06078V4.88422Z" fill="white"/>
                        </svg>
                    </button>
                </section>
            </div>
        </swiper-slide>
    </ng-container>
</swiper-container>
