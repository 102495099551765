import { Component, OnInit } from '@angular/core';
import { FaqService } from '../../services/faq.service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  faqRes: Array<any>;
  categories: Array<any>;
  faqLoading: boolean = true;
  value: null

  constructor(
    private faqService: FaqService,
    private _user: UserService
  ) { }

  ngOnInit() {
    let user = this._user.getUserValue;
    this.faqService.getFAQ(user?.profile?.operator).subscribe({
      next: resp => {
        this.faqRes = resp;
      },
      error: err => console.log(err),
      complete: () => this.faqLoading = !this.faqLoading,
    });
  }
}
