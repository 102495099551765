import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-stars-rating',
  templateUrl: './stars-rating.component.html',
  styleUrls: ['./stars-rating.component.css']
})
export class StarsRatingComponent implements OnInit {

  @Output() changeValue = new EventEmitter<number>();

  starRating = [
    { index: 1, name: "star_outline" },
    { index: 2, name: "star_outline" },
    { index: 3, name: "star_outline" },
    { index: 4, name: "star_outline" },
    { index: 5, name: "star_outline" },
  ];
  
  @Input("initValue") initValue = 0;
  @Input("currentValue") currentValue = 0;
  @Input("readOnly") readOnly = false;
  @Input("class") class = "small";

  ngOnInit(): void {
    this.setValue(this.initValue, true);
  }

  setValue(index: number, isInit = false) {
    this.currentValue = index == this.currentValue? index - 1 : index;
    this.starRating = this.starRating.map( s => {
      s.name = (s.index <= this.currentValue)? "star": "star_outline";
      return s;
    });
    !isInit && this.changeValue.emit(this.currentValue);
  }

}
