<main class="pt-5 mt-4">
  <div class="mt-3 container">
    <div [ngClass]="loading ? 'not-scrollable-folder' : 'scrollable-folder'">
      <!--Request-->
      <div class="title-section-otherdispatch row">
        <app-back-home> </app-back-home>
      </div>
      <div class="row light-title">
        <div class="col-12 message-header">
          Check the dispatch of another flight by completing the fields
        </div>
      </div>
      <div *ngIf="formGroup" class="w-100 latam_regular form-find-another-dispatch" [formGroup]="formGroup">
        <div class="col-lg-12 mb-3">
          <div class="card-generic no-elevation p-4 small-screens-alignment text-center">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <mat-form-field class="input-100">
                  <mat-select placeholder="Operator" formControlName="operator" required>
                    <mat-option *ngFor="let owner of ownerList" [value]="owner.name">
                      {{ owner.name }}</mat-option>
                  </mat-select>
                  <mat-error>
                    Operator is
                    <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-12 col-md-6">
                <mat-form-field class="input-100">
                  <input type="text" matInput placeholder="Flight number" maxlength="5" pattern="([0-9]{1,4}[a-z]{0,1})"
                    formControlName="flightNumber" required>
                    <mat-hint>Example: 1 or 500</mat-hint>
                  <mat-error>
                    Format is
                    <strong> max 4 number </strong>(example: 1000)
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <mat-form-field class="input-100">
                  <input type="text" maxlength="3" placeholder="Departure" style="text-transform: uppercase;" matInput
                    [matAutocomplete]="departureAC" formControlName="departure" required>
                  <mat-autocomplete #departureAC="matAutocomplete">
                    <mat-option *ngFor="let option of departureAirports | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-sm-12 col-md-6">
                <mat-form-field class="input-100">
                  <input matInput #picker1 [matDatepicker]="picker" placeholder="Flight date"
                    formControlName="flightDate" [max]="maxDate" (click)="clickPicker()" required>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker touchUi #picker></mat-datepicker>
                  <mat-error>
                    Flight date is
                    <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-auto m-auto">
                  <button color="primary" class="btn-pilot pilot-primary" [disabled]="loading" (click)="searchDispatchFolder()">Search</button>
              </div>
              <br>
              <div class="col-sm-12">
                <app-alerts *ngIf="isInvalidForm" [text]="'All fields are required.'" [class]="'w-100'"
                  [type]="'danger'">
                </app-alerts>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="targetDispatchFolder">

        <dispatch-folder *ngIf="flightIsDefined" [flight]="flightToSearch" (flightWasFound)="flightWasFound($event)"
          [anotherDispatch]="true"></dispatch-folder>
      </div>
    </div>
  </div>
</main>