export enum CaeStatusEnum {
    SUCCESS = "SUCCESS",
    FORBIDDEN = "FORBIDDEN",
    NOT_FOUND = "NOT_FOUND",
    ERROR = "ERROR"
}

export enum LoadSheetStatusEnum {
    SUCCESS = "SUCCESS",
    FORBIDDEN = "FORBIDDEN",
    NOT_FOUND = "NOT_FOUND",
    ERROR = "ERROR"
}