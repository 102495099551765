<main class="indicator_container m5">
    <h5 class="latam_bold t-gray-4">GPWS/EGPWS</h5>
    <div class="body_indicators" *ngIf="showLoading">
        <mat-progress-spinner  style="margin: 0 auto" [color]="'accent'" [mode]="'indeterminate'" [value]="50">
        </mat-progress-spinner>
    </div>
    <div class="body_indicators" *ngIf="!showLoading">        
        <div *ngIf="barChartData.datasets.length>0 && !theresData" class="not-found t-gray-6">
            Results not found for the selected filters
        </div>
        <div class="row indicators_data" *ngIf="true">
            <div class="col-md-13 col-sm-13">
                <canvas baseChart width="{{chartWidth}}" height="400" [data]="barChartData" [options]="barChartOptions" [legend]="barChartLegend" [type]="barChartType"></canvas>
            </div>
        </div>
    </div>
</main>