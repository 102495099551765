import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NewsletterService } from '../../../services/newsletter.service';
import { openBrowser } from 'src/app/core/utils/utils';

@Component({
  selector: 'app-newsletter-modal',
  templateUrl: './newsletter-modal.component.html',
  styleUrls: ['./newsletter-modal.component.css']
})
export class NewsletterModalComponent implements OnInit {
  disabledAcknowdge: boolean;

  constructor(
    private newsletterService: NewsletterService,
    public dialogNewsletterView: MatDialogRef<NewsletterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public newsletter: any,
  ) { }

  ngOnInit() {
    if (this.newsletter.acknowledge) {
      this.disabledAcknowdge = true;
    }
  }

  acknowledge() {
    this.disabledAcknowdge = true;
    this.newsletterService.setAcknowledge(this.newsletter.id).subscribe({
      next: resp => {
        this.disabledAcknowdge = false;
        this.dialogNewsletterView.close(true);
      },
      error: err => {
        console.log(err);
        this.disabledAcknowdge = false;
      }
    });
  }

  closeClick(): void {
    this.dialogNewsletterView.close(false);
  }

  openExternal(externalUrl: string) {
    openBrowser(externalUrl);
  }
}
