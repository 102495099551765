
<mat-tab-group class="customTabs" [selectedIndex]="0" (selectedTabChange)="changeTabInfo($event);">

    <mat-tab label="EVENTS">
        <div class="alert-not-connected">
            <app-alerts *ngIf="!isConnected"
                [text]="'Oops, It seems you don`t have access to internet. Please connect your device to a network'"
                [class]="'w-100'">
            </app-alerts>
        </div>
        <app-safety-list [showLoading]="showLoading" [events]="dataGraphicsIndicators['events']"></app-safety-list>
    </mat-tab>

    <mat-tab label="INDICATORS">
        <main class="latam_regular mt-20">
            <div class="alert-not-connected">
                <app-alerts *ngIf="!isConnected"
                    [text]="'Oops, It seems you don`t have access to internet. Please connect your device to a network'"
                    [class]="'w-100'">
                </app-alerts>
            </div>
            <app-safety-indicators
                [dataGraphicsIndicators]="dataGraphicsIndicators"
                [showLoading]="showLoading"
                [isHiddenAlpha]="isHiddenAlpha"
                [isHiddenStick]="isHiddenStick"
                [isConnected]="isConnected"
            >
            </app-safety-indicators>
        </main>
    </mat-tab>


</mat-tab-group>


