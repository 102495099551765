import { Injectable } from '@angular/core';
import { PilotConstants } from '../commons/pilot-constants';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpParams, HttpClient } from '@angular/common/http';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SafetyEventsService {

  constructor(
    private httpClient: HttpClient, 
    private constants: PilotConstants
  ) {}

  getNotifications(period): Observable<any>{
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.SAFETY}${this.constants.EVENT_NOTIFICATIONS}`;
    const params = new HttpParams()
      .set('flightDateFrom', period.startDate)
      .set('flightDateTo', period.endDate);
    return this.httpClient.get(URL, { params });
  }

  markVideoEventAsSeen(eventNumber: string, flightDate: string, phase: string): Observable<any>{
    const URL = `${environment.apiUrl}${this.constants.BFF_PATH}${this.constants.SAFETY_API}${this.constants.EVENT_NOTIFICATIONS}${this.constants.MARK_SAFETY_VIDEO_AS_SEEN}`;
    const params = new HttpParams()
      .set('eventNumber', eventNumber)
      .set('flightDate', moment(flightDate, "DD-MM-YYYY").format('YYYY-MM-DD'))
      .set('eventPhase', phase);
    return this.httpClient.put(URL, null, { params });
  }
}
