export class FlightId {
    flightNumber: number;
    tail: string;
    operator: string;
    flightDate: any;
    departureIataCode: string;
    arrivalIataCode: string;
    aircraftSubtype: string;
    localFlightDate: any;
    dispatchId: number;
    carrierOperating: string;
    flightDayUtc: string;
    dateOfFlight: string

    constructor(params: {
        flightNumber: number,
        operator: string,
        flightDate: any, 
        departureIataCode: string, 
        aircraftSubtype?: string, 
        arrivalIataCode?: string , 
        tail?: string, 
        localFlightDate?: any, 
        carrierOperating?: string , 
        flightDayUtc?: string,
        dateOfFlight?: string
    }) {
        this.flightNumber = params.flightNumber;
        this.tail = params.tail;
        this.operator = params.operator;
        this.flightDate = params.flightDate;
        this.localFlightDate = params.localFlightDate;
        this.departureIataCode = params.departureIataCode;
        this.aircraftSubtype = params.aircraftSubtype;
        this.arrivalIataCode = params.arrivalIataCode;
        this.carrierOperating = params.carrierOperating
        this.flightDayUtc = params.flightDayUtc
        this.dateOfFlight = params.dateOfFlight
    }

    public hasBasicInfo(): boolean{
        return this.flightNumber != null && this.operator != null && this.flightDate != null && this.departureIataCode != null;
    }

    public empty(){
        this.flightNumber = null;
        this.tail = null;
        this.flightDate = null;
        this.departureIataCode = null;
        this.arrivalIataCode = null;
        this.aircraftSubtype = null;
    }
}